import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-table-loading-more',
  templateUrl: './table-loading-more.component.html',
  styleUrls: ['./table-loading-more.component.scss'],
})
export class TableLoadingMoreComponent {
  @Input() loadingMore = false;
  @Input() isLastPage = false;
}
