import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotesApi } from '@app/apis/notes.api';
import { OpportunityApi } from '@app/apis/opportunity.api';
import { ACTION_EDIT, CATEGORY_DETAIL_PAGE } from '@app/constants/matomo';
import { NoteType } from '@app/constants/note';
import { YYYY_MM_DD_PATTERN } from '@app/constants/regexp-pattern';
import { OpportunityDetail, OpportunityLegalDocumentsInfo } from '@app/interfaces/OpportunityDetail';
import { AclService } from '@app/services/acl.service';
import { isEmpty } from 'lodash';
import { MatomoTracker } from 'ngx-matomo';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { formatterMoney, parserMoney } from '@utils/money';
import { CURRENCYS } from '@app/constants/currency';
import { DocumentTypeLabel } from '@app/constants/documentType';
import { AutoUnsubscribe } from '@app/decorators/autoUnsubscribe';
import { SpinnerService } from '@services/spinner.service';
import { UpdateOpportunityStatusService } from '@services/state-service/update-opportunity-status.service';
import { OpportunityLegalDocument } from '@interfaces/legalDocument';

@AutoUnsubscribe()
@Component({
  selector: 'app-legal-documents',
  templateUrl: './legal-documents.component.html',
  styleUrls: ['./legal-documents.component.scss'],
})
export class LegalDocumentsComponent implements OnInit {
  @Input() opportunityDetail: OpportunityDetail;
  showEditLegalDocumentModal = false;
  legalDocumentsInfo: OpportunityLegalDocumentsInfo;
  legalDocumentsColumnsWidth = ['208px', '180px', '148px', '120px', '108px', '90px', '86px', '66px', '124px', '116px', '126px'];
  disableAddItem: boolean;
  readonly DocumentTypeLabel = DocumentTypeLabel;
  readonly CURRENCYS = CURRENCYS;
  formatterMoney = formatterMoney;
  parserMoney = parserMoney;
  checked = true;
  currentLegalDocumentId: number = null;
  private opportunityId: number;
  private unsubscribe = new Subject();

  constructor(
    public aclService: AclService,
    private matomoTracker: MatomoTracker,
    private notesApi: NotesApi,
    private opportunityApi: OpportunityApi,
    private route: ActivatedRoute,
    private spinnerService: SpinnerService,
    private updateOpportunityStatusService: UpdateOpportunityStatusService,
  ) {}

  ngOnInit(): void {
    this.opportunityId = this.route.snapshot.params.id;
    this.getLegalDocumentsInfo();
    this.setDisabledNewItem();
  }

  clickEditBtn(legalDocument: OpportunityLegalDocument) {
    this.showEditLegalDocumentModal = true;
    this.currentLegalDocumentId = legalDocument.id;
  }

  private getLegalDocumentsInfo(): void {
    this.opportunityApi
      .getOpportunityLegalDocumentsInfo(this.opportunityId)
      .pipe(this.spinnerService.loading())
      .subscribe((legalDocumentsInfo) => {
        this.legalDocumentsInfo = legalDocumentsInfo;
        const { amountOfMSA, amountOfPO, amountOfServiceContract, amountOfSOW } = legalDocumentsInfo.legalDocumentTotalAmount || {};
        this.legalDocumentsInfo.legalDocumentTotalAmount = {
          ...legalDocumentsInfo.legalDocumentTotalAmount,
          amountOfMSA: amountOfMSA === 0 ? null : amountOfMSA,
          amountOfPO: amountOfPO === 0 ? null : amountOfPO,
          amountOfServiceContract: amountOfServiceContract === 0 ? null : amountOfServiceContract,
          amountOfSOW: amountOfSOW === 0 ? null : amountOfSOW,
        };
        this.legalDocumentsInfo.opportunityLegalDocuments = legalDocumentsInfo.opportunityLegalDocuments?.map((item) => ({
          ...item,
          documentFormat: item.documentFormat ? (item.documentFormat as any).split(',') : null,
        }));
      });
  }

  setDisabledNewItem() {
    const { firstBillableTimecardDate, startDate } = this.opportunityDetail;
    this.disableAddItem = !(YYYY_MM_DD_PATTERN.exec(firstBillableTimecardDate) || YYYY_MM_DD_PATTERN.exec(startDate));
  }

  sortByDocumentType(a: OpportunityLegalDocument, b: OpportunityLegalDocument) {
    return a.documentType.localeCompare(b.documentType);
  }

  sortByDate(a: OpportunityLegalDocument, b: OpportunityLegalDocument) {
    return new Date(a.contractReceivedDate).getTime() - new Date(b.contractReceivedDate).getTime();
  }

  editContractNote(noteContent: string) {
    this.matomoTracker.trackEvent(CATEGORY_DETAIL_PAGE, ACTION_EDIT, `Edit Contract Note for ${this.opportunityDetail.name}`);

    if (isEmpty(noteContent)) {
      this.notesApi.deleteNotes(this.legalDocumentsInfo.contractNote.id).pipe(takeUntil(this.unsubscribe)).subscribe();
      this.legalDocumentsInfo.contractNote = null;
      return;
    }
    if (isEmpty(this.legalDocumentsInfo.contractNote)) {
      this.notesApi
        .createNotes(this.opportunityId, noteContent, NoteType.CONTRACT)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((note) => {
          this.legalDocumentsInfo.contractNote = note;
        });
    } else {
      this.notesApi.updateNotes(this.legalDocumentsInfo.contractNote.id, noteContent).pipe(takeUntil(this.unsubscribe)).subscribe();
    }
  }

  closeDialog(isSave: boolean) {
    if (isSave) {
      this.getLegalDocumentsInfo();
      this.updateOpportunityStatusService.update();
    }
    this.showEditLegalDocumentModal = false;
    this.currentLegalDocumentId = null;
  }
}
