import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { OpportunityApi } from '@app/apis/opportunity.api';
import { ACTION_SAVE, CATEGORY_DETAIL_PAGE } from '@app/constants/matomo';
import { GOOGLE_DRIVE_LINK_PATTERN } from '@app/constants/regexp-pattern';
import { AccountOpportunityInfo, OpportunityDetail } from '@app/interfaces/OpportunityDetail';
import { AclService } from '@app/services/acl.service';
import {
  cloneDeep,
  filter,
  find,
  findIndex,
  flatten,
  forEach,
  get,
  groupBy,
  head,
  includes,
  isEmpty,
  isNil,
  keys,
  last,
  map,
  orderBy,
  reduce,
  size,
  sortBy,
} from 'lodash';
import { MatomoTracker } from 'ngx-matomo';
import { finalize } from 'rxjs/operators';
import { FormArray, FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { formatterMoney, formatterMoneyWithCurrency, preventTypingWhenExceed } from '@utils/money';
import { CURRENCYS, OTHER_CURRENCY } from '@app/constants/currency';
import { DOCUMENT_TYPE_OPTIONS } from '@app/constants/documentType';
import { formatDate } from '@constants/dateFormat';
import { LegalDocumentApi } from '@apis/legal-document.api';
import { SpinnerService } from '@services/spinner.service';
import { ToastMessageService } from '@services/toast-message.service';
import { CreateLegalDocumentRequest } from '@interfaces/legalDocument';
import { Router } from '@angular/router';
import { isSameLegalDocumentInfo, toDate, trimOrReturnNull } from '@utils/utils';
import { scrollToFirstInvalid } from '@utils/dom';

enum LegalDocumentDialogStatus {
  ADD_MODE, // 表示弹窗当前处于 opportunity 页面的添加状态
  EDIT_MODE, // 表示弹窗当前处于 opportunity 页面的编辑状态
  ADD_MODE_WITHOUT_OPPORTUNITY, // 表示弹窗当前处于 legal document page 的添加状态
  EDIT_MODE_WITHOUT_OPPORTUNITY, // 表示弹窗当前处于 legal document page 的编辑状态
}

const withoutOpportunityDialogStatus = [
  LegalDocumentDialogStatus.ADD_MODE_WITHOUT_OPPORTUNITY,
  LegalDocumentDialogStatus.EDIT_MODE_WITHOUT_OPPORTUNITY,
];

const opportunityDialogStatus = [LegalDocumentDialogStatus.ADD_MODE, LegalDocumentDialogStatus.EDIT_MODE];

const editDialogStatus = [LegalDocumentDialogStatus.EDIT_MODE, LegalDocumentDialogStatus.EDIT_MODE_WITHOUT_OPPORTUNITY];

interface CalculateAmount {
  totalAllocatedAmount: number;
  totalAllocatedAmountPercentage: number;
  remainingAmount: number;
  remainingAmountPercentage: number;
  isTotalAllocatedAmountExceed: boolean;
}

interface OpportunityRow {
  opportunityId: number;
  opportunityName: string;
  allocateDateRange: string[];
  allocatedAmount: number;
  firstBillableTimecardDate: string;
  isProjectActive: boolean;
  opportunityDetailLink: string;
}

@Component({
  selector: 'app-add-legal-document-dialog',
  templateUrl: './add-legal-document-dialog.component.html',
  styleUrls: ['./add-legal-document-dialog.component.scss'],
})
export class AddLegalDocumentDialogComponent implements OnInit {
  @Input() defaultOpportunity: OpportunityDetail = {} as OpportunityDetail;
  @Input() legalDocumentId: number = null;
  @ViewChild('formElement') formElement;
  @Output() closeDialog = new EventEmitter<boolean>();
  isEdit = false;
  allocationColumnsWidth = ['348px', '220px', '140px', '46px'];
  isSaving = false;
  confirmPopVisible = false;
  readonly DOCUMENT_TYPE_OPTIONS = DOCUMENT_TYPE_OPTIONS;
  readonly CURRENCYS = CURRENCYS;
  documentForm!: UntypedFormGroup;
  formatterMoney = formatterMoney;
  LegalDocumentDialogStatus = LegalDocumentDialogStatus;
  formatterMoneyWithCurrency = (value) => {
    return formatterMoneyWithCurrency(value, OTHER_CURRENCY[this.documentForm.controls['currency'].value]);
  };
  preventTypingWhenExceed = preventTypingWhenExceed;
  checked = true;
  calculateData: CalculateAmount = {
    totalAllocatedAmount: 0,
    totalAllocatedAmountPercentage: 0,
    isTotalAllocatedAmountExceed: false,
    remainingAmount: 0,
    remainingAmountPercentage: 0,
  } as CalculateAmount;
  allocationTableData = [];
  private opportunityId: number;
  isAllocationDropdownOpen = false;
  accountList: string[] = [];
  originAccountOpportunityOptionList = [];
  dialogStatus: LegalDocumentDialogStatus = LegalDocumentDialogStatus.ADD_MODE;
  allReferenceNo: string[];
  editLegalDocumentReferenceNo: string;
  disableSave: boolean;
  documentFormatOptions = [
    { label: 'Paper', value: 'Paper', checked: false },
    { label: 'Digital', value: 'Digital', checked: false },
    { label: 'Other', value: 'Other', checked: false },
  ];
  currentUrlArray = this.route.url.split('/');
  path: string;
  dsoOppPath: string;

  constructor(
    public aclService: AclService,
    private matomoTracker: MatomoTracker,
    private opportunityApi: OpportunityApi,
    private fb: UntypedFormBuilder,
    private legalDocumentApi: LegalDocumentApi,
    private spinnerService: SpinnerService,
    private toastMessageService: ToastMessageService,
    private route: Router,
  ) {}

  ngOnInit(): void {
    this.opportunityId = get(this.defaultOpportunity, 'id', null);
    this.setDialogCurrentModeAndOppPath();
    this.isEdit = editDialogStatus.includes(this.dialogStatus);
    this.disableSave = this.isEdit;
    this.initNewDocument();
    this.getAllocationOptionInfo();
    this.addAmountChangeListener();
  }

  setDialogCurrentModeAndOppPath() {
    if (isNil(this.opportunityId)) {
      this.dialogStatus = isNil(this.legalDocumentId)
        ? LegalDocumentDialogStatus.ADD_MODE_WITHOUT_OPPORTUNITY
        : LegalDocumentDialogStatus.EDIT_MODE_WITHOUT_OPPORTUNITY;
      this.path = last(this.currentUrlArray);
    } else {
      this.dialogStatus = isNil(this.legalDocumentId) ? LegalDocumentDialogStatus.ADD_MODE : LegalDocumentDialogStatus.EDIT_MODE;
      this.path = 'legal-documents';
    }
    this.dsoOppPath = `/dso/${this.path}/opportunity`;
  }

  fillInFormData() {
    this.legalDocumentApi
      .getLegalDocumentDetail(this.legalDocumentId)
      .pipe(this.spinnerService.loading())
      .subscribe((legalDocumentInfo) => {
        this.editLegalDocumentReferenceNo = legalDocumentInfo.referenceNumber;
        const opportunities = cloneDeep(legalDocumentInfo.allocatedOpportunities);
        const index = findIndex(opportunities, (item) => item.opportunityId === this.opportunityId);
        if (index !== -1) {
          const defaultOpportunity = opportunities.splice(index, 1)[0];
          this.setTableRow([defaultOpportunity, ...orderBy(opportunities, ['projectCode', 'opportunityName'], 'asc')]);
        } else {
          this.setTableRow(orderBy(opportunities, ['projectCode', 'opportunityName'], 'asc'));
        }

        if (this.dialogStatus === LegalDocumentDialogStatus.EDIT_MODE_WITHOUT_OPPORTUNITY) {
          this.filterOpportunityOptions();
        }

        this.documentForm.controls['referenceNumber'].setValue(legalDocumentInfo.referenceNumber);
        this.allocationTableData = this.documentForm.controls['tableRowArray'].value;
        this.documentForm.controls['referenceNumber'].setValue(legalDocumentInfo.referenceNumber);
        this.documentForm.controls['documentName'].setValue(legalDocumentInfo.documentName);
        this.documentForm.controls['signedLegalEntity'].setValue(legalDocumentInfo.signedLegalEntity);
        this.documentForm.controls['documentType'].setValue(legalDocumentInfo.documentType);
        this.documentForm.controls['contractReceivedDate'].setValue(toDate(legalDocumentInfo.contractReceivedDate));
        const documentDateRange = legalDocumentInfo.startDate
          ? [toDate(legalDocumentInfo.startDate), toDate(legalDocumentInfo.endDate)]
          : [];
        this.documentForm.controls['documentDateRange'].setValue(documentDateRange);
        this.documentForm.controls['currency'].setValue(legalDocumentInfo.currency);
        this.documentForm.controls['documentAmount'].setValue(legalDocumentInfo.documentAmount);
        this.documentForm.controls['googleDriveLink'].setValue(legalDocumentInfo.googleDriveLink);
        this.documentForm.controls['documentFormat'].setValue(
          map(this.documentFormatOptions, (option) => ({
            ...option,
            checked: includes(legalDocumentInfo.documentFormat, option.value),
          })),
        );
        this.documentForm.controls['note'].setValue(legalDocumentInfo.note);
        this.setCheckBoxStatus();
        const originalLegalDocumentInfo = cloneDeep(legalDocumentInfo);
        this.documentFormListener(originalLegalDocumentInfo);
      });
  }

  documentFormListener(originalLegalDocumentInfo) {
    this.documentForm.valueChanges.subscribe((item) => {
      const changedLegalDocumentInfo = this.convertFormValueToLegalDocument(item);
      this.disableSave = isSameLegalDocumentInfo(originalLegalDocumentInfo, changedLegalDocumentInfo);
    });
  }

  resetOpportunityOptions() {
    (this.documentForm.get('opportunities') as FormArray).clear();
    this.setFormOpportunities(this.originAccountOpportunityOptionList);
  }

  filterOpportunityOptions(id?: number) {
    const opportunityId = id ? id : get(head(this.documentForm.value.tableRowArray), 'opportunityId');
    const currentOption = find(this.originAccountOpportunityOptionList, (item) => item.opportunityId === opportunityId) || {};
    const parentAccountId = get(currentOption, 'parentAccountId');
    const filterOptions = isNil(parentAccountId)
      ? filter(this.originAccountOpportunityOptionList, (item) => item.accountId === currentOption.accountId)
      : filter(this.originAccountOpportunityOptionList, (item) => item.parentAccountId === parentAccountId);
    (this.documentForm.get('opportunities') as FormArray).clear();
    this.setFormOpportunities(filterOptions);
  }

  addAmountChangeListener() {
    const calculateValue = (documentAmount: number, tableArrayData: OpportunityRow[]) => {
      const totalAllocatedAmount = reduce(tableArrayData, (result, row) => (row.allocatedAmount || 0) + result, 0);
      let remainingAmount = documentAmount - totalAllocatedAmount;
      remainingAmount = remainingAmount > 0 ? remainingAmount : 0;
      this.calculateData = {
        totalAllocatedAmount: totalAllocatedAmount,
        totalAllocatedAmountPercentage: documentAmount === 0 ? 0 : totalAllocatedAmount / documentAmount,
        isTotalAllocatedAmountExceed: totalAllocatedAmount - documentAmount > 0,
        remainingAmount: remainingAmount,
        remainingAmountPercentage: documentAmount === 0 ? 0 : remainingAmount / documentAmount,
      };
    };
    this.documentForm.get('tableRowArray').valueChanges.subscribe((value: OpportunityRow[]) => {
      calculateValue(this.documentForm.value.documentAmount || 0, value);
    });
    this.documentForm.get('documentAmount').valueChanges.subscribe((value: number) => {
      calculateValue(value || 0, this.documentForm.value.tableRowArray);
    });
    this.documentForm.get('currency').valueChanges.subscribe(() => {
      forEach((this.documentForm.controls['tableRowArray'] as FormArray).controls, (control: FormGroup) => {
        const allocatedAmount = control.value.allocatedAmount;
        control.get('allocatedAmount').setValue(allocatedAmount);
      });
    });

    // 在legal document page 添加legal document时，allocated option list 会随着第一次选择的 opportunity,
    // 筛选出当前opportunity 所属parentAccount 下所有的opportunity
    if (isNil(this.opportunityId)) {
      this.documentForm.get('allocationSelectedValue').valueChanges.subscribe((value: number[]) => {
        if (this.documentForm.value.allocationSelectedValue.length === 0 && value.length === 1) {
          const opportunityId: number = head(value);
          this.filterOpportunityOptions(opportunityId);
        }

        if (value.length === 0 && this.documentForm.value.tableRowArray.length === 0) {
          this.resetOpportunityOptions();
        }
      });
    }
  }

  initNewDocument(): void {
    const defaultOpportunity: FormGroup = this.fb.group({
      opportunityId: this.opportunityId,
      opportunityName: `[${this.defaultOpportunity.projectCode}] ${this.defaultOpportunity.name}`,
      opportunityDetailLink: `${this.dsoOppPath}/${this.opportunityId}`,
      allocateDateRange: [[toDate(this.defaultOpportunity.startDate), toDate(this.defaultOpportunity.endDate)], [Validators.required]],
      allocatedAmount: null,
      firstBillableTimecardDate: this.defaultOpportunity.firstBillableTimecardDate,
      isProjectActive: this.defaultOpportunity.projectActive,
    });

    this.documentForm = this.fb.group({
      signedLegalEntity: [null, [Validators.required, Validators.maxLength(150)]],
      documentType: [null, [Validators.required, Validators.maxLength(150)]],
      referenceNumber: [null, [Validators.maxLength(80), this.referenceNoValidator]],
      documentName: [null, [Validators.maxLength(150)]],
      contractReceivedDate: [null],
      documentDateRange: [[]],
      currency: ['CNY'],
      documentAmount: [null, [Validators.max(1.0e9)]],
      allocationSelectedValue: [[]],
      googleDriveLink: [null, [Validators.pattern(GOOGLE_DRIVE_LINK_PATTERN)]],
      documentFormat: [this.documentFormatOptions],
      note: [null, [Validators.maxLength(255)]],
      tableRowArray: this.fb.array(this.isEdit || isEmpty(this.defaultOpportunity) ? [] : [defaultOpportunity], Validators.required),
      opportunities: this.fb.array([]),
    });
    this.allocationTableData = this.isEdit || isEmpty(this.defaultOpportunity) ? [] : [defaultOpportunity.value];
    opportunityDialogStatus.includes(this.dialogStatus) && this.setAllReferenceNoAndValidate(this.opportunityId);
  }

  referenceNoValidator = (referenceNoControl: UntypedFormControl): { [s: string]: boolean } => {
    if (includes(this.allReferenceNo, referenceNoControl.value)) {
      return { error: true };
    }
    return {};
  };

  setFormOpportunities(data) {
    const accountOpportunities = groupBy(data, (item) => `[${item.accountCode}] ${item.accountName}`);
    this.accountList = keys(accountOpportunities).sort();
    forEach(this.accountList, (key) => {
      const formArray: FormArray = this.fb.array([]);
      const opportunityList = sortBy(accountOpportunities[key], ['projectCode', 'opportunityName']);
      forEach(opportunityList, (item) => {
        formArray.push(this.fb.group({ ...item, disabled: false, checked: false }));
      });

      (this.documentForm.get('opportunities') as FormArray).push(formArray);
    });
    this.setCheckBoxStatus();
  }

  private getAllocationOptionInfo() {
    this.opportunityApi
      .getAllocationOptionInfo(this.opportunityId)
      .pipe(this.spinnerService.loading())
      .subscribe((data: AccountOpportunityInfo[]) => {
        this.originAccountOpportunityOptionList = data;
        this.setFormOpportunities(data);
        if (this.isEdit) {
          this.fillInFormData();
        }
      });
  }

  submitForm(): void {
    if (this.documentForm.valid && !this.calculateData.isTotalAllocatedAmountExceed) {
      this.confirmPopVisible = true;
    } else {
      Object.values(this.documentForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      scrollToFirstInvalid(this.formElement);
    }
  }

  convertFormValueToLegalDocument(formValue): CreateLegalDocumentRequest {
    const legalDocument: CreateLegalDocumentRequest = {
      id: this.legalDocumentId,
      signedLegalEntity: trimOrReturnNull(formValue.signedLegalEntity),
      documentType: trimOrReturnNull(formValue.documentType),
      currency: formValue.currency,
      documentAmount: formValue.documentAmount === '' ? null : formValue.documentAmount,
      referenceNumber: trimOrReturnNull(formValue.referenceNumber),
      documentName: trimOrReturnNull(formValue.documentName),
      contractReceivedDate: formValue.contractReceivedDate ? formatDate(formValue.contractReceivedDate) : null,
      startDate: formValue.documentDateRange[0] ? formatDate(formValue.documentDateRange[0]) : null,
      endDate: formValue.documentDateRange[1] ? formatDate(formValue.documentDateRange[1]) : null,
      googleDriveLink: trimOrReturnNull(formValue.googleDriveLink),
      note: trimOrReturnNull(formValue.note),
      documentFormat: map(
        filter(formValue.documentFormat, (item) => item.checked),
        'value',
      ).join(','),
      allocatedOpportunities: map(formValue.tableRowArray, (item) => ({
        opportunityId: item.opportunityId,
        allocatedAmount: item.allocatedAmount === '' ? null : item.allocatedAmount,
        allocateFrom: formatDate(item.allocateDateRange[0]),
        allocateTo: formatDate(item.allocateDateRange[1]),
      })),
    };

    return legalDocument;
  }

  saveLegalDocument(): void {
    this.matomoTracker.trackEvent(CATEGORY_DETAIL_PAGE, ACTION_SAVE, `Create new legal document`);
    this.confirmPopVisible = false;
    this.isSaving = true;

    const legalDocument = this.convertFormValueToLegalDocument(this.documentForm.value);

    this.legalDocumentApi
      .createLegalDocument(legalDocument)
      .pipe(
        this.spinnerService.loading(),
        finalize(() => {
          this.isSaving = false;
        }),
      )
      .subscribe(() => {
        this.closeDialog.emit(true);
        this.toastMessageService.success('Saved successfully');
      });
  }

  changeSelectedValue(selectedValues, isCancelChoose = false): void {
    forEach((this.documentForm.controls['opportunities'] as FormArray).controls, (formArray: FormArray) => {
      forEach(formArray.controls, (opportunityFormGroup: FormGroup) => {
        const values = opportunityFormGroup.value;
        if (!values.disabled) {
          opportunityFormGroup.controls['checked'].setValue(isCancelChoose ? false : selectedValues.includes(values.opportunityId));
        }
      });
    });
  }

  cancelSelect(): void {
    this.changeSelectedValue(null, true);
    this.isAllocationDropdownOpen = false;
    this.documentForm.controls['allocationSelectedValue'].setValue([]);

    const tableRowArray = this.documentForm.controls['tableRowArray'].value;

    if (withoutOpportunityDialogStatus.includes(this.dialogStatus) && size(tableRowArray) === 0) {
      this.resetOpportunityOptions();
    }
  }

  confirmAllocatedOpportunities(): void {
    const opportunities: AccountOpportunityInfo[] = flatten(this.documentForm.value.opportunities);
    const checkedOpportunity = filter(opportunities, (item) => item.checked && !item.disabled);
    this.documentForm.controls['allocationSelectedValue'].setValue([]);
    this.setTableRow(checkedOpportunity);
    this.isAllocationDropdownOpen = false;
    if (withoutOpportunityDialogStatus.includes(this.dialogStatus)) {
      this.filterOpportunityOptions();
    }
    this.setCheckBoxStatus();
  }

  setTableRow(opportunities): void {
    if (this.documentForm.controls['tableRowArray'].value.length === 0) {
      this.setAllReferenceNoAndValidate(opportunities[0].opportunityId);
    }

    forEach(opportunities, (item) => {
      const opportunityRow: FormGroup = this.fb.group({
        opportunityId: item.opportunityId,
        opportunityName: `[${item.projectCode}] ${item.opportunityName}`,
        opportunityDetailLink: `${this.dsoOppPath}/${item.opportunityId}`,
        allocateDateRange: [
          [toDate(item.startDate || item.allocatedStartDate), toDate(item.endDate || item.allocatedEndDate)],
          [Validators.required],
        ],
        allocatedAmount: item.allocatedAmount,
        firstBillableTimecardDate: item.firstBillableTimecardDate,
        isProjectActive: item.isProjectActive,
      });
      (this.documentForm.controls['tableRowArray'] as FormArray).push(opportunityRow);
    });

    this.documentForm.controls['tableRowArray'].markAsDirty();
    this.allocationTableData = this.documentForm.controls['tableRowArray'].value;
  }

  setAllReferenceNoAndValidate(opportunityId: number): void {
    this.legalDocumentApi.getAllRelatedReferenceNo(opportunityId).subscribe((data: string[]) => {
      this.allReferenceNo = data.filter((referenceNo) => referenceNo != this.editLegalDocumentReferenceNo);
      this.documentForm.controls['referenceNumber'].updateValueAndValidity();
    });
  }

  setCheckBoxStatus(): void {
    const allocationTableData = this.documentForm.controls['tableRowArray'].value;
    forEach((this.documentForm.controls['opportunities'] as FormArray).controls, (formArray: FormArray) => {
      forEach(formArray.controls, (opportunityFormGroup: FormGroup) => {
        const opportunityId = opportunityFormGroup.value.opportunityId;
        opportunityFormGroup.controls['disabled'].setValue(map(allocationTableData, 'opportunityId').includes(opportunityId));
        opportunityFormGroup.controls['checked'].setValue(map(allocationTableData, 'opportunityId').includes(opportunityId));
      });
    });
  }

  deleteAllocationItem(opportunityId: number): void {
    const tableRowArray = this.documentForm.controls['tableRowArray'].value;
    forEach(tableRowArray, (row, index: number) => {
      if (row.opportunityId === opportunityId) {
        (this.documentForm.controls['tableRowArray'] as FormArray).removeAt(index);
      }
    });
    this.allocationTableData = this.documentForm.controls['tableRowArray'].value;
    if (this.allocationTableData.length === 0) {
      this.allReferenceNo = [];
      this.documentForm.controls['referenceNumber'].updateValueAndValidity();
    }
    if (withoutOpportunityDialogStatus.includes(this.dialogStatus) && this.allocationTableData.length === 0) {
      this.resetOpportunityOptions();
      return;
    }
    this.setCheckBoxStatus();
  }

  cancelLegalDocument(): void {
    this.confirmPopVisible = false;
  }

  deleteLegalDocument(): void {
    this.legalDocumentApi.deleteLegalDocument(this.legalDocumentId).subscribe(() => {
      this.closeDialog.emit(true);
      this.toastMessageService.success('Deleted successfully');
    });
  }
}
