<ng-container>
  <nz-select
    nzSize="small"
    [(ngModel)]="selectedOption"
    (ngModelChange)="onSelectFilterCondition()"
    [nzSuffixIcon]="selectIcon"
    [nzOptionHeightPx]="24"
    class="opportunity-filter-select"
  >
    <nz-option *ngFor="let filterItem of opportunityFilterOptions" [nzLabel]="filterItem.label" [nzValue]="filterItem.value"></nz-option>
  </nz-select>
  <ng-template #selectIcon>
    <i class="select-arrow" nz-icon nzType="svg:arrow" alt="arrow"></i>
  </ng-template>
</ng-container>
