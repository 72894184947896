export const HIGH_RISK_RISK_LEVEL_MAP = {
  HIGH_RISK: 'High Risk',
  MEDIUM_RISK: 'Medium Risk',
  LOW_RISK: 'Low Risk',
};

export const HIGH_RISK_RISK_LEVEL_OPTION_LIST = [
  { label: 'High Risk', value: 'HIGH_RISK' },
  { label: 'Medium Risk', value: 'MEDIUM_RISK' },
  { label: 'Low Risk', value: 'LOW_RISK' },
];
