export const COUNTDOWN_L0_MILESTONE = -15;
export const COUNTDOWN_L1_MILESTONE = 0;
export const COUNTDOWN_L2_MILESTONE = 30;
export const COUNTDOWN_L3_MILESTONE = 60;
export const MILESTONE_FILTER_RANGE_LIST = [
  {
    from: COUNTDOWN_L0_MILESTONE,
    to: COUNTDOWN_L1_MILESTONE,
  },
  { from: COUNTDOWN_L1_MILESTONE, to: COUNTDOWN_L2_MILESTONE },
  {
    from: COUNTDOWN_L2_MILESTONE,
    to: COUNTDOWN_L3_MILESTONE,
  },
  { from: COUNTDOWN_L3_MILESTONE },
];

export const COUNTDOWN_L0_INVOICE = -1;
export const COUNTDOWN_L1_INVOICE = 30;
export const COUNTDOWN_L2_INVOICE = 55;
export const COUNTDOWN_L3_INVOICE = 90;
export const INVOICE_FILTER_RANGE_LIST = [
  {
    from: COUNTDOWN_L0_INVOICE,
    to: COUNTDOWN_L1_INVOICE,
  },
  { from: COUNTDOWN_L1_INVOICE, to: COUNTDOWN_L2_INVOICE },
  {
    from: COUNTDOWN_L2_INVOICE,
    to: COUNTDOWN_L3_INVOICE,
  },
  { from: COUNTDOWN_L3_INVOICE },
];
export const COUNTDOWN_TYPE_INVOICE = 'invoice';
