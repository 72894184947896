<app-right-drawer [isDataTipsVisible]="isDataTipsVisible" (closeTips)="closeTips()">
  <div class="info-area">
    <div class="info-world">As of Date</div>
    <div>
      <span class="bold">1. In Opportunity DSO block:</span>
      It indicates that the accumulated AR (Account Receivable) and accumulated WIP (Work in Progress) used to calculate DSO are all data
      added up before the date. It applies to
      <span class="bold">Current DSO, Billed DSO, Unbilled DSO, AR (in the Current DSO tooltip), WIP (in the Current DSO tooltip)</span>
    </div>
    <div class="top24">
      <span class="bold">2. In the upper left corner of the opportunity detail page:</span>
      It indicates the latest updated date of the data in opportunity detail page. It applies to data in
      <span class="bold">Record of AR, WIP in opportunity DSO block, billing info, milestone info, invoice info blocks</span>
    </div>
  </div>
  <div class="info-area">
    <div class="info-world">Deactive Opportunity</div>
    <p>1. Deactivate opportunity means an opportunity which meets all conditions:</p>
    <ul class="deactive-info">
      <li>Project active status is false</li>
      <li>AR (Accumulated to the current date) equals 0</li>
      <li>WIP (Accumulated to the current date) equals 0</li>
    </ul>
    <p>2. Opportunity DSO history will freeze at the latest as of date before the deactivate date</p>
    <p>3. Other data in the opportunity detail page will continue updating according to the data source</p>
  </div>
  <div class="info-area">
    <div class="info-world">AR, WIP, Recognized Revenue</div>
    <ul class="invoice-info top16">
      <li class="bold">
        <div></div>
        <div>Duration</div>
        <div>Update Frequency</div>
        <div>Currency</div>
        <div>Calculation Logic</div>
      </li>
      <li>
        <div class="ita">
          <span class="bold">AR</span>
          in Record (exclude tax)
        </div>
        <div>Accumulated to the current date</div>
        <div>Monthly</div>
        <div>Original Currency</div>
        <div>Accumulated invoice amount (exclude Tax) - Accumulated payment amount (exclude Tax)</div>
      </li>
      <li>
        <div class="ita">
          <span class="bold">AR</span>
          in Current DSO tooltip (exclude tax)
        </div>
        <div>Accumulated to the as of date in Opportunity DSO block</div>
        <div>Monthly</div>
        <div>Original Currency</div>
        <div>Same as above</div>
      </li>
      <li>
        <div class="ita">
          <span class="bold">WIP</span>
          in Record (exclude tax)
        </div>
        <div>Accumulated to the current date</div>
        <div>Monthly</div>
        <div>Original Currency</div>
        <div>Accumulated Recognized Revenue- Accumulated invoice amount (exclude Tax)</div>
      </li>
      <li>
        <div class="ita">
          <span class="bold">WIP</span>
          in Current DSO tooltip (exclude tax)
        </div>
        <div>Accumulated to the as of date in Opportunity DSO block</div>
        <div>Monthly</div>
        <div>Original Currency</div>
        <div>Same as above</div>
      </li>
      <li>
        <div><p class="bold ita">Recognized Revenue</p></div>
        <div>Accumulated to the as of date in Opportunity DSO block</div>
        <div>Monthly</div>
        <div>Original Currency</div>
        <div>Accumulated monthly recognized revenue of the opportunity until as of date in Opportunity DSO block</div>
      </li>
    </ul>
  </div>
  <div class="info-area">
    <div class="info-world">DSO/Billed DSO/Unbilled DSO</div>
    <div class="top24">
      <p class="bold">1. Update Frequency</p>
      <span>Monthly, normally updated in the middle of the month.</span>
    </div>
    <div class="top24">
      <p class="bold">2. Opportunity DSO Calculation Logic</p>
    </div>
    <div class="top24">
      <p class="bold tab12">(1) DSO is calculated by countback method</p>
      <span>
        The DSO value is the total number of days recognized revenue that can be deducted from the outstanding total Current DSO AR+WIP
        (Accumulated until as of date) without leaving a remainder.
      </span>
    </div>
    <div class="bold top24 tab12">(2) Billed DSO = DSO*AR/(AR+WIP)</div>
    <div class="top24">
      <p class="bold top24 tab12">(3) Unbilled DSO = DSO - Billed DSO</p>
      <div class="ita unbilled-info">
        <p>* Opportunity DSO/Billed DSO/Unbilled DSO will not be calculated when either of the following conditions have been met:</p>
        <p>
          (1) Look back upon 24 months, all monthly recognized revenue is 0 or Accumulated AR+WIP is not positive. (Opportunity which meets
          this condition means that this opportunity has received the payment before it starts to work. Unnecessary to show its DSO)
        </p>
        <p>
          (2) Accumulated AR+WIP is larger than the sum up of all monthly recognized revenue or Accumulated AR is larger than the sum up of
          all monthly recognized revenue. (Opportunity which meets this condition means that the data is abnormal. Unnecessary to show its
          DSO)
        </p>
        <p>
          (3) Accumulated AR+WIP is equal to or less than 10 (Opportunity which meets this condition means that this opportunity has a small
          balance of AR/WIP.Unnecessary to show its DSO)
        </p>
      </div>
    </div>
    <div>
      <p class="bold top24">3. Highlight DSO larger than these benchmarks:</p>
      <p>DSO equals to or more than 150 days</p>
      <p>Billed DSO equals to or more than 55 days</p>
      <p>Unbilled DSO equals to or more than 90 days</p>
    </div>
  </div>
  <div class="info-area">
    <div class="info-world">Countdown</div>
    <ul class="invoice-info countdown-info top16">
      <li class="bold">
        <div></div>
        <div>Update Frequency</div>
        <div>Calculation Logic</div>
        <div>Legend</div>
      </li>
      <li>
        <div class="ita">In Milestone Info Block</div>
        <div>Daily</div>
        <div>
          <p>Date Today - Milestone Due Date</p>
          <p class="top16">Freeze the countdown when invoice status turns to "Invoice posted to AR".</p>
        </div>
        <div><img src="/assets/svg/milestone-countdown.svg" /></div>
      </li>
      <li>
        <div class="ita">In Invoice Info Block</div>
        <div>Monthly</div>
        <div>
          <p>Date Today - Invoice Due Date</p>
          <p class="top16">Freeze the countdown when invoice status turns to "Invoice Paid" or “Part Paid”.</p>
        </div>
        <div><img src="/assets/svg/invoice-countdown.svg" /></div>
      </li>
    </ul>
  </div>
</app-right-drawer>
