import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-error-tooltip',
  templateUrl: './error-tooltip.component.html',
  styleUrls: ['./error-tooltip.component.scss'],
})
export class ErrorTooltipComponent {
  @Input() message: any = 'No permission';
  @Input() type: 'warning' | 'error' = 'error';
}
