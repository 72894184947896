import { Injectable } from '@angular/core';
import { OpportunityList } from '../interfaces/OpportunityDashboard';

@Injectable({
  providedIn: 'root',
})
export class OpportunityPageService {
  opportunityPage: OpportunityList = {};

  set(page: OpportunityList) {
    this.opportunityPage = page;
  }

  get() {
    return this.opportunityPage;
  }
}
