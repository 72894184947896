import { NgModule } from '@angular/core';
import { NgxEchartsModule } from 'ngx-echarts';
import { SharedModule } from '@shared/shared.module';
import { OpportunityListComponent } from './dso-page/opportunity-list/opportunity-list.component';
import { DsoPageComponent } from './dso-page/dso-page.component';
import { HistoryBarChartComponent } from './opportunity-detail/history-bar-chart/history-bar-chart.component';
import { OpportunityDetailComponent } from './opportunity-detail/opportunity-detail.component';
import { PageNotFoundComponent } from '../error-page/not-found.component';
import { InternalServerErrorComponent } from '../error-page/internal-server-error.component';
import { LayOutComponent } from './lay-out/lay-out.component';
import { MilestonePageComponent } from './milestone-page/milestone-page.component';
import { DsoViewRoutingModule } from './dso-view-routing.module';
import { DashboardDataTipsComponent } from './dso-page/data-tips/data-tips.component';
import { ForbiddenComponent } from '../error-page/forbidden.component';
import { DetailDataTipsComponent } from './opportunity-detail/data-tips/data-tips.component';
import { DataTipsComponent } from './milestone-page/data-tips/data-tips.component';
import { AccountListComponent } from './dso-page/account-list/account-list.component';
import { InvoicePageComponent } from './invoice-page/invoice-page.component';
import { BadDebtComponent } from './opportunity-detail/bad-debt/bad-debt.component';
import { MuDashboardComponent } from './dso-page/mu-dashboard/mu-dashboard.component';
import { OpportunityOwnerListComponent } from './dso-page/opportunity-owner-list/opportunity-owner-list.component';
import { InvoiceDataTipsComponent } from './invoice-page/data-tip/invoice-data-tips.component';
import { RoleAccessComponent } from './role-access/role-access.component';
import { NgZorroAntdModule } from '../../ng-zorro-antd.module';
import { HighRiskPageComponent } from './high-risk-page/high-risk-page.component';
import { WithoutContractPageComponent } from '@view/dso-view/without-contract-page/without-contract-page.component';
import { WithoutContractDataTipsComponent } from '@view/dso-view/without-contract-page/data-tips/data-tips.component';
import { OpportunityStatusComponent } from '@view/dso-view/opportunity-detail/opportunity-status/opportunity-status.component';
import { OpportunityApplicationComponent } from './opportunity-detail/opportunity-status/opportunity-application/opportunity-application.component';
import { LegalDocumentsComponent } from './opportunity-detail/legal-document/legal-documents.component';
import { AddLegalDocumentDialogComponent } from '@view/dso-view/opportunity-detail/legal-document/add-legal-document-dialog/add-legal-document-dialog.component';
import { LegalDocumentsPageComponent } from '@view/dso-view/legal-documents-page/legal-documents-page.component';
import { OpportunityMilestoneComponent } from '@view/dso-view/opportunity-detail/opportunity-milestone/opportunity-milestone.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { OpportunityNoPermissionComponent } from '@view/error-page/opportunity-no-permission.component';
import { OpportunityPageComponent } from './opportunity-page/opportunity-page.component';
import { AddMilestoneDialogComponent } from './opportunity-detail/opportunity-milestone-edit/add-milestone-dialog/add-milestone-dialog.component';
import { MarkMilestoneDoneDialogComponent } from './opportunity-detail/opportunity-milestone-edit/mark-milestone-done-dialog/mark-milestone-done-dialog.component';
import { OpportunityMilestoneEditComponent } from './opportunity-detail/opportunity-milestone-edit/opportunity-milestone-edit.component';
import { UserSearchDropdownComponent } from './opportunity-detail/user-search-dropdown/user-search-dropdown.component';
import { UserGroupComponent } from './role-access/user-group/user-group.component';
import { PermissionsComponent } from './role-access/permissions/permissions.component';
import { EditGovernanceUnitDialogComponent } from '@view/dso-view/role-access/user-group/edit-governance-unit-dialog/edit-governance-unit-dialog.component';
import { MilestoneHistoryComponent } from './opportunity-detail/milestone-history/milestone-history.component';

@NgModule({
  declarations: [
    OpportunityListComponent,
    DsoPageComponent,
    HistoryBarChartComponent,
    OpportunityDetailComponent,
    PageNotFoundComponent,
    InternalServerErrorComponent,
    LayOutComponent,
    MilestonePageComponent,
    WithoutContractPageComponent,
    InvoicePageComponent,
    DashboardDataTipsComponent,
    ForbiddenComponent,
    OpportunityNoPermissionComponent,
    DetailDataTipsComponent,
    DataTipsComponent,
    AccountListComponent,
    WithoutContractDataTipsComponent,
    BadDebtComponent,
    MuDashboardComponent,
    OpportunityOwnerListComponent,
    InvoiceDataTipsComponent,
    RoleAccessComponent,
    HighRiskPageComponent,
    OpportunityApplicationComponent,
    LegalDocumentsComponent,
    OpportunityStatusComponent,
    AddLegalDocumentDialogComponent,
    LegalDocumentsPageComponent,
    OpportunityPageComponent,
    OpportunityMilestoneComponent,
    AddMilestoneDialogComponent,
    MarkMilestoneDoneDialogComponent,
    OpportunityMilestoneEditComponent,
    UserSearchDropdownComponent,
    UserGroupComponent,
    PermissionsComponent,
    EditGovernanceUnitDialogComponent,
    MilestoneHistoryComponent,
  ],
  imports: [SharedModule, NgxEchartsModule, NgZorroAntdModule, ScrollingModule],
  exports: [
    DsoPageComponent,
    HistoryBarChartComponent,
    OpportunityDetailComponent,
    PageNotFoundComponent,
    InternalServerErrorComponent,
    LayOutComponent,
    MilestonePageComponent,
    WithoutContractPageComponent,
    DsoViewRoutingModule,
    ForbiddenComponent,
    OpportunityNoPermissionComponent,
    WithoutContractDataTipsComponent,
    MuDashboardComponent,
    OpportunityOwnerListComponent,
    InvoiceDataTipsComponent,
    RoleAccessComponent,
    AddLegalDocumentDialogComponent,
    OpportunityPageComponent,
    EditGovernanceUnitDialogComponent,
  ],
})
export class DsoViewModule {}
