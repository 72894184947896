<div>
  <app-search-input [page]="pageName"></app-search-input>
  <div class="legal-documents-list">
    <div class="legal-documents-info">
      <div class="title">
        <div>Legal Documents</div>
        <div class="total-number">
          <span class="total">Total:</span>
          <span>{{ totalElements }}</span>
        </div>
      </div>
    </div>
    <div class="add-item-container">
      <app-icon-text
        [className]="'icon-add'"
        [text]="'Add Item'"
        (click)="this.showEditLegalDocumentModal = true"
        *ngIf="aclService.isPermission(aclService.features.contractInfo)"
      ></app-icon-text>
    </div>
    <nz-table
      #legalDocumentsListDashboard
      [nzWidthConfig]="columnsWidth"
      [nzData]="legalDocumentsList"
      [nzScroll]="{ x: '2000px', y: 'calc(100vh - 238px)' }"
      [nzFrontPagination]="false"
      [nzShowPagination]="false"
      [nzNoResult]="noData"
    >
      <thead>
        <tr>
          <th
            nzLeft
            nzShowSort
            [nzSortOrder]="mapOfSort['referenceNumber']"
            [nzSortFn]="true"
            (nzSortOrderChange)="sort($event, 'referenceNumber')"
          >
            Reference No. Provided by Client
          </th>
          <th nzShowSort [nzSortOrder]="mapOfSort['documentName']" [nzSortFn]="true" (nzSortOrderChange)="sort($event, 'documentName')">
            Document Name
          </th>
          <th
            nzShowSort
            [nzSortOrder]="mapOfSort['signedLegalEntity']"
            [nzSortFn]="true"
            (nzSortOrderChange)="sort($event, 'signedLegalEntity')"
          >
            Signed Legal Entity
          </th>
          <th nzShowSort [nzSortOrder]="mapOfSort['documentType']" [nzSortFn]="true" (nzSortOrderChange)="sort($event, 'documentType')">
            Document Type
          </th>
          <th
            nzShowSort
            [nzSortOrder]="mapOfSort['contractReceivedDate']"
            [nzSortFn]="true"
            (nzSortOrderChange)="sort($event, 'contractReceivedDate')"
          >
            Date Received
          </th>
          <th
            nzShowSort
            [nzSortOrder]="mapOfSort['startDate']"
            [nzSortFn]="true"
            (nzSortOrderChange)="sort($event, 'startDate')"
            class="legal-documents-start-date"
          >
            Start Date
          </th>
          <th nzShowSort [nzSortOrder]="mapOfSort['endDate']" [nzSortFn]="true" (nzSortOrderChange)="sort($event, 'endDate')">End Date</th>
          <th nzShowSort [nzSortOrder]="mapOfSort['currency']" [nzSortFn]="true" (nzSortOrderChange)="sort($event, 'currency')">
            Currency
          </th>
          <th nzShowSort [nzSortOrder]="mapOfSort['documentAmount']" [nzSortFn]="true" (nzSortOrderChange)="sort($event, 'documentAmount')">
            Document Amount (Excluding Tax)
          </th>
          <th nzShowSort [nzSortOrder]="mapOfSort['documentFormat']" [nzSortFn]="true" (nzSortOrderChange)="sort($event, 'documentFormat')">
            Document Format
          </th>
          <th nzRight nzAlign="center">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of legalDocumentsList; index as i">
          <td nzLeft class="legal-documents-referenceNumber">
            <span>{{ item.referenceNumber | emptyFormat }}</span>
          </td>
          <td>
            <span>{{ item.documentName | emptyFormat }}</span>
          </td>
          <td>
            <span>{{ item.signedLegalEntity | emptyFormat }}</span>
          </td>
          <td>
            <span>{{ DocumentTypeLabel[item.documentType] | emptyFormat }}</span>
          </td>
          <td>
            <span>{{ item.contractReceivedDate | emptyFormat }}</span>
          </td>
          <td>
            <span>{{ item.startDate | emptyFormat }}</span>
          </td>
          <td>
            <span>{{ item.endDate | emptyFormat }}</span>
          </td>
          <td>
            <span>{{ item.currency | emptyFormat }}</span>
          </td>
          <td>
            <span>{{ item.documentAmount | currencyFormat : item.currency : false }}</span>
          </td>
          <td>
            <ng-container *ngIf="item.documentFormat?.length > 0; else noDocumentFormat">
              <span class="document-format-item" *ngFor="let formatItem of item.documentFormat">{{ formatItem | titlecase }}</span>
            </ng-container>
            <ng-template #noDocumentFormat>--</ng-template>
          </td>
          <td nzRight class="operation-col">
            <i
              *ngIf="aclService.isPermission(aclService.features.contractInfo)"
              nz-icon
              nzType="svg:edit-no-circle"
              class="action-icon edit-icon circle-icon-wrapper"
              nz-tooltip
              nzTooltipOverlayClassName="custom-tooltip"
              nzTooltipTitle="Edit"
              (click)="editLegalDocument(item.id)"
              nzTooltipPlacement="bottomLeft"
            ></i>
            <a
              class="google-drive-link"
              *ngIf="item.googleDriveLink"
              target="_blank"
              [href]="item.googleDriveLink"
              referrerpolicy="no-referrer"
              nz-tooltip
              nzTooltipOverlayClassName="custom-tooltip"
              nzTooltipTitle="Open Link"
              nzTooltipPlacement="bottomLeft"
            >
              <i nz-icon nzType="svg:external-link" class="action-icon circle-icon-wrapper"></i>
            </a>
            <i
              *ngIf="item.note"
              nz-icon
              nzType="svg:comment"
              class="action-icon circle-icon-wrapper"
              nz-tooltip
              nzTooltipOverlayClassName="custom-tooltip legal-document-note"
              [nzTooltipTitle]="'Note: ' + item.note"
              [nzTooltipPlacement]="['bottomLeft', 'bottomRight']"
            ></i>
          </td>
        </tr>
        <tr *ngIf="legalDocumentsList?.length > 0">
          <td align="center" colspan="11" style="border-bottom: none; background-color: white">
            <app-table-loading-more [isLastPage]="isLastPage" [loadingMore]="loadingMore"></app-table-loading-more>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>

<app-add-legal-document-dialog
  *ngIf="showEditLegalDocumentModal"
  [legalDocumentId]="currentLegalDocumentId"
  (closeDialog)="closeDialog($event)"
></app-add-legal-document-dialog>

<ng-template #noData>
  <app-table-no-data [loadingFirst]="loadingFirst"></app-table-no-data>
</ng-template>
