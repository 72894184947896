<div class="dropdown-item">
  <div class="with-border">
    <p class="title">{{ title }}</p>
    <div class="dropdown-item-content">
      <p class="no-data-found break-word" *ngIf="data?.length === 0 && !loading">No {{ title | lowercaseExceptMu }} found</p>
      <nz-spin *ngIf="loading"></nz-spin>
      <ng-content></ng-content>
    </div>
  </div>
  <app-selector
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    [pageNumber]="pageNumber"
    (pageChange)="pageChange.emit($event)"
  ></app-selector>
</div>
