import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Role } from '@interfaces/Acl';
import { ControlVisibleService } from '@services/control-visible.service';
import { RoleControlComponent } from '../role-control/role-control.component';
import { UntypedFormControl, Validators } from '@angular/forms';
import { isNil } from 'lodash';
import { calculateTextWidth } from '@utils/dom';

@Component({
  selector: 'app-role-title-container',
  templateUrl: './role-title-container.component.html',
  styleUrls: ['./role-title-container.component.scss'],
})
export class RoleTitleContainerComponent implements OnInit {
  @Input() role: Role = { description: '', id: undefined };
  @Input() isAdmin = false;
  @Input() disableControl = false;
  @Input() index = -1;
  @Output() emitEditBtn = new EventEmitter();
  @Output() emitDeleteRole = new EventEmitter();
  @Output() emitClickSaveBtn = new EventEmitter<string>();
  @ViewChild('inputElement') private inputEle: ElementRef;
  @ViewChild('controlComponent', { static: true }) public controlCom: RoleControlComponent;
  userName: UntypedFormControl;
  @Input() type: 'original' | 'edit' = 'original';
  cloneValue;
  checkValid = false;

  constructor(private controlVisibleService: ControlVisibleService) {}

  ngOnInit(): void {
    this.userName = new UntypedFormControl(this.role.description, [Validators.maxLength(50), Validators.required]);
    this.cloneValue = this.userName.value;
  }

  onClickEditBtn() {
    this.emitEditBtn.emit();
  }

  deleteRole() {
    this.emitDeleteRole.emit();
  }

  onClickRoleControl() {
    if (!this.disableControl) {
      this.controlVisibleService.triggerVisible(this.index);
    }
  }
  onBlur() {
    this.cloneValue = this.userName.value;
    if (!isNil(this.cloneValue)) {
      this.ellipsisWords(this.cloneValue);
    }
  }

  private ellipsisWords(value: string) {
    if (calculateTextWidth(this.inputEle.nativeElement, value) >= this.inputEle.nativeElement.clientWidth - 20) {
      this.userName.setValue(value.substring(0, value.length - 1) + '...');
      this.ellipsisWords(value.substring(0, value.length - 1));
    }
  }

  onFocus() {
    this.userName.setValue(this.cloneValue);
  }

  onClickSave() {
    this.checkValid = true;
    if (this.userName.valid) {
      this.emitClickSaveBtn.emit(this.cloneValue);
    }
  }

  onInputAmount(value: string) {
    value = value.replace(/[`~!@$%^&*()+.\-=[\]{}|;:'"<,>#?/\\]/, '');
    this.userName.setValue(value);
    this.role.description = value;
  }
}
