export const ACTION_DEPARTMENT_MAP = {
  DELIVERY: 'Delivery',
  DEMAND: 'Demand',
  DELIVERY_DEMAND: 'Delivery&Demand',
};

export const ACTION_DEPARTMENT_OPTION_LIST = [
  { label: 'Delivery', value: 'DELIVERY' },
  { label: 'Demand', value: 'DEMAND' },
  { label: 'Delivery&Demand', value: 'DELIVERY_DEMAND' },
];
