import { Inject, Injectable } from '@angular/core';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import { EMPTY, Subject, Subscription, timer } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { DsoSessionMessage } from '@interfaces/DsoSessionMessage';
import { isNil } from 'lodash';

const RECONNECT_INTERVAL = 60000;

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  private socket$: WebSocketSubject<any>;
  private messagesSubject$ = new Subject();
  public messages$ = this.messagesSubject$;
  private subscribeSendHeartBeat: Subscription;
  private times = 0;

  constructor(@Inject(OKTA_AUTH) private oktaAuth: OktaAuth) {}

  public async connect(): Promise<void> {
    this.times++;
    const token = this.oktaAuth.getAccessToken();
    if (!isNil(token)) {
      await this.initWebSocket(token);
    } else {
      await this.oktaAuth.signInWithRedirect();
    }
  }

  private async initWebSocket(token: string) {
    if (!this.socket$ || this.socket$.closed) {
      this.socket$ = await this.getNewWebSocket(token);
      this.socket$
        .pipe(
          tap({
            error: (error) => console.info('[Websocket Service] websocket error:', error),
          }),
          catchError(() => EMPTY),
        )
        .subscribe((data) => {
          this.messagesSubject$.next(data);
          this.times = 1;
        });
    }
  }

  private getNewWebSocket(token: string) {
    return webSocket({
      url: `${environment.wsEndpoint}/websocket/notifications`,
      protocol: token,
      openObserver: {
        next: () => {
          console.info('[Websocket Service] connect successfully!');
        },
      },
      closeObserver: {
        next: () => {
          console.info('[Websocket Service] connection closed.');
          this.socket$ = undefined;
          if (this.subscribeSendHeartBeat) {
            this.subscribeSendHeartBeat.unsubscribe();
          }
          timer(RECONNECT_INTERVAL * Math.pow(2, this.times)).subscribe(async () => {
            await this.connect();
          });
        },
      },
    });
  }

  sendMessage(value: DsoSessionMessage) {
    if (this.socket$) {
      this.socket$.next(value);
    }
  }

  close() {
    if (this.socket$) {
      this.socket$.complete();
    }
  }
}
