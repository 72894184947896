<div class="amount-input">
  <nz-input-number-group nzPrefix="￥" nzSize="small" [nzSuffix]="inputClearTpl" class="prefix-input">
    <nz-input-number
      nzSize="small"
      [(ngModel)]="inputValue"
      [nzPrecision]="2"
      nzPrecisionMode="cut"
      [nzFormatter]="formatterMoney"
      (keydown)="preventTypingWhenExceed($event, 11)"
    ></nz-input-number>
  </nz-input-number-group>
  <app-icon-text class="save-contract" [className]="'icon-save-contract'" (click)="callSave($event)"></app-icon-text>
</div>
<ng-template #inputClearTpl>
  <i nz-icon class="ant-input-clear-icon" nzTheme="outline" nzType="close-circle" *ngIf="inputValue" (mousedown)="clear()"></i>
</ng-template>
