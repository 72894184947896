<div class="months-picker-container" [ngClass]="{ 'range-picker': currentMonthsInfo.isRange }">
  <nz-input-group [nzSuffix]="suffixIcon" class="months-input-group" [class.has-value]="currentMonthsInfo.values.join('').length > 0">
    <input
      *ngIf="!currentMonthsInfo.isRange"
      type="text"
      readonly
      nz-input
      placeholder="Please select months"
      [ngModel]="currentMonthsInfo.values.join(', ')"
    />
    <input
      *ngIf="currentMonthsInfo.isRange"
      type="text"
      readonly
      nz-input
      placeholder="Start month"
      class="start-month-input"
      [ngModel]="currentMonthsInfo.values[0] || ''"
    />
    <i *ngIf="currentMonthsInfo.isRange" nz-icon nzType="arrow-right" nzTheme="outline" class="input-middle-icon"></i>
    <input
      *ngIf="currentMonthsInfo.isRange"
      type="text"
      readonly
      nz-input
      placeholder="End month"
      class="end-month-input"
      [ngModel]="currentMonthsInfo.values[1] || ''"
    />
  </nz-input-group>

  <nz-radio-group
    class="type-selector"
    [(ngModel)]="currentMonthsInfo.isRange"
    [nzButtonStyle]="'outline'"
    (ngModelChange)="onChangePickerType()"
  >
    <label nz-radio-button [nzValue]="true">Range Picker</label>
    <label nz-radio-button [nzValue]="false">Month Picker</label>
  </nz-radio-group>

  <nz-range-picker
    *ngIf="currentMonthsInfo.isRange"
    nzMode="month"
    nzInline
    class="months-picker"
    [nzBorderless]="true"
    [(ngModel)]="rangePickerModel"
    (nzOnCalendarChange)="onRangeCalendarChange($event)"
  ></nz-range-picker>
  <app-multi-months-picker
    *ngIf="!currentMonthsInfo.isRange"
    [defaultYearMonths]="monthPickerModel"
    (changedYearMonths)="onMultiMonthsSelect($event)"
  ></app-multi-months-picker>

  <div class="buttons-group">
    <button class="cancel-btn" (click)="onClickCancel()" nz-button nzType="default">Cancel</button>
    <button class="submit-btn" (click)="onClickSubmit()" nz-button nzType="primary" [disabled]="disableSubmit">Submit</button>
  </div>
</div>

<ng-template #suffixIcon>
  <i nz-icon nzType="calendar" (click)="clear()"></i>
  <i nz-icon nzType="close-circle" nzTheme="outline" (click)="clear()"></i>
</ng-template>
