import { Component, Input, OnChanges } from '@angular/core';
import { completedInvoiceStatus } from '@constants/invoiceStatus';
import { COUNTDOWN_L0_INVOICE } from '@constants/countdown';
import { includes, isEmpty, isNumber } from 'lodash';
import { DateType } from '@constants/dateFormat';

@Component({
  selector: 'app-countdown-format-invoice',
  templateUrl: './countdown-format-invoice.component.html',
})
export class CountdownFormatInvoiceComponent implements OnChanges {
  @Input() countDown: string | number;
  @Input() invoiceStatus: string;
  @Input() invoiceDueDate: string;
  className = '';
  invoiceStop = false;
  dateType = DateType;

  ngOnChanges(): void {
    const countDown = this.countDown;
    const invoiceStatus = this.invoiceStatus;
    this.invoiceStop = includes(completedInvoiceStatus, invoiceStatus);

    if (isEmpty(countDown) && !isNumber(countDown)) {
      return;
    }
    if (countDown > COUNTDOWN_L0_INVOICE) {
      this.className = 'red';
      this.countDown = countDown > 0 ? `+ ${countDown}` : countDown;
    } else {
      this.className = 'default';
      this.countDown = countDown < 0 ? `- ${Math.abs(countDown as number)}` : countDown;
    }
  }
}
