import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-text',
  templateUrl: './icon-text.component.html',
  styleUrls: ['./icon-text.component.scss'],
})
export class IconTextComponent {
  @Input() className = '';
  @Input() text = '';
  @Input() isDisabled = false;
  @Input() reverse = false;
  @Input() size = 'normal';
}
