import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { cloneDeep, get, isEmpty, orderBy, startCase } from 'lodash';
import { Note } from '@interfaces/Milestone';
import { NotesApi } from '@apis/notes.api';
import { NoteService } from '@services/note.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatomoTracker } from 'ngx-matomo';
import { ACTION_EDIT } from '@constants/matomo';
import { DateType } from '@constants/dateFormat';
import { NoteType } from '@constants/note';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
})
export class NoteComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() invoiceNote = false;
  @Input() type: string;
  @Input() lookupId: string;
  @Input() disableEditNote = false;
  @Input() page: string;
  @Input() opportunityName: string;
  @Input() supportEmailInfo: string;
  @ViewChildren('popoverElement') popoverContentEle: QueryList<HTMLElement>;
  @Output() emitEditSupportEmailInfo = new EventEmitter<any>();
  @Output() changed = new EventEmitter();
  dateType = DateType;
  highRiskNoteType = NoteType.HIGH_RISK;
  originalSupportEmailInfo: string;
  originalNotes: Note[];
  notes: Note[];
  showPopover: boolean;
  hasNote: boolean;
  hasSupportEmailInfo: boolean;
  popoverPlacement = 'left';
  maxPopoverHeight = 450;
  focusIndex = -1;
  disableAddItem = false;
  disableSave = true;
  isEditSupportEmailInfo = true;
  disableSaveSupportEmailInfo = true;
  isRequesting = false;
  clickPopover = false;
  private contentHTML: HTMLElement;
  private unsubscribe = new Subject();
  private modifyComplete = true;

  constructor(private notesApi: NotesApi, private noteService: NoteService, private matomoTracker: MatomoTracker) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.lookupId) {
      this.initNoteData();
    }
  }

  ngOnInit() {
    this.initNoteData();
    this.initSupportEmailInfo();
  }

  initNoteData() {
    this.originalNotes = orderBy(this.noteService.findNotesByTypeAndLookupId(this.type, this.lookupId), ['createdDate'], ['desc']);
    this.notes = cloneDeep(this.originalNotes);
    this.hasNote = this.originalNotes.length > 0;
  }

  initSupportEmailInfo() {
    this.originalSupportEmailInfo = this.supportEmailInfo;
    this.hasSupportEmailInfo = !isEmpty(this.originalSupportEmailInfo);
    this.isEditSupportEmailInfo = !!isEmpty(this.originalSupportEmailInfo);
    this.disableSaveSupportEmailInfo = true;
  }

  ngAfterViewInit(): void {
    this.popoverContentEle.changes.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
      this.contentHTML = get(data._results[0], 'nativeElement');
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  clickPopoverBtn(event) {
    if (event.pageY > this.maxPopoverHeight) {
      this.popoverPlacement = 'leftBottom';
    } else {
      this.popoverPlacement = 'left';
      if (this.invoiceNote) {
        this.popoverPlacement = 'leftTop';
      }
    }
  }

  popoverVisibleChange(visible: boolean): void {
    if (visible && this.notes.length === 0) {
      this.addNewItem();
    }
  }

  addNewItem(disableClick = false) {
    if (disableClick) {
      return;
    }
    if (!this.disableAddItem) {
      if (this.contentHTML) {
        this.contentHTML.scrollTop = 0;
      }
      this.notes.unshift({
        lookupId: this.lookupId,
        createdDate: new Date().toDateString(),
        content: '',
      });
      this.focusIndex = 0;
      this.setDisableAddItem();
    }
  }

  focusNoteInput(index: number) {
    if (this.focusIndex === -1 && !this.disableEditNote) {
      this.focusIndex = index;
      this.setDisableAddItem();
    }
  }

  changeNotes(content: string) {
    this.setDisableSave(content);
  }

  cancel(noteId, index) {
    if (noteId) {
      this.notes[index].content = this.originalNotes.find((note) => note.id === noteId).content;
      this.focusIndex = -1;
      this.disableSave = true;
    } else {
      if (this.originalNotes.length === 0) {
        this.showPopover = false;
        this.notes.shift();
        this.focusIndex = -1;
        this.disableSave = true;
      } else {
        this.notes.shift();
        this.focusIndex = -1;
        this.disableSave = true;
      }
    }
    this.setDisableAddItem();
  }

  save(note: Note) {
    this.isRequesting = true;
    if (note.id && this.modifyComplete) {
      this.modifyComplete = false;
      this.notesApi.updateNotes(note.id, note.content).subscribe(() => {
        this.noteService.updateNote(this.type, note);
        this.originalNotes.unshift(cloneDeep(note));
        this.isRequesting = false;
        this.focusIndex = -1;
        this.disableSave = true;
        this.setDisableAddItem();
        this.modifyComplete = true;
        this.changed.emit();
        this.matomoTracker.trackEvent(this.page, ACTION_EDIT, `Edit ${startCase(this.type)} Note for ${startCase(this.opportunityName)}`);
      });
    } else if (this.modifyComplete) {
      this.modifyComplete = false;
      this.notesApi.createNotes(note.lookupId, note.content, this.type).subscribe((data) => {
        this.noteService.addNote(this.type, data);
        this.hasNote = true;
        this.notes[0] = data;
        this.originalNotes.unshift(cloneDeep(data));
        this.isRequesting = false;
        this.focusIndex = -1;
        this.disableSave = true;
        this.setDisableAddItem();
        this.modifyComplete = true;
        this.changed.emit();
        this.matomoTracker.trackEvent(this.page, ACTION_EDIT, `Edit ${startCase(this.type)} Note for ${startCase(this.opportunityName)}`);
      });
    }
  }

  deleteNote(noteId, index) {
    this.notesApi.deleteNotes(noteId).subscribe(() => {
      this.noteService.deleteNote(this.type, noteId);
      this.originalNotes.splice(index, 1);
      this.hasNote = this.originalNotes.length > 0;
      this.isRequesting = false;
      this.notes.splice(index, 1);
      this.focusIndex = -1;
      this.disableSave = true;
      if (this.notes.length === 0) {
        this.addNewItem();
      }
      this.setDisableAddItem();
      this.changed.emit();
      this.matomoTracker.trackEvent(this.page, ACTION_EDIT, `Edit ${startCase(this.type)} Note for ${startCase(this.opportunityName)}`);
    });
  }

  setDisableAddItem() {
    this.disableAddItem = this.focusIndex !== -1;
  }

  setDisableSave(content) {
    const originalNote = this.originalNotes.find((note) => note.id === this.notes[this.focusIndex].id);
    this.disableSave = content === '' || (originalNote && originalNote.content === content);
  }

  changeSupportEmailInfo(supportEmailInfo: string) {
    this.disableSaveSupportEmailInfo =
      this.disableEditNote || supportEmailInfo === '' || this.originalSupportEmailInfo === supportEmailInfo;
  }

  saveSupportEmailInfo(supportEmailInfo: string) {
    this.emitEditSupportEmailInfo.emit({
      highRiskId: this.lookupId,
      supportEmailInfo,
    });
    this.supportEmailInfo = supportEmailInfo;
    this.initSupportEmailInfo();
  }

  cancelEditSupportEmail() {
    this.supportEmailInfo = this.originalSupportEmailInfo;
    this.disableSaveSupportEmailInfo = true;
    if (isEmpty(this.supportEmailInfo)) {
      this.showPopover = false;
    } else {
      this.isEditSupportEmailInfo = false;
    }
  }

  editSupportEmailInfo() {
    this.isEditSupportEmailInfo = true;
  }
}
