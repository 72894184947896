import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NAME_DASHBOARD_HIGH_RISK } from '@constants/matomo';
import { DateType, MomentFormat } from '@constants/dateFormat';
import { HighRiskOpportunity, HighRiskOpportunityList } from '@interfaces/HighRiskOpportunity';
import { HighRiskOpportunityApi } from '@apis/high-risk-opportunity.api';
import { DEFAULT_HIGH_RISK_OPPORTUNITY_SORTING, SORT_MAP_TYPE_HIGH_RISK, sorting } from '@constants/sortMapping';
import { getSearchFilterList } from '@utils/filter';
import { Filter, SearchItem } from '@interfaces/Filter';
import { SortMappingService } from '@services/sort-mapping.service';
import { FilterService } from '@services/filter.service';
import { HighRiskOpportunityPageService } from '@services/high-risk-opportunity-page.service';
import { concat, isEmpty } from 'lodash';
import { exhaustMap, filter, map, takeUntil, throttleTime } from 'rxjs/operators';
import { InvoiceList } from '@interfaces/Invoice';
import { fromEvent, Observable, Subject } from 'rxjs';
import { NzTableComponent } from 'ng-zorro-antd/table';
import * as moment from 'moment';
import { NoteType } from '@constants/note';
import { NoteService } from '@services/note.service';
import { ToastMessageService } from '@services/toast-message.service';
import { AclService } from '@services/acl.service';
import { ACTION_DEPARTMENT_MAP, ACTION_DEPARTMENT_OPTION_LIST } from '@constants/highRiskActionDepartment';
import { HIGH_RISK_AMOUNT_TYPE_MAP, HIGH_RISK_AMOUNT_TYPE_OPTION_LIST } from '@constants/highRiskAmountType';
import { HIGH_RISK_RISK_LEVEL_MAP, HIGH_RISK_RISK_LEVEL_OPTION_LIST } from '@constants/highRiskRiskLevel';
import { HIGH_RISK_CATEGORY_MAP } from '@constants/highRiskCategory';

@Component({
  selector: 'app-high-risk-page',
  templateUrl: './high-risk-page.component.html',
  styleUrls: ['./high-risk-page.component.scss'],
})
export class HighRiskPageComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('highRiskDashboard') tableCom: NzTableComponent<any>;
  pageName = NAME_DASHBOARD_HIGH_RISK;
  highRiskNoteType = NoteType.HIGH_RISK;
  dateType = DateType;
  highRiskOpportunityList: HighRiskOpportunity[] = [];
  columnsWidth = [
    '108px',
    '141px',
    '136px',
    '112px',
    '141px',
    '141px',
    '150px',
    '124px',
    '141px',
    '156px',
    '166px',
    '130px',
    '141px',
    '136px',
    '156px',
    '156px',
    '136px',
    '156px',
    '161px',
    '161px',
    '90px',
  ];
  loadingFirst = false;
  loadingMore = false;
  isLastPage = false;
  totalElements: number;
  mapOfSort = {};
  private pageIndex = -1;
  private pageSize = 100;
  private scrollEle: HTMLElement;
  private currentScrollTop: number;
  private filters: Array<SearchItem>;
  private orders: any = DEFAULT_HIGH_RISK_OPPORTUNITY_SORTING;
  private unsubscribe = new Subject();
  editId: string = null;
  highRiskAmountMap = HIGH_RISK_AMOUNT_TYPE_MAP;
  highRiskRiskLevelMap = HIGH_RISK_RISK_LEVEL_MAP;
  highRiskAmountTypeOptionList = HIGH_RISK_AMOUNT_TYPE_OPTION_LIST;
  highRiskRiskLevelOptionList = HIGH_RISK_RISK_LEVEL_OPTION_LIST;
  highRiskCategoryMap = HIGH_RISK_CATEGORY_MAP;
  actionDepartmentOptionList = ACTION_DEPARTMENT_OPTION_LIST;
  actionDepartmentMap = ACTION_DEPARTMENT_MAP;

  constructor(
    private highRiskOpportunityApi: HighRiskOpportunityApi,
    private highRiskOpportunityPageService: HighRiskOpportunityPageService,
    private sortMappingService: SortMappingService,
    private noteService: NoteService,
    private toastMessageService: ToastMessageService,
    private filterService: FilterService,
    public aclService: AclService,
  ) {}

  ngOnInit(): void {
    this.initFilterAndSortFromService();
    this.initPageData();
    this.triggerFilter();
    this.listenCategoryChanged();
  }

  ngAfterViewInit(): void {
    this.listenScrollAndLoadMore();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private initFilterAndSortFromService() {
    this.mapOfSort = this.sortMappingService.getSort(SORT_MAP_TYPE_HIGH_RISK);
    this.filters = getSearchFilterList(this.filterService.getFilterItem());
  }

  private initPageData() {
    this.loadingFirst = true;
    this.highRiskOpportunityList = [];
    this.totalElements = undefined;
    this.pageIndex = -1;
    this.postForHighRiskOpportunityList()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: InvoiceList) => {
        this.loadingFirst = false;
        this.scrollEle = null;
        this.setPageData(data);
      });
  }

  private triggerFilter() {
    this.filterService
      .listenFilter()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((item: Filter) => {
        this.filters = getSearchFilterList(item);
        this.clearSort();
        this.initPageData();
      });
  }

  private clearSort() {
    this.orders = DEFAULT_HIGH_RISK_OPPORTUNITY_SORTING;
    this.mapOfSort = {};
    this.sortMappingService.clear(SORT_MAP_TYPE_HIGH_RISK);
  }

  private postForHighRiskOpportunityList(): Observable<InvoiceList> {
    const targetPageIndex = this.pageIndex + 1;

    return this.highRiskOpportunityApi.getHighRiskOpportunityList(targetPageIndex, this.pageSize, this.filters, this.orders);
  }

  private setPageData(data: HighRiskOpportunityList) {
    this.highRiskOpportunityList = data.content.map((item) => {
      item.createdAt = moment(item.createdAt).format(MomentFormat.DATE);
      if (!isEmpty(item.notes)) {
        this.noteService.storeNotes(NoteType.HIGH_RISK, item.notes);
      }

      return item;
    });
    this.pageIndex = data.number;
    this.isLastPage = data.last;
    this.totalElements = data.totalElements;
    this.setHighRiskOpportunityPageToService();
  }

  private listenScrollAndLoadMore() {
    const nativeElement = this.tableCom.nzTableInnerScrollComponent.tableBodyElement.nativeElement;
    this.listenScroll(nativeElement)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: InvoiceList) => {
        this.loadingMore = false;
        data.content = concat(this.highRiskOpportunityList, data.content);
        this.setPageData(data);
        this.scrollEle.scrollTop = this.currentScrollTop;
      });
  }

  private listenScroll(nativeElement) {
    return fromEvent(nativeElement, 'scroll').pipe(
      map((event) => this.scrollEle || (event as Event).target),
      filter((ele: HTMLElement) => {
        const isTriggerBottom = ele.scrollTop + ele.offsetHeight + 20 >= ele.scrollHeight;

        return !this.isLastPage && ele.scrollHeight !== 0 && isTriggerBottom;
      }),
      exhaustMap((ele: HTMLElement) => {
        this.scrollEle = ele;
        this.currentScrollTop = ele.scrollHeight - ele.offsetHeight + 40;

        this.loadingMore = true;

        return this.postForHighRiskOpportunityList();
      }),
      throttleTime(1000),
    );
  }

  sort(sortingType, sortKey: string) {
    if (!isEmpty(sortingType)) {
      this.orders = { [sortKey]: sorting[sortingType] };
      this.mapOfSort = { [sortKey]: sortingType };
      this.sortMappingService.setSort(SORT_MAP_TYPE_HIGH_RISK, this.mapOfSort);
      this.initPageData();
    }
  }

  private listenCategoryChanged() {
    this.highRiskOpportunityPageService
      .listenHighRiskPage()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.initPageData();
      });
  }

  onChangeSupportEmailInfo({ highRiskId, supportEmailInfo }) {
    this.highRiskOpportunityApi.updateHighRiskOpportunityInfo(highRiskId, 'supportEmailInfo', supportEmailInfo).subscribe(() => {
      if (isEmpty(supportEmailInfo)) {
        this.toastMessageService.success('Deleted successfully');
      } else {
        this.toastMessageService.success('Saved successfully');
      }
    });
  }

  private setHighRiskOpportunityPageToService() {
    const highRiskOpportunityPage = {
      content: this.highRiskOpportunityList,
      number: this.pageIndex,
      last: this.isLastPage,
      totalElements: this.totalElements,
    };
    this.highRiskOpportunityPageService.set(highRiskOpportunityPage);
  }

  getRevenueRecognitionPeriodForDisplay(period?: string) {
    return (period || '').replace(/,/g, ',<br>');
  }

  handleCloseRevenueRecognitionPeriodPopover(visible: boolean) {
    if (!visible) {
      this.editId = null;
    }
  }

  startEdit(editId: string): void {
    this.editId = editId;
  }

  stopEdit(): void {
    this.editId = null;
  }

  saveEdit(saveValue: string, index: number, category: string) {
    this.stopEdit();
    let updateValue = null;
    switch (category) {
      case 'expectedDateImpactToNoi':
      case 'impactToNoiDate':
      case 'actualBadDebtDate':
        updateValue = saveValue === null ? saveValue : moment(saveValue).format('YYYY-MM-DD');
        break;
      case 'countdown':
        if (saveValue === null) {
          updateValue = saveValue;
        } else {
          const parsedValue = parseFloat(saveValue);
          if (Number.isInteger(parsedValue)) {
            updateValue = parsedValue;
          } else {
            updateValue = Math.trunc(parsedValue);
          }
        }
        break;
      default:
        updateValue = saveValue;
    }
    this.highRiskOpportunityList[index][category] = saveValue;
    this.highRiskOpportunityApi.updateHighRiskOpportunityInfo(this.highRiskOpportunityList[index].id, category, updateValue).subscribe();
  }
}
