import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AccountList, AccountMuHistory, AccountMuList } from '@interfaces/AccountList';
import { SortOrder } from '@interfaces/OpportunityDashboard';
import { SearchItem } from '@interfaces/Filter';

@Injectable({ providedIn: 'root' })
export class AccountApi {
  constructor(private http: HttpClient) {}

  getAccountList = (page: number, size: number, orders: SortOrder, filters: Array<SearchItem>): Observable<AccountList> => {
    return this.http.post<AccountList>(`/accounts`, {
      page,
      size,
      filters,
      orders,
    });
  };

  getMUList = (): Observable<AccountMuList[]> => {
    return this.http.get<AccountMuList[]>(`/market-unit`);
  };

  getMuHistory = (lookUpId: number): Observable<AccountMuHistory[]> => {
    return this.http.get<AccountMuHistory[]>(`/market-unit/account/market-unit-snapshots?lookupId=${lookUpId}`);
  };

  updateAccountMu = (accountId: number, mu: string): Observable<AccountMuHistory> => {
    return this.http.post<AccountMuHistory>(`/market-unit/account/mu?accountId=${accountId}&mu=${mu}`, {});
  };
}
