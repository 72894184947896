import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RegionalDso } from '@interfaces/Regional';

@Injectable({ providedIn: 'root' })
export class RegionalApi {
  constructor(private http: HttpClient) {}

  getRegionalDso(): Observable<RegionalDso> {
    return this.http.get(`/dso/regional`);
  }
}
