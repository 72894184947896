<div class="vertical-filter">
  <div class="advanced-filter-container">
    <div class="input-container">
      <nz-select
        [(ngModel)]="selectedAccounts"
        (ngModelChange)="handleFilterChange(NAME_FILTER_EVENT_MAPPING.accountId)"
        [nzOptionHeightPx]="24"
        class="account-select gray-light-placeholder gap"
        nzPlaceHolder="Account"
        nzMode="multiple"
        nzShowArrow="true"
        nzMaxTagCount="1"
        data-testid="account-select"
      >
        <nz-option *ngFor="let accountOption of accountOptions" [nzLabel]="accountOption.label" [nzValue]="accountOption.value"></nz-option>
      </nz-select>
      <nz-select
        [(ngModel)]="selectedMarkets"
        (ngModelChange)="handleFilterChange(NAME_FILTER_EVENT_MAPPING.market)"
        [nzOptionHeightPx]="24"
        class="market-select gray-light-placeholder gap"
        nzPlaceHolder="Market"
        nzMode="multiple"
        nzShowArrow="true"
        nzMaxTagCount="1"
        data-testid="market-select"
      >
        <nz-option *ngFor="let marketOption of marketOptions" [nzLabel]="marketOption.label" [nzValue]="marketOption.value"></nz-option>
      </nz-select>
      <nz-select
        [(ngModel)]="selectedGovernanceUnits"
        (ngModelChange)="handleFilterChange(NAME_FILTER_EVENT_MAPPING.governanceUnit)"
        [nzOptionHeightPx]="24"
        class="governance-unit-select gray-light-placeholder gap"
        nzPlaceHolder="Delivery Unit"
        nzMode="multiple"
        nzShowArrow="true"
        nzMaxTagCount="1"
        data-testid="governance-unit-select"
      >
        <nz-option
          *ngFor="let governanceUnitOption of governanceUnitOptions"
          [nzLabel]="governanceUnitOption.label"
          [nzValue]="governanceUnitOption.value"
        ></nz-option>
      </nz-select>
      <nz-select
        [(ngModel)]="selectedOpportunityOwners"
        (ngModelChange)="handleFilterChange(NAME_FILTER_EVENT_MAPPING.ownerEmail)"
        [nzOptionHeightPx]="24"
        class="opportunity-owner-select gray-light-placeholder gap"
        nzPlaceHolder="Opportunity Owner"
        nzMode="multiple"
        nzShowArrow="true"
        nzMaxTagCount="1"
        data-testid="opportunity-owner-select"
      >
        <nz-option
          *ngFor="let opportunityOwnerOption of opportunityOwnerOptions"
          [nzLabel]="opportunityOwnerOption.label"
          [nzValue]="opportunityOwnerOption.value"
        ></nz-option>
      </nz-select>
      <nz-select
        [(ngModel)]="selectedPms"
        (ngModelChange)="handleFilterChange(NAME_FILTER_EVENT_MAPPING.projectManagerId)"
        [nzOptionHeightPx]="24"
        class="pm-select gray-light-placeholder gap"
        nzPlaceHolder="PM"
        nzMode="multiple"
        nzShowArrow="true"
        nzMaxTagCount="1"
        data-testid="pm-select"
      >
        <nz-option *ngFor="let pmOption of pmOptions" [nzLabel]="pmOption.label" [nzValue]="pmOption.value"></nz-option>
      </nz-select>
      <nz-select
        [(ngModel)]="selectedRisks"
        (ngModelChange)="handleFilterChange(NAME_FILTER_EVENT_MAPPING.isHighRisk)"
        [nzOptionHeightPx]="24"
        class="risk-select gray-light-placeholder gap"
        nzPlaceHolder="Risk"
        nzMode="multiple"
        nzShowArrow="true"
        nzMaxTagCount="1"
        data-testid="risks-select"
      >
        <nz-option *ngFor="let riskOption of riskOptions" [nzLabel]="riskOption.label" [nzValue]="riskOption.value"></nz-option>
      </nz-select>
    </div>
    <div class="action-container">
      <app-icon-text
        class="gap arrow-icon-text"
        text="Advanced Search"
        reverse="true"
        size="large"
        [className]="isShowMore ? 'icon-arrow-up' : 'icon-arrow-down'"
        (click)="handleShowMoreToggle()"
      ></app-icon-text>
      <div class="divider"></div>
      <button nz-button nzType="default" (click)="handleReset()" class="gap">Reset All</button>
      <button nz-button nzType="primary" (click)="handleFilter()" class="gap">View</button>
      <div class="download" (click)="download()">
        <div class="download-icon"></div>
        <span>Download</span>
      </div>
    </div>
  </div>
  <ng-container *ngIf="isShowMore" class="customize-input-container">
    <ng-content></ng-content>
  </ng-container>
</div>
