import { Component, OnInit } from '@angular/core';
import { GovernanceUnit, GovernanceUnitUser } from '@interfaces/GovernanceUnit';
import { GovernanceUnitApi } from '@apis/governance-unit.api';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '@app/decorators/autoUnsubscribe';
import { finalize, takeUntil } from 'rxjs/operators';
import { EmptyPlaceHolder } from '@constants/common';

@AutoUnsubscribe()
@Component({
  selector: 'app-user-group',
  templateUrl: './user-group.component.html',
  styleUrls: ['./user-group.component.scss'],
})
export class UserGroupComponent implements OnInit {
  EmptyPlaceHolder = EmptyPlaceHolder;
  unsubscribe = new Subject();
  units: GovernanceUnit[] = [];
  isLoading = false;
  showEditGovernanceUnitModal = false;
  currentUnitName: string;
  currentUserInfos: GovernanceUnitUser[];

  constructor(private governanceUnitApi: GovernanceUnitApi) {}

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.isLoading = true;
    this.units = [];
    this.governanceUnitApi
      .getUnits()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe((value: GovernanceUnit[]) => {
        this.units = value;
      });
  }

  getUserNamesString(users: GovernanceUnitUser[]) {
    const names = users.map((user) => user.name);
    return names.length > 0 ? names.join('; ') : '';
  }

  openGovernanceUnitDialog(unitName: string, users: GovernanceUnitUser[]) {
    this.showEditGovernanceUnitModal = true;
    this.currentUnitName = unitName;
    this.currentUserInfos = users;
  }

  closeEditDialog(isSave: boolean) {
    isSave && this.loadData();
    this.showEditGovernanceUnitModal = false;
  }
}
