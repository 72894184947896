import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterService } from '@services/filter.service';
import { DEFAULT_FILTER_ITEM } from '@constants/filterType';

@Component({
  selector: 'app-search-capsule',
  template: `
    <div class="capsule">
      <span class="capsule-tag-name" (click)="search()">{{ type | capsuleTag }}</span>
      <span class="capsule-search-item" (click)="search()">{{ capsuleNameFormat(type) }}</span>
      <img src="/assets/svg/clear.svg" alt="clear" class="clear-icon" (click)="clearCapsule()" />
    </div>
  `,
  styleUrls: ['./search-capsule.component.scss'],
})
export class SearchCapsuleComponent {
  @Input() type: string;
  @Input() searchItem: string;
  @Input() searchItemId: string;
  @Output() searchByCapsuleName = new EventEmitter<string>();

  constructor(private filterService: FilterService) {}

  capsuleNameFormat(type: string) {
    const normalCapsule = `${this.searchItem}`;
    const typeMap = {
      document: `[${this.searchItemId || ' -- '}] ${this.searchItem || ' -- '}`,
      mu: normalCapsule,
      account: normalCapsule,
      opportunity: normalCapsule,
      owner: normalCapsule,
    };
    return typeMap[type];
  }

  clearCapsule() {
    this.filterService.triggerFilter(DEFAULT_FILTER_ITEM);
  }

  search() {
    this.searchByCapsuleName.emit(this.searchItem);
  }
}
