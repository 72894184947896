import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectOptions } from '@interfaces/Filter';

@Component({
  selector: 'app-opportunity-filter-select',
  templateUrl: './opportunity-filter-select.component.html',
  styleUrls: ['./opportunity-filter-select.component.scss'],
})
export class OpportunityFilterSelectComponent {
  @Output() selectedOptionChange = new EventEmitter();
  @Input() opportunityFilterOptions: SelectOptions[];
  @Input() selectedOption: string;

  onSelectFilterCondition(): void {
    this.selectedOptionChange.emit(this.selectedOption);
  }
}
