import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { fromEvent, Observable, Subject } from 'rxjs';
import { exhaustMap, filter, map, takeUntil, throttleTime } from 'rxjs/operators';
import { concat, isEmpty, startCase } from 'lodash';
import { MilestoneInfo, MilestoneList } from '@interfaces/Milestone';
import { MilestoneApi } from '@apis/milestone.api';
import { getAdvancedFilterConditions, getMilestoneOverduePageFilterList } from '@utils/filter';
import { SearchItem } from '@interfaces/Filter';
import { DEFAULT_MILESTONE_SORTING, sorting } from '@constants/sortMapping';
import { NoteService } from '@services/note.service';
import { NoteType } from '@constants/note';
import { NzTableComponent } from 'ng-zorro-antd/table';
import { InvoiceList } from '@interfaces/Invoice';
import { AclService } from '@services/acl.service';
import { MatomoTracker } from 'ngx-matomo';
import {
  ACTION_CLICK,
  ACTION_FILTER,
  ACTION_SORT,
  CATEGORY_DATE_TIPS,
  NAME_DASHBOARD_MILESTONE,
  NAME_FILTER_EVENT_MAPPING,
} from '@constants/matomo';
import { AutoUnsubscribe } from '@app/decorators/autoUnsubscribe';
import { MILESTONE_FILTER_RANGE_LIST } from '@constants/countdown';
import { FILTER_MILESTONE_OVERDUE_DAYS } from '@constants/filterType';
import { formatDate, MomentFormat } from '@constants/dateFormat';
import { HighRiskOpportunityPageService } from '@services/high-risk-opportunity-page.service';
import moment from 'moment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ExportApi } from '@apis/export.api';
import { saveAs } from 'file-saver';
import { ToastMessageService } from '@services/toast-message.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-milestone-page',
  templateUrl: './milestone-page.component.html',
  styleUrls: ['./milestone-page.component.scss'],
})
export class MilestonePageComponent implements OnInit, AfterViewInit {
  @ViewChild('milestoneDashboard') tableCom: NzTableComponent<any>;
  milestoneList: MilestoneInfo[] = [];
  loadingFirst = true;
  loadingMore = false;
  isLastPage = false;
  totalElements: number;
  mapOfSort = {};
  milestoneNoteType = NoteType.MILESTONE;
  isDataTipsVisible = false;
  pageName = NAME_DASHBOARD_MILESTONE;
  filterRangeList = MILESTONE_FILTER_RANGE_LIST;
  filterItem = {};
  milestoneOverdueDaysFilterOptions = [
    { label: '-14 - 0', value: FILTER_MILESTONE_OVERDUE_DAYS.DAYS_LEVEL_1 },
    { label: '1 - 30', value: FILTER_MILESTONE_OVERDUE_DAYS.DAYS_LEVEL_2 },
    { label: '31 - 55', value: FILTER_MILESTONE_OVERDUE_DAYS.DAYS_LEVEL_3 },
    { label: '56 - 90', value: FILTER_MILESTONE_OVERDUE_DAYS.DAYS_LEVEL_4 },
    { label: '> 90', value: FILTER_MILESTONE_OVERDUE_DAYS.DAYS_LEVEL_5 },
  ];
  selectedMilestoneOverdueDays: string[] = [];
  demandActionNeededFilterOptions = [
    { label: 'Need Demand Action', value: true },
    { label: 'No Demand Action Needed', value: false },
  ];
  selectedDemandActionNeeded: string[] = [];
  milestoneDueDateRange = [];
  NAME_FILTER_EVENT_MAPPING = NAME_FILTER_EVENT_MAPPING;
  private pageIndex = 0;
  private pageSize = 100;
  private scrollEle: HTMLElement;
  private filters: Array<SearchItem>;
  private searchItem = {};
  private unsubscribe = new Subject();
  private currentScrollTop: number;

  constructor(
    private milestoneApi: MilestoneApi,
    private noteService: NoteService,
    private highRiskOpportunityPageService: HighRiskOpportunityPageService,
    public aclService: AclService,
    private matomoTracker: MatomoTracker,
    private message: NzMessageService,
    private exportApi: ExportApi,
    private toastService: ToastMessageService,
  ) {}

  ngOnInit() {
    this.initFilterAndSort();
    this.initPageData();
  }

  ngAfterViewInit(): void {
    this.listenScrollAndLoadMore();
    this.listenCategoryChanged();
  }

  sort(sortingType, sortField: string) {
    if (!isEmpty(sortingType)) {
      this.mapOfSort = { [sortField]: sortingType };
      this.initPageData();
      this.matomoTracker.trackEvent(this.pageName, ACTION_SORT, `Sort by ${startCase(sortField)}`);
    } else {
      const currentSortField = Object.keys(this.mapOfSort)[0];
      if (sortField && currentSortField === sortField) {
        this.mapOfSort = {};
        this.initPageData();
        this.matomoTracker.trackEvent(this.pageName, ACTION_SORT, `Sort by ${startCase(sortField)}`);
      }
    }
  }

  openDataTips(): void {
    this.isDataTipsVisible = true;
    this.matomoTracker.trackEvent(CATEGORY_DATE_TIPS, ACTION_CLICK, `Check Data Specification of ${this.pageName}`);
  }

  closeDataTips(): void {
    this.isDataTipsVisible = false;
  }

  private initFilterAndSort() {
    this.mapOfSort = {};
    this.filters = [];
    this.searchItem = {};
  }

  private initPageData(tag = '') {
    this.loadingFirst = true;
    this.milestoneList = [];
    this.pageIndex = -1;
    this.postForMilestoneList()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: InvoiceList) => {
        this.loadingFirst = false;
        this.scrollEle = null;
        this.setPageData(data);
        this.recordMatomoWhenClickViewOrResetOrDownload(tag);
      });
  }

  private recordMatomoWhenClickViewOrResetOrDownload(tag: string) {
    if (tag === 'clickView' && this.filters.length > 0) {
      const filters = this.filters
        .filter((item) => Object.keys(NAME_FILTER_EVENT_MAPPING).includes(item.name))
        .map((item) => NAME_FILTER_EVENT_MAPPING[item.name] || item.name);
      const deduplicateFilters = Array.from(new Set(filters)).join('&');
      this.matomoTracker.trackEvent(this.pageName, ACTION_FILTER, `View filters ${deduplicateFilters} result`);
    }
    if (tag === 'clickReset') {
      this.matomoTracker.trackEvent(this.pageName, ACTION_FILTER, 'Reset all filters');
    }
    if (tag === 'clickDownload') {
      this.matomoTracker.trackEvent(this.pageName, ACTION_FILTER, 'click download');
    }
  }

  private setPageData(data: InvoiceList) {
    this.milestoneList = data.content;
    this.pageIndex = data.number;
    this.isLastPage = data.last;
    this.totalElements = data.totalElements;
    this.milestoneList.forEach((invoice) => {
      if (!isEmpty(invoice.notes)) {
        this.noteService.storeNotes(NoteType.MILESTONE, invoice.notes);
      }
    });
  }

  private listenScrollAndLoadMore() {
    const nativeElement = this.tableCom.nzTableInnerScrollComponent.tableBodyElement.nativeElement;
    this.listenScroll(nativeElement)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: InvoiceList) => {
        this.loadingMore = false;
        data.content = concat(this.milestoneList, data.content);
        this.setPageData(data);
        this.scrollEle.scrollTop = this.currentScrollTop;
      });
  }

  private listenScroll(nativeElement) {
    return fromEvent(nativeElement, 'scroll').pipe(
      map((event) => this.scrollEle || (event as Event).target),
      filter((ele: HTMLElement) => {
        const isTriggerBottom = ele.scrollTop + ele.offsetHeight + 20 >= ele.scrollHeight;

        return !this.isLastPage && ele.scrollHeight !== 0 && isTriggerBottom;
      }),
      exhaustMap((ele: HTMLElement) => {
        this.scrollEle = ele;
        this.currentScrollTop = ele.scrollHeight - ele.offsetHeight + 40;

        this.loadingMore = true;

        return this.postForMilestoneList();
      }),
      throttleTime(1000),
    );
  }

  private listenCategoryChanged() {
    this.highRiskOpportunityPageService
      .listenHighRiskPage()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.initPageData();
      });
  }

  private postForMilestoneList(): Observable<MilestoneList> {
    this.filters = getMilestoneOverduePageFilterList(this.getPageSearchItemAndCustomFilterFormat());
    this.filters.push(...getAdvancedFilterConditions(this.filterItem));
    const orders = isEmpty(this.mapOfSort)
      ? DEFAULT_MILESTONE_SORTING
      : Object.keys(this.mapOfSort)
          .map((field) => ({ field, value: sorting[this.mapOfSort[field]] }))
          .reduce((acc, cur) => {
            acc[cur.field] = cur.value;
            return acc;
          }, {});
    return this.milestoneApi.getMilestoneList(++this.pageIndex, this.pageSize, this.filters, orders);
  }

  onSearchChange(searchItem) {
    this.searchItem = searchItem;
    this.filters = [];
    this.initPageData();
  }

  handleFilterChanged(event: any) {
    this.filterItem = {
      ...event,
    };
    this.initPageData('clickView');
  }

  handleFilterReset(event: any) {
    this.filterItem = {
      ...event,
    };
    this.selectedMilestoneOverdueDays = [];
    this.selectedDemandActionNeeded = [];
    this.milestoneDueDateRange = [];
    this.initPageData('clickReset');
  }

  private getPageSearchItemAndCustomFilterFormat() {
    const formatMilestoneDueDateRange =
      this.milestoneDueDateRange.length === 0
        ? {}
        : {
            from: formatDate(this.milestoneDueDateRange[0]),
            to: formatDate(this.milestoneDueDateRange[1]),
          };
    return {
      searchItem: this.searchItem,
      overdueDays: this.selectedMilestoneOverdueDays,
      milestoneDueDate: formatMilestoneDueDateRange,
      demandActionNeeded: this.selectedDemandActionNeeded,
    };
  }

  handleSelectedCommonFilterChangeForMatomoRecord(event: any) {
    this.matomoTracker.trackEvent(this.pageName, ACTION_FILTER, event);
  }

  handleSelectedAdvancedFilterChangeForMatomoRecord(filterName: string) {
    this.matomoTracker.trackEvent(this.pageName, ACTION_FILTER, filterName);
  }
  download() {
    this.recordMatomoWhenClickViewOrResetOrDownload('clickDownload');
    const id = this.message.loading('Action in progress..', { nzDuration: 0 }).messageId;
    this.exportApi
      .exportExcel('dso-milestone-overdue', this.filters)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (data) => {
          const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
          this.message.remove(id);
          saveAs(blob, `Milestone Overdue Dashboard (Download date ${moment(Date.now()).format(MomentFormat.DATE)})`);
          this.toastService.success('Export finished');
        },
        () => {
          this.message.remove(id);
        },
      );
  }
}
