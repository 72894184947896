import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { OpportunityInfo } from '@interfaces/OpportunityDetail';
import { ActivatedRoute } from '@angular/router';
import { OpportunityApi } from '@apis/opportunity.api';
import { NzTableComponent } from 'ng-zorro-antd/table';
import { exhaustMap, filter, map, takeUntil, throttleTime } from 'rxjs/operators';
import { fromEvent, Observable, Subject } from 'rxjs';
import { OpportunityList } from '@interfaces/OpportunityDashboard';
import { concat, get, isEmpty, startCase } from 'lodash';
import {
  DEFAULT_DASHBOARD_OPPORTUNITY_SORTING,
  SORT_MAP_TYPE_ACCOUNT,
  SORT_MAP_TYPE_OPPORTUNITY,
  SORT_MAP_TYPE_OWNER,
  sorting,
} from '@constants/sortMapping';
import { FilterService } from '@services/filter.service';
import { Filter, SearchItem } from '@interfaces/Filter';
import { getDashboardFilterList, isOpportunityPageChange } from '@utils/filter';
import { FILTER_STATUS_DEACTIVATED, FILTER_STATUS_ONGOING } from '@constants/filterType';
import { SortMappingService } from '@services/sort-mapping.service';
import { AccountPageService } from '@services/account-page.service';
import { OpportunityPageService } from '@services/opportunity-page.service';
import { OpportunityOwnerPageService } from '@services/opportunity-owner-page.service';
import { MuPageService } from '@services/mu-page.service';
import { MatomoTracker } from 'ngx-matomo';
import { ACTION_FILTER, ACTION_SORT, CATEGORY_DASHBOARD_OPPORTUNITY } from '@constants/matomo';

@Component({
  selector: 'app-opportunity-list',
  templateUrl: './opportunity-list.component.html',
  styleUrls: ['./opportunity-list.component.scss'],
})
export class OpportunityListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('opportunityDashboard') tableCom: NzTableComponent<any>;
  opportunityList: OpportunityInfo[] = [];
  columnsWidth = ['9%', '11%', '9%', '8%', '8%', '8%', '8%', '9%', '9%', '10%', '10%'];
  loadingFirst = false;
  loadingMore = false;
  isLastPage = false;
  status: string = FILTER_STATUS_ONGOING;
  filters: Array<SearchItem>;
  mapOfSort = {};
  pageName = CATEGORY_DASHBOARD_OPPORTUNITY;
  private pageIndex = 0;
  private pageSize = 100;
  private scrollEle: HTMLElement;
  private orders: any = DEFAULT_DASHBOARD_OPPORTUNITY_SORTING;
  private currentScrollTop: number;
  private unsubscribe = new Subject();

  constructor(
    private route: ActivatedRoute,
    private opportunityApi: OpportunityApi,
    private filterService: FilterService,
    private sortMappingService: SortMappingService,
    private opportunityPageService: OpportunityPageService,
    private opportunityOwnerPageService: OpportunityOwnerPageService,
    private accountPageService: AccountPageService,
    private muPageService: MuPageService,
    private matomoTracker: MatomoTracker,
  ) {}

  ngOnInit() {
    this.initFilterAndSortFromService();
    this.initOpportunityPage();
    this.triggerFilter();
  }

  ngAfterViewInit(): void {
    this.listenScrollAndLoadMore();
  }

  ngOnDestroy(): void {
    this.setOpportunityPageToService();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private initFilterAndSortFromService() {
    this.status = this.filterService.getOpportunityStatus();
    this.filters = getDashboardFilterList(this.filterService.getFilterItem());
    this.mapOfSort = this.sortMappingService.getSort(SORT_MAP_TYPE_OPPORTUNITY);
  }

  private initOpportunityPage() {
    const opportunityPage = get(this.opportunityPageService.get(), 'content', []);
    const isLastPage = get(this.opportunityPageService.get(), 'last', false);
    isEmpty(opportunityPage) && !isLastPage ? this.initPageData() : this.setPageData(this.opportunityPageService.get());
  }

  private initPageData() {
    this.loadingFirst = true;
    this.opportunityList = [];
    this.pageIndex = -1;
    this.isLastPage = false;
    this.postForOpportunityList()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: OpportunityList) => {
        this.loadingFirst = false;
        this.scrollEle = null;
        this.setPageData(data);
      });
  }

  private setPageData(data: OpportunityList) {
    this.opportunityList = data.content;
    this.pageIndex = data.number;
    this.isLastPage = data.last;
  }

  private setOpportunityPageToService() {
    const opportunityPage = {
      content: this.opportunityList,
      number: this.pageIndex,
      last: this.isLastPage,
    };
    this.opportunityPageService.set(opportunityPage);
  }

  private listenScrollAndLoadMore() {
    const nativeElement = this.tableCom.nzTableInnerScrollComponent.tableBodyElement.nativeElement;
    this.listenScroll(nativeElement)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: OpportunityList) => {
        this.loadingMore = false;
        data.content = concat(this.opportunityList, data.content);
        this.setPageData(data);
        this.scrollEle.scrollTop = this.currentScrollTop;
      });
  }

  private listenScroll(nativeElement) {
    return fromEvent(nativeElement, 'scroll').pipe(
      map((event) => this.scrollEle || (event as Event).target),
      filter((ele: HTMLElement) => {
        const isTriggerBottom = ele.scrollTop + ele.offsetHeight + 20 >= ele.scrollHeight;

        return !this.isLastPage && ele.scrollHeight !== 0 && isTriggerBottom;
      }),
      exhaustMap((ele: HTMLElement) => {
        this.scrollEle = ele;
        this.currentScrollTop = ele.scrollHeight - ele.offsetHeight + 40;

        this.loadingMore = true;

        return this.postForOpportunityList();
      }),
      throttleTime(1000),
    );
  }

  private postForOpportunityList(): Observable<OpportunityList> {
    return this.opportunityApi.getOpportunityList(++this.pageIndex, this.pageSize, this.orders, this.filters);
  }

  sort(sortingType, sortKey: string) {
    if (!isEmpty(sortingType)) {
      this.orders = { [sortKey]: sorting[sortingType] };
      this.mapOfSort = { [sortKey]: sortingType };
      this.sortMappingService.setSort(SORT_MAP_TYPE_OPPORTUNITY, this.mapOfSort);
      this.initPageData();
      this.matomoTracker.trackEvent(CATEGORY_DASHBOARD_OPPORTUNITY, ACTION_SORT, `Sort by ${startCase(sortKey)}`);
    }
  }

  private triggerFilter() {
    this.filterService
      .listenFilter()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((item: Filter) => {
        if (isOpportunityPageChange(this.filters, (this.filters = getDashboardFilterList(item)))) {
          this.muPageService.set([]);
          this.opportunityOwnerPageService.set({});
          this.sortMappingService.clear(SORT_MAP_TYPE_OWNER);
          this.accountPageService.set({});
          this.sortMappingService.clear(SORT_MAP_TYPE_ACCOUNT);
        }

        this.status = get(item, 'status', FILTER_STATUS_ONGOING);
        this.clearSort();
        this.initPageData();
      });
  }

  private clearSort() {
    this.orders = DEFAULT_DASHBOARD_OPPORTUNITY_SORTING;
    this.mapOfSort = {};
    this.sortMappingService.clear(SORT_MAP_TYPE_OPPORTUNITY);
  }

  switchRadioButton(status) {
    this.filterService.triggerFilter({ status });
    this.matomoTracker.trackEvent(this.pageName, ACTION_FILTER, `Filter by ${startCase(status)}`);
  }

  isDeactivated(status: string) {
    return status ? status === FILTER_STATUS_DEACTIVATED : true;
  }
}
