<app-right-drawer [isDataTipsVisible]="isDataTipsVisible" (closeTips)="closeTips()">
  <div class="info-area">
    <div class="info-world">Currency</div>
    <div>The currency of the invoice amount is unified as CNY.</div>
  </div>
  <div class="info-area">
    <div class="info-world">Total amount in the upper right</div>
    <div>The number is the total amount of the overdue invoices in CNY.</div>
  </div>
  <div class="info-area">
    <div class="info-world">Countdown</div>
    <ul class="invoice-info top16 countdown-info">
      <li class="bold">
        <div></div>
        <div>Update Frequency</div>
        <div>Calculation Logic</div>
      </li>
      <li>
        <div><p class="bold ita">Countdown</p></div>
        <div>Daily</div>
        <div>
          <p>Countdown = Date Today - Invoice Due Date (means the day after the payment term, starting from date invoice sent)</p>
          <p class="top16">
            Invoices overdue or at the due date are displayed on this dashboard. All invoices are in “Invoice posted to AR” status.
          </p>
        </div>
      </li>
    </ul>
  </div>
  <div class="info-area">
    <p class="bold ita">* Default sorting</p>
    <span>The default sorting of the dashboard is countdown descending.</span>
  </div>
</app-right-drawer>
