import { find, includes, isEmpty, isEqual, map, omit, orderBy, set, snakeCase, sortBy, trim, uniq } from 'lodash';
import { CreateLegalDocumentRequest, LegalDocumentInfoWithAllocatedOpportunity } from '@interfaces/legalDocument';
import { Role } from '@interfaces/Acl';
import { Invoice } from '@interfaces/Invoice';
import { YYYY_MM_DD_PATTERN } from '@constants/regexp-pattern';
import moment from 'moment/moment';
import { MomentFormat } from '@constants/dateFormat';
import { INVOICE_STATUS_CREDIT, INVOICE_STATUS_POSTED_TO_AR, updateCountdown } from '@constants/invoiceStatus';

export function findSameValueIndexes(dataset: string[], ignoreValues: string[] = []) {
  const mapping = {};
  const indexes = [];
  for (let i = 0; i < dataset.length; i++) {
    const value = dataset[i];
    if (ignoreValues.includes(value)) {
      continue;
    }
    if (mapping[value] === undefined) {
      mapping[value] = i;
    } else {
      indexes.push(i, mapping[value]);
    }
  }
  return uniq(indexes).sort();
}

export function isSameTotalAmount(opportunityAmount: number, totalMilestoneAmount: number) {
  return Math.abs(opportunityAmount - totalMilestoneAmount) <= 1;
}

export function toDate(dateString: string) {
  return dateString ? new Date(dateString) : null;
}

export function conversionFromChineseToEnglish(value: string) {
  let idx = -1;
  const MAP = 'ABCDEFGHJKLMNOPQRSTWXYZ';
  const boundaryChar = '驁簿錯鵽樲鰒餜靃攟鬠纙鞪黁漚曝裠鶸蜶籜鶩鑂韻糳';

  return snakeCase(value)
    .split('')
    .map((char) => {
      if (/[^\u4e00-\u9fa5]/.test(char)) {
        return char;
      }
      for (let i = 0; i < boundaryChar.length; i++) {
        if (boundaryChar[i].localeCompare(char, 'zh-CN-u-co-pinyin') >= 0) {
          idx = i;
          break;
        }
      }

      return MAP[idx];
    })
    .join('');
}

export function trimOrReturnNull(value: string): string {
  return isEmpty(trim(value)) ? null : trim(value);
}

export function getDiffDays(endDate: Date, startDate: Date, showNegative: boolean): number {
  const timesDiff = endDate.getTime() - startDate.getTime();
  const diffDays = Math.floor(timesDiff / (1000 * 60 * 60 * 24));
  if (showNegative) {
    return diffDays;
  }

  return diffDays > 0 ? diffDays : 0;
}

export function isSameLegalDocumentInfo(
  originalLegalDocumentInfo: LegalDocumentInfoWithAllocatedOpportunity,
  changedLegalDocumentInfo: CreateLegalDocumentRequest,
): boolean {
  const isLegalDocumentSame = isEqual(
    omit(originalLegalDocumentInfo, ['allocatedOpportunities']),
    omit(changedLegalDocumentInfo, ['allocatedOpportunities']),
  );

  const originalAllocations = map(originalLegalDocumentInfo.allocatedOpportunities, (allocation) => ({
    opportunityId: allocation.opportunityId,
    allocatedAmount: allocation.allocatedAmount,
    allocateFrom: allocation.allocatedStartDate,
    allocateTo: allocation.allocatedEndDate,
  }));
  const isAllocationSame = isEqual(
    sortBy(originalAllocations, 'opportunityId'),
    sortBy(changedLegalDocumentInfo.allocatedOpportunities, 'opportunityId'),
  );
  return isLegalDocumentSame && isAllocationSame;
}

export function formatRole(roles: Role[]) {
  (roles || []).forEach((role) => {
    set(role, 'isAdmin', !isEmpty(find(role.permissions, { name: 'admin.access' })));
  });

  return orderBy(roles, ['isAdmin', 'permissions'], ['desc', 'desc']);
}

export function getDiffDaysWithDueDate(invoiceItem: Invoice, endDate: string) {
  const { invoiceDueDate } = invoiceItem;

  if (!isEmpty(invoiceItem)) {
    if (YYYY_MM_DD_PATTERN.exec(invoiceDueDate)) {
      set(invoiceItem, 'countdown', getDiffDays(new Date(endDate), new Date(invoiceDueDate), true));
    }
  }
}

export function getInvoiceCountdown(invoiceItem: Invoice) {
  const { invoiceStatus, paymentReceiveDate } = invoiceItem;
  const today = moment(new Date()).format(MomentFormat.DATE);

  if (includes(updateCountdown, invoiceStatus)) {
    getDiffDaysWithDueDate(invoiceItem, paymentReceiveDate);
  } else if (isEqual(INVOICE_STATUS_CREDIT, invoiceStatus)) {
    set(invoiceItem, 'countdown', null);
  } else if (isEqual(INVOICE_STATUS_POSTED_TO_AR, invoiceStatus)) {
    getDiffDaysWithDueDate(invoiceItem, today);
  }
}
