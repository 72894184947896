<nz-modal
  nzClassName="mark-milestone-done-modal"
  [nzVisible]="isVisible"
  [nzMaskClosable]="false"
  [nzWidth]="480"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  (nzOnCancel)="handleCancel()"
>
  <ng-template #modalTitle>
    <div class="modal-title-with-label">{{ title }}</div>
  </ng-template>

  <ng-template #modalContent>
    <div class="milestone-dialog-body">
      <div class="scroll-container">
        <nz-form-item>
          <div class="form-label">Milestone Name</div>
          <input nz-input nzAutosize data-testid="milestone-name" [value]="milestone.name" disabled />
        </nz-form-item>
        <nz-form-item>
          <div class="form-label">Milestone Due Date</div>
          <input nz-input nzAutosize data-testid="milestone-due-date" [value]="milestone.milestoneDueDate" disabled />
        </nz-form-item>
        <nz-form-item>
          <div class="form-label">Milestone Amount</div>
          <input
            nz-input
            nzAutosize
            data-testid="milestone-amount"
            [value]="milestone.milestoneAmount | currencyFormat : currency : false"
            disabled
          />
        </nz-form-item>
        <nz-form-item class="last-for-item">
          <div class="form-label">
            <span style="color: red">*</span>
            Client Acceptance Materials
          </div>
          <app-upload
            class="upload"
            [isReupload]="isReupload"
            [milestoneEditId]="milestone.milestoneEditId"
            (emitSubmitFile)="submitFile()"
          >
            <div class="form-label" style="color: #db5157" *ngIf="showWarn">Please upload the client acceptance materials.</div>
            <div class="form-tips">
              <div>
                <app-icon-text className="icon-tips"></app-icon-text>
                <span>Tips</span>
              </div>
              <ul>
                <li class="tip-text">
                  Material should include the evidence that can prove the client has accepted our delivery of this milestone. Evidence types
                  include email attachment, screenshot, meeting minutes, etc.
                </li>
              </ul>
            </div>
          </app-upload>
        </nz-form-item>
      </div>
    </div>
  </ng-template>

  <ng-template #modalFooter>
    <div class="milestone-dialog-footer">
      <div class="operation-btns">
        <button class="cancel-btn" nz-button nzType="default" (click)="handleCancel()">Cancel</button>
        <button
          nz-popconfirm
          [nzPopconfirmVisible]="isConfirmPopVisible"
          [nzPopconfirmTrigger]="null"
          [nzPopconfirmShowArrow]="false"
          nzOkText="Yes"
          nzCancelText="Cancel"
          nzPopconfirmTitle="After submitted, the Milestone will not be able to be modified. Are you sure to submit?"
          nzPopconfirmPlacement="bottom"
          nzPopconfirmOverlayClassName="confirmDeletePopover"
          (nzOnConfirm)="handleSubmit()"
          (nzOnCancel)="handleCancelSubmit()"
          nz-button
          nzType="primary"
          type="button"
          [nzLoading]="isSubmitting"
          (click)="openConfirmPop()"
          data-testid="submit-button"
        >
          Submit
        </button>
      </div>
    </div>
  </ng-template>
</nz-modal>
