import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SelectOptions } from '@interfaces/Filter';
import { AdvancedFilterApi } from '@apis/advanced-filter.api';
import { NAME_FILTER_EVENT_MAPPING } from '@constants/matomo';

@Component({
  selector: 'app-advanced-filter',
  templateUrl: './advanced-filter.component.html',
  styleUrls: ['./advanced-filter.component.scss'],
})
export class AdvancedFilterComponent implements OnInit {
  @Output() filterChanged = new EventEmitter();
  @Output() filterReset = new EventEmitter();
  //定义一个事件发射器，download会发射这个事件
  @Output() downloadEvent = new EventEmitter();
  @Output() selectedFilterChanged = new EventEmitter();
  accountOptions: SelectOptions[] = [];
  selectedAccounts: SelectOptions[] = [];
  marketOptions: SelectOptions[] = [];
  selectedMarkets: SelectOptions[] = [];
  governanceUnitOptions: SelectOptions[] = [];
  selectedGovernanceUnits: SelectOptions[] = [];
  opportunityOwnerOptions: SelectOptions[] = [];
  selectedOpportunityOwners: SelectOptions[] = [];
  pmOptions: SelectOptions[] = [];
  selectedPms: SelectOptions[] = [];
  riskOptions: SelectOptions[] = [];
  selectedRisks: SelectOptions[] = [];
  isShowMore = false;
  NAME_FILTER_EVENT_MAPPING = NAME_FILTER_EVENT_MAPPING;

  constructor(private advancedFilterApi: AdvancedFilterApi) {}

  ngOnInit(): void {
    this.advancedFilterApi.getAdvancedFilterData().subscribe((data) => {
      this.accountOptions = data.accounts.map((account) => ({ label: account.name, value: account.id }));
      this.marketOptions = data.markets.map((market) => ({
        label: market,
        value: market,
      }));
      this.governanceUnitOptions = data.governanceUnits.map((governanceUnit) => ({
        label: governanceUnit,
        value: governanceUnit,
      }));
      this.opportunityOwnerOptions = data.opportunityOwners.map((opportunityOwner) => ({
        label: opportunityOwner.firstName + ' ' + opportunityOwner.lastName,
        value: opportunityOwner.email,
      }));
      this.pmOptions = data.projectManagers.map((pm) => ({
        label: pm.firstName + ' ' + pm.lastName,
        value: pm.employeeId,
      }));
    });
    this.riskOptions = [
      { label: 'With Risk', value: true },
      { label: 'Without Risk', value: false },
    ];
  }

  handleShowMoreToggle() {
    this.isShowMore = !this.isShowMore;
  }

  handleReset() {
    this.selectedAccounts = [];
    this.selectedRisks = [];
    this.selectedMarkets = [];
    this.selectedGovernanceUnits = [];
    this.selectedOpportunityOwners = [];
    this.selectedPms = [];
    this.filterReset.emit(this.getFilterResult());
  }

  handleFilter() {
    this.filterChanged.emit(this.getFilterResult());
  }

  getFilterResult() {
    return {
      account: this.selectedAccounts,
      market: this.selectedMarkets,
      governanceUnit: this.selectedGovernanceUnits,
      opportunityOwner: this.selectedOpportunityOwners,
      projectManager: this.selectedPms,
      risk: this.selectedRisks,
    };
  }

  download() {
    this.downloadEvent.emit(this.getFilterResult());
  }

  handleFilterChange(filterName: string) {
    this.selectedFilterChanged.emit(filterName);
  }
}
