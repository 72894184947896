<div
  class="mu-tag"
  [ngStyle]="{ backgroundColor: getMuColor(mu) }"
  nz-popover
  (click)="clickPopover = true; clickPopoverBtn($event)"
  [nzPopoverTitle]="popoverTitle"
  nzPopoverTrigger="click"
  [(nzPopoverVisible)]="showPopover"
  [nzPopoverContent]="popoverContent"
  [nzPopoverPlacement]="popoverPlacement"
  [nzPopoverOverlayClassName]="'editMuPopover'"
>
  {{ mu ? mu : 'Unassigned' }}
</div>

<ng-template #popoverTitle>
  <div class="popover-title-section-edit" (click)="clickPopover = true">
    <p class="note-title-edit">
      Edit Account MU
      <app-error-tooltip
        *ngIf="!aclService.isPermission(aclService.features.accountEditMu)"
        [message]="'No permission to edit mu tag'"
      ></app-error-tooltip>
    </p>
    <div>
      <nz-select
        [ngModel]="mu"
        class="mu-select"
        [nzSuffixIcon]="selectIcon"
        [nzPlaceHolder]="mu ? mu : 'Select MU'"
        (nzOpenChange)="clickPopover = true"
        [nzOptionHeightPx]="24"
        [disabled]="!aclService.isPermission(aclService.features.accountEditMu)"
        (ngModelChange)="onSelectChange($event)"
      >
        <nz-option
          *ngFor="let selectOption of muList"
          class="mu-select-options"
          [nzLabel]="selectOption.name"
          [nzValue]="selectOption.name"
        ></nz-option>
      </nz-select>
      <button nz-button nzType="primary" class="save-button-edit" (click)="saveMu()" [disabled]="disableSave">
        <i nz-icon nzType="loading" *ngIf="isRequesting" class="centre"></i>
        <span *ngIf="!isRequesting">Save</span>
      </button>
    </div>
    <span class="note-title-edit">MU Edit History</span>
  </div>
</ng-template>

<ng-template #selectIcon>
  <img src="/assets/svg/arrow.svg" alt="arrow" />
</ng-template>

<ng-template #popoverContent>
  <div class="popover-context-section-edit" (click)="clickPopover = true">
    <nz-spin class="centre" nzSimple *ngIf="loadingMuHistory"></nz-spin>
    <ul>
      <nz-timeline *ngIf="!loadingMuHistory">
        <nz-timeline-item *ngFor="let history of muHistory; index as i" class="timeline-item">
          <li class="edit-history-content-edit">
            <p class="mu-name">{{ history.name }}</p>
            <p>{{ history.createDate | date : dateType.DATE }}</p>
            <p class="edit-by">
              <span>edited by</span>
              <span>{{ history.operatorUserName }}</span>
            </p>
          </li>
        </nz-timeline-item>
      </nz-timeline>
    </ul>
    <div *ngIf="!hasMu" class="no-note-left-edit">
      <img src="/assets/svg/error.svg" alt="error" />
      <span>No History Data</span>
    </div>
  </div>
</ng-template>
