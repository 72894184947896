import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { fromEvent, Observable, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { exhaustMap, filter, map, takeUntil, throttleTime } from 'rxjs/operators';
import { concat, get, isEmpty, startCase } from 'lodash';
import { OwnerInfo, OwnerList } from '../../../../interfaces/OwnerList';
import { NzTableComponent } from 'ng-zorro-antd/table';
import { Filter, SearchItem } from '../../../../interfaces/Filter';
import { FilterService } from '../../../../services/filter.service';
import {
  DEFAULT_DASHBOARD_OWNER_SORTING,
  SORT_MAP_TYPE_ACCOUNT,
  SORT_MAP_TYPE_OPPORTUNITY,
  SORT_MAP_TYPE_OWNER,
  sorting,
} from '../../../../constants/sortMapping';
import { SortMappingService } from '../../../../services/sort-mapping.service';
import { OpportunityOwnerPageService } from '../../../../services/opportunity-owner-page.service';
import { getSearchFilterList } from '@utils/filter';
import { OpportunityApi } from '../../../../apis/opportunity.api';
import { AccountPageService } from '../../../../services/account-page.service';
import { OpportunityPageService } from '../../../../services/opportunity-page.service';
import { MuPageService } from '../../../../services/mu-page.service';
import { MatomoTracker } from 'ngx-matomo';
import { ACTION_SORT, CATEGORY_DASHBOARD_OPPORTUNITY_OWNER } from '../../../../constants/matomo';
import { TabIndexService } from '../../../../services/tab-index.service';
import { FILTER_STATUS_ONGOING } from '../../../../constants/filterType';

@Component({
  selector: 'app-opportunity-owner-list',
  templateUrl: './opportunity-owner-list.component.html',
  styleUrls: ['./opportunity-owner-list.component.scss'],
})
export class OpportunityOwnerListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('ownerDashboard') tableCom: NzTableComponent<any>;
  ownerList: OwnerInfo[] = [];
  columnsWidth = ['16%', '12%', '12%', '12%', '12%', '12%', '12%'];
  loadingFirst = false;
  loadingMore = false;
  isLastPage = false;
  mapOfSort = {};
  private pageIndex = -1;
  private pageSize = 100;
  private currentScrollTop: number;
  private scrollEle: HTMLElement;
  private filters: Array<SearchItem>;
  private unsubscribe = new Subject();
  private orders: any = DEFAULT_DASHBOARD_OWNER_SORTING;

  constructor(
    private route: ActivatedRoute,
    private ownerApi: OpportunityApi,
    private tabIndexService: TabIndexService,
    private filterService: FilterService,
    private opportunityOwnerPageService: OpportunityOwnerPageService,
    private sortMappingService: SortMappingService,
    private accountPageService: AccountPageService,
    private opportunityPageService: OpportunityPageService,
    private muPageService: MuPageService,
    private matomoTracker: MatomoTracker,
  ) {}

  ngOnInit() {
    this.initFilterAndSortFromService();
    this.initOwnerPage();
    this.triggerFilter();
  }

  ngAfterViewInit(): void {
    this.listenScrollAndLoadMore();
  }

  ngOnDestroy(): void {
    this.setOwnerPageToService();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private initFilterAndSortFromService() {
    this.mapOfSort = this.sortMappingService.getSort(SORT_MAP_TYPE_OWNER);
    this.filters = getSearchFilterList(this.filterService.getFilterItem());
  }

  private initOwnerPage() {
    const ownerPage = get(this.opportunityOwnerPageService.get(), 'content', []);
    const isLastPage = get(this.opportunityOwnerPageService.get(), 'last', false);
    isEmpty(ownerPage) && !isLastPage ? this.initPageData() : this.setPageData(this.opportunityOwnerPageService.get());
  }

  private initPageData() {
    this.loadingFirst = true;
    this.ownerList = [];
    this.pageIndex = -1;
    this.isLastPage = false;
    this.postForOwnerList()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: OwnerList) => {
        this.loadingFirst = false;
        this.scrollEle = null;
        this.setPageData(data);
      });
  }

  private setPageData(data: OwnerList) {
    this.ownerList = data.content;
    this.pageIndex = data.number;
    this.isLastPage = data.last;
  }

  private setOwnerPageToService() {
    const ownerPage = {
      content: this.ownerList,
      number: this.pageIndex,
      last: this.isLastPage,
    };
    this.opportunityOwnerPageService.set(ownerPage);
  }

  private listenScrollAndLoadMore() {
    const nativeElement = this.tableCom.nzTableInnerScrollComponent.tableBodyElement.nativeElement;
    this.listenScroll(nativeElement)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: OwnerList) => {
        this.loadingMore = false;
        data.content = concat(this.ownerList, data.content);
        this.setPageData(data);
        this.scrollEle.scrollTop = this.currentScrollTop;
      });
  }

  private listenScroll(nativeElement) {
    return fromEvent(nativeElement, 'scroll').pipe(
      map((event) => this.scrollEle || (event as Event).target),
      filter((ele: HTMLElement) => {
        const isTriggerBottom = ele.scrollTop + ele.offsetHeight + 20 >= ele.scrollHeight;

        return !this.isLastPage && ele.scrollHeight !== 0 && isTriggerBottom;
      }),
      exhaustMap((ele: HTMLElement) => {
        this.scrollEle = ele;
        this.currentScrollTop = ele.scrollHeight - ele.offsetHeight + 40;

        this.loadingMore = true;

        return this.postForOwnerList();
      }),
      throttleTime(1000),
    );
  }

  private postForOwnerList(): Observable<OwnerList> {
    const targetPageIndex = this.pageIndex + 1;

    return this.ownerApi.getOwnerList(targetPageIndex, this.pageSize, this.filters, this.orders);
  }

  private triggerFilter() {
    this.filterService
      .listenFilter()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((item: Filter) => {
        this.filters = getSearchFilterList(item);
        this.clearSort();
        this.initPageData();
        this.clearDsoOtherDashboard();
      });
  }

  private clearDsoOtherDashboard() {
    this.muPageService.set([]);
    this.opportunityPageService.set({});
    this.sortMappingService.clear(SORT_MAP_TYPE_OPPORTUNITY);
    this.accountPageService.set({});
    this.sortMappingService.clear(SORT_MAP_TYPE_ACCOUNT);
  }

  private clearSort() {
    this.orders = DEFAULT_DASHBOARD_OWNER_SORTING;
    this.mapOfSort = {};
    this.sortMappingService.clear(SORT_MAP_TYPE_OWNER);
  }

  sort(sortingType, sortKey: string) {
    if (!isEmpty(sortingType)) {
      this.orders = { [sortKey]: sorting[sortingType] };
      this.mapOfSort = { [sortKey]: sortingType };
      this.sortMappingService.setSort(SORT_MAP_TYPE_OWNER, this.mapOfSort);
      this.initPageData();
      this.matomoTracker.trackEvent(CATEGORY_DASHBOARD_OPPORTUNITY_OWNER, ACTION_SORT, `Sort by ${startCase(sortKey)}`);
    }
  }

  getSort(value) {
    return get(this.mapOfSort, value, null);
  }

  filterOpportunityByOpportunityOwner(ownerEmail: string, ownerName: string) {
    const searchItem = { id: ownerEmail, name: ownerName, type: 'owner' };
    const filterItem: Filter = {
      searchItem,
      status: FILTER_STATUS_ONGOING,
      countDown: {},
      withoutPoDays: {},
    };
    this.filterService.triggerFilter(filterItem);
    this.tabIndexService.setIndex(2);
  }
}
