import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { Subscription } from 'rxjs';
import { MilestoneApi } from '@apis/milestone.api';
import { ToastMessageService } from '@services/toast-message.service';
import { FileResponse } from '@interfaces/FileResponse';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnInit {
  @ViewChild('uploadForm') uploadForm: any;
  @Input() milestoneEditId: number;
  @Input() isReupload = false;
  @Output() emitSubmitFile = new EventEmitter<any>();
  constructor(private msg: NzMessageService, private milestoneApi: MilestoneApi, private toastMessageService: ToastMessageService) {}

  fileList: NzUploadFile[] = [];

  totalFilesLimit = 5; // 设置最大上传文件总数

  uploadedFileCount = 0; // 已上传文件数

  isLoading = true;

  limitMessage = true;
  //打开组件默认调用方法
  ngOnInit(): void {
    if (this.isReupload) {
      this.milestoneApi.getUploadedFiles(this.milestoneEditId).subscribe((response: FileResponse[]) => {
        this.isLoading = false;
        response.map((item) => {
          const file = {
            uid: item.id,
            name: item.name,
            response: {
              fileId: item.id,
            },
          };
          this.fileList = [...this.fileList, file];
        });
        this.uploadedFileCount = this.fileList.length;
      });
    } else {
      this.isLoading = false;
    }
  }
  beforeUpload = (file: NzUploadFile, fileList: NzUploadFile[]): boolean => {
    if (fileList.length + this.uploadedFileCount > this.totalFilesLimit) {
      if (this.limitMessage) {
        this.toastMessageService.error('Please upload files no more than 5');
        this.limitMessage = false;

        setTimeout(() => {
          this.limitMessage = true;
        }, 1000);
        return false;
      }
      return false;
    }
    if (file.size > 1024 * 1024 * 30) {
      this.toastMessageService.error('Please upload files with size no more than 30M');
      return false;
    }
    if (
      file.type !== 'application/pdf' &&
      file.type !== 'image/jpeg' &&
      file.type !== 'image/png' &&
      file.type !== 'image/jpg' &&
      file.type !== 'application/x-rar' &&
      file.type !== 'application/zip'
    ) {
      this.toastMessageService.error('Please upload files with zip/pdf/jpeg/png/jpg/rar type');
      return false;
    }
    return true;
  };

  handleChange({ file, fileList }: NzUploadChangeParam): void {
    const status = file.status;
    if (status === 'done') {
      this.uploadedFileCount++;
      this.fileList = fileList;
      this.toastMessageService.success(`${file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      this.toastMessageService.error(`${file.name} file upload failed.`);
    } else if (status === 'removed') {
      this.uploadedFileCount--;
    }
  }
  submitFiles = (item: any): Subscription => {
    return this.milestoneApi.submitFile(item.file, this.milestoneEditId).subscribe(
      (response) => {
        // 处理上传成功的响应
        this.emitSubmitFile.emit();
        item.onSuccess(response, item.file);
      },
      (error) => {
        // 处理上传失败的错误
        item.onError(error, item.file);
      },
    );
  };
  removeFile = (file: NzUploadFile): boolean => {
    this.fileList = this.fileList.filter((item) => item.uid !== file.uid);
    return true;
  };
  validFiles() {
    return this.fileList.length > 0;
  }

  cancelSubmit() {
    this.milestoneApi.cancelSubmit(this.milestoneEditId).subscribe((response) => {
      console.log(response);
    }, console.error);
  }
}
