<div
  nz-dropdown
  nzTrigger="click"
  nzPlacement="bottomRight"
  [nzDropdownMenu]="contract"
  (click)="handleMatomoClickEvent('Contact Us Icon')"
  class="contract-icon icon-button-24"
></div>
<nz-dropdown-menu #contract>
  <ul nz-menu class="contract-menu">
    <li nz-menu-item>
      <a
        class="normal-color-link"
        href="https://central.thoughtworks.net/home/techops-regional-it-china"
        target="_blank"
        (click)="handleMatomoClickEvent('About Us')"
      >
        About Us
      </a>
    </li>
    <li nz-menu-item>
      <a class="normal-color-link" href="mailto:chinaitdso@thoughtworks.com" target="_blank" (click)="handleMatomoClickEvent('Contact Us')">
        Contact Us
      </a>
    </li>
  </ul>
</nz-dropdown-menu>
