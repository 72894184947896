import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { OpportunityApi } from '../apis/opportunity.api';
import { OpportunityDetail } from '../interfaces/OpportunityDetail';

@Injectable({
  providedIn: 'root',
})
export class OpportunityDetailResolveService implements Resolve<OpportunityDetail> {
  constructor(private opportunityApi: OpportunityApi) {}

  resolve(route: ActivatedRouteSnapshot): Observable<OpportunityDetail> {
    const id = route.paramMap.get('id');

    return this.opportunityApi.getOpportunityDetail(id);
  }
}
