<app-back-button></app-back-button>
<div class="role-control-container">
  <div class="title">
    <app-icon-text [className]="'authority'"></app-icon-text>
    <div>User and Permission Administration</div>
  </div>
  <div class="tabs">
    <nz-tabset [nzSelectedIndex]="selectedTabIndex">
      <nz-tab nzTitle="Permissions">
        <ng-template nz-tab>
          <app-permissions></app-permissions>
        </ng-template>
      </nz-tab>
      <nz-tab nzTitle="User & Group">
        <ng-template nz-tab>
          <app-user-group></app-user-group>
        </ng-template>
      </nz-tab>
    </nz-tabset>
  </div>
</div>
