import { Component } from '@angular/core';

@Component({
  selector: 'app-no-permission',
  template:
    '<div class="under-construction-page">\n' +
    '  <img src="/assets/svg/under-construction.svg" alt="under-construction-logo">\n' +
    '  <h1>No permission to access this page.</h1>\n' +
    '  <p>Please come back later.</p>\n' +
    '</div>',
  styleUrls: ['./no-permission.component.scss'],
})
export class NoPermissionComponent {}
