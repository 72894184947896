import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MuInfo } from '@interfaces/MuList';
import { SearchItem } from '@interfaces/Filter';

@Injectable({ providedIn: 'root' })
export class MuApi {
  constructor(private http: HttpClient) {}

  getMuList(filters: Array<SearchItem>): Observable<MuInfo[]> {
    const page = 1;
    const size = 100;

    return this.http.post<MuInfo[]>(`/market-unit/with-dso-info`, {
      page,
      size,
      filters,
    });
  }
}
