import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AclService } from '@services/acl.service';
import { exhaustMap, filter, map, takeUntil, throttleTime } from 'rxjs/operators';
import { fromEvent, Observable, Subject } from 'rxjs';
import { Pageable } from '@interfaces/OpportunityDashboard';
import { LegalDocumentApi } from '@apis/legal-document.api';
import { NzTableComponent } from 'ng-zorro-antd/table';
import { concat, isEmpty, startCase } from 'lodash';
import { DEFAULT_LEGAL_DOCUMENTS_SORTING, SORT_MAP_TYPE_LEGAL_DOCUMENTS, sorting } from '@constants/sortMapping';
import { SortMappingService } from '@services/sort-mapping.service';
import { ACTION_SORT, NAME_DASHBOARD_LEGAL_DOCUMENTS } from '@constants/matomo';
import { MatomoTracker } from 'ngx-matomo';
import { DocumentTypeLabel } from '@app/constants/documentType';
import { getSearchFilterList } from '@utils/filter';
import { Filter, SearchItem } from '@interfaces/Filter';
import { FilterService } from '@services/filter.service';
import { LegalDocument } from '@interfaces/legalDocument';

export interface LegalDocumentsList {
  content?: LegalDocument[];
  pageable?: Pageable;
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  totalPages?: number;
  totalElements?: number;
  size?: number;
  number?: number;
  numberOfElements?: number;
}

@Component({
  selector: 'app-legal-documents-page',
  templateUrl: './legal-documents-page.component.html',
  styleUrls: ['./legal-documents-page.component.scss'],
})
export class LegalDocumentsPageComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('legalDocumentsListDashboard') tableCom: NzTableComponent<any>;
  pageName = NAME_DASHBOARD_LEGAL_DOCUMENTS;
  columnsWidth = ['12%', '12%', '12%', '8%', '8%', '7%', '6%', '6%', '10%', '7%', '8%'];
  loadingFirst = false;
  loadingMore = false;
  isLastPage = true;
  totalElements: number;
  legalDocumentsList?: LegalDocument[] = [];
  mapOfSort = {};
  showEditLegalDocumentModal = false;
  currentLegalDocumentId: number = null;

  private pageIndex = -1;

  private pageSize = 100;

  private orders: any = {};

  private filters: Array<SearchItem>;

  scrollEle: HTMLElement;

  private currentScrollTop: number;

  private unsubscribe = new Subject();

  readonly DocumentTypeLabel = DocumentTypeLabel;

  constructor(
    public aclService: AclService,
    private legalDocumentsListApi: LegalDocumentApi,
    private sortMappingService: SortMappingService,
    private matomoTracker: MatomoTracker,
    private filterService: FilterService,
  ) {}

  ngOnInit(): void {
    this.initFilterAndSortFromService();
    this.initPageData();
    this.triggerFilter();
  }

  ngAfterViewInit(): void {
    this.listenScrollAndLoadMore();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private initFilterAndSortFromService() {
    this.mapOfSort = this.sortMappingService.getSort(SORT_MAP_TYPE_LEGAL_DOCUMENTS);
    this.filters = getSearchFilterList(this.filterService.getFilterItem());
  }

  private triggerFilter() {
    this.filterService
      .listenFilter()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((item: Filter) => {
        this.filters = getSearchFilterList(item);
        this.clearSort();
        this.initPageData();
      });
  }

  private clearSort() {
    this.orders = DEFAULT_LEGAL_DOCUMENTS_SORTING;
    this.mapOfSort = {};
    this.sortMappingService.clear(SORT_MAP_TYPE_LEGAL_DOCUMENTS);
  }

  private initPageData() {
    this.loadingFirst = true;
    this.legalDocumentsList = [];
    this.totalElements = undefined;
    this.pageIndex = -1;
    this.postForLegalDocumentsList()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: LegalDocumentsList) => {
        this.loadingFirst = false;
        this.scrollEle = null;
        this.setPageData(data);
      });
  }

  private setPageData(data: LegalDocumentsList) {
    this.legalDocumentsList = data.content;
    this.pageIndex = data.number;
    this.isLastPage = data.last;
    this.totalElements = data.totalElements;
  }

  private postForLegalDocumentsList(): Observable<LegalDocumentsList> {
    const targetPageIndex = this.pageIndex + 1;
    return this.legalDocumentsListApi.getLegalDocumentsList(targetPageIndex, this.pageSize, this.filters, this.orders);
  }

  private listenScrollAndLoadMore() {
    const nativeElement = this.tableCom.nzTableInnerScrollComponent.tableBodyElement.nativeElement;
    this.listenScroll(nativeElement)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: LegalDocumentsList) => {
        this.loadingMore = false;
        data.content = concat(this.legalDocumentsList, data.content);
        this.setPageData(data);
        this.scrollEle.scrollTop = this.currentScrollTop;
      });
  }

  listenScroll(nativeElement) {
    return fromEvent(nativeElement, 'scroll').pipe(
      map((event) => this.scrollEle || (event as Event).target),
      filter((ele: HTMLElement) => {
        const isTriggerBottom = ele.scrollTop + ele.offsetHeight + 20 >= ele.scrollHeight;

        return !this.isLastPage && ele.scrollHeight !== 0 && isTriggerBottom;
      }),
      exhaustMap((ele: HTMLElement) => {
        this.scrollEle = ele;
        this.currentScrollTop = ele.scrollHeight - ele.offsetHeight + 40;

        this.loadingMore = true;

        return this.postForLegalDocumentsList();
      }),
      throttleTime(1000),
    );
  }

  sort(sortingType, sortKey: string) {
    if (!isEmpty(sortingType)) {
      this.orders = { [sortKey]: sorting[sortingType] };
      this.mapOfSort = { [sortKey]: sortingType };
      this.sortMappingService.setSort(SORT_MAP_TYPE_LEGAL_DOCUMENTS, this.mapOfSort);
      this.initPageData();
      this.matomoTracker.trackEvent(this.pageName, ACTION_SORT, `Sort by ${startCase(sortKey)}`);
    }
  }

  editLegalDocument(documentId: number) {
    this.showEditLegalDocumentModal = true;
    this.currentLegalDocumentId = documentId;
  }

  closeDialog(isUpdate: boolean) {
    if (isUpdate) {
      this.initPageData();
    }
    this.showEditLegalDocumentModal = false;
    this.currentLegalDocumentId = null;
  }
}
