import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, retry, switchMap, takeUntil } from 'rxjs/operators';
import { ESPeople } from '@interfaces/People';
import { SearchApi } from '@apis/search.api';

@Component({
  selector: 'app-user-search-dropdown',
  templateUrl: './user-search-dropdown.component.html',
  styleUrls: ['./user-search-dropdown.component.scss'],
})
export class UserSearchDropdownComponent implements OnInit, AfterViewInit {
  @ViewChild('inputField') inputField: ElementRef;
  @Output() updateFormValue = new EventEmitter<string>();
  @Output() markAsTouched = new EventEmitter();
  @Input() defaultSearchList: ESPeople[];
  @Input() defaultInputValue = '';
  @Input() status: string;
  @Input() touched: boolean;
  @Input() dataTestId: string;
  @Input() errorTip = '';
  inputValue = '';
  visibleSearchList = false;
  loading = false;
  searchList: ESPeople[] = [];

  private searchTextSub = new Subject();
  private unsubscribe = new Subject();

  constructor(private searchApi: SearchApi) {}

  ngOnInit(): void {
    this.inputValue = this.defaultInputValue || '';
    this.subscribeInputChange();
  }

  ngAfterViewInit() {
    this.inputField.nativeElement.addEventListener('focus', () => {
      if (this.inputValue.length == 0 || this.inputValue === this.defaultInputValue) {
        this.searchList = [{ name: 'Please search and select an owner', employeeId: null }, ...this.defaultSearchList];
      }
    });
    this.inputField.nativeElement.addEventListener('blur', () => {
      this.markAsTouched.emit();
    });
  }

  private subscribeInputChange(): void {
    this.searchTextSub
      .pipe(
        debounceTime(500),
        switchMap((input: any) => {
          this.updateFormValue.emit(null);
          if (input.length >= 2) {
            this.visibleSearchList = true;
            this.loading = true;
            return this.searchApi.getSearchResponseByPeople(input);
          }
        }),
        retry(3),
        takeUntil(this.unsubscribe),
      )
      .subscribe(
        (response: ESPeople[]) => {
          this.loading = false;
          this.searchList = response;
        },
        () => {
          this.loading = false;
        },
      );
  }

  handleSearchTextChange(inputText: string): void {
    this.searchTextSub.next(inputText);
  }

  onClickItem(esPeople: ESPeople) {
    this.visibleSearchList = false;
    this.inputValue = esPeople.employeeId ? esPeople.name : '';
    this.updateFormValue.emit(esPeople.employeeId);
  }

  isDisabled() {
    return this.status === 'DISABLED';
  }

  showError() {
    return this.status === 'INVALID' && this.touched;
  }
}
