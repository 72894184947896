<div>
  <app-search-input [page]="pageName" (changeSearch)="onSearchChange($event)"></app-search-input>
  <div class="milestone-overdue">
    <div class="milestone-info">
      <div class="title">
        <div>Milestone Overdue</div>
        <div class="total-number">
          <span class="total">Total:</span>
          <span>{{ totalElements }}</span>
        </div>
      </div>
    </div>
    <app-advanced-filter
      (filterChanged)="handleFilterChanged($event)"
      (filterReset)="handleFilterReset($event)"
      (selectedFilterChanged)="handleSelectedCommonFilterChangeForMatomoRecord($event)"
      (downloadEvent)="download()"
    >
      <div class="hidden-filter">
        <nz-select
          [(ngModel)]="selectedMilestoneOverdueDays"
          (ngModelChange)="handleSelectedAdvancedFilterChangeForMatomoRecord(NAME_FILTER_EVENT_MAPPING.overdueDays)"
          [nzOptionHeightPx]="24"
          class="milestone-overdue-days-select gray-light-placeholder gap"
          nzPlaceHolder="Milestone Overdue Days"
          nzMaxTagCount="1"
          data-testid="milestone-overdue-days-select"
          nzAllowClear="true"
          nzShowArrow="true"
        >
          <nz-option
            *ngFor="let milestoneOverdueDaysFilterOption of milestoneOverdueDaysFilterOptions"
            [nzLabel]="milestoneOverdueDaysFilterOption.label"
            [nzValue]="milestoneOverdueDaysFilterOption.value"
          ></nz-option>
        </nz-select>
        <nz-range-picker
          class="milestone-due-date-picker gap"
          [nzPlaceHolder]="['Milestone Due Start Date', 'End Date']"
          nzMode="date"
          data-testid="milestone-due-date-picker"
          nzFormat="yyyy-MM-dd"
          [(ngModel)]="milestoneDueDateRange"
          (ngModelChange)="handleSelectedAdvancedFilterChangeForMatomoRecord(NAME_FILTER_EVENT_MAPPING.milestoneDueDate)"
        ></nz-range-picker>
        <nz-select
          [(ngModel)]="selectedDemandActionNeeded"
          (ngModelChange)="handleSelectedAdvancedFilterChangeForMatomoRecord(NAME_FILTER_EVENT_MAPPING.demandActionNeeded)"
          [nzOptionHeightPx]="24"
          class="demand-action-needed-select gray-light-placeholder gap"
          nzPlaceHolder="Demand Action Needed"
          nzMode="multiple"
          nzMaxTagCount="1"
          nzShowArrow="true"
          data-testid="demand-action-needed-select"
        >
          <nz-option
            *ngFor="let demandActionNeededFilterOption of demandActionNeededFilterOptions"
            [nzLabel]="demandActionNeededFilterOption.label"
            [nzValue]="demandActionNeededFilterOption.value"
          ></nz-option>
        </nz-select>
      </div>
    </app-advanced-filter>
    <nz-table
      #milestoneDashboard
      [nzData]="milestoneList"
      [nzFrontPagination]="false"
      [nzNoResult]="noData"
      [nzScroll]="{ y: 'calc(100vh - 217px)', x: '2000px' }"
      [nzShowPagination]="false"
    >
      <thead>
        <tr>
          <th
            nzLeft
            nzShowSort
            nzWidth="145px"
            [nzSortFn]="true"
            [nzSortOrder]="mapOfSort['account']"
            (nzSortOrderChange)="sort($event, 'account')"
            class="account"
          >
            Account
          </th>
          <th
            nzLeft
            nzShowSort
            nzWidth="145px"
            [nzSortFn]="true"
            [nzSortOrder]="mapOfSort['opportunity']"
            (nzSortOrderChange)="sort($event, 'opportunity')"
          >
            Opportunity
          </th>
          <th
            nzWidth="165px"
            nzShowSort
            [nzSortFn]="true"
            [nzSortOrder]="mapOfSort['projectCode']"
            (nzSortOrderChange)="sort($event, 'projectCode')"
            class="break-word projectCode"
          >
            Project Code
          </th>
          <th
            nzWidth="120px"
            [nzSortFn]="true"
            [nzSortOrder]="mapOfSort['opportunityMarket']"
            (nzSortOrderChange)="sort($event, 'opportunityMarket')"
            class="break-word"
            nzShowSort
          >
            Market
          </th>
          <th
            nzWidth="140px"
            [nzSortFn]="true"
            [nzSortOrder]="mapOfSort['deliveryUnit']"
            (nzSortOrderChange)="sort($event, 'deliveryUnit')"
            class="break-word"
            nzShowSort
          >
            Delivery Unit
          </th>
          <th
            nzWidth="160px"
            [nzSortFn]="true"
            [nzSortOrder]="mapOfSort['name']"
            (nzSortOrderChange)="sort($event, 'name')"
            class="break-word"
            nzShowSort
          >
            Milestone Name
          </th>
          <th
            nzWidth="120px"
            [nzSortFn]="true"
            [nzSortOrder]="mapOfSort['amount']"
            (nzSortOrderChange)="sort($event, 'amount')"
            class="break-word amount"
            nzShowSort
          >
            Milestone Amount
          </th>
          <th
            nzWidth="125px"
            [nzSortFn]="true"
            [nzSortOrder]="mapOfSort['milestoneDueDate']"
            (nzSortOrderChange)="sort($event, 'milestoneDueDate')"
            class="break-word dueDate"
            nzShowSort
          >
            Milestone Due Date
          </th>
          <th
            nzWidth="106px"
            [nzSortFn]="true"
            [nzSortOrder]="mapOfSort['overdueDays']"
            (nzSortOrderChange)="sort($event, 'overdueDays')"
            nzShowSort
          >
            Overdue Days
          </th>
          <th
            nzWidth="106px"
            [nzSortFn]="true"
            [nzSortOrder]="mapOfSort['hasDeliveryRisk']"
            (nzSortOrderChange)="sort($event, 'hasDeliveryRisk')"
            nzShowSort
          >
            Has Delivery Risk
          </th>
          <th
            nzWidth="106px"
            [nzSortFn]="true"
            [nzSortOrder]="mapOfSort['demandActionNeeded']"
            (nzSortOrderChange)="sort($event, 'demandActionNeeded')"
            nzShowSort
          >
            Demand Action Needed
          </th>
          <th
            nzWidth="130px"
            [nzSortFn]="true"
            [nzSortOrder]="mapOfSort['demandActionOwner']"
            (nzSortOrderChange)="sort($event, 'demandActionOwner')"
            nzShowSort
          >
            Demand Action Owner
          </th>
          <th
            nzWidth="130px"
            [nzSortFn]="true"
            [nzSortOrder]="mapOfSort['projectManagerName']"
            (nzSortOrderChange)="sort($event, 'projectManagerName')"
            class="break-word owner"
            nzShowSort
          >
            Project Manager
          </th>
          <th
            nzWidth="130px"
            [nzSortFn]="true"
            [nzSortOrder]="mapOfSort['delegatedProjectManagerName']"
            (nzSortOrderChange)="sort($event, 'delegatedProjectManagerName')"
            class="break-word owner"
            nzShowSort
          >
            Delegated Project Manager
          </th>
          <th
            nzWidth="130px"
            [nzSortFn]="true"
            [nzSortOrder]="mapOfSort['ownerName']"
            (nzSortOrderChange)="sort($event, 'ownerName')"
            class="break-word owner"
            nzShowSort
          >
            Opportunity Owner
          </th>
          <th nzWidth="120px" nzRight nzAlign="center" class="note">
            Actions
            <app-error-tooltip
              *ngIf="!aclService.isPermission(aclService.features.milestoneEditNote)"
              [message]="'No permission to edit milestone note'"
            ></app-error-tooltip>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of milestoneList; index as i">
          <td nzLeft colspan="2">
            <app-small-mu-tag-combination
              [accountName]="item?.accountName"
              [muName]="item?.opportunityMarket"
              [opportunityId]="item?.opportunityId"
              [opportunityName]="item?.opportunityName"
              [isHighRisk]="item?.isHighRisk"
              [riskCategories]="item?.riskCategories"
              [page]="pageName"
            ></app-small-mu-tag-combination>
          </td>
          <td>
            <span>{{ item?.projectCode | emptyFormat }}</span>
          </td>
          <td>
            <span>{{ item?.opportunityMarket | emptyFormat }}</span>
          </td>
          <td>
            <span>{{ item?.deliveryUnit | emptyFormat }}</span>
          </td>
          <td>
            <span>{{ item?.name | emptyFormat }}</span>
          </td>
          <td>
            <span>{{ item?.milestoneAmount | currencyFormat : 'CNY' }}</span>
          </td>
          <td>
            <span>{{ item?.milestoneDueDate | emptyFormat }}</span>
            <span
              *ngIf="item.originMilestoneDueDate"
              [nzTooltipTitle]="milestoneDueDateToolTips"
              nzTooltipPlacement="bottomLeft"
              nz-tooltip
              data-testid="milestone-due-date-icon"
            >
              <i nz-icon nzType="info-circle" nzTheme="fill" class="milestone-due-date-tips-icon"></i>
            </span>
            <ng-template #milestoneDueDateToolTips>
              <div class="milestone-due-date-tips">
                <div class="tips-content">
                  <div>{{ item.originMilestoneDueDate }}</div>
                  <div class="tips-content-title">Original Milestone Due Date</div>
                </div>
                <div class="divider"></div>
                <div class="tips-content">
                  <div [ngClass]="item.originOverdueDays && item.originOverdueDays > 0 && 'is-overdue'">
                    {{ item.originOverdueDays && item.originOverdueDays > 0 ? item.originOverdueDays : '--' }}
                  </div>
                  <div class="tips-content-title">Original Overdue Days</div>
                </div>
              </div>
            </ng-template>
          </td>
          <td appMilestoneHighlight [countDown]="item?.overdueDays" class="countdown-column">
            <span *ngIf="item?.overdueDays > 0" class="overdue-days-value">{{ item?.overdueDays }}</span>
            <span *ngIf="item?.overdueDays <= 0">--</span>
          </td>
          <td>
            <span>
              {{ item?.hasDeliveryRisk | booleanFormat }}
            </span>
          </td>
          <td>
            <span>
              {{ item?.demandActionNeeded | booleanFormat }}
            </span>
          </td>
          <td>
            <span>
              {{ item?.demandActionOwnerFullName | emptyFormat }}
            </span>
          </td>
          <td>
            <span>
              {{ item?.projectManagerName | emptyFormat }}
            </span>
          </td>
          <td>
            <span>
              {{ item?.delegatedProjectManagerName | emptyFormat }}
            </span>
          </td>
          <td>
            <span>
              {{ item?.opportunityOwnerName | emptyFormat }}
            </span>
          </td>
          <td nzRight nzAlign="center" class="action-column">
            <app-record-history-popover
              class="action-item"
              [pageName]="pageName"
              [milestoneId]="item.milestoneEditId"
            ></app-record-history-popover>
            <app-note
              class="action-item"
              [disableEditNote]="!aclService.isPermission(aclService.features.milestoneEditNote)"
              [lookupId]="item?.id.toString()"
              [opportunityName]="item?.opportunityName"
              [page]="pageName"
              [type]="milestoneNoteType"
            ></app-note>
          </td>
        </tr>
        <tr *ngIf="milestoneList?.length > 0">
          <td colspan="15" style="border-bottom: none; background-color: white">
            <app-table-loading-more [isLastPage]="isLastPage" [loadingMore]="loadingMore"></app-table-loading-more>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
  <app-info-tag (openDataTips)="openDataTips()"></app-info-tag>
</div>

<app-milestone-data-tips (closeDataTips)="closeDataTips()" [isDataTipsVisible]="isDataTipsVisible"></app-milestone-data-tips>

<ng-template #noData>
  <app-table-no-data [loadingFirst]="loadingFirst"></app-table-no-data>
</ng-template>
