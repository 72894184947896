export const routers$ = [
  {
    title: 'Opportunity',
    path: 'opportunity',
    collapsedTitle: 'Opp...',
  },
  {
    title: 'Without Contract',
    path: 'po',
    collapsedTitle: 'Wit...',
    hasIndent: true,
  },
  {
    title: 'Milestone Overdue',
    path: 'milestone',
    collapsedTitle: 'Mil...',
    hasIndent: true,
  },
  {
    title: 'Invoice Overdue',
    path: 'invoice',
    collapsedTitle: 'Inv...',
    hasIndent: true,
  },
  {
    title: 'Risk Project',
    path: 'risk-project',
    collapsedTitle: 'Ris...',
    hasIndent: true,
  },
  {
    title: 'DSO',
    path: 'dashboard',
    collapsedTitle: 'DSO',
  },
  {
    title: 'Legal Documents',
    path: 'legal-documents',
    collapsedTitle: 'Leg...',
  },
];
