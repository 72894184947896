import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AdvancedFilter } from '@interfaces/AdvancedFilter';

@Injectable({ providedIn: 'root' })
export class AdvancedFilterApi {
  constructor(private http: HttpClient) {}

  getAdvancedFilterData = (): Observable<AdvancedFilter> => {
    return this.http.get<AdvancedFilter>(`/advanced-filter`);
  };
}
