export const CURRENCY_$ = ['USD', 'CAD', 'SGD', 'CLP', 'AUD', 'HKD', 'TWD'];
export const CURRENCYS = ['CNY', 'HKD', 'TWD', 'USD'];

export const OTHER_CURRENCY = {
  GBP: '£',
  EUR: '€',
  TRY: '₺',
  INR: '₹',
  THB: '฿',
  CNY: '¥',
  BRL: 'R$',
  ZAR: 'R',
  UGX: 'USh',
  RUB: '₽',
  AED: 'د.إ',
  HKD: '$',
  TWD: '$',
  USD: '$',
};

export const FIX_BID_TYPE = 'Fixed Bid';
export const TIME_MATERIAL = 'Time & Materials';
