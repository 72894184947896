<app-toast></app-toast>
<nz-layout class="layout">
  <nz-sider nzCollapsible [(nzCollapsed)]="isCollapsed" [nzTrigger]="null" class="sidebar" nzWidth="215px">
    <a class="logo" (click)="onClickNav('dashboard')" routerLink="/dso/dashboard">
      <img class="logo-dso" src="/assets/png/logo@3x.png" alt="DSO Logo" (click)="onClickDsoLogo()" />
      <span class="logo-management" *ngIf="!isCollapsed" [class.expand-menu]="!isCollapsed">Management</span>
    </a>
    <ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
      <li
        nz-menu-item
        *ngFor="let item of routers"
        class="side-item"
        [class.has-indent]="item.hasIndent"
        [class.side-item-collapse]="isCollapsed"
        [class.side-item-normal]="!isCollapsed"
        routerLinkActive="ant-menu-item-selected"
      >
        <a [routerLink]="item.path" class="side-item-link" (click)="onClickNav(item.path)">
          <i nz-icon class="side-item-dot">
            <svg width="20" height="20">
              <path
                d="M512 512m-101.98376445 0a99.66104433 99.66104433 0 1 0 203.96752871 0 99.66104433 99.66104433 0 1 0-203.96752871 0Z"
              ></path>
            </svg>
          </i>
          <span *ngIf="isCollapsed" class="collapsed-title">{{ item.collapsedTitle }}</span>
          <span *ngIf="!isCollapsed" class="expand-menu">{{ item.title }}</span>
        </a>
      </li>
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header class="header">
      <div class="header-left">
        <div class="collapse" (click)="onClickCollapseIcon()">
          <i class="collapse-icon" nz-icon [nzType]="isCollapsed ? 'right' : 'left'" nzTheme="outline" style="font-size: 11px"></i>
        </div>
      </div>
      <div class="header-right">
        <app-export-dropdown [nz-tooltip]="exportMenuToolTips" nzTooltipPlacement="bottom" nz-tooltip></app-export-dropdown>
        <app-icon-text
          [className]="hasUnreadNotifications ? 'new-notification' : 'notification'"
          (click)="openNotification()"
          [nz-tooltip]="notificationToolTips"
          nzTooltipPlacement="bottom"
          nz-tooltip
        ></app-icon-text>
        <app-contract-information [nz-tooltip]="contactUsToolTips" nzTooltipPlacement="bottom" nz-tooltip></app-contract-information>
        <app-login-menu [nz-tooltip]="profileToolTips" nzTooltipPlacement="bottom" nz-tooltip></app-login-menu>
      </div>
    </nz-header>
    <nz-content>
      <router-outlet></router-outlet>
    </nz-content>
  </nz-layout>
</nz-layout>

<app-notification
  [isNotificationVisible]="isNotificationVisible"
  (closeNotification)="closeNotification()"
  (emitHasNotification)="updateNotificationIcon($event)"
></app-notification>

<ng-template #exportMenuToolTips>
  <div class="tooltips">
    <span>Export Dashboard</span>
  </div>
</ng-template>

<ng-template #notificationToolTips>
  <div class="tooltips">
    <span>Notification</span>
  </div>
</ng-template>

<ng-template #contactUsToolTips>
  <div class="tooltips">
    <span>Contact Us</span>
  </div>
</ng-template>

<ng-template #profileToolTips>
  <div class="tooltips">
    <span>Profile & Roles</span>
  </div>
</ng-template>

<nz-spin nzSimple [nzSize]="'large'" *ngIf="isLoading" class="page-loading"></nz-spin>
