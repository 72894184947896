export const formatterMoney = (val: number): string => val?.toLocaleString();
// eslint-disable-next-line no-useless-escape
export const parserMoney = (valStr: string): string => valStr.replace(/\,/g, '');

export const formatterMoneyWithCurrency = (value: number, currency: string) => (value ? currency + ' ' + value.toLocaleString() : '');

export const preventTypingWhenExceed = (event: KeyboardEvent, maxLength: number): void => {
  const newValue = Number(parserMoney((event.target as HTMLInputElement).value) + event.key);
  if (String(newValue).length > maxLength) {
    event.preventDefault();
  }
};
