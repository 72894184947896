import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LegalDocumentsList } from '@view/dso-view/legal-documents-page/legal-documents-page.component';
import { SortOrder } from '@interfaces/OpportunityDashboard';
import { CreateLegalDocumentRequest, LegalDocumentInfoWithAllocatedOpportunity } from '@interfaces/legalDocument';
import { SearchItem } from '@interfaces/Filter';

@Injectable({ providedIn: 'root' })
export class LegalDocumentApi {
  constructor(private http: HttpClient) {}

  createLegalDocument(legalDocumentInfo: CreateLegalDocumentRequest): Observable<void> {
    return this.http.post<void>('/legal-documents', legalDocumentInfo);
  }

  deleteLegalDocument(id: number): Observable<any> {
    return this.http.delete<number>(`/legal-documents/${id}`);
  }

  getLegalDocumentsList = (page: number, size: number, filters: Array<SearchItem>, orders: SortOrder): Observable<LegalDocumentsList> => {
    return this.http.post<LegalDocumentsList>('/legal-documents/all-pages', {
      page,
      size,
      filters,
      orders,
    });
  };

  getLegalDocumentDetail(legalDocumentId: number): Observable<LegalDocumentInfoWithAllocatedOpportunity> {
    return this.http.get<LegalDocumentInfoWithAllocatedOpportunity>(`/legal-documents/detail/${legalDocumentId}`);
  }

  getAllRelatedReferenceNo = (opportunityId: number) => {
    return this.http.get<string[]>(`/legal-documents/all-related-reference-number`, { params: { opportunityId } });
  };
}
