import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appMyAutofocus]',
})
export class MyAutofocusDirective implements OnInit {
  @Input() focus = false;
  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    if (this.focus) {
      this.elementRef.nativeElement.focus();
    }
  }
}
