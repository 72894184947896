import { NgModule } from '@angular/core';
import { AsOfDateComponent } from './as-of-date/as-of-date.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { CountdownFormatComponent } from './countdown-format/countdown-format.component';
import { DsoFormatComponent } from './dso-format/dso-format.component';
import { SearchDropdownComponent } from './search-dropdown/search-dropdown.component';
import { ItemBorderComponent } from './search-dropdown/item-border/item-border.component';
import { SearchInputComponent } from './search-input/search-input.component';
import { SmallMuTagCombinationComponent } from './small-mu-tag-combination/small-mu-tag-combination.component';
import { HighRiskTagComponent } from './high-risk-tag/high-risk-tag.component';
import { MonthsPickerComponent } from './months-picker/months-picker.component';
import { MultiMonthsPickerComponent } from './multi-months-picker/multi-months-picker.component';
import { NoPermissionComponent } from './no-permission/no-permission.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PipeModule } from '../pipes/pipe.module';
import { DirectiveModule } from '../directives/directive.module';
import { RouterModule } from '@angular/router';
import { RightDrawerComponent } from './right-drawer/right-drawer.component';
import { InfoTagComponent } from './info-tag/info-tag.component';
import { SearchCapsuleComponent } from './search-capsule/search-capsule.component';
import { NoteComponent } from './note/note.component';
import { ToastComponent } from './toast/toast.component';
import { EditMuComponent } from './edit-mu/edit-mu.component';
import { ExportDropdownComponent } from './export-dropdown/export-dropdown.component';
import { TableLoadingMoreComponent } from './table-loading-more/table-loading-more.component';
import { TableNoDataComponent } from './table-no-data/table-no-data.component';
import { CountdownFormatInvoiceComponent } from './countdown-format-invoice/countdown-format-invoice.component';
import { ExpectedCollectionDueDateComponent } from './expected-collection-due-date/expected-collection-due-date.component';
import { MuTagComponent } from './mu-tag/mu-tag.component';
import { MuBlockItemComponent } from './mu-block-item/mu-block-item.component';
import { IconTextComponent } from './icon-text/icon-text.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ContractInformationComponent } from './contract-infomation/contract-information.component';
import { NotificationComponent } from './notification/notification.component';
import { SelectorComponent } from './selector/selector.component';
import { NotificationItemComponent } from './notification-item/notification-item.component';
import { RoleControlComponent } from './role-control/role-control.component';
import { ErrorTooltipComponent } from './error-tooltip/error-tooltip.component';
import { LoginMenuComponent } from './login-menu/login-menu.component';
import { RoleTitleContainerComponent } from './role-title-container/role-title-container.component';
import { EditExpectedMilestoneDueDateComponent } from './edit-expected-milestone-due-date/edit-expected-milestone-due-date.component';
import { NgZorroAntdModule } from '../ng-zorro-antd.module';
import { InfoEditSelectorComponent } from './info-edit-selector/info-edit-selector.component';
import { InfoEditDatePickerComponent } from './info-edit-date-picker/info-edit-date-picker.component';
import { InfoEditCurrencyInputComponent } from './info-edit-currency-input/info-edit-currency-input.component';
import { RangeFilterComponent } from '@shared/countdown-filter/range-filter.component';
import { OpportunityCardComponent } from './opportunity-card/opportunity-card.component';
import { OpportunityFilterSelectComponent } from '@shared/opportunity-filter-select/opportunity-filter-select.component';
import { RecordHistoryPopoverComponent } from './record-history-popover/record-history-popover.component';
import { InfoEditInputComponent } from '@shared/info-edit-input/info-edit-input.component';
import { AdvancedFilterComponent } from './advanced-filter/advanced-filter.component';
import { UploadComponent } from '@shared/upload/upload.component';
import { NzUploadModule } from 'ng-zorro-antd/upload';

@NgModule({
  declarations: [
    AsOfDateComponent,
    BackButtonComponent,
    CountdownFormatComponent,
    DsoFormatComponent,
    SearchDropdownComponent,
    ItemBorderComponent,
    SearchInputComponent,
    SmallMuTagCombinationComponent,
    HighRiskTagComponent,
    MonthsPickerComponent,
    MultiMonthsPickerComponent,
    NoPermissionComponent,
    SearchCapsuleComponent,
    NoteComponent,
    RightDrawerComponent,
    InfoTagComponent,
    ToastComponent,
    EditMuComponent,
    ExportDropdownComponent,
    TableLoadingMoreComponent,
    TableNoDataComponent,
    CountdownFormatInvoiceComponent,
    ExpectedCollectionDueDateComponent,
    IconTextComponent,
    MuTagComponent,
    MuBlockItemComponent,
    ContractInformationComponent,
    NotificationComponent,
    SelectorComponent,
    NotificationItemComponent,
    RoleControlComponent,
    ErrorTooltipComponent,
    LoginMenuComponent,
    RoleTitleContainerComponent,
    EditExpectedMilestoneDueDateComponent,
    InfoEditSelectorComponent,
    InfoEditDatePickerComponent,
    InfoEditCurrencyInputComponent,
    RangeFilterComponent,
    OpportunityCardComponent,
    OpportunityFilterSelectComponent,
    RecordHistoryPopoverComponent,
    InfoEditInputComponent,
    AdvancedFilterComponent,
    UploadComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    PipeModule,
    DirectiveModule,
    RouterModule,
    NgxEchartsModule,
    ScrollingModule,
    NgZorroAntdModule,
    NzUploadModule,
  ],
  exports: [
    RouterModule,
    DirectiveModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    PipeModule,
    AsOfDateComponent,
    BackButtonComponent,
    CountdownFormatComponent,
    DsoFormatComponent,
    SearchDropdownComponent,
    ItemBorderComponent,
    SearchInputComponent,
    SmallMuTagCombinationComponent,
    HighRiskTagComponent,
    MonthsPickerComponent,
    MultiMonthsPickerComponent,
    NoPermissionComponent,
    RightDrawerComponent,
    InfoTagComponent,
    NoteComponent,
    ToastComponent,
    EditMuComponent,
    ExportDropdownComponent,
    TableLoadingMoreComponent,
    TableNoDataComponent,
    CountdownFormatInvoiceComponent,
    ExpectedCollectionDueDateComponent,
    MuTagComponent,
    MuBlockItemComponent,
    IconTextComponent,
    ScrollingModule,
    ContractInformationComponent,
    NotificationComponent,
    SelectorComponent,
    NotificationItemComponent,
    ErrorTooltipComponent,
    LoginMenuComponent,
    RoleTitleContainerComponent,
    EditExpectedMilestoneDueDateComponent,
    InfoEditSelectorComponent,
    InfoEditDatePickerComponent,
    InfoEditCurrencyInputComponent,
    RangeFilterComponent,
    OpportunityCardComponent,
    OpportunityFilterSelectComponent,
    RecordHistoryPopoverComponent,
    InfoEditInputComponent,
    AdvancedFilterComponent,
    UploadComponent,
  ],
})
export class SharedModule {}
