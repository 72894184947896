<nz-drawer [nzClosable]="false" [nzVisible]="isNotificationVisible" nzPlacement="right" [nzWidth]="430" (nzOnClose)="closeDrawer()">
  <div class="drawer-content" *nzDrawerContent>
    <div class="info-column" (click)="closeDrawer()"></div>
    <div class="info-detail">
      <p class="info-title">Notification</p>
      <div class="buttons">
        <app-icon-text [text]="'Mark all as read'" [isDisabled]="disableMarkButton" (click)="markAllRead()"></app-icon-text>
        <div class="radio">
          <nz-radio-group [(ngModel)]="status" [nzButtonStyle]="'solid'" nzSize="small" (ngModelChange)="switchRadioButton($event)">
            <label nz-radio-button [nzValue]="'ALL'" class="view-all">View all</label>
            <label nz-radio-button [nzValue]="'UNREAD'" class="view-unread">View unread</label>
          </nz-radio-group>
        </div>
      </div>
      <div class="info-content">
        <app-notification-item
          *ngFor="let item of currentNotificationList"
          [item]="item"
          class="notificationContent"
          (click)="onClickItem()"
        ></app-notification-item>
        <app-table-no-data
          [type]="status === 'ALL' ? 'notification' : 'unreadNotification'"
          class="notification-no-data"
          *ngIf="data.length === 0"
        ></app-table-no-data>
      </div>
      <app-selector
        [showPageIndex]="true"
        [pageSize]="selectorInfo.pageSize"
        [pageIndex]="selectorInfo.pageIndex"
        [pageNumber]="selectorInfo.pageNumber"
        class="notification-selector"
        (pageChange)="handlePageChange($event)"
      ></app-selector>
    </div>
  </div>
</nz-drawer>
