import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { concat, sortBy, without } from 'lodash';

@Component({
  selector: 'app-multi-months-picker',
  templateUrl: './multi-months-picker.component.html',
  styleUrls: ['./multi-months-picker.component.scss'],
})
export class MultiMonthsPickerComponent implements OnChanges {
  @Input() defaultYearMonths: Array<string> = [];
  @Output() changedYearMonths = new EventEmitter<Array<string>>();

  MONTH_OPTIONS = [
    { text: 'Jan', value: '01' },
    { text: 'Feb', value: '02' },
    { text: 'Mar', value: '03' },
    { text: 'Apr', value: '04' },
    { text: 'May', value: '05' },
    { text: 'Jun', value: '06' },
    { text: 'Jul', value: '07' },
    { text: 'Aug', value: '08' },
    { text: 'Sep', value: '09' },
    { text: 'Oct', value: '10' },
    { text: 'Nov', value: '11' },
    { text: 'Dec', value: '12' },
  ];
  selectedYear = new Date().getFullYear();
  selectedYearMonths: Array<string> = [];

  ngOnChanges(): void {
    this.selectedYearMonths = this.defaultYearMonths;
  }

  getMonthClass(month: string): string {
    if (this.selectedYearMonths.includes(`${this.selectedYear}-${month}`)) {
      return 'selected';
    }

    return '';
  }

  onSelectPreviousYear() {
    this.selectedYear -= 1;
  }

  onSelectNextYear() {
    this.selectedYear += 1;
  }

  onMonthSelect(month: string) {
    const yearMonth = `${this.selectedYear}-${month}`;
    if (this.selectedYearMonths.includes(yearMonth)) {
      this.selectedYearMonths = without(this.selectedYearMonths, yearMonth);
    } else {
      this.selectedYearMonths = sortBy(concat(this.selectedYearMonths, yearMonth));
    }
    this.changedYearMonths.emit(this.selectedYearMonths);
  }
}
