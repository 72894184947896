export const FEATURE_PERMISSION_WITH_FIXED_ORDER = {
  DsoAccess: 'dso.access',
  DsoAccessALL: 'dso.access.all',
  accountEditMu: 'account.mu',
  contractInfo: 'contract',
  milestoneEditHistoryNote: 'milestone.history_note.edit',
  milestoneEditNote: 'milestone.note.edit',
  milestoneAcceptanceMaterialReuploadAndDownload: 'milestone.acceptance.material.reupload_and_download',
  invoiceEditExpectedDueDate: 'invoice.expected_collection_due_date.edit',
  invoiceEditBadDebt: 'invoice.bad_debt',
  invoiceEditNote: 'invoice.note.edit',
  highRiskEdit: 'high_risk.edit',
  highRiskCreateAndDelete: 'high_risk.create_delete',
  applicationInformationEdit: 'application_information.edit',
  opportunityStatusNoteEdit: 'opportunity_status.note.edit',
  adminAccess: 'admin.access',
};

export const ROLES = {
  ROLE_SYSTEM_ADMIN: 'ROLE_SYSTEM_ADMIN',
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_DEFAULT_PM: 'ROLE_DEFAULT_PM',
};
