export enum DocumentType {
  MSA = 'MSA',
  SOW = 'SOW',
  SERVICE_CONTRACT = 'SERVICE_CONTRACT',
  PO = 'PO',
  OTHER = 'OTHER',
}

export const DocumentTypeLabel = {
  [DocumentType.MSA]: 'MSA',
  [DocumentType.SOW]: 'SOW',
  [DocumentType.SERVICE_CONTRACT]: 'Service Contract',
  [DocumentType.PO]: 'PO',
  [DocumentType.OTHER]: 'Other',
};

export const DOCUMENT_TYPE_OPTIONS = [
  { label: DocumentTypeLabel[DocumentType.MSA], value: DocumentType.MSA },
  { label: DocumentTypeLabel[DocumentType.SOW], value: DocumentType.SOW },
  { label: DocumentTypeLabel[DocumentType.SERVICE_CONTRACT], value: DocumentType.SERVICE_CONTRACT },
  { label: DocumentTypeLabel[DocumentType.PO], value: DocumentType.PO },
  { label: DocumentTypeLabel[DocumentType.OTHER], value: DocumentType.OTHER },
];
