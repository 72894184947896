import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { fromEvent, Observable, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { exhaustMap, filter, map, takeUntil, throttleTime } from 'rxjs/operators';
import { concat, get, isEmpty, isEqual, isNil, set, startCase } from 'lodash';
import { InvoiceApi } from '@apis/invoice.api';
import { InvoiceInfo, InvoiceList } from '@interfaces/Invoice';
import { Filter, SearchItem } from '@interfaces/Filter';
import { FilterService } from '@services/filter.service';
import { NoteService } from '@services/note.service';
import { InvoiceOverduePageService } from '@services/invoice-overdue-page.service';
import { NzTableComponent } from 'ng-zorro-antd/table';
import { SortMappingService } from '@services/sort-mapping.service';
import { DEFAULT_INVOICE_SORTING, SORT_MAP_TYPE_INVOICE, sorting } from '@constants/sortMapping';
import { INVOICE_STATUS_POSTED_TO_AR } from '@constants/invoiceStatus';
import { NoteType } from '@constants/note';
import { getAdvancedFilterConditions, getInvoiceOverdueFilterList } from '@utils/filter';
import { MatomoTracker } from 'ngx-matomo';
import { AclService } from '@services/acl.service';
import {
  ACTION_CLICK,
  ACTION_EDIT,
  ACTION_FILTER,
  ACTION_SORT,
  CATEGORY_DATE_TIPS,
  NAME_DASHBOARD_INVOICE,
  NAME_FILTER_EVENT_MAPPING,
} from '@constants/matomo';
import { DateType, MomentFormat } from '@constants/dateFormat';
import { HIGH_RISK_CATEGORY_MAP } from '@constants/highRiskCategory';
import moment from 'moment/moment';
import { FILTER_INVOICE_OVERDUE_DAYS, FILTER_INVOICE_STATUS } from '@constants/filterType';
import { OpportunityStatusLabel } from '@constants/opportunity';
import { NzMessageService } from 'ng-zorro-antd/message';
import { OpportunityApi } from '@apis/opportunity.api';
import { ToastMessageService } from '@services/toast-message.service';
import { INVOICE_FILTER_RANGE_LIST } from '@constants/countdown';
import { HighRiskOpportunityPageService } from '@services/high-risk-opportunity-page.service';
import { ExportApi } from '@apis/export.api';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-invoice-page',
  templateUrl: './invoice-page.component.html',
  styleUrls: ['./invoice-page.component.scss'],
})
export class InvoicePageComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('invoiceDashboard') tableCom: NzTableComponent<any>;
  dateType = DateType;
  invoiceList: InvoiceInfo[] = [];
  columnsWidth = ['10%', '10%', '10%', '8%', '8%', '9%', '8%', '10%', '8%', '9%', '10%', '9%', '7%'];
  invoiceTotalAmount: number;
  invoiceTotalAmountIntegerPart: string;
  loadingFirst = false;
  loadingMore = false;
  isLastPage = false;
  totalElements: number;
  mapOfSort = {};
  invoiceStatus = INVOICE_STATUS_POSTED_TO_AR;
  invoiceStatusList: string[] = [];
  invoiceNoteType = NoteType.INVOICE;
  pageName = NAME_DASHBOARD_INVOICE;
  isDataTipsVisible = false;
  highRiskCategory = HIGH_RISK_CATEGORY_MAP.INVOICE_OVERDUE;
  selectedInvoiceStatus: string[] = [];
  selectedInvoiceOverdueDays: string[] = [];
  selectedExpectedCollectionDueDate: string[] = [];
  NAME_FILTER_EVENT_MAPPING = NAME_FILTER_EVENT_MAPPING;

  invoiceFilterOptions = [
    {
      label: OpportunityStatusLabel.INVOICE_POSTED_TO_AR,
      value: FILTER_INVOICE_STATUS.INVOICE_POSTED_TO_AR,
    },
    { label: OpportunityStatusLabel.PART_PAID, value: FILTER_INVOICE_STATUS.PART_PAID },
  ];
  filterRangeList = INVOICE_FILTER_RANGE_LIST;
  invoiceOverDueDaysOptions = [
    { label: '1 - 30', value: FILTER_INVOICE_OVERDUE_DAYS.DAYS_LEVEL_1 },
    { label: '31 - 55', value: FILTER_INVOICE_OVERDUE_DAYS.DAYS_LEVEL_2 },
    { label: '56 - 90', value: FILTER_INVOICE_OVERDUE_DAYS.DAYS_LEVEL_3 },
    { label: '> 90', value: FILTER_INVOICE_OVERDUE_DAYS.DAYS_LEVEL_4 },
  ];
  invoiceStatusFilterSelectedOption: FILTER_INVOICE_STATUS.INVOICE_POSTED_TO_AR | FILTER_INVOICE_STATUS.PART_PAID;
  filterItem = {};
  previewFilter = [];
  private pageIndex = -1;
  private pageSize = 100;
  private currentScrollTop: number;
  private scrollEle: HTMLElement;
  private filters: Array<SearchItem>;
  private unsubscribe = new Subject();
  private orders: any = DEFAULT_INVOICE_SORTING;

  constructor(
    private toastService: ToastMessageService,
    private opportunityApi: OpportunityApi,
    private exportApi: ExportApi,
    private message: NzMessageService,
    private route: ActivatedRoute,
    private invoiceApi: InvoiceApi,
    private filterService: FilterService,
    private noteService: NoteService,
    private invoiceOverduePageService: InvoiceOverduePageService,
    private sortMappingService: SortMappingService,
    private highRiskOpportunityPageService: HighRiskOpportunityPageService,
    private matomoTracker: MatomoTracker,
    public aclService: AclService,
  ) {}

  ngOnInit() {
    this.initFilterAndSortFromService();
    this.initInvoiceOverduePage();
    this.triggerFilter();
  }

  ngAfterViewInit(): void {
    this.listenScrollAndLoadMore();
    this.listenCategoryChanged();
  }

  ngOnDestroy(): void {
    this.setInvoiceOverduePageToService();
    this.setInvoiceTotalAmountDataToService();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private initInvoiceOverduePage() {
    const invoiceOverduePage = this.invoiceOverduePageService.get();
    isEmpty(invoiceOverduePage) ? this.initPageData() : this.setPageData(invoiceOverduePage);
  }

  private initPageData(tag = '') {
    this.loadingFirst = true;
    this.invoiceList = [];
    this.totalElements = undefined;
    this.pageIndex = -1;
    this.postForInvoiceOverdueList()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: InvoiceList) => {
        this.loadingFirst = false;
        this.scrollEle = null;
        this.setPageData(data);
        this.recordMatomoWhenClickViewOrResetOrDownload(tag);
      });
    const invoiceTotalAmount = this.invoiceOverduePageService.getInvoiceTotalAmount();
    isNil(invoiceTotalAmount) ? this.initInvoiceTotalAmount() : this.setInvoiceTotalAmountData(invoiceTotalAmount);
  }

  private recordMatomoWhenClickViewOrResetOrDownload(tag: string) {
    if (tag === 'clickView' && this.filters.length > 0) {
      const filters = this.filters
        .filter((item) => Object.keys(NAME_FILTER_EVENT_MAPPING).includes(item.name))
        .map((item) => NAME_FILTER_EVENT_MAPPING[item.name] || item.name);
      const deduplicateFilters = Array.from(new Set(filters)).join('&');
      this.matomoTracker.trackEvent(this.pageName, ACTION_FILTER, `View filters ${deduplicateFilters} result`);
    }
    if (tag === 'clickReset') {
      this.matomoTracker.trackEvent(this.pageName, ACTION_FILTER, 'Reset all filters');
    }
    if (tag === 'clickDownload') {
      this.matomoTracker.trackEvent(this.pageName, ACTION_FILTER, 'click download');
    }
  }

  private initInvoiceTotalAmount() {
    this.invoiceTotalAmountIntegerPart = '--';
    this.invoiceApi.getInvoiceTotalAmount(this.filters).subscribe((totalAmount) => {
      this.setInvoiceTotalAmountData(totalAmount.totalInvoiceAmount);
    });
  }

  private setPageData(data: InvoiceList) {
    this.invoiceList = data.content;
    this.pageIndex = data.number;
    this.isLastPage = data.last;
    this.totalElements = data.totalElements;
    this.invoiceList.forEach((invoice) => {
      if (!isEmpty(invoice.notes)) {
        this.noteService.storeNotes(NoteType.INVOICE, invoice.notes);
      }
    });
  }

  private setInvoiceTotalAmountData(totalAmount: number) {
    this.invoiceTotalAmount = totalAmount;
    this.invoiceTotalAmountIntegerPart = Math.trunc(totalAmount).toLocaleString();
  }

  private setInvoiceOverduePageToService() {
    const invoiceOverduePage = {
      content: this.invoiceList,
      number: this.pageIndex,
      last: this.isLastPage,
      totalElements: this.totalElements,
    };
    this.invoiceOverduePageService.set(invoiceOverduePage);
  }

  private setInvoiceTotalAmountDataToService() {
    this.invoiceOverduePageService.setInvoiceTotalAmount(this.invoiceTotalAmount);
  }

  private listenScrollAndLoadMore() {
    const nativeElement = this.tableCom.nzTableInnerScrollComponent.tableBodyElement.nativeElement;
    this.listenScroll(nativeElement)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: InvoiceList) => {
        this.loadingMore = false;
        data.content = concat(this.invoiceList, data.content);
        this.setPageData(data);
        this.scrollEle.scrollTop = this.currentScrollTop;
      });
  }

  private listenScroll(nativeElement) {
    return fromEvent(nativeElement, 'scroll').pipe(
      map((event) => this.scrollEle || (event as Event).target),
      filter((ele: HTMLElement) => {
        const isTriggerBottom = ele.scrollTop + ele.offsetHeight + 20 >= ele.scrollHeight;

        return !this.isLastPage && ele.scrollHeight !== 0 && isTriggerBottom;
      }),
      exhaustMap((ele: HTMLElement) => {
        this.scrollEle = ele;
        this.currentScrollTop = ele.scrollHeight - ele.offsetHeight + 40;

        this.loadingMore = true;

        return this.postForInvoiceOverdueList();
      }),
      throttleTime(1000),
    );
  }

  private listenCategoryChanged() {
    this.highRiskOpportunityPageService
      .listenHighRiskPage()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.initPageData();
      });
  }

  private postForInvoiceOverdueList(): Observable<InvoiceList> {
    const targetPageIndex = this.pageIndex + 1;
    this.initFilterAndSortFromService();
    this.filters.push(...getAdvancedFilterConditions(this.filterItem));
    return this.invoiceApi.getInvoiceList(targetPageIndex, this.pageSize, this.filters, this.orders);
  }

  private initFilterAndSortFromService() {
    this.mapOfSort = this.sortMappingService.getSort(SORT_MAP_TYPE_INVOICE);
    this.filters = getInvoiceOverdueFilterList(this.getPageCustomFilterFormat());
  }

  private getPageCustomFilterFormat(): Filter {
    const formatExpectedCollectionDueDate =
      this.selectedExpectedCollectionDueDate.length === 0
        ? {}
        : {
            from: moment(this.selectedExpectedCollectionDueDate[0]).format('YYYY-MM-DD'),
            to: moment(this.selectedExpectedCollectionDueDate[1]).format('YYYY-MM-DD'),
          };
    return {
      searchItem: this.filterService.getFilterItem().searchItem,
      invoiceStatusList: this.selectedInvoiceStatus,
      invoiceOverdueDays: this.selectedInvoiceOverdueDays,
      invoiceExpectedCollectionDueDate: formatExpectedCollectionDueDate,
    };
  }

  onChangeExpectedDueDate(editExpectedCollectionDueDate: string, item: InvoiceInfo, index: number) {
    if (!isNil(editExpectedCollectionDueDate)) {
      if (!isEqual(this.invoiceList[index].expectedCollectionDueDate, editExpectedCollectionDueDate)) {
        this.invoiceApi
          .updateExpectedDueDate(item.id, editExpectedCollectionDueDate)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(() => {
            set(this.invoiceList[index], 'expectedCollectionDueDate', editExpectedCollectionDueDate);
            this.matomoTracker.trackEvent(this.pageName, ACTION_EDIT, `Edit Expected Due Date for ${startCase(item.opportunityName)}`);
          });
      }
    } else if (isNil(editExpectedCollectionDueDate) && !isNil(this.invoiceList[index].expectedCollectionDueDate)) {
      this.invoiceApi
        .deleteExpectedDueDate(item.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          set(this.invoiceList[index], 'expectedCollectionDueDate', editExpectedCollectionDueDate);
          this.matomoTracker.trackEvent(this.pageName, ACTION_EDIT, `Edit Expected Due Date for ${startCase(item.opportunityName)}`);
        });
    }
  }

  private triggerFilter() {
    this.filterService
      .listenFilter()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((item: Filter) => {
        this.clearSort();
        this.initPageData();
      });
  }

  private clearSort() {
    this.orders = DEFAULT_INVOICE_SORTING;
    this.mapOfSort = {};
    this.sortMappingService.clear(SORT_MAP_TYPE_INVOICE);
  }

  sort(sortingType, sortKey: string) {
    if (!isEmpty(sortingType)) {
      this.orders = { [sortKey]: sorting[sortingType] };
      this.mapOfSort = { [sortKey]: sortingType };
      this.sortMappingService.setSort(SORT_MAP_TYPE_INVOICE, this.mapOfSort);
      this.initPageData();
      this.matomoTracker.trackEvent(this.pageName, ACTION_SORT, `Sort by ${startCase(sortKey)}`);
    }
  }

  getSort(value) {
    return get(this.mapOfSort, value, null);
  }

  openDataTips(): void {
    this.isDataTipsVisible = true;
    this.matomoTracker.trackEvent(CATEGORY_DATE_TIPS, ACTION_CLICK, `Check Data Specification of ${this.pageName}`);
  }

  closeDataTips(): void {
    this.isDataTipsVisible = false;
  }

  handleFilterChanged(event: any) {
    this.filterItem = {
      ...event,
    };
    this.initPageData('clickView');
  }

  handleFilterReset(event: any) {
    this.filterItem = {
      ...event,
    };
    this.selectedInvoiceStatus = [];
    this.selectedInvoiceOverdueDays = [];
    this.selectedExpectedCollectionDueDate = [];
    this.initPageData('clickReset');
  }

  download() {
    const id = this.message.loading('Action in progress..', { nzDuration: 0 }).messageId;
    this.exportApi
      .exportExcel('invoice-overdue', this.filters)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (data) => {
          const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
          this.message.remove(id);
          saveAs(blob, `Invoice Overdue Dashboard (Download date ${moment(Date.now()).format(MomentFormat.DATE)})`);
          this.toastService.success('Export finished');
        },
        () => {
          this.message.remove(id);
        },
      );
    this.recordMatomoWhenClickViewOrResetOrDownload('clickDownload');
  }

  handleSelectedCommonFilterChangeForMatomoRecord(event: any) {
    this.matomoTracker.trackEvent(this.pageName, ACTION_FILTER, event);
  }

  handleSelectedAdvancedFilterChangeForMatomoRecord(filterName: string) {
    this.matomoTracker.trackEvent(this.pageName, ACTION_FILTER, filterName);
  }
}
