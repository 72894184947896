import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AclService } from '@services/acl.service';
import { DSOMilestone } from '@interfaces/Milestone';
import { NoteType } from '@constants/note';
import { ACTION_CLICK, CATEGORY_DETAIL_PAGE, NAME_ACCEPTANCE_MATERIALS_LINK_ICON, NAME_ADD_MILESTONE_ICON } from '@constants/matomo';
import { OpportunityDetail } from '@interfaces/OpportunityDetail';
import { MilestoneStatus } from '@constants/opportunity';
import { isEmpty } from 'lodash';
import { MatomoTracker } from 'ngx-matomo';
import { isSameTotalAmount } from '@utils/utils';
import { takeUntil } from 'rxjs/operators';
import moment from 'moment';
import { MomentFormat } from '@constants/dateFormat';
import { Subject } from 'rxjs';
import { MilestoneApi } from '@apis/milestone.api';
import { ToastMessageService } from '@services/toast-message.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-opportunity-milestone-edit',
  templateUrl: './opportunity-milestone-edit.component.html',
  styleUrls: ['./opportunity-milestone-edit.component.scss'],
})
export class OpportunityMilestoneEditComponent implements OnChanges {
  @Input() opportunityDetail: OpportunityDetail;
  @Input() milestones: DSOMilestone[];
  @Input() lastSubmitMilestones: DSOMilestone[];
  @Output() changed = new EventEmitter();
  showAddMilestoneDialog: boolean;
  showMarkMilestoneDoneDialog: boolean;
  reupload = false;
  selectedMilestone: DSOMilestone;
  milestoneNoteType = NoteType.MILESTONE;
  pageName = CATEGORY_DETAIL_PAGE;
  isHistoryDialogVisible = false;
  MilestoneStatus = MilestoneStatus;
  milestoneHasUploadFile = false;
  isSameTotalAmount = isSameTotalAmount;
  private unsubscribe = new Subject();

  constructor(
    public aclService: AclService,
    private milestoneApi: MilestoneApi,
    private toastService: ToastMessageService,
    private message: NzMessageService,
    private matomoTracker: MatomoTracker,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.milestones) {
      this.milestones.forEach((milestone) => {
        if (milestone.status === 'done') {
          this.milestoneHasUploadFile = true;
          return false;
        }
      });
    }
  }

  get hasSubmittedMilestone() {
    return this.milestones.some((milestone) => milestone.hasSubmitted);
  }

  get lastSubmitDate() {
    return this.lastSubmitMilestones[0]?.updateTime;
  }

  getOverDueClass(overdueDays) {
    if (!overdueDays || overdueDays <= 0) {
      return '';
    }
    return overdueDays > 60 ? 'highlight-range-3' : overdueDays > 30 ? 'highlight-range-2' : 'highlight-range-1';
  }

  handleCloseDialog() {
    this.showAddMilestoneDialog = false;
    this.showMarkMilestoneDoneDialog = false;
  }

  handleOpenDialog() {
    this.showAddMilestoneDialog = true;
    this.matomoTracker.trackEvent(this.pageName, ACTION_CLICK, NAME_ADD_MILESTONE_ICON);
  }

  handleChangedDialog() {
    this.changed.emit();
    this.handleCloseDialog();
  }

  handleMarkMilestoneDone(milestone: DSOMilestone) {
    if (this.disableMarkMilestoneDoneMessage(milestone).length === 0) {
      this.showMarkMilestoneDoneDialog = true;
      this.reupload = false;
      this.selectedMilestone = milestone;
    }
  }

  handleReUploadMilestone(milestone: DSOMilestone) {
    this.showMarkMilestoneDoneDialog = true;
    this.reupload = true;
    this.selectedMilestone = milestone;
  }

  disableMarkMilestoneDoneMessage(milestone: DSOMilestone) {
    const disableReasons = [];
    if (milestone.status === MilestoneStatus.saved) {
      disableReasons.push('Draft milestone can not be marked as done, please submit it first');
    }
    if (!this.isSameTotalAmount(this.opportunityDetail.contractAmount, this.opportunityDetail.totalMilestoneAmount)) {
      disableReasons.push(
        'Milestone can not be marked as done as the Milestone Total Amount is different with the ' +
          'Opportunity Amount, please update the Milestone first',
      );
    }
    if (milestone.milestoneDueDate < new Date().toISOString().slice(0, 10)) {
      disableReasons.push(
        'The overdue milestone can not be marked as done, please update the Milestone Due Date to ' + 'today or any date in the future',
      );
    }

    return disableReasons;
  }

  disableMarkMilestoneDone(milestone: DSOMilestone) {
    return this.disableMarkMilestoneDoneMessage(milestone).length > 0;
  }

  disabledLink(milestone: DSOMilestone) {
    return isEmpty(milestone.googleDriveLink);
  }

  disableDownload(milestone: DSOMilestone) {
    return !(milestone.fileSubmitted && this.aclService.hasReuploadAndDownloadAcceptanceMaterialPermission());
  }

  disableReupload(milestone: DSOMilestone) {
    return !(milestone.status === 'done' && this.aclService.hasReuploadAndDownloadAcceptanceMaterialPermission());
  }

  openDriveLink(milestone: DSOMilestone) {
    if (!this.disabledLink(milestone)) {
      window.open(milestone.googleDriveLink, '_blank');
      this.matomoTracker.trackEvent(this.pageName, ACTION_CLICK, NAME_ACCEPTANCE_MATERIALS_LINK_ICON);
    }
  }

  downloadMilestoneFile(milestone: DSOMilestone) {
    if (!this.disableDownload(milestone)) {
      const id = this.message.loading('Download in progress..', { nzDuration: 0 }).messageId;

      this.milestoneApi
        .downloadFile(milestone.milestoneEditId)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (data) => {
            const blob = new Blob([data], { type: 'application/octet-stream' });
            this.message.remove(id);
            saveAs(
              blob,
              `acceptance-materials-${this.opportunityDetail.projectCode}-${milestone.name}-${moment(Date.now()).format(
                MomentFormat.DATE,
              )}.zip`,
            );
            this.toastService.success('Acceptance Materials Downloaded Successfully');
          },
          () => {
            this.message.remove(id);
          },
        );
    }
  }

  shouldDisableEditNote(milestoneEditId: number) {
    const milestoneEdit = this.lastSubmitMilestones.find((milestone) => milestone.milestoneEditId === milestoneEditId);
    return !milestoneEdit || !this.aclService.isPermission(this.aclService.features.milestoneEditNote);
  }

  findNoteLookupId(milestoneEditId: number) {
    const milestone = this.lastSubmitMilestones.find((milestone) => milestone.milestoneEditId === milestoneEditId);
    if (milestone) {
      return milestone.id.toString();
    }
  }

  handleCloseHistoryDialog() {
    this.isHistoryDialogVisible = false;
  }
}
