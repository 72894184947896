import { Pipe, PipeTransform } from '@angular/core';
import { isEmpty, isNumber } from 'lodash';

@Pipe({
  name: 'integerFormat',
})
export class IntegerFormatPipe implements PipeTransform {
  transform(value: undefined | null | string | number): any {
    if (isEmpty(value) && !isNumber(value)) {
      return '--';
    }
    if (typeof value === 'string') {
      const parsedValue = Number(value);
      if (!isNaN(parsedValue)) {
        return Math.trunc(parsedValue);
      }
    }
    if (isNumber(value)) {
      return Math.trunc(value);
    }
    return value;
  }
}
