<app-icon-text [className]="'icon-more'"></app-icon-text>
<nz-modal class="role-control-model" [(nzVisible)]="showRoleControl" [nzFooter]="null" (nzOnCancel)="handleCancel()">
  <ng-container *nzModalContent>
    <div class="role-control-content">
      <p class="role-control-header">Add User to the Role Group</p>
      <div class="role-control-list">
        <div class="role-control-list-header">
          <div>
            <div class="title">Role</div>
            <div class="role-description">
              {{ role.description }}
            </div>
          </div>
          <div class="delete-role">
            <app-icon-text
              [text]="'Delete the Role'"
              [className]="'icon-delete'"
              nz-popconfirm
              nzOkText="Yes"
              nzCancelText="Cancel"
              nzPopconfirmTitle="You will remove this role and all connected users. Are you sure to remove this role?"
              nzPopconfirmPlacement="bottom"
              nzPopconfirmOverlayClassName="confirmDeletePopover"
              (nzOnConfirm)="deleteRole()"
            ></app-icon-text>
          </div>
        </div>
        <div class="search-user">
          <div class="title">Add A User</div>
          <label nz-dropdown [nzDropdownMenu]="search" nzTrigger="click" [(nzVisible)]="visibleSearchList" class="user-search-input">
            <input
              type="text"
              nz-input
              nzSize="small"
              [(ngModel)]="inputValue"
              maxlength="121"
              placeholder="Search a user by name or email"
              (ngModelChange)="handleSearchTextChange($event)"
            />
          </label>
        </div>
        <div class="user-group" *ngIf="!loading">
          <div class="title">User in the Group</div>
          <ul class="users-list-container">
            <li class="users-list" *ngFor="let people of users">
              <div class="people-name">{{ people | formatPeopleName }}</div>
              <div class="people-email">{{ people.email }}</div>
              <app-icon-text
                [className]="'icon-delete-gray'"
                nz-popconfirm
                nzOkText="Yes"
                nzCancelText="Cancel"
                nzPopconfirmTitle="Are you sure to remove the user in this role?"
                nzPopconfirmPlacement="bottom"
                nzPopconfirmOverlayClassName="confirmDeletePopover"
                (nzOnConfirm)="deleteUser(people.employeeId)"
              ></app-icon-text>
            </li>
            <app-table-no-data [type]="'users'" class="uses-no-data" *ngIf="users.length === 0"></app-table-no-data>
          </ul>
        </div>
      </div>
    </div>
    <div *ngIf="loading" class="load-icon">
      <nz-spin nzSimple></nz-spin>
    </div>
  </ng-container>
</nz-modal>

<nz-dropdown-menu #search="nzDropdownMenu">
  <div class="user-result-container" *ngIf="inputValue?.length >= 2">
    <div *ngFor="let item of esPeople" [class.hidden]="loadingSearch" class="item owner-item" (click)="onClickItem(item)">
      <div class="left">
        <div class="people-name">{{ item?.name }}</div>
        <div class="people-email">{{ item?.email }}</div>
      </div>
      <div class="right">
        <app-icon-text [className]="'icon-plus-default'"></app-icon-text>
      </div>
    </div>
    <p class="no-data-found" *ngIf="esPeople?.length === 0 && !loadingSearch">No user found</p>
    <nz-spin nzSimple class="load-icon-search" *ngIf="loadingSearch"></nz-spin>
  </div>
</nz-dropdown-menu>
