import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { beforeCompletedStatus, completedStatus } from '../constants/invoiceStatus';
import { concat, includes, isNil } from 'lodash';
import { COUNTDOWN_L1_MILESTONE, COUNTDOWN_L2_MILESTONE, COUNTDOWN_L3_MILESTONE } from '../constants/countdown';

@Directive({
  selector: '[appMilestoneHighlight]',
})
export class MilestoneHighlightDirective implements AfterViewInit {
  @Input() countDown: number;
  @Input() invoiceStatus: string;

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    if (includes(completedStatus, this.invoiceStatus)) {
      this.el.nativeElement.style.opacity = 0.4;
    }
    if (!includes(concat(completedStatus, beforeCompletedStatus), this.invoiceStatus) && !isNil(this.countDown)) {
      if (this.countDown > COUNTDOWN_L3_MILESTONE) {
        this.el.nativeElement.classList.add('highlight-range-3');
      } else if (this.countDown > COUNTDOWN_L2_MILESTONE) {
        this.el.nativeElement.classList.add('highlight-range-2');
      } else if (this.countDown > COUNTDOWN_L1_MILESTONE) {
        this.el.nativeElement.classList.add('highlight-range-1');
      }
    }
  }
}
