export const HIGH_RISK_AMOUNT_TYPE_MAP = {
  AMOUNT_WITHOUT_CONTRACT: 'Amount without Contract',
  WIP_AMOUNT: 'WIP Amount',
  AR_BALANCE: 'AR Balance',
  REVENUE_HOLDBACK: 'Revenue Holdback',
};

export const HIGH_RISK_AMOUNT_TYPE_OPTION_LIST = [
  { label: 'Amount without Contract', value: 'AMOUNT_WITHOUT_CONTRACT' },
  { label: 'WIP Amount', value: 'WIP_AMOUNT' },
  { label: 'AR Balance', value: 'AR_BALANCE' },
  { label: 'Revenue Holdback', value: 'REVENUE_HOLDBACK' },
];
