import { Component } from '@angular/core';
import { environment } from '@environments/environment';
import { replace } from 'lodash';

@Component({
  template:
    '<div class="error-page">\n' +
    '  <img src="/assets/svg/error-page.svg" alt="error-logo">\n' +
    '  <h1>Page Forbidden</h1>\n' +
    '  <p>The page you visited is forbidden.</p>\n' +
    '  <a [href]="url">Redirect to DSO home page</a>\n' +
    '</div>',
  styleUrls: ['./error-page.component.scss'],
})
export class ForbiddenComponent {
  url = replace(environment.authConfig.redirectUri, '/implicit/callback', '');
}
