<div class="account-list">
  <nz-table
    #accountListEle
    [nzData]="hashMap"
    [nzWidthConfig]="columnsWidth"
    [nzScroll]="{ y: 'calc(100vh - 287px)', x: '100%' }"
    [nzFrontPagination]="false"
    [nzShowPagination]="false"
    [nzNoResult]="noData"
  >
    <thead>
      <tr>
        <th nzShowSort class="account" [nzSortOrder]="mapOfSort['market']" [nzSortFn]="true" (nzSortOrderChange)="sort($event, 'market')">
          Market
        </th>
        <th nzShowSort class="opportunity" [nzSortOrder]="mapOfSort['name']" [nzSortFn]="true" (nzSortOrderChange)="sort($event, 'name')">
          Account
        </th>
        <th nzShowSort [nzSortFn]="true" [nzSortOrder]="mapOfSort['dso']" (nzSortOrderChange)="sort($event, 'dso')">DSO</th>
        <th
          nzShowSort
          class="billed"
          [nzSortOrder]="mapOfSort['billedDso']"
          [nzSortFn]="true"
          (nzSortOrderChange)="sort($event, 'billedDso')"
        >
          Billed DSO
        </th>
        <th
          nzShowSort
          class="unbilled"
          [nzSortOrder]="mapOfSort['unBilledDso']"
          [nzSortFn]="true"
          (nzSortOrderChange)="sort($event, 'unBilledDso')"
        >
          Unbilled DSO
        </th>
        <th nzShowSort [nzSortFn]="true" [nzSortOrder]="mapOfSort['totalArRevenue']" (nzSortOrderChange)="sort($event, 'totalArRevenue')">
          AR
        </th>
        <th nzShowSort [nzSortFn]="true" [nzSortOrder]="mapOfSort['totalWipRevenue']" (nzSortOrderChange)="sort($event, 'totalWipRevenue')">
          WIP
        </th>
        <th
          class="RR"
          nzShowSort
          [nzSortOrder]="mapOfSort['currentYearTotalRecognizedRevenue']"
          [nzSortFn]="true"
          (nzSortOrderChange)="sort($event, 'currentYearTotalRecognizedRevenue')"
        >
          YTD Recognized Revenue
        </th>
        <th
          class="client-code"
          nzShowSort
          [nzSortOrder]="mapOfSort['accountCode']"
          [nzSortFn]="true"
          (nzSortOrderChange)="sort($event, 'accountCode')"
        >
          Client Code
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let id of accountListEle.data">
        <ng-container *ngFor="let item of mapOfExpandedData[id]; index as i">
          <tr
            *ngIf="(item.parent && item.parent.expand) || !item.parent"
            [class.children-row]="!!item.parent"
            [class.parent-row]="!!item.children && item?.children.length > 0 && item.expand"
            [class.last-child]="mapOfExpandedData[id].length - 1 === i && item.parent"
            [ngClass]="{ 'selected-account': selectedAccount === item.id }"
          >
            <td [nzShowExpand]="!!item.children && item?.children.length > 0" [(nzExpand)]="item.expand" colspan="2" class="account-column">
              <app-edit-mu
                [mu]="item?.market"
                [muList]="muList"
                [lookUpId]="item.id"
                (editNewMu)="onChangeMu($event, id, item)"
              ></app-edit-mu>
              <div
                class="account-name"
                (click)="filterOpportunityByAccount(item?.name, item?.id)"
                [class.hasExpand]="(!!item.children && item?.children.length > 0) || item?.parent?.expand"
              >
                {{ item?.name | emptyFormat }}
              </div>
            </td>
            <td>
              <app-dso-format dsoType="dso" [dsoValue]="item?.dso"></app-dso-format>
            </td>
            <td>
              <app-dso-format dsoType="billed" [dsoValue]="item?.billedDso"></app-dso-format>
            </td>
            <td>
              <app-dso-format dsoType="unbilled" [dsoValue]="item?.unBilledDso"></app-dso-format>
            </td>
            <td class="break-all">
              <span>
                {{ item?.totalArRevenue | currencyFormat : 'CNY' }}
              </span>
            </td>
            <td class="break-all">
              <span>
                {{ item?.totalWipRevenue | currencyFormat : 'CNY' }}
              </span>
            </td>
            <td class="break-all">
              <span>
                {{ item?.currentYearTotalRecognizedRevenue | currencyFormat : 'CNY' }}
              </span>
            </td>
            <td class="break-all">
              <span>
                {{ item?.accountCode | emptyFormat }}
              </span>
            </td>
          </tr>
        </ng-container>
      </ng-container>
      <tr></tr>
      <tr *ngIf="hashMap?.length > 0">
        <td colspan="9" style="border-bottom: none; background-color: white">
          <app-table-loading-more [isLastPage]="isLastPage" [loadingMore]="loadingMore"></app-table-loading-more>
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>

<ng-template #noData>
  <app-table-no-data [loadingFirst]="loadingFirst"></app-table-no-data>
</ng-template>
