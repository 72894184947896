import { Component, Input } from '@angular/core';
import { MilestoneApi } from '@apis/milestone.api';
import { DSOMilestoneHistory } from '@interfaces/Milestone';
import { finalize, takeUntil } from 'rxjs/operators';
import { formatDate } from '@constants/dateFormat';
import { CurrencyFormatPipe } from '@pipes/currency-format.pipe';
import { EmptyFormatPipe } from '@pipes/empty-format.pipe';
import { BooleanFormatPipe } from '@pipes/boolean-format.pipe';
import { AutoUnsubscribe } from '@app/decorators/autoUnsubscribe';
import { Subject } from 'rxjs';
import { MatomoTracker } from 'ngx-matomo';
import { ACTION_CLICK, NAME_RECORD_HISTORY_ICON } from '@constants/matomo';

interface HistoryRenderInfo {
  name: string;
  label: string;
  value: any;
  isMultipleLine?: boolean;
}

@AutoUnsubscribe()
@Component({
  selector: 'app-record-history-popover',
  templateUrl: './record-history-popover.component.html',
  styleUrls: ['./record-history-popover.component.scss'],
  providers: [CurrencyFormatPipe, EmptyFormatPipe, BooleanFormatPipe],
})
export class RecordHistoryPopoverComponent {
  @Input() isDisabled = false;
  @Input() milestoneId: number;
  @Input() pageName: string;
  isLoading = false;
  milestoneHistories: DSOMilestoneHistory[];
  formatDate = formatDate;
  private unsubscribe = new Subject();

  constructor(
    private milestoneApi: MilestoneApi,
    private currencyFormat: CurrencyFormatPipe,
    private emptyFormat: EmptyFormatPipe,
    private booleanFormat: BooleanFormatPipe,
    private matomoTracker: MatomoTracker,
  ) {}

  handleVisibleChange(visible) {
    if (visible) {
      this.loadMilestoneHistory();
      this.matomoTracker.trackEvent(this.pageName, ACTION_CLICK, NAME_RECORD_HISTORY_ICON);
    }
  }

  private loadMilestoneHistory() {
    this.isLoading = true;
    this.milestoneApi
      .getMilestoneHistory(this.milestoneId)
      .pipe(
        takeUntil(this.unsubscribe),
        finalize(() => {
          this.isLoading = false;
          this.fixPopoverPosition();
        }),
      )
      .subscribe((data) => {
        this.milestoneHistories = data;
      });
  }

  isChanged(field: HistoryRenderInfo, historyIndex: number) {
    if (historyIndex === this.milestoneHistories.length - 1) {
      return false;
    }
    const history = this.milestoneHistories[historyIndex];
    const prevHistory = this.milestoneHistories[historyIndex + 1];
    return history[field.name] !== prevHistory[field.name];
  }

  getHistoryFields(history: DSOMilestoneHistory): HistoryRenderInfo[] {
    return [
      {
        name: 'milestoneDueDate',
        label: 'Milestone Due Date',
        value: history.milestoneDueDate,
      },
      {
        name: 'milestoneAmount',
        label: 'Milestone Amount',
        value: this.currencyFormat.transform(history.milestoneAmount, history.currency, false),
      },
      {
        name: 'deliveryActionOwner',
        label: 'Delivery Action Owner',
        value: this.emptyFormat.transform(history.deliveryActionOwnerFullName),
      },
      {
        name: 'hasDeliveryRisk',
        label: 'Has Delivery Risk',
        value: this.booleanFormat.transform(history.hasDeliveryRisk),
      },
      {
        name: 'demandActionNeeded',
        label: 'Demand Action Needed',
        value: this.booleanFormat.transform(history.demandActionNeeded),
      },
      {
        name: 'demandActionOwner',
        label: 'Demand Action Owner',
        value: this.emptyFormat.transform(history.demandActionOwnerFullName),
      },
      {
        name: 'reason',
        label: 'Reason',
        value: this.emptyFormat.transform(history.reason),
        isMultipleLine: true,
      },
      {
        name: 'status',
        label: 'Status',
        value: this.emptyFormat.transform(history.status),
      },
    ];
  }

  fixPopoverPosition() {
    window.dispatchEvent(new Event('resize'));
  }
}
