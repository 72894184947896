import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { fromEvent, merge, Observable, Subject } from 'rxjs';
import { exhaustMap, filter, finalize, map, takeUntil, throttleTime } from 'rxjs/operators';
import { concat, isEmpty, isNumber, startCase } from 'lodash';
import { OpportunityApi } from '@apis/opportunity.api';
import { WithoutPoInfo, WithoutPoPage, WithoutPoPageData } from '@interfaces/WithoutPo';
import { Filter, SearchItem } from '@interfaces/Filter';
import { getAdvancedFilterConditions, getWithoutContractFilterList } from '@utils/filter';
import { WITHOUT_PO_DAYS_L1, WITHOUT_PO_DAYS_L2 } from '@constants/withoutPoDays';
import { WithoutPoPageService } from '@services/without-po-page.service';
import { NzTableComponent } from 'ng-zorro-antd/table';
import { SortMappingService } from '@services/sort-mapping.service';
import { DEFAULT_WITHOUT_PO_SORTING, SORT_MAP_TYPE_WITHOUT_PO, sorting } from '@constants/sortMapping';
import { MatomoTracker } from 'ngx-matomo';
import {
  ACTION_CLICK,
  ACTION_FILTER,
  ACTION_SORT,
  CATEGORY_DATE_TIPS,
  NAME_DASHBOARD_PO,
  NAME_FILTER_EVENT_MAPPING,
} from '@constants/matomo';
import { AclService } from '@services/acl.service';
import { NoteType } from '@constants/note';
import { NoteService } from '@services/note.service';
import { DateType, MomentFormat } from '@constants/dateFormat';
import { FILTER_WITHOUT_CONTRACT_DAYS, FILTER_WITHOUT_CONTRACT_STATUS } from '@app/constants/filterType';
import { OpportunityStatusLabel } from '@constants/opportunity';
import { AutoUnsubscribe } from '@app/decorators/autoUnsubscribe';
import { getTagConfig } from '@utils/dso-mu';
import moment from 'moment';
import { FilterService } from '@services/filter.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { saveAs } from 'file-saver';
import { ToastMessageService } from '@services/toast-message.service';
import { ExportApi } from '@apis/export.api';

@AutoUnsubscribe()
@Component({
  selector: 'app-without-contract-page',
  templateUrl: './without-contract-page.component.html',
  styleUrls: ['./without-contract-page.component.scss'],
})
export class WithoutContractPageComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('withoutContractDashboard') tableCom: NzTableComponent<any>;
  dateType = DateType;
  pageName = NAME_DASHBOARD_PO;
  withoutPoList: WithoutPoInfo[] = [];
  columnsWidth = ['10%', '10%', '9%', '9%', '11%', '130px', '10%', '8%', '9%', '10%', '9%', '10%', '7%'];
  loadingFirst = false;
  loadingMore = false;
  isLastPage = false;
  totalElements: number;
  mapOfSort = {};
  isDataTipsVisible = false;
  withoutPoNoteType = NoteType.WITHOUTPO;
  withoutContractStatusFilterOptions = [
    { label: OpportunityStatusLabel.APPLICATION_OVERDUE, value: FILTER_WITHOUT_CONTRACT_STATUS.APPLICATION_OVERDUE },
    { label: OpportunityStatusLabel.NO_APPLICATION, value: FILTER_WITHOUT_CONTRACT_STATUS.NO_APPLICATION },
  ];
  withoutContractDaysFilterOptions = [
    { label: '0 - 30', value: FILTER_WITHOUT_CONTRACT_DAYS.DAYS_LEVEL_1 },
    { label: '31 - 55', value: FILTER_WITHOUT_CONTRACT_DAYS.DAYS_LEVEL_2 },
    { label: '56 - 90', value: FILTER_WITHOUT_CONTRACT_DAYS.DAYS_LEVEL_3 },
    { label: '> 90', value: FILTER_WITHOUT_CONTRACT_DAYS.DAYS_LEVEL_4 },
  ];
  filterItem = {};
  selectedWithoutContractStatus: string[] = [];
  selectedWithoutContractDays = {};
  getTagConfig = getTagConfig;
  private pageIndex = -1;
  private pageSize = 100;
  private currentScrollTop: number;
  private scrollEle: HTMLElement;
  private filters: Array<SearchItem>;
  private unsubscribe;
  private stopLastSubscribe = new Subject();
  private orders: any = DEFAULT_WITHOUT_PO_SORTING;
  selectedContractReceivedDateRange: any = [];
  NAME_FILTER_EVENT_MAPPING = NAME_FILTER_EVENT_MAPPING;

  constructor(
    private opportunityApi: OpportunityApi,
    private exportApi: ExportApi,
    private noteService: NoteService,
    private sortMappingService: SortMappingService,
    private withoutPoPageService: WithoutPoPageService,
    private filterService: FilterService,
    private message: NzMessageService,
    private toastService: ToastMessageService,
    public aclService: AclService,
    private matomoTracker: MatomoTracker,
  ) {}

  ngOnInit() {
    this.initWithoutContractPage();
    this.triggerFilter();
  }

  ngAfterViewInit(): void {
    this.listenScrollAndLoadMore();
  }

  ngOnDestroy(): void {
    this.setWithoutContractPageToService();
  }

  private initFilterAndSortFromService() {
    this.mapOfSort = this.sortMappingService.getSort(SORT_MAP_TYPE_WITHOUT_PO);
    this.filters = getWithoutContractFilterList(this.getSearchItemAndPageCustomFilterFormat());
  }

  private getSearchItemAndPageCustomFilterFormat() {
    const formatContractReceivedDate =
      this.selectedContractReceivedDateRange.length === 0
        ? {}
        : {
            from: moment(this.selectedContractReceivedDateRange[0]).format('YYYY-MM-DD'),
            to: moment(this.selectedContractReceivedDateRange[1]).format('YYYY-MM-DD'),
          };
    return {
      searchItem: this.filterService.getFilterItem().searchItem,
      withoutContractStatus: this.selectedWithoutContractStatus,
      withoutPoDays: this.selectedWithoutContractDays,
      expectedContractReceivedDate: formatContractReceivedDate,
    };
  }

  private initWithoutContractPage() {
    const withoutPoPage = this.withoutPoPageService.get();
    isEmpty(withoutPoPage) ? this.initPageData() : this.setPageData(withoutPoPage);
  }

  private initPageData(tag = '') {
    this.loadingFirst = true;
    this.withoutPoList = [];
    this.pageIndex = -1;
    this.stopLastSubscribe.next();
    this.postForWithoutContractList()
      .pipe(takeUntil(merge(this.unsubscribe, this.stopLastSubscribe)))
      .subscribe((data: WithoutPoPage) => {
        this.loadingFirst = false;
        this.scrollEle = null;
        this.setPageData(data);
      });
    this.recordMatomoWhenClickViewOrResetOrDownload(tag);
  }

  private recordMatomoWhenClickViewOrResetOrDownload(tag: string) {
    if (tag === 'clickView' && this.filters.length > 0) {
      const filters = this.filters
        .filter((item) => Object.keys(NAME_FILTER_EVENT_MAPPING).includes(item.name))
        .map((item) => NAME_FILTER_EVENT_MAPPING[item.name] || item.name);
      const deduplicateFilters = Array.from(new Set(filters)).join('&');
      this.matomoTracker.trackEvent(this.pageName, ACTION_FILTER, `View filters ${deduplicateFilters} result`);
    }
    if (tag === 'clickReset') {
      this.matomoTracker.trackEvent(this.pageName, ACTION_FILTER, 'Reset all filters');
    }
    if (tag === 'clickDownload') {
      this.matomoTracker.trackEvent(this.pageName, ACTION_FILTER, 'click download');
    }
  }

  private setPageData(data: WithoutPoPage | WithoutPoPageData) {
    this.withoutPoList = data.content;
    this.pageIndex = data.number;
    this.isLastPage = data.last;
    this.totalElements = data.totalElements;
    this.withoutPoList.forEach((invoice) => {
      if (!isEmpty(invoice.notes)) {
        this.noteService.storeNotes(NoteType.WITHOUTPO, invoice.notes);
      }
    });
  }

  private setWithoutContractPageToService() {
    const withoutContractPage = {
      content: this.withoutPoList,
      number: this.pageIndex,
      last: this.isLastPage,
      totalElements: this.totalElements,
    };
    this.withoutPoPageService.set(withoutContractPage);
  }

  private listenScrollAndLoadMore() {
    const nativeElement = this.tableCom.nzTableInnerScrollComponent.tableBodyElement.nativeElement;
    this.listenScroll(nativeElement)
      .pipe(takeUntil(merge(this.unsubscribe)))
      .subscribe((data: WithoutPoPage) => {
        data.content = concat(this.withoutPoList, data.content);
        this.setPageData(data);
        this.scrollEle.scrollTop = this.currentScrollTop;
      });
  }

  private listenScroll(nativeElement) {
    return fromEvent(nativeElement, 'scroll').pipe(
      map((event) => this.scrollEle || (event as Event).target),
      filter((ele: HTMLElement) => {
        const isTriggerBottom = ele.scrollTop + ele.offsetHeight + 20 >= ele.scrollHeight;
        return !this.isLastPage && ele.scrollHeight !== 0 && isTriggerBottom && this.withoutPoList.length > 0;
      }),
      exhaustMap((ele: HTMLElement) => {
        this.scrollEle = ele;
        this.currentScrollTop = ele.scrollHeight - ele.offsetHeight + 40;
        this.loadingMore = true;
        return this.postForWithoutContractList();
      }),
      throttleTime(1000),
    );
  }

  private postForWithoutContractList(): Observable<WithoutPoPage> {
    const targetPageIndex = this.pageIndex + 1;
    this.initFilterAndSortFromService();
    this.filters.push(...getAdvancedFilterConditions(this.filterItem));
    return this.opportunityApi
      .getWithoutContractList(targetPageIndex, this.pageSize, this.filters, this.orders)
      .pipe(takeUntil(this.stopLastSubscribe))
      .pipe(
        finalize(() => {
          this.loadingMore = false;
        }),
      );
  }

  private triggerFilter() {
    this.filterService
      .listenFilter()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((item: Filter) => {
        this.clearSort();
        this.initPageData();
      });
  }

  private clearSort() {
    this.orders = DEFAULT_WITHOUT_PO_SORTING;
    this.mapOfSort = {};
    this.sortMappingService.clear(SORT_MAP_TYPE_WITHOUT_PO);
  }

  sort(sortingType, sortKey: string) {
    if (!isEmpty(sortingType)) {
      this.orders = { [sortKey]: sorting[sortingType] };
      this.mapOfSort = { [sortKey]: sortingType };
      this.sortMappingService.setSort(SORT_MAP_TYPE_WITHOUT_PO, this.mapOfSort);
      this.initPageData();
      this.matomoTracker.trackEvent(this.pageName, ACTION_SORT, `Sort by ${startCase(sortKey)}`);
    }
  }

  getDaysColor(days: number) {
    let color = 'green';
    if (days > WITHOUT_PO_DAYS_L2) {
      color = 'red';
    } else if (days > WITHOUT_PO_DAYS_L1) {
      color = 'yellow';
    }
    return color;
  }

  getApplicationOverdueCountdownColor(days: number) {
    if (isEmpty(days) && !isNumber(days)) {
      return 'black';
    }
    if (days > 0) {
      return 'red';
    } else if (days >= -3) {
      return 'yellow';
    } else {
      return 'blue';
    }
  }

  openDataTips(): void {
    this.isDataTipsVisible = true;
    this.matomoTracker.trackEvent(CATEGORY_DATE_TIPS, ACTION_CLICK, `Check Data Specification of ${this.pageName}`);
  }

  closeDataTips(): void {
    this.isDataTipsVisible = false;
  }

  handleFilterChanged(event: any) {
    this.filterItem = {
      ...event,
    };
    this.initPageData('clickView');
  }

  handleFilterReset(event: any) {
    this.filterItem = {
      ...event,
    };
    this.selectedWithoutContractStatus = [];
    this.selectedWithoutContractDays = [];
    this.selectedContractReceivedDateRange = [];
    this.initPageData('clickReset');
  }

  handleDownload() {
    const id = this.message.loading('Action in progress..', { nzDuration: 0 }).messageId;
    this.exportApi
      .exportExcel('without-po', this.filters)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (data) => {
          const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
          this.message.remove(id);
          saveAs(blob, `Opportunity Without Contract Dashboard (Download date ${moment(Date.now()).format(MomentFormat.DATE)})`);
          this.toastService.success('Export finished');
        },
        () => {
          this.message.remove(id);
        },
      );
    this.recordMatomoWhenClickViewOrResetOrDownload('clickDownload');
  }

  handleSelectedCommonFilterChangeForMatomoRecord(event: any) {
    this.matomoTracker.trackEvent(this.pageName, ACTION_FILTER, event);
  }

  handleSelectedAdvancedFilterChangeForMatomoRecord(filterName: string) {
    this.matomoTracker.trackEvent(this.pageName, ACTION_FILTER, filterName);
  }
}
