import { Pipe, PipeTransform } from '@angular/core';
import { indexOf, keys } from 'lodash';
import { CAPSULE_TAG } from '../constants/capsuleTag';

@Pipe({
  name: 'capsuleTag',
})
export class CapsuleTagPipe implements PipeTransform {
  transform(value: string): string {
    if (indexOf(keys(CAPSULE_TAG), value) > -1) {
      return CAPSULE_TAG[value];
    }
  }
}
