import { dsoFormat } from './dsoFormat';
import { isNil } from 'lodash';

export class BarChart {
  public static NORMAL_BILLED = '#fad455';
  public static HIGHLIGHT_BILLED = '#db5157';
  public static NORMAL_UNBILLED = '#ffd4d6';
  public static HIGHLIGHT_UNBILLED = '#f3f3f3';

  public static GRAY_LIGHT = '#dfdfdf';
  public static GRAY_DARK = '#4a4a4a';

  public static SCROLL_MAX_NUMBER = 6;
  public static DATA_LENGTH = 10;
  public static HISTORY_MAX_NUMBER = 13;
  public static BEAUTY_TOKEN = 'beautyStyle';

  public static BAR_CHART_OPTION = {
    grid: {
      left: 70,
    },
    xAxis: {
      min: 0,
      axisLine: { show: false },
      axisTick: { show: false },
      splitLine: { show: false },
      show: true,
      type: 'category',
      data: [],
      axisLabel: {
        interval: 0,
        margin: 14,
        formatter(value) {
          if (value === BarChart.BEAUTY_TOKEN) {
            return '';
          } else if (!isNil(value) && value !== 'undefined') {
            const strings = value.split('-');

            return '{year|' + strings[0].slice(2) + '}/{month|' + strings[1] + '}';
          }

          return '--';
        },
        rich: {
          month: {
            fontWeight: 'bolder',
            fontSize: 10,
            color: 'black',
            lineHeight: 12,
            verticalAlign: 'bottom',
          },
          year: {
            fontWeight: '300',
            fontSize: 8,
            color: 'gray',
            lineHeight: 12,
            verticalAlign: 'bottom',
          },
        },
      },
    },
    yAxis: {
      min: 0,
      max: 0,
      silent: true,
      axisLine: { show: false },
      axisTick: { show: false },
      type: 'value',
      splitLine: {
        interval: 0,
        lineStyle: {
          width: 1,
          type: 'dashed',
          color: BarChart.GRAY_LIGHT,
        },
      },
      axisLabel: {
        margin: 30,
        color: 'rgba(26,115,186,0.4)',
        fontFamily: 'Avenir',
        fontSize: 10,
        fontWeight: 'bolder',
      },
    },

    series: [
      {
        z: 5,
        name: 'overall',
        type: 'candlestick',
        barWidth: 20,
        barMinHeight: 20,
        data: [],
        silent: false,
        emphasis: {
          itemStyle: {
            barBorderColor: '#ffffff',
            color: '#ffffff',
            borderWidth: 12,
            shadowColor: 'rgba(0, 0, 0, 0.1)',
            shadowBlur: 12,
          },
        },
        markLine: {
          animation: false,
          silent: true,
          lineStyle: {
            color: BarChart.HIGHLIGHT_BILLED,
          },
          symbol: 'none',
          label: {
            show: false,
          },
          data: [
            {
              yAxis: dsoFormat.dso,
            },
          ],
        },
        itemStyle: {
          color: '#ffffff',
          borderColor: '#ffffff',
        },
        markPoint: {
          symbol: 'rect',
          symbolSize: 0.000000000000001,
          silent: true,
          label: {
            show: true,
            position: 'top',
            distance: 14,
            color: 'black',
            fontSize: 10,
            formatter: (res) => res.data.value,
          },
          data: [],
        },
      },
      {
        z: 6,
        name: 'billed',
        silent: true,
        type: 'bar',
        stack: 'dso',
        barWidth: 20,
        barMinHeight: 7,
        data: [],
        itemStyle: {
          color({ value }) {
            return value === 0 ? 'transparent' : value >= dsoFormat.billed ? BarChart.HIGHLIGHT_BILLED : BarChart.NORMAL_BILLED;
          },
          barBorderRadius: [4, 4, 4, 4],
        },
        barGap: '-100%',
        label: {
          show: true,
          position: 'inside',
          fontSize: 10,
          formatter({ value }: { value: any }): string {
            return value === 0 ? '' : value;
          },
        },
      },
      {
        z: 6,
        name: 'unbilled',
        type: 'bar',
        silent: true,
        stack: 'dso',
        barWidth: 20,
        barMinHeight: 7,
        data: [],
        itemStyle: {
          color({ value }) {
            return value === 0 ? 'transparent' : value >= dsoFormat.unbilled ? BarChart.NORMAL_UNBILLED : BarChart.HIGHLIGHT_UNBILLED;
          },
          barBorderRadius: [4, 4, 4, 4],
        },
        label: {
          show: true,
          formatter({ value }) {
            return value === 0 ? '' : value >= dsoFormat.unbilled ? '{red|' + value + '}' : '{normal|' + value + '}';
          },
          rich: {
            normal: {
              color: BarChart.GRAY_DARK,
              fontSize: 10,
            },
            red: {
              color: BarChart.HIGHLIGHT_BILLED,
              fontSize: 10,
            },
          },
        },
      },
    ],
    tooltip: {
      trigger: 'item',
      position: 'bottom',
      triggerOn: 'mousemove | click',
      showDelay: 10,
      hideDelay: 100,
      enterable: true,
      backgroundColor: 'rgba(255,255,255,0)',
      padding: 0,
      className: 'bar-chart-tooltip',
      formatter(params: { name }) {
        let { name } = params;
        name = isNil(params) || name === 'undefined' ? '--' : name;

        return (
          '<div' +
          '      style="text-align: center;width: 79px;height: 32px;border-radius: 16px;' +
          'box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);background-color: rgba(74, 74, 74, 0.9);">' +
          '       <p style="text-align: center;opacity: 0.75 !important;line-height: 1.33;' +
          'padding:8px;font-size: 12px;font-weight: 300;color: #ffffff;">' +
          name +
          '</p>' +
          '</div>'
        );
      },
    },
    dataZoom: [
      {
        type: 'slider',
        startValue: 1,
        endValue: 6,
        height: 5,
        showDetail: false,
        zoomLock: true,
        backgroundColor: 'rgba(243,243,243,0.6)',
        borderRadius: 3,
        filterHeight: 20,
        fillerColor: BarChart.GRAY_LIGHT,
        borderColor: 'rgba(243,243,243,0.3)',
        bottom: 20,
        left: 50,
        showDataShadow: false,
        handleIcon: `M 100, 100 m -75, 0
                     a 75,75 0 1,0 150,0
                     a 75,75 0 1,0 -150,0`,
        handleSize: 2,
        handleStyle: {
          color: BarChart.GRAY_LIGHT,
          width: 200,
          height: 200,
          borderColor: BarChart.GRAY_LIGHT,
        },
      },
    ],
  };
}
