<div class="info-item" [ngClass]="{ 'highlight-notification': !item.read }" (click)="onClickNotification()">
  <div class="text">
    <div class="icon"></div>
    <div *ngIf="item.type === 'bad_debt_removed'">
      The bad debt(Invoice ID:
      <strong>{{ item.content.invoiceId }}</strong>
      ) of the Opportunity
      <strong>{{ item.content.opportunityName }}</strong>
      has been removed due to the invoice status change
    </div>
    <div *ngIf="item.type === 'opportunity_without_po'">
      Please add a contract information for the Opportunity
      <strong>{{ item.content.opportunityName }}</strong>
    </div>
    <div *ngIf="item.type === 'account_without_mu'">
      Please add MU Tag for the Account
      <strong>{{ item.content.accountName }}</strong>
    </div>
  </div>
  <div class="date">
    {{ item.createdDate | date : dateType.DATE }}
  </div>
</div>
