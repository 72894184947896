import { Component, Inject, OnInit } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

@Component({
  template: `
    <div style="font-size: 38px; padding: 30px">{{ error }}</div>
  `,
})
export class OktaCallbackComponent implements OnInit {
  error: string;

  constructor(@Inject(OKTA_AUTH) private oktaAuth: OktaAuth) {}

  async ngOnInit() {
    try {
      await this.oktaAuth.handleLoginRedirect();
      const redirectUrl = sessionStorage.getItem('redirectUrl');
      document.location.replace(redirectUrl);
    } catch (e) {
      this.error = e.message;
    }
  }
}
