import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { isEqual, orderBy } from 'lodash';
import { AccountMuHistory, AccountMuList } from '@interfaces/AccountList';
import { AccountApi } from '@apis/account.api';
import { AclService } from '@services/acl.service';
import { takeUntil } from 'rxjs/operators';
import { DateType } from '@constants/dateFormat';
import { getMuColor } from '@utils/dso-mu';

@Component({
  selector: 'app-edit-mu',
  templateUrl: './edit-mu.component.html',
  styleUrls: ['./edit-mu.component.scss'],
})
export class EditMuComponent implements OnDestroy {
  @Input() mu: string;
  @Input() muList: AccountMuList[];
  @Input() lookUpId: number;
  @Output() editMuPopoverIsVisible = new EventEmitter<boolean>();
  @Output() editNewMu = new EventEmitter<string>();
  clickPopover: boolean;
  showPopover: boolean;
  editMu: string;
  disableSave: any;
  isRequesting: boolean;
  muHistory: AccountMuHistory[];
  loadingMuHistory: boolean;
  hasMu = true;
  popoverPlacement = 'bottomLeft';
  dateType = DateType;

  private height = 650;
  private unsubscribe = new Subject();
  private modifyComplete = true;

  constructor(private accountApi: AccountApi, public aclService: AclService) {}

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getMuColor(mu: string) {
    return getMuColor(mu);
  }

  clickPopoverBtn(event: MouseEvent) {
    this.initMuHistory();
    this.editMuPopoverIsVisible.emit(this.clickPopover);
    this.setPopoverPlacement(event);
  }

  private setPopoverPlacement(event: MouseEvent) {
    if (event.pageY < this.height) {
      this.popoverPlacement = 'bottomLeft';
    } else {
      this.popoverPlacement = 'topLeft';
    }
  }

  private initMuHistory() {
    this.disableSave = true;
    this.loadingMuHistory = true;
    this.accountApi
      .getMuHistory(this.lookUpId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((muHistory) => {
        this.muHistory = orderBy(muHistory, ['createDate'], ['desc']);
        this.hasMu = this.muHistory.length !== 0;
        this.loadingMuHistory = false;
      });
  }

  saveMu() {
    this.isRequesting = true;
    this.loadingMuHistory = true;
    if (this.modifyComplete) {
      this.modifyComplete = false;
      this.accountApi
        .updateAccountMu(this.lookUpId, this.editMu)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((results) => {
          this.muHistory.unshift(results);
          this.hasMu = this.muHistory.length !== 0;
          this.disableSave = true;
          this.loadingMuHistory = false;
          this.isRequesting = false;
          this.editNewMu.emit(this.editMu);
          this.modifyComplete = true;
        });
    }
  }

  onSelectChange(selectedMu: string) {
    this.disableSave = isEqual(selectedMu, this.mu);
    this.editMu = selectedMu;
  }
}
