import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SearchResponse } from '@interfaces/SearchResult';
import { ESPeople } from '@interfaces/People';

@Injectable({ providedIn: 'root' })
export class SearchApi {
  constructor(private http: HttpClient) {}

  getSearchResponseByName(name: any): Observable<SearchResponse> {
    return this.http.get('/search', { params: { name } });
  }

  getSearchResponseByPeople(input: string): Observable<ESPeople[]> {
    return this.http.get<ESPeople[]>('/search/people', { params: { input } });
  }
}
