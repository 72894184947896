import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { ContractResponse } from '@interfaces/ContractInfo';
import { AclApi } from '@apis/acl.api';

@Injectable({
  providedIn: 'root',
})
export class RoleResolveService implements Resolve<ContractResponse> {
  constructor(private aclApi: AclApi) {}

  resolve(): Observable<any> {
    return forkJoin([this.aclApi.getRoles(), this.aclApi.getAllPermissions()]);
  }
}
