import { Component, Input, OnInit } from '@angular/core';
import { MuInfo } from '@interfaces/MuList';
import { dsoFormat } from '@constants/dsoFormat';

@Component({
  selector: 'app-mu-block-item',
  templateUrl: './mu-block-item.component.html',
  styleUrls: ['./mu-block-item.component.scss'],
})
export class MuBlockItemComponent implements OnInit {
  @Input() muList: MuInfo;
  options;

  ngOnInit(): void {
    const { billedDso, unBilledDso } = this.muList;
    this.options = {
      tooltip: {
        show: false,
      },
      series: [
        {
          name: 'dso',
          type: 'pie',
          radius: ['57%', '70%'],
          legendHoverLink: false,
          hoverAnimation: false,
          avoidLabelOverlap: false,
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          silent: true,
          itemStyle: {
            color: ({ dataIndex, value }) => {
              if (dataIndex === 0) {
                return '#dfdfdf';
              } else if (dataIndex === 1) {
                if (value < dsoFormat.billed || billedDso < 0) {
                  return '#fad455';
                }

                return '#db5157';
              } else {
                if (value < dsoFormat.unbilled || unBilledDso < 0) {
                  return '#dfdfdf';
                }

                return '#ffd4d6';
              }
            },
          },
          data: [
            { value: 0, name: 'empty' },
            { value: billedDso < 0 ? Math.abs(billedDso) : billedDso, name: 'billedDso' },
            { value: unBilledDso < 0 ? Math.abs(unBilledDso) : unBilledDso, name: 'unBilledDso' },
          ],
        },
      ],
    };
  }
}
