<p
  class="high-risk-tag"
  [ngClass]="{ active: currentHighRisk, inactive: !currentHighRisk, disabled: !hasHighRiskTagEditPermission }"
  nz-popconfirm
  [nzPopconfirmTitle]="customTitleContent"
  nzOkText="Yes"
  nzCancelText="No"
  nzPopconfirmPlacement="bottomLeft"
  nzPopconfirmOverlayClassName="confirmDeletePopover"
  (nzPopconfirmVisibleChange)="onPopconfirmVisibleChange($event)"
  (nzOnCancel)="handleCancelConfirm()"
  [nzBeforeConfirm]="handleBeforeUpdateConfirm"
  [nzPopconfirmTrigger]="hasHighRiskTagEditPermission ? 'click' : null"
  [nzPopconfirmBackdrop]="true"
  data-testid="high-risk-tag"
>
  <span class="default-text">Risk Tag Edit</span>
  <span class="active-text" nz-tooltip [nzTooltipTitle]="riskCategoryToolTips" [nzTooltipTrigger]="isHighRisk ? 'hover' : null">
    Risk Project
  </span>
</p>

<ng-template #customTitleContent>
  <div class="custom-content-container">
    <app-icon-text class="popover-title" text="Please select risk category" [className]="'icon-warning'"></app-icon-text>
    <p *ngIf="showAllTagClearWarn" class="clear-label-warn">
      You will lost the risk tag if you empty the label. Are you sure to remove it？
    </p>
    <nz-checkbox-group class="checkbox-group" [(ngModel)]="checkOptions" (ngModelChange)="handleCheckBoxChange()"></nz-checkbox-group>
  </div>
</ng-template>

<ng-template #riskCategoryToolTips>
  <div class="risk-tag-tool-tip">
    <div *ngFor="let category of riskCategories; index as i">
      <p>{{ HIGH_RISK_CATEGORY_MAP[category] }}</p>
    </div>
  </div>
</ng-template>
