<nz-modal
  nzClassName="modal-common-style"
  [nzVisible]="isVisible"
  [nzMaskClosable]="false"
  [nzWidth]="928"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  (nzOnCancel)="handleCancel()"
  (nzAfterClose)="handleAfterClose()"
  [nzBodyStyle]="{ height: 'calc(80vh - 190px)' }"
>
  <ng-template #modalTitle>
    <div class="modal-title-with-label">{{ hasSubmitted ? 'Edit Milestone' : 'Add New Milestone' }}</div>
  </ng-template>

  <ng-template #modalContent>
    <div class="milestone-dialog-body">
      <div class="action-bar">
        <app-icon-text className="icon-add" text="Add Item" (click)="addMilestone()"></app-icon-text>
      </div>
      <div class="scroll-container">
        <form nz-form class="milestones-form" [formGroup]="form" nzLayout="vertical">
          <div formArrayName="milestones" *ngFor="let control of formMilestones.controls; let $index = index">
            <div class="milestone-row" [formGroupName]="$index" #milestoneRows>
              <div class="input-container">
                <div class="input-container-title">
                  <div class="title">Milestone</div>
                  <div *ngIf="control.getRawValue().status === MilestoneStatus.done" class="status-tag green-done">Done</div>
                  <div *ngIf="control.getRawValue().status === MilestoneStatus.saved" class="status-tag orange-warning">Draft</div>
                </div>
                <div nz-row [nzGutter]="16">
                  <div nz-col [nzSpan]="8">
                    <nz-form-item>
                      <nz-form-label nzRequired>Milestone Name</nz-form-label>
                      <nz-form-control nzErrorTip="Please input Milestone Name">
                        <input nz-input nzAutosize maxlength="255" formControlName="name" data-testid="milestone-name" />
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                  <div nz-col [nzSpan]="8">
                    <nz-form-item>
                      <nz-form-label nzRequired>Milestone Due Date</nz-form-label>
                      <nz-form-control nzErrorTip="Please select a date">
                        <nz-date-picker
                          formControlName="milestoneDueDate"
                          nzPlaceHolder=" "
                          nzFormat="yyyy-MM-dd"
                          [ngClass]="form?.errors?.dueDateError && form?.errors?.indexes.indexOf($index) !== -1 && 'input-error'"
                          data-testid="milestone-due-date"
                        ></nz-date-picker>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                  <div nz-col [nzSpan]="8">
                    <nz-form-item>
                      <nz-form-label nzRequired>Milestone Amount</nz-form-label>
                      <nz-form-control nzErrorTip="Please input a valid number">
                        <nz-input-group nzCompact class="amount-input-group">
                          <div
                            [ngClass]="
                              control.getRawValue().status === MilestoneStatus.done ? 'amount-input-prefix-disabled' : 'amount-input-prefix'
                            "
                          >
                            {{ currency }}
                          </div>
                          <nz-input-number
                            formControlName="milestoneAmount"
                            [nzPrecision]="2"
                            nzPrecisionMode="cut"
                            [nzFormatter]="formatterMoney"
                            [nzMax]="9999999999.99"
                            [nzMin]="0"
                            class="amount-input"
                            [ngClass]="control.getRawValue().status !== MilestoneStatus.done && form?.errors?.amountError && 'input-error'"
                            data-testid="milestone-amount"
                          ></nz-input-number>
                        </nz-input-group>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>
                <div nz-row [nzGutter]="16" *ngIf="hasMilestoneSubmitted(control.getRawValue())">
                  <div nz-col [nzSpan]="8">
                    <nz-form-item>
                      <nz-form-label nzRequired>Delivery Action Owner</nz-form-label>
                      <app-user-search-dropdown
                        (updateFormValue)="setFormItemValue($index, 'deliveryActionOwner', $event)"
                        (markAsTouched)="markAsTouched($index, 'deliveryActionOwner')"
                        [defaultSearchList]="defaultDeliveryActionOwnerDropdownList"
                        [defaultInputValue]="control.getRawValue().deliveryActionOwnerFullName"
                        [status]="getFormControlStatus($index, 'deliveryActionOwner')"
                        [touched]="getFormControlTouched($index, 'deliveryActionOwner')"
                        errorTip="Please input Delivery Action Owner"
                        dataTestId="deliveryActionOwner"
                      ></app-user-search-dropdown>
                    </nz-form-item>
                  </div>
                  <div nz-col [nzSpan]="8">
                    <nz-form-item>
                      <nz-form-label nzRequired>Demand Action Needed</nz-form-label>
                      <nz-form-control nzErrorTip="Please select Demand Action Needed">
                        <nz-select
                          class="default-size"
                          [nzSuffixIcon]="selectIcon"
                          [nzOptions]="YesOrNoOptions"
                          formControlName="demandActionNeeded"
                          nzDropdownClassName="default-size-dropdown"
                          data-testid="demandActionNeeded"
                        ></nz-select>
                        <ng-template #selectIcon>
                          <i class="select-arrow" nz-icon nzType="svg:arrow" alt="arrow"></i>
                        </ng-template>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                  <div nz-col [nzSpan]="8">
                    <nz-form-item>
                      <nz-form-label nzRequired>Demand Action Owner</nz-form-label>
                      <app-user-search-dropdown
                        (updateFormValue)="setFormItemValue($index, 'demandActionOwner', $event)"
                        (markAsTouched)="markAsTouched($index, 'demandActionOwner')"
                        [defaultSearchList]="defaultDemandActionOwnerDropdownList"
                        [defaultInputValue]="control.getRawValue().demandActionOwnerFullName"
                        [status]="getFormControlStatus($index, 'demandActionOwner')"
                        [touched]="getFormControlTouched($index, 'demandActionOwner')"
                        errorTip="Please input Demand Action Owner"
                        dataTestId="demandActionOwner"
                      ></app-user-search-dropdown>
                    </nz-form-item>
                  </div>
                  <div nz-col [nzSpan]="8">
                    <nz-form-item>
                      <nz-form-label nzRequired>Has Delivery Risk</nz-form-label>
                      <nz-form-control nzErrorTip="Please select Has Delivery Risk">
                        <nz-select
                          class="default-size"
                          [nzSuffixIcon]="selectIcon"
                          [nzOptions]="YesOrNoOptions"
                          formControlName="hasDeliveryRisk"
                          nzDropdownClassName="default-size-dropdown"
                          data-testid="hasDeliveryRisk"
                        ></nz-select>
                        <ng-template #selectIcon>
                          <i class="select-arrow" nz-icon nzType="svg:arrow" alt="arrow"></i>
                        </ng-template>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                  <div nz-col [nzSpan]="24">
                    <nz-form-item>
                      <nz-form-label nzRequired>Reason</nz-form-label>
                      <nz-form-control nzErrorTip="Please input a reason" class="reason-form-control">
                        <div class="reason-tip">
                          Q1: what was the reason for delay (from client/our side)? Q2: what is the action plan for next step?
                        </div>
                        <nz-textarea-count [nzMaxCharacterCount]="255">
                          <textarea
                            formControlName="reason"
                            class="reason-textarea"
                            nz-input
                            nzAutosize
                            placeholder="comments here..."
                            maxlength="255"
                            data-testid="reason"
                          ></textarea>
                        </nz-textarea-count>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>
              </div>
              <div class="delete-icon-container">
                <app-icon-text
                  [isDisabled]="!allowDelete($index)"
                  className="icon-delete"
                  nz-tooltip
                  [nzTooltipTitle]="allowDelete($index) ? '' : 'This milestone cannot be deleted'"
                  nzTooltipOverlayClassName="custom-tooltip"
                  nzTooltipPlacement="bottomRight"
                  (click)="deleteMilestone($index)"
                  data-testid="icon-delete"
                ></app-icon-text>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </ng-template>

  <ng-template #modalFooter>
    <div class="milestone-dialog-footer">
      <div>
        <div class="error-tip-container" *ngIf="getErrorMessage()">
          <div class="error-tip">
            <img src="/assets/svg/error-hint.svg" />
            <span>{{ getErrorMessage() }}</span>
          </div>
        </div>
        <div class="amount-container">
          <span class="amount-text">Opportunity Amount</span>
          <span class="opportunity-amount amount-value">
            {{ opportunityDetail.contractAmount | currencyFormat : currency : false : true }}
          </span>
          <span class="amount-text">Milestone Total Amount</span>
          <span class="milestone-total-amount amount-value" [ngClass]="form?.errors?.amountError && 'error'">
            {{ totalAmount | currencyFormat : currency : false : true }}
          </span>
        </div>
      </div>
      <div class="operation-btns">
        <button class="cancel-btn" nz-button nzType="default" (click)="handleCancel()">Cancel</button>
        <button class="save-btn" nz-button nzType="default" [nzLoading]="isSaving" (click)="handleSave()">Save</button>
        <button
          nz-popconfirm
          [nzPopconfirmVisible]="isConfirmPopVisible"
          [nzPopconfirmTrigger]="null"
          [nzPopconfirmShowArrow]="false"
          nzOkText="Yes"
          nzCancelText="Cancel"
          [nzPopconfirmTitle]="confirmTip"
          nzPopconfirmPlacement="bottom"
          nzPopconfirmOverlayClassName="confirmDeletePopover"
          (nzOnConfirm)="handleSubmit()"
          (nzOnCancel)="handleCancelSubmit()"
          nz-button
          nzType="primary"
          type="button"
          [nzLoading]="isSubmitting"
          (click)="openConfirmPop()"
        >
          Submit
        </button>
      </div>
    </div>
  </ng-template>
</nz-modal>
