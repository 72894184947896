import { ComponentFactoryResolver, Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Toggle } from '../interfaces/Toggle';
import { ToggleService } from '../services/toggle.service';
import { NoPermissionComponent } from '../shared/no-permission/no-permission.component';

@Directive({
  selector: '[appFeatureToggle]',
})
export class ToggleDirective implements OnInit {
  @Input('appFeatureToggle') featureName: string;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('appFeatureToggleShowUnderConstruction') showUnderConstruction: boolean;

  private toggles: Toggle[] = [];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private toggleService: ToggleService,
  ) {}

  async ngOnInit() {
    if (await this.isEnabled()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else if (this.showUnderConstruction) {
      const factory = this.componentFactoryResolver.resolveComponentFactory(NoPermissionComponent);
      this.viewContainer.createComponent(factory);
    } else {
      this.viewContainer.clear();
    }
  }

  async isEnabled() {
    this.toggles = await this.toggleService.getToggles();

    const featureToggle = this.toggles.find((toggle) => toggle.name === this.featureName);

    return featureToggle == null || featureToggle.value;
  }
}
