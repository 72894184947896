import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FilterService } from '../services/filter.service';
import { isEmpty, isEqual, toNumber } from 'lodash';
import { WITHOUT_PO_DAYS_L0, WITHOUT_PO_DAYS_L1, WITHOUT_PO_DAYS_L2 } from '../constants/withoutPoDays';
import { MatomoTracker } from 'ngx-matomo';
import { ACTION_FILTER, NAME_DASHBOARD_PO } from '../constants/matomo';
import { Filter } from '@app/interfaces/Filter';

@Directive({
  selector: '[appWithoutPoDaysClick]',
})
export class WithoutPoDaysClickDirective implements OnInit, OnDestroy {
  @Input() withoutPoDaysLevel: string;
  private subscribeFilter: Subscription;

  constructor(private el: ElementRef, private filterService: FilterService, private matomoTracker: MatomoTracker) {}

  @HostListener('click')
  onClick() {
    this.matomoTracker.trackEvent(NAME_DASHBOARD_PO, ACTION_FILTER, 'Filter by ' + this.getRange(true));
    if (this.el.nativeElement.className === 'days-without-po-filter-active') {
      this.filterService.triggerFilter({ withoutPoDays: {} });
    } else {
      this.filterService.triggerFilter({ withoutPoDays: this.getRange() });
      this.el.nativeElement.classList.add('days-without-po-filter-active');
    }
  }

  ngOnInit(): void {
    this.getPreviousStatus();
    this.subscribeFilter = this.filterService.listenFilter().subscribe((item: Filter) => {
      if (!isEqual(item.withoutPoDays, this.getRange()) && this.el.nativeElement.className === 'days-without-po-filter-active') {
        this.el.nativeElement.classList.remove('days-without-po-filter-active');
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscribeFilter) {
      this.subscribeFilter.unsubscribe();
    }
  }

  private getPreviousStatus() {
    const withoutPoDaysFilter = this.filterService.getWithoutPoDays();
    if (!isEmpty(withoutPoDaysFilter) && withoutPoDaysFilter.from.toString() === this.withoutPoDaysLevel) {
      this.el.nativeElement.classList.add('days-without-po-filter-active');
    } else {
      this.el.nativeElement.classList.remove('days-without-po-filter-active');
    }
  }

  getRange(matomo = false) {
    const withoutPoDays = toNumber(this.withoutPoDaysLevel);

    if (withoutPoDays === WITHOUT_PO_DAYS_L0) {
      if (matomo) {
        return `<=${WITHOUT_PO_DAYS_L1}`;
      }

      return { from: WITHOUT_PO_DAYS_L0, to: WITHOUT_PO_DAYS_L1 };
    }

    if (withoutPoDays === WITHOUT_PO_DAYS_L1) {
      if (matomo) {
        return `${WITHOUT_PO_DAYS_L1}-${WITHOUT_PO_DAYS_L2}`;
      }

      return { from: WITHOUT_PO_DAYS_L1, to: WITHOUT_PO_DAYS_L2 };
    }

    if (withoutPoDays === WITHOUT_PO_DAYS_L2) {
      if (matomo) {
        return `>=${WITHOUT_PO_DAYS_L2 + 1}`;
      }

      return { from: WITHOUT_PO_DAYS_L2, to: null };
    }
  }
}
