import { Pipe, PipeTransform } from '@angular/core';
import { UserInfo } from '@interfaces/People';
import { isNil } from 'lodash';

@Pipe({
  name: 'formatPeopleName',
})
export class FormatPeopleNamePipe implements PipeTransform {
  transform(user: UserInfo): string {
    let { firstName, middleName, lastName } = user;
    firstName = isNil(firstName) ? '' : firstName.concat(' ');
    middleName = isNil(middleName) ? '' : middleName;
    lastName = isNil(lastName) ? '' : ' '.concat(lastName);

    return firstName.concat(middleName, lastName);
  }
}
