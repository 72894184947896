import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { formatterMoney, preventTypingWhenExceed } from '@utils/money';

@Component({
  selector: 'app-info-edit-currency-input',
  templateUrl: './info-edit-currency-input.component.html',
  styleUrls: ['./info-edit-currency-input.component.scss'],
})
export class InfoEditCurrencyInputComponent implements OnInit {
  @Input() initValue: number;
  @Output() save = new EventEmitter<string>();
  inputValue = null;
  formatterMoney = formatterMoney;
  preventTypingWhenExceed = preventTypingWhenExceed;

  ngOnInit(): void {
    this.inputValue = this.initValue;
  }

  clear() {
    this.inputValue = null;
  }

  callSave($event: MouseEvent) {
    $event.stopPropagation();
    this.save.emit(this.inputValue);
  }
}
