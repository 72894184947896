import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { OKTA_AUTH } from '@okta/okta-angular';
import { Observable, from } from 'rxjs';
import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from '@environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(@Inject(OKTA_AUTH) private oktaAuth: OktaAuth) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const cloneReq = request.clone({ url: `${environment.host}${request.url}` });
    return from(this.handleAccess(cloneReq, next));
  }

  private async handleAccess(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    const accessToken = this.oktaAuth.getAccessToken();
    const withCredentialStatus = environment.env !== 'dev';
    const authRequest = request.clone({
      setHeaders: { Authorization: `Bearer ${accessToken}` },
      withCredentials: withCredentialStatus,
    });

    return next.handle(authRequest).toPromise();
  }
}
