<div class="role-container">
  <div class="menu">
    <div class="left">Role</div>
    <div class="right">
      <app-icon-text *ngIf="type === 'original'" [className]="'icon-edit'" (click)="onClickEditBtn()"></app-icon-text>
      <app-icon-text
        *ngIf="type === 'edit'"
        [className]="'icon-edit-hover'"
        [text]="'Save'"
        [reverse]="true"
        (click)="onClickSave()"
      ></app-icon-text>
      <app-role-control
        [role]="role"
        #controlComponent
        (emitDeleteRole)="deleteRole()"
        (click)="onClickRoleControl()"
        [index]="index"
      ></app-role-control>
    </div>
  </div>
  <div class="user-info">
    <app-icon-text [className]="isAdmin ? 'admin-user' : 'normal-user'"></app-icon-text>
    <div class="name" nz-tooltip nzTooltipPlacement="top" *ngIf="type === 'original'" [nzTooltipTitle]="roleNameToolTips">
      {{ role.description }}
    </div>
    <label>
      <input
        type="text"
        [formControl]="userName"
        (focus)="onFocus()"
        (blur)="onBlur()"
        (input)="onInputAmount($event?.target?.value)"
        [class.error-input]="!userName?.valid && checkValid"
        *ngIf="type === 'edit'"
        maxlength="50"
        #inputElement
      />
    </label>
  </div>
</div>

<ng-template #roleNameToolTips>
  <div class="role-name-tooltips">
    <span>{{ role.description }}</span>
  </div>
</ng-template>
