import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NotesApi {
  constructor(private http: HttpClient) {}

  updateNotes(id, content): Observable<any> {
    return this.http.put(`/notes/${id}`, { content });
  }

  createNotes(lookupId, content, type): Observable<any> {
    return this.http.post(`/notes`, { lookupId, content, type });
  }

  deleteNotes(id): Observable<any> {
    return this.http.delete(`/notes/${id}`);
  }
}
