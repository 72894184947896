import { FileItem, FileUploader, FileUploaderOptions } from 'ng2-file-upload';

export class MultipleFileUploader extends FileUploader {
  constructor(fileUploaderOptions: FileUploaderOptions) {
    super(fileUploaderOptions);
  }

  uploadAllFiles(): void {
    if (this.queue.length === 1) {
      this.uploadItem(this.queue[0]);
    } else if (this.queue.length > 1) {
      if (this.options.disableMultipart) {
        return;
      }
      const items = this.getNotUploadedItems().filter((item: FileItem) => !item.isUploading);
      if (!items.length) {
        return;
      }
      items.map((item: FileItem) => item._prepareToUploading());
      if (this.isUploading) {
        return;
      }
      this.isUploading = true;
      const xmlHttpRequest = new XMLHttpRequest();
      const formData = new FormData();
      for (const fileItem of this.queue) {
        this._onBeforeUploadItem(fileItem);
        if (typeof fileItem._file.size !== 'number') {
          throw new TypeError('The file specified is no longer valid');
        }
        this._onBuildItemForm(fileItem, formData);
        formData.append('files', fileItem._file, fileItem.file.name);
      }
      xmlHttpRequest.upload.onprogress = (event: any) => {
        const progress = Math.round(event.lengthComputable ? (event.loaded * 100) / event.total : 0);
        for (const fileItem of this.queue) {
          this._onProgressItem(fileItem, progress);
        }
      };
      xmlHttpRequest.onload = () => {
        this.resultHandle(xmlHttpRequest, 1);
      };
      xmlHttpRequest.onerror = () => {
        this.resultHandle(xmlHttpRequest, 0);
      };
      xmlHttpRequest.onabort = () => {
        this.resultHandle(xmlHttpRequest);
      };
      xmlHttpRequest.open(this.options.method, this.options.url, true);
      xmlHttpRequest.withCredentials = true;
      if (this.options.headers) {
        for (const head of this.options.headers) {
          xmlHttpRequest.setRequestHeader(head.name, head.value);
        }
      }
      if (this.authToken) {
        xmlHttpRequest.setRequestHeader(this.authTokenHeader, this.authToken);
      }
      xmlHttpRequest.send(formData);
      this._render();
    }
  }

  resultHandle = (xmlHttpRequest: XMLHttpRequest, flag = -1) => {
    const headers = this._parseHeaders(xmlHttpRequest.getAllResponseHeaders());
    const response = this._transformResponse(xmlHttpRequest.response, headers);
    for (const fileItem of [...this.queue]) {
      if (flag === 1) {
        if (xmlHttpRequest.status >= 200 && xmlHttpRequest.status < 300) {
          this._onSuccessItem(fileItem, response, xmlHttpRequest.status, headers);
        } else {
          this._onErrorItem(fileItem, response, xmlHttpRequest.status, headers);
        }
      } else if (flag === 0) {
        this._onErrorItem(fileItem, response, xmlHttpRequest.status, headers);
      } else {
        this._onCancelItem(fileItem, response, xmlHttpRequest.status, headers);
      }
      this._onCompleteItem(fileItem, response, xmlHttpRequest.status, headers);
    }
  };
}
