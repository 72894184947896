import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';
import { indexOf, isEmpty, isNil, isNumber, keys, toNumber } from 'lodash';
import { CURRENCY_$, OTHER_CURRENCY } from '@constants/currency';

@Pipe({
  name: 'currencyFormat',
})
export class CurrencyFormatPipe implements PipeTransform {
  transform(value: undefined | null | string | number, type: string, isInt = true, hasSpace = false): any {
    if ((isEmpty(value) && !isNumber(value)) || isNil(type)) {
      return '--';
    }

    let displayValue =
      isInt || Number.isInteger(toNumber(value))
        ? formatNumber(parseInt(toNumber(value).toString(), 10), 'en-US', '1.0-0')
        : formatNumber(parseFloat(value.toString()), 'en-US', '1.0-2');
    displayValue = displayValue.startsWith('-') ? displayValue.substring(1) : displayValue;
    const negative = toNumber(value) < 0;

    if (indexOf(CURRENCY_$, type) > -1) {
      const splitChar = hasSpace ? ' ' : '';
      return negative ? `-$${splitChar}${displayValue}` : `$${splitChar}${displayValue}`;
    }
    if (indexOf(keys(OTHER_CURRENCY), type) > -1) {
      const displayValueWithCurrency = OTHER_CURRENCY[type] + (hasSpace ? ' ' : '') + displayValue;
      return negative ? `-${displayValueWithCurrency}` : displayValueWithCurrency;
    }

    return displayValue;
  }
}
