import { Component, Inject, OnInit } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import { FileItem } from 'ng2-file-upload';
import { MultipleFileUploader } from '../multiple-file-uploader';
import { environment } from '@environments/environment';
import { OktaAuth } from '@okta/okta-auth-js';

@Component({
  selector: 'app-upload-revenue',
  templateUrl: './upload-revenue.component.html',
  styleUrls: ['./upload-revenue.component.scss'],
})
export class UploadRevenueComponent implements OnInit {
  fileUploader: MultipleFileUploader;

  constructor(@Inject(OKTA_AUTH) private oktaAuth: OktaAuth) {
    this.fileUploader = new MultipleFileUploader({
      allowedFileType: ['xls'],
      url: `${environment.host}/import/revenue`,
      method: 'PUT',
      removeAfterUpload: true,
      itemAlias: 'files',
    });
  }

  async ngOnInit() {
    const accessToken = this.oktaAuth.getAccessToken();
    this.fileUploader.authToken = `Bearer ${accessToken}`;
    this.fileUploader.onAfterAddingFile = (fileItem: FileItem) => {
      if (this.fileUploader.queue.filter((file) => file._file.name === fileItem._file.name).length > 1) {
        fileItem.remove();
      }
    };
    this.fileUploader.response.subscribe((response) => console.info(response));
  }
}
