export enum OpportunityCardStatus {
  //  The opportunity has no contract now but has no-contract application.
  WITHOUT_CONTRACT = 'WITHOUT_CONTRACT',
  // The contracts in opportunity neither has receiveDate nor no-contract application.
  NO_APPLICATION = 'NO_APPLICATION',
  APPLICATION_OVERDUE = 'APPLICATION_OVERDUE',
}

export enum OpportunityStatusLabel {
  NO_APPLICATION = 'No Application',
  APPLICATION_OVERDUE = 'Application Overdue',
  ALL = 'All',
  MILESTONE_TOTAL_AMOUNT_NOT_MATCHED = 'Milestone Total Amount Not Matched',
  MILESTONE_NOT_SUBMITTED = 'Milestone Not Submitted',
  INVOICE_POSTED_TO_AR = 'Invoice Posted to AR',
  PART_PAID = 'Part Paid',
}

export const MilestoneStatus = {
  saved: 'saved',
  submitted: 'submitted',
  done: 'done',
};
