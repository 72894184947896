export const requestMap = [
  {
    url: 'risk-project',
    method: 'DELETE',
    message: {
      success: 'Remove high risk project successfully',
      error: 'Failed to delete',
    },
  },
  {
    url: 'risk-project',
    method: 'PUT',
    message: {
      success: 'Saved successfully',
      error: 'Failed to save',
    },
  },
  {
    url: 'records',
    method: 'PUT',
    message: {
      success: 'Saved new record comment successfully',
      error: 'Failed to save',
    },
  },
  {
    url: 'expected-milestone-due-date',
    method: 'POST',
    message: {
      success: 'Saved successfully',
      error: 'Failed to save',
    },
  },
  {
    url: 'applications',
    method: 'POST',
    message: {
      success: 'Saved successfully',
      error: 'Failed to save',
    },
  },
  {
    url: 'applications',
    method: 'PATCH',
    message: {
      success: 'Saved successfully',
      error: 'Failed to save',
    },
  },
  {
    url: 'notes',
    method: 'DELETE',
    message: {
      success: 'Deleted successfully',
      error: 'Failed to delete',
    },
  },
  {
    url: 'notes',
    method: 'PUT',
    message: {
      success: 'Saved successfully',
      error: 'Failed to save',
    },
  },
  {
    url: 'notes',
    method: 'POST',
    message: {
      success: 'Saved successfully',
      error: 'Failed to save',
    },
  },
  {
    url: 'account',
    method: 'POST',
    message: {
      success: 'Saved successfully',
      error: 'Failed to save',
    },
  },
  {
    url: 'contract',
    method: 'POST',
    message: {
      success: 'Saved successfully',
      error: 'Failed to save',
    },
  },
  {
    url: 'contract',
    method: 'PUT',
    message: {
      success: 'Saved successfully',
      error: 'Failed to save',
    },
  },
  {
    url: 'contract',
    method: 'DELETE',
    message: {
      success: 'Deleted successfully',
      error: 'Failed to delete',
    },
  },
  {
    url: 'expected-collection-due-date',
    method: 'PUT',
    message: {
      success: 'Expected invoice due date is changed.',
      error: 'Expected invoice due date change failed.',
    },
  },
  {
    url: 'expected-collection-due-date',
    method: 'DELETE',
    message: {
      success: 'Expected invoice due date has been cleared.',
      error: 'Expected invoice due date clear failed.',
    },
  },
  {
    url: 'bad-debt',
    method: 'PUT',
    message: {
      success: 'Bad debt saved successfully.',
      error: 'Failed to save bad debt.',
    },
  },
  {
    url: 'bad-debt',
    method: 'DELETE',
    message: {
      success: 'Bad debt deleted successfully.',
      error: 'Failed to delete bad debt.',
    },
  },
  {
    url: 'role',
    method: 'POST',
    message: {
      success: 'Saved role successfully',
      error: 'Failed to save role',
    },
  },
  {
    url: 'role',
    method: 'PUT',
    message: {
      success: 'Saved role successfully',
      error: 'Failed to save role',
    },
  },
  {
    url: 'role',
    method: 'DELETE',
    message: {
      success: 'Deleted role successfully',
      error: 'Failed to delete role',
    },
  },
];

export const defaultErrorMessage = 'Something went wrong, please refresh the page';

export const forbiddenRequestMap = [
  {
    url: 'role',
    method: 'DELETE',
    message: {
      error: 'No permission to delete own role',
    },
  },
  {
    url: 'user-role',
    method: 'DELETE',
    message: {
      error: 'No permission to delete owe user in current role',
    },
  },
  {
    url: 'user-role',
    method: 'POST',
    message: {
      error: 'No permission to add other users in current role',
    },
  },
  {
    url: 'opportunities',
    method: 'GET',
  },
];

export const errorCodes = {
  REFERENCE_NO_EXISTS: 'The reference number already exists',
  INCONSISTENT_AMOUNT: 'Please make sure the Milestone Total Amount same as the Opportunity Amount',
  DUPLICATE_CONTENT: 'Please make sure the milestone due dates are not the same',
  ROLE_ACCESS_DENIED: 'No Access，Please refresh page',
};
