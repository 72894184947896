import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-info-tag',
  templateUrl: './info-tag.component.html',
  styleUrls: ['./info-tag.component.scss'],
})
export class InfoTagComponent {
  @Output() openDataTips = new EventEmitter<any>();

  openDrawer() {
    this.openDataTips.emit();
  }
}
