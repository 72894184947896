import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { dsoFormat } from '@constants/dsoFormat';

@Component({
  selector: 'app-dso-format',
  templateUrl: './dso-format.component.html',
})
export class DsoFormatComponent implements OnChanges {
  @Input() dsoType: string;
  @Input() dsoValue: any;
  @Input() isDeactivated = false;
  isOverBenchmark = false;

  ngOnChanges(changes: SimpleChanges): void {
    this.isOverBenchmark = changes.dsoValue.currentValue >= dsoFormat[this.dsoType];
  }
}
