import { BarChart } from '@constants/barChart';
import { assign, drop, filter, isEmpty, isNil, orderBy, set } from 'lodash';
import { DsoHistory } from '@interfaces/OpportunityDetail';
import { EchartsConfig } from '@interfaces/EchartsConfig';
import { dsoFormat } from '@constants/dsoFormat';

const getArr = (dsoHistory: DsoHistory[], type: string) => {
  return dsoHistory.map((item) => item[type]);
};

const saveDsoDate = (dsoHistory: DsoHistory[], dsoHistoryLength, chartOption) => {
  const dateArr = getArr(dsoHistory, 'refreshDate').map((item) => {
    if (!isNil(item)) {
      return item.slice(0, BarChart.DATA_LENGTH);
    }
  });

  set(chartOption, 'xAxis.data', dateArr);
};

const showMarkLine = (overall, chartOption, billed) => {
  if (
    overall.filter((item) => item[1] >= dsoFormat.dso).length < 1 &&
    billed.filter((item) => item <= dsoFormat.dso).length === billed.length
  ) {
    chartOption.yAxis.max = dsoFormat.dso;
  } else {
    chartOption.yAxis.max = null;
  }
};

const modifyNegativeNumber = (unbilled, chartOption, billed) => {
  if (!isEmpty(filter(unbilled, (item) => item < 0)) || !isEmpty(filter(billed, (item) => item < 0))) {
    chartOption.yAxis.min = null;
  } else {
    chartOption.yAxis.min = 0;
  }
};

const saveDso = (dsoHistory: DsoHistory[], dsoHistoryLength, chartOption) => {
  const overall = [];
  const overallMarkPoint = [];

  const billed = getArr(dsoHistory, 'billedDso');
  const unbilled = getArr(dsoHistory, 'unBilledDso');

  dsoHistory.forEach((item, index) => {
    if (item.billedDso < 0 && item.unBilledDso > 0) {
      overall.push([item.billedDso, item.unBilledDso, item.unBilledDso, item.billedDso]);
      overallMarkPoint.push({ coord: [index, item.unBilledDso], value: item.dso });
    } else if (item.billedDso > 0 && item.unBilledDso < 0) {
      overall.push([item.unBilledDso, item.billedDso, item.billedDso, item.unBilledDso]);
      overallMarkPoint.push({ coord: [index, item.billedDso], value: item.dso });
    } else {
      overall.push([0, item.dso, item.dso, 0]);
      overallMarkPoint.push({ coord: [index, item.dso], value: item.dso });
    }
  });

  if (dsoHistoryLength > 0) {
    showMarkLine(overall, chartOption, billed);
  }
  modifyNegativeNumber(unbilled, chartOption, billed);

  set(chartOption, 'series[0].data', overall);
  set(chartOption, 'series[0].markPoint.data', overallMarkPoint);
  set(chartOption, 'series[1].data', billed);
  set(chartOption, 'series[2].data', unbilled);
};

const modifyChartScroll = (dsoHistoryLength, chartOption) => {
  if (dsoHistoryLength <= BarChart.SCROLL_MAX_NUMBER) {
    set(chartOption, 'dataZoom', []);
  } else {
    set(chartOption, 'dataZoom[0].endValue', dsoHistoryLength - 1);
    set(chartOption, 'dataZoom[0].startValue', dsoHistoryLength - BarChart.SCROLL_MAX_NUMBER);
  }
};

export const getBarChartOption = (dsoHistory: DsoHistory[]): EchartsConfig => {
  const dsoHistoryLength = dsoHistory.length;

  const chartOption = assign({}, BarChart.BAR_CHART_OPTION);

  saveDsoDate(dsoHistory, dsoHistoryLength, chartOption);
  saveDso(dsoHistory, dsoHistoryLength, chartOption);
  modifyChartScroll(dsoHistoryLength, chartOption);

  if (dsoHistoryLength === 0) {
    chartOption.yAxis.max = null;
  }

  return chartOption;
};

export const orderDsoHistory = (opportunityDetail): DsoHistory[] => {
  const deleteHistoryNumber =
    opportunityDetail.dsoHistory.length >= BarChart.HISTORY_MAX_NUMBER
      ? opportunityDetail.dsoHistory.length - BarChart.HISTORY_MAX_NUMBER
      : 0;

  return drop(orderBy(opportunityDetail.dsoHistory, ['year', 'month'], ['asc', 'asc']), deleteHistoryNumber);
};
