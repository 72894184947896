import { AfterViewChecked, Directive, ElementRef, Input } from '@angular/core';
import { CURRENCY_$, OTHER_CURRENCY } from '../constants/currency';
import { indexOf, isEmpty, isNil, isNumber, keys } from 'lodash';

@Directive({
  selector: '[appChangeCurrency]',
})
export class ChangeCurrencyDirective implements AfterViewChecked {
  @Input() currency: string;

  constructor(private elementRef: ElementRef) {}

  ngAfterViewChecked(): void {
    if ((isEmpty(this.currency) && !isNumber(this.currency)) || isNil(this.currency)) {
      this.elementRef.nativeElement.innerHTML = '';
    }
    if (indexOf(CURRENCY_$, this.currency) > -1) {
      this.elementRef.nativeElement.innerHTML = '$';
    }
    if (indexOf(keys(OTHER_CURRENCY), this.currency) > -1) {
      this.elementRef.nativeElement.innerHTML = `${OTHER_CURRENCY[this.currency]}`;
    }
  }
}
