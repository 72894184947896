import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  AccountOpportunityInfo,
  OpportunityApplication,
  OpportunityDetail,
  OpportunityLegalDocumentsInfo,
  OpportunityStatusInfo,
} from '@interfaces/OpportunityDetail';
import { OpportunityList, SortOrder } from '@interfaces/OpportunityDashboard';
import { SearchItem } from '@interfaces/Filter';
import { WithoutPoPage } from '@interfaces/WithoutPo';
import { OwnerList } from '@interfaces/OwnerList';
import { isNil } from 'lodash';

@Injectable({ providedIn: 'root' })
export class OpportunityApi {
  constructor(private http: HttpClient) {}

  getOpportunityStatusInfo = (id: number): Observable<OpportunityStatusInfo> => {
    return this.http.get<OpportunityStatusInfo>(`/opportunities/${id}/status`);
  };

  getOpportunityLegalDocumentsInfo = (id: number): Observable<OpportunityLegalDocumentsInfo> => {
    return this.http.get<OpportunityLegalDocumentsInfo>(`/opportunities/${id}/legal-documents`);
  };

  getAllocationOptionInfo = (id: number) => {
    return this.http.get<AccountOpportunityInfo[]>(`/opportunities/${isNil(id) ? '' : id + '/'}allocation-options`);
  };

  getOpportunityDetail = (id: string): Observable<OpportunityDetail> => {
    return this.http.get<OpportunityDetail>(`/opportunities/${id}`);
  };

  getOpportunityApplicationInfo(opportunityId: number): Observable<OpportunityApplication> {
    return this.http.get<OpportunityApplication>(`/opportunities/${opportunityId}/applications`);
  }

  createOpportunityApplication(opportunityId: number, opportunity: OpportunityApplication): Observable<void> {
    return this.http.post<void>(`/opportunities/${opportunityId}/applications`, opportunity);
  }

  getOpportunityList = (page: number, size: number, orders: SortOrder, filters: Array<SearchItem>): Observable<OpportunityList> => {
    return this.http.post<OpportunityList>(`/opportunities`, {
      page,
      size,
      orders,
      filters,
    });
  };

  getWithoutContractList = (page: number, size: number, filters: Array<SearchItem>, orders: SortOrder): Observable<WithoutPoPage> => {
    return this.http.post<WithoutPoPage>(`/opportunities/without-po`, {
      page,
      size,
      filters,
      orders,
    });
  };

  getOwnerList = (page: number, size: number, filters: Array<SearchItem>, orders: SortOrder): Observable<OwnerList> => {
    return this.http.post<OwnerList>(`/opportunities/owner-with-dso-info`, {
      page,
      size,
      filters,
      orders,
    });
  };

  downloadOpportunityList(orders: SortOrder, filters: any[]): Observable<any> {
    return this.http.post(`/opportunities/download`, { filters, orders }, { responseType: 'blob' });
  }
}
