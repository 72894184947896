import { filterMapping } from '@constants/sortMapping';
import { concat, find, get, isEmpty, isEqual, isNil } from 'lodash';
import {
  FILTER_MILESTONE_TOTAL_AMOUNT_STATUS,
  FILTER_TYPE_CUSTOM,
  FILTER_TYPE_EQUAL,
  FILTER_TYPE_GREATER_THAN,
  FILTER_TYPE_GREATER_THAN_EQUAL,
  FILTER_TYPE_IN,
  FILTER_TYPE_LESS_THAN_EQUAL,
  FILTER_TYPE_LIKE,
  FILTER_WITHOUT_CONTRACT_STATUS,
} from '@constants/filterType';
import { Filter, SearchItem, SearchItemName } from '@interfaces/Filter';

export const getSearchFilter = (searchItem: any): SearchItem => {
  return {
    name: filterMapping[searchItem.type],
    value: searchItem.type === 'mu' ? searchItem.name : searchItem.id,
    type: FILTER_TYPE_EQUAL,
  };
};

export const getStatusFilter = (status: 'ONGOING' | 'DEACTIVATED'): SearchItem => {
  return {
    name: 'status',
    value: status,
    type: FILTER_TYPE_CUSTOM,
  };
};

export const getAdvancedFilterConditions = (filterItem: any): Array<SearchItem> => {
  const filterKeySearchItemMapping = {
    account: 'accountId',
    market: 'muName',
    governanceUnit: 'governanceUnit',
    opportunityOwner: 'ownerEmail',
    projectManager: 'projectManagerId',
    risk: 'isHighRisk',
    status: 'status',
  };
  return Object.keys(filterItem)
    .filter((key) => filterItem[key] && filterItem[key].length > 0)
    .map((key) => ({
      name: filterKeySearchItemMapping[key] as SearchItemName,
      value: filterItem[key],
      type: FILTER_TYPE_IN,
    }));
};

export const getDemandActionNeededFilter = (filterItem: Filter): Array<SearchItem> => {
  return isEmpty(filterItem.demandActionNeeded)
    ? []
    : [
        {
          name: 'demandActionNeeded',
          value: filterItem.demandActionNeeded,
          type: FILTER_TYPE_CUSTOM,
        },
      ];
};

export const getInvoiceStatusFilter = (statusList: any[]): Array<SearchItem> => {
  if (isEmpty(statusList)) {
    return [];
  }
  return [{ name: 'invoiceStatus', value: statusList, type: FILTER_TYPE_IN }];
};

export const getMileStoneTotalAmountFilter = (status: any): Array<SearchItem> => {
  if (isEmpty(status)) {
    return [];
  }
  const mileStoneFilterStatus: SearchItem =
    status === FILTER_MILESTONE_TOTAL_AMOUNT_STATUS.MILESTONE_TOTAL_AMOUNT_NOT_MATCHED
      ? {
          name: 'totalAmountNotMatch',
          value: 'null',
          type: FILTER_TYPE_CUSTOM,
        }
      : {
          name: 'totalMilestoneAmount',
          value: 'null',
          type: FILTER_TYPE_EQUAL,
        };
  return [
    mileStoneFilterStatus,
    {
      name: 'probability',
      value: 100,
      type: FILTER_TYPE_EQUAL,
    },
    {
      name: 'contractType',
      value: 'Fixed Bid',
      type: FILTER_TYPE_EQUAL,
    },
  ];
};

export const getMuFilters = (filterItem: Filter): Array<SearchItem> => {
  const { searchItem } = filterItem;

  return isEmpty(searchItem) ? [] : [getSearchFilter(searchItem)];
};

export const getAccountFilters = (searchItem: any): Array<SearchItem> => {
  return [getSearchFilter(searchItem)];
};

export const getDashboardFilterList = (filterItem: Filter): Array<SearchItem> => {
  const { searchItem, status } = filterItem;

  return isEmpty(searchItem) ? [getStatusFilter(status)] : [getSearchFilter(searchItem), getStatusFilter(status)];
};

export const getSearchFilterList = (filterItem: Filter): Array<SearchItem> => {
  const { searchItem } = filterItem;

  return isEmpty(searchItem) ? [] : [getSearchFilter(searchItem)];
};

export const getWithoutContractFilterList = (filterItem: any): Array<SearchItem> => {
  return getSearchItemFilter(filterItem)
    .concat(getDataRangeFilter(filterItem, 'withoutPoDays'))
    .concat(getDataRangeFilter(filterItem, 'expectedContractReceivedDate'))
    .concat(getWithoutContractStatusFilterCondition(filterItem));
};

export const getInvoiceOverdueFilterList = (filterItem: any): Array<SearchItem> => {
  return getSearchItemFilter(filterItem)
    .concat(getDataRangeFilter(filterItem, 'invoiceOverdueDays'))
    .concat(getDataRangeFilter(filterItem, 'invoiceExpectedCollectionDueDate'))
    .concat(getInvoiceStatusFilter(filterItem.invoiceStatusList));
};

export const getMilestoneOverduePageFilterList = (filterItem: any): Array<SearchItem> => {
  return getSearchItemFilter(filterItem)
    .concat(getDataRangeFilter(filterItem, 'overdueDays'))
    .concat(getDataRangeFilter(filterItem, 'milestoneDueDate'))
    .concat(getDemandActionNeededFilter(filterItem));
};

export const getWithoutContractStatusFilterCondition = (filterItem: any): Array<SearchItem> => {
  const keyName = 'withoutContractStatus';
  return filterItem[keyName] && filterItem[keyName].length > 0
    ? [
        {
          name: keyName,
          value: filterItem[keyName].map((item) => `${FILTER_WITHOUT_CONTRACT_STATUS.ALL},${item}`),
          type: FILTER_TYPE_IN,
        },
      ]
    : [
        {
          name: keyName,
          value: FILTER_WITHOUT_CONTRACT_STATUS.ALL,
          type: FILTER_TYPE_LIKE,
        },
      ];
};

const getSearchItemFilter = (filterItem: Filter) => {
  const { searchItem } = filterItem;
  return isEmpty(searchItem) ? [] : [getSearchFilter(searchItem)];
};

const getDataRangeFilter = (
  filterItem: Filter,
  keyName:
    | 'countDown'
    | 'withoutPoDays'
    | 'expectedContractReceivedDate'
    | 'invoiceOverdueDays'
    | 'invoiceExpectedCollectionDueDate'
    | 'overdueDays'
    | 'milestoneDueDate',
  fromType = FILTER_TYPE_GREATER_THAN_EQUAL,
  toType = FILTER_TYPE_LESS_THAN_EQUAL,
) => {
  const filterName = get(filterItem, keyName, null);
  const fromFilter =
    isEmpty(filterName) || isNil(filterName.from)
      ? []
      : {
          name: keyName,
          value: filterName.from,
          type: fromType,
        };
  const toFilter =
    isEmpty(filterName) || isNil(filterName.to)
      ? []
      : {
          name: keyName,
          value: filterName.to,
          type: toType,
        };

  return concat(fromFilter, toFilter);
};
export const isOpportunityPageChange = (oldFilter: SearchItem[], newFilter: SearchItem[]): boolean => {
  const oldSearchFilter = find(oldFilter, (i) => i.name !== 'status');
  const newSearchFilter = find(newFilter, (i) => i.name !== 'status');

  return !isEqual(oldSearchFilter, newSearchFilter);
};
