<nz-modal
  nzClassName="document-modal modal-common-style"
  [nzVisible]="true"
  [nzMaskClosable]="false"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzWidth]="928"
  [nzFooter]="modalFooter"
  (nzOnCancel)="closeDialog.emit(false)"
>
  <ng-template #modalTitle>
    <div class="modal-title-with-label">{{ isEdit ? 'Edit Document Information' : 'Add a New Document' }}</div>
  </ng-template>

  <ng-template #modalContent>
    <div class="modal-scroll-container" cdkScrollable>
      <form *ngIf="!!documentForm" nz-form nzLayout="vertical" [formGroup]="documentForm" #formElement>
        <div nz-row [nzGutter]="16">
          <div nz-col [nzSpan]="12">
            <nz-form-item class="reference-number">
              <nz-form-label>Reference Number Provided by Client</nz-form-label>
              <nz-form-control nzErrorTip="The reference number already exists.">
                <input nz-input formControlName="referenceNumber" nzAutosize maxlength="80" data-testid="reference-number" />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="12">
            <nz-form-item class="Document Name">
              <nz-form-label>Document Name</nz-form-label>
              <nz-form-control>
                <input nz-input formControlName="documentName" nzAutosize maxlength="150" data-testid="document-name-input" />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-row [nzGutter]="16">
          <div nz-col [nzSpan]="12">
            <nz-form-item class="signed Legal Entity">
              <nz-form-label nzRequired>Signed Legal Entity</nz-form-label>
              <nz-form-control nzErrorTip="Please input signed legal entity">
                <input nz-input formControlName="signedLegalEntity" nzAutosize maxlength="150" data-testid="signed-legal-entity" />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="6">
            <nz-form-item>
              <nz-form-label nzRequired>Document Type</nz-form-label>
              <nz-form-control nzErrorTip="Please select document type">
                <nz-input-group nzCompact>
                  <nz-select
                    style="width: 100%"
                    formControlName="documentType"
                    [nzSuffixIcon]="selectIcon"
                    class="currency-select"
                    [nzOptionHeightPx]="32"
                    nzDropdownClassName="default-size-dropdown"
                  >
                    <nz-option
                      *ngFor="let documentTypeOption of DOCUMENT_TYPE_OPTIONS"
                      [nzLabel]="documentTypeOption.label"
                      [nzValue]="documentTypeOption.value"
                    ></nz-option>
                  </nz-select>
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="6">
            <nz-form-item>
              <nz-form-label>Date Received</nz-form-label>
              <nz-form-control>
                <nz-date-picker
                  style="width: 100%"
                  nzPlaceHolder=" "
                  class="legal-document-date-picker"
                  formControlName="contractReceivedDate"
                  [nzSuffixIcon]="null"
                  nzFormat="yyyy-MM-dd"
                  data-testid="contract-received-date"
                ></nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-row [nzGutter]="16">
          <div nz-col [nzSpan]="12">
            <nz-form-item>
              <nz-form-label>Document Date Range</nz-form-label>
              <nz-range-picker
                class="legal-document-date-picker"
                formControlName="documentDateRange"
                nzMode="date"
                data-testid="legal-document-date-picker"
              ></nz-range-picker>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="6">
            <nz-form-item>
              <nz-form-label>Document Amount (Excluding Tax)</nz-form-label>
              <nz-form-control
                [nzValidateStatus]="documentForm.controls.documentAmount.errors?.max ? 'error' : 'success'"
                nzErrorTip="Maximum value exceeded"
              >
                <nz-input-group nzCompact>
                  <nz-select
                    style="width: 35%"
                    formControlName="currency"
                    nzDropdownClassName="default-size-dropdown"
                    class="currency-select"
                    [nzSuffixIcon]="selectIcon"
                    [nzOptionHeightPx]="32"
                    data-testid="currency-select"
                  >
                    <nz-option *ngFor="let currencyItem of CURRENCYS" [nzLabel]="currencyItem" [nzValue]="currencyItem"></nz-option>
                  </nz-select>
                  <ng-template #selectIcon>
                    <i class="select-arrow" nz-icon nzType="svg:arrow" alt="arrow"></i>
                  </ng-template>
                  <nz-input-number
                    style="width: 65%"
                    formControlName="documentAmount"
                    [nzPrecision]="2"
                    nzPrecisionMode="cut"
                    [nzFormatter]="formatterMoney"
                    (keydown)="preventTypingWhenExceed($event, 11)"
                    data-testid="document-amount"
                  ></nz-input-number>
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-row [nzGutter]="16">
          <div nz-col [nzSpan]="12">
            <nz-form-item>
              <nz-form-label>Google Drive Link</nz-form-label>
              <nz-form-control nzErrorTip="Please input a valid link">
                <input formControlName="googleDriveLink" nz-input maxlength="255" data-testid="google-drive-link" />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="12">
            <nz-form-item>
              <nz-form-label>Document Format</nz-form-label>
              <nz-form-control>
                <nz-checkbox-group formControlName="documentFormat"></nz-checkbox-group>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-row [nzGutter]="16">
          <div nz-col [nzSpan]="24">
            <nz-form-item>
              <nz-form-label>Note</nz-form-label>
              <nz-form-control>
                <nz-textarea-count [nzMaxCharacterCount]="255">
                  <textarea
                    class="note"
                    nz-input
                    placeholder="comments here..."
                    formControlName="note"
                    nzAutosize
                    maxlength="255"
                    data-testid="document-note"
                  ></textarea>
                </nz-textarea-count>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-row [nzGutter]="16">
          <div nz-col [nzSpan]="24">
            <nz-form-item>
              <nz-form-label class="allocation-detail-label">Allocation Detail</nz-form-label>
              <nz-select
                data-testid="allocation-select"
                nzShowSearch
                nzMode="multiple"
                nzAllowClear
                nzPlaceHolder="Select Opportunities or Search by Opportunity Name/Project Code "
                formControlName="allocationSelectedValue"
                [nzMaxTagCount]="2"
                [nzDropdownClassName]="'opportunity-account-selector'"
                [(nzOpen)]="isAllocationDropdownOpen"
                [nzDropdownRender]="renderBottomArea"
                (ngModelChange)="changeSelectedValue($event)"
              >
                <nz-form-control formArrayName="opportunities">
                  <nz-option-group *ngFor="let account of this.accountList; let $index = index" nzLabel="{{ account }}">
                    <nz-form-control [formArrayName]="$index.toString()">
                      <nz-option
                        nzCustomContent
                        *ngFor="
                          let control of $any(documentForm.controls['opportunities']).controls[$index].controls;
                          let secondIndex = index
                        "
                        [nzValue]="control.value.opportunityId"
                        [nzDisabled]="control.value.disabled"
                        nzLabel="[{{ control.value.projectCode }}] {{ control.value.opportunityName }}"
                      >
                        <nz-form-control [formGroupName]="secondIndex">
                          <label
                            class="allocation-checkbox"
                            nz-checkbox
                            formControlName="checked"
                            data-testid="allocation-select-option-label"
                          >
                            <span class="text-ellipsis">[{{ control.value.projectCode }}] {{ control.value.opportunityName }}</span>
                            <div *ngIf="!control.value.isProjectActive" class="status-tag">deactive</div>
                          </label>
                        </nz-form-control>
                      </nz-option>
                    </nz-form-control>
                  </nz-option-group>
                </nz-form-control>
              </nz-select>
            </nz-form-item>
          </div>
        </div>

        <nz-form-item>
          <nz-form-control formArrayName="tableRowArray">
            <nz-table
              class="legal-document-table allocated-table"
              [nzData]="allocationTableData"
              [nzNoResult]="atLeaseOneResultRef"
              [nzWidthConfig]="allocationColumnsWidth"
              [nzShowPagination]="false"
              [nzFrontPagination]="false"
              data-testid="allocated-table"
            >
              <thead>
                <tr>
                  <th>
                    <nz-form-label nzRequired>Allocated Opportunity</nz-form-label>
                  </th>
                  <th>
                    <nz-form-label nzRequired>Allocated Date Range</nz-form-label>
                  </th>
                  <th>Allocated Amount Excl. Tax</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of allocationTableData; let $index = index">
                  <td class="allocated-opportunity">
                    <a [routerLink]="item.opportunityDetailLink" target="_blank">
                      <div [ngClass]="defaultOpportunity.id === item.opportunityId && 'default-opportunity'" class="opportunity-name">
                        {{ item.opportunityName }}
                      </div>
                    </a>
                    <div *ngIf="!item.isProjectActive" class="status-tag">deactive</div>
                  </td>
                  <td>
                    <nz-form-control [formGroupName]="$index">
                      <nz-form-item class="allocated-date-picker">
                        <nz-form-control nzErrorTip="Please select date">
                          <nz-range-picker
                            class="allocated-date-picker"
                            style="width: 100%"
                            [nzPlaceHolder]="['Allocate From', 'Allocate To']"
                            formControlName="allocateDateRange"
                            [nzSuffixIcon]="null"
                            nzFormat="yyyy-MM-dd"
                          ></nz-range-picker>
                        </nz-form-control>
                      </nz-form-item>
                    </nz-form-control>
                  </td>
                  <td>
                    <nz-form-control [formGroupName]="$index">
                      <nz-form-control>
                        <nz-input-number
                          class="allocation-amount"
                          nzSize="small"
                          formControlName="allocatedAmount"
                          nzPrecisionMode="cut"
                          [ngClass]="calculateData.isTotalAllocatedAmountExceed && ['allocation-input-error', 'ng-invalid']"
                          [nzPrecision]="2"
                          [nzFormatter]="formatterMoneyWithCurrency"
                          (keydown)="preventTypingWhenExceed($event, 11)"
                        ></nz-input-number>
                      </nz-form-control>
                    </nz-form-control>
                  </td>
                  <td>
                    <div
                      nz-tooltip
                      *ngIf="item.opportunityId === defaultOpportunity.id"
                      nzTooltipOverlayClassName="allocated-disable-tooltip custom-tooltip"
                      nzTooltipTitle="Current opportunity can‘t be removed. If you need to delete this legal document, please go to legal documents list"
                      nzTooltipPlacement="left"
                      style="text-align: center; padding-right: 12px"
                    >
                      <button style="padding: 0" disabled nz-button nzType="primary" type="button" class="allocation-delete-button">
                        <app-icon-text className="icon-delete" class="allocation-delete-icon"></app-icon-text>
                      </button>
                    </div>

                    <button
                      *ngIf="item.opportunityId !== defaultOpportunity.id"
                      nz-button
                      nz-popconfirm
                      nzType="primary"
                      type="button"
                      nzOkText="Yes"
                      nzCancelText="Cancel"
                      nzPopconfirmTitle="Are you sure to remove this opportunity from the allocation detail table?"
                      nzPopconfirmPlacement="bottom"
                      nzPopconfirmOverlayClassName="confirmDeletePopover"
                      class="allocation-delete-button"
                      [nzPopconfirmShowArrow]="false"
                      [nzPopconfirmTrigger]="'click'"
                      (nzOnConfirm)="deleteAllocationItem(item.opportunityId)"
                    >
                      <app-icon-text className="icon-delete" class="allocation-delete-icon"></app-icon-text>
                    </button>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td [colSpan]="5">
                    <div class="calculate-amount-block">
                      <div class="allocated-amount-exceed" *ngIf="calculateData.isTotalAllocatedAmountExceed">
                        <img src="/assets/svg/error-hint.svg" />
                        Total allocated amount can’t exceed document amount
                      </div>

                      <div
                        class="no-allocated-opportunity"
                        *ngIf="documentForm.controls.tableRowArray.dirty && documentForm.controls.tableRowArray.value.length === 0"
                      >
                        <img src="/assets/svg/warning.svg" />
                        Please allocate this document to at least one opportunity
                      </div>

                      <div class="calculate-amount-container">
                        <div>
                          <div>
                            <span class="amount-label">Document Amount</span>
                            <span class="amount-value">
                              {{ documentForm.value?.documentAmount || 0 | currencyFormat : documentForm.value?.currency : false : true }}
                            </span>
                          </div>
                        </div>

                        <div [ngClass]="calculateData.isTotalAllocatedAmountExceed ? 'amount-red' : 'amount-blue'">
                          <div>
                            <span class="amount-label">Total Allocated Amount</span>
                            <span class="amount-value">
                              {{ calculateData.totalAllocatedAmount | currencyFormat : documentForm.value?.currency : false : true }}
                            </span>
                          </div>
                          <div class="amount-value">
                            {{ calculateData.totalAllocatedAmountPercentage | calculatePercentage }}
                          </div>
                        </div>
                        <div>
                          <div>
                            <span class="amount-label">Remaining Amount</span>
                            <span class="amount-value amount-blue">
                              {{ calculateData.remainingAmount | currencyFormat : documentForm.value?.currency : false : true }}
                            </span>
                          </div>
                          <div class="amount-value amount-blue">
                            {{ calculateData.remainingAmountPercentage | calculatePercentage }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </nz-table>
          </nz-form-control>
        </nz-form-item>
      </form>
    </div>
  </ng-template>
  <ng-template #modalFooter>
    <div class="operation-btns">
      <button
        *ngIf="dialogStatus === LegalDocumentDialogStatus.EDIT_MODE_WITHOUT_OPPORTUNITY"
        nz-popconfirm
        [nzPopconfirmShowArrow]="false"
        class="document-delete-btn"
        nz-button
        (nzOnConfirm)="deleteLegalDocument()"
        nzType="text"
        type="button"
        nzOkText="Yes"
        nzCancelText="Cancel"
        nzPopconfirmTitle="You will lose all existing allocation details if you delete the document. Are you sure to delete it?"
        nzPopconfirmPlacement="bottom"
        nzPopconfirmOverlayClassName="confirmDeletePopover"
      >
        <i class="document-delete-icon"></i>
        <span>Delete</span>
      </button>
      <button class="cancel-btn" nz-button nzType="default" type="button" (click)="closeDialog.emit(false)">Cancel</button>
      <button
        nz-popconfirm
        [nzPopconfirmVisible]="confirmPopVisible"
        [nzPopconfirmTrigger]="null"
        [nzPopconfirmShowArrow]="false"
        nzOkText="Yes"
        nzCancelText="Cancel"
        nzPopconfirmTitle="This document will be allocated to selected opportunities, are you sure to save it ?"
        nzPopconfirmPlacement="bottom"
        nzPopconfirmOverlayClassName="confirmDeletePopover"
        (nzOnConfirm)="saveLegalDocument()"
        (nzOnCancel)="cancelLegalDocument()"
        nz-button
        nzType="primary"
        type="button"
        [nzLoading]="isSaving"
        (click)="submitForm()"
        [disabled]="disableSave"
        data-testid="save-legal-document"
      >
        Save
      </button>
    </div>
  </ng-template>
</nz-modal>

<ng-template #atLeaseOneResultRef>
  <div class="at-lease-one-result">Add at least one opportunity</div>
</ng-template>
<ng-template #renderBottomArea>
  <div class="button-area">
    <button (click)="cancelSelect()" nz-button nzType="default">Cancel</button>
    <button (click)="confirmAllocatedOpportunities()" nz-button nzType="primary">Confirm</button>
  </div>
</ng-template>
