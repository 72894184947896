import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { RouterType } from '@interfaces/RouterType';
import { routers$ } from '@constants/router';
import { DOCUMENT } from '@angular/common';
import { DEFAULT_FILTER_ITEM } from '@constants/filterType';
import { FilterService } from '@services/filter.service';
import { SortMappingService } from '@services/sort-mapping.service';
import {
  SORT_MAP_TYPE_ACCOUNT,
  SORT_MAP_TYPE_INVOICE,
  SORT_MAP_TYPE_MILESTONE,
  SORT_MAP_TYPE_OPPORTUNITY,
  SORT_MAP_TYPE_OWNER,
  SORT_MAP_TYPE_WITHOUT_PO,
} from '@constants/sortMapping';
import { TabIndexService } from '@services/tab-index.service';
import { WithoutPoPageService } from '@services/without-po-page.service';
import { InvoiceOverduePageService } from '@services/invoice-overdue-page.service';
import { MuPageService } from '@services/mu-page.service';
import { AccountPageService } from '@services/account-page.service';
import { OpportunityPageService } from '@services/opportunity-page.service';
import { MilestonePageService } from '@services/milestone-page.service';
import { OpportunityOwnerPageService } from '@services/opportunity-owner-page.service';
import { MatomoTracker } from 'ngx-matomo';
import {
  ACTION_CLICK,
  CATEGORY_NAVIGATION_LEFT,
  CATEGORY_NAVIGATION_TOP,
  NAME_CLICK_DSO_LOGO,
  NAME_DASHBOARD_DSO,
  NAME_DASHBOARD_HIGH_RISK,
  NAME_DASHBOARD_INVOICE,
  NAME_DASHBOARD_LEGAL_DOCUMENTS,
  NAME_DASHBOARD_MILESTONE,
  NAME_DASHBOARD_PO,
  NAME_NOTIFICATION_MENU,
  NAME_OPPORTUNITY_DASHBOARD,
} from '@constants/matomo';
import { SpinnerService } from '@services/spinner.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-lay-out',
  templateUrl: './lay-out.component.html',
  styleUrls: ['./lay-out.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LayOutComponent implements OnInit {
  isCollapsed = false;
  routers: RouterType[];
  isNotificationVisible = false;
  hasUnreadNotifications = false;
  isLoading = false;
  delay = 200;

  constructor(
    @Inject(DOCUMENT) private doc: Document,
    private filterService: FilterService,
    private sortMappingService: SortMappingService,
    private tabIndexService: TabIndexService,
    private withoutPoPageService: WithoutPoPageService,
    private invoiceOverduePageService: InvoiceOverduePageService,
    private muPageService: MuPageService,
    private accountPageService: AccountPageService,
    private opportunityPageService: OpportunityPageService,
    private opportunityOwnerPageService: OpportunityOwnerPageService,
    private milestonePageService: MilestonePageService,
    private matomoTracker: MatomoTracker,
    private spinnerService: SpinnerService,
  ) {}

  ngOnInit(): void {
    this.routers = routers$;
    this.spinnerService.isRunning.pipe(debounceTime(this.delay)).subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
  }

  onClickNav(path: string) {
    this.filterService.resetFilter(DEFAULT_FILTER_ITEM);
    if (this.tabIndexService.getIndex() !== 0) {
      this.tabIndexService.setIndex(0);
    }
    if (path === 'opportunity') {
      this.handleMatomoClickLeftEvent(NAME_OPPORTUNITY_DASHBOARD);
    }
    if (path === 'dashboard') {
      this.handleMatomoClickLeftEvent(NAME_DASHBOARD_DSO);
      this.muPageService.set([]);
      this.sortMappingService.clear(SORT_MAP_TYPE_ACCOUNT);
      this.sortMappingService.clear(SORT_MAP_TYPE_OPPORTUNITY);
      this.sortMappingService.clear(SORT_MAP_TYPE_OWNER);
      this.accountPageService.set({});
      this.opportunityPageService.set({});
      this.opportunityOwnerPageService.set({});
    }
    if (path === 'milestone') {
      this.handleMatomoClickLeftEvent(NAME_DASHBOARD_MILESTONE);
      this.sortMappingService.clear(SORT_MAP_TYPE_MILESTONE);
      this.milestonePageService.set({});
    }
    if (path === 'po') {
      this.handleMatomoClickLeftEvent(NAME_DASHBOARD_PO);
      this.sortMappingService.clear(SORT_MAP_TYPE_WITHOUT_PO);
      this.withoutPoPageService.set({});
    }
    if (path === 'invoice') {
      this.handleMatomoClickLeftEvent(NAME_DASHBOARD_INVOICE);
      this.invoiceOverduePageService.set({});
      this.sortMappingService.clear(SORT_MAP_TYPE_INVOICE);
      this.invoiceOverduePageService.setInvoiceTotalAmount(undefined);
    }
    if (path === 'risk-project') {
      this.handleMatomoClickLeftEvent(NAME_DASHBOARD_HIGH_RISK);
    }
    if (path === 'legal-documents') {
      this.handleMatomoClickLeftEvent(NAME_DASHBOARD_LEGAL_DOCUMENTS);
    }
  }

  openNotification(): void {
    this.isNotificationVisible = true;
    this.handleMatomoClickTopEvent(NAME_NOTIFICATION_MENU);
  }

  closeNotification(): void {
    this.isNotificationVisible = false;
  }

  updateNotificationIcon(hasUnreadNotifications: boolean) {
    this.hasUnreadNotifications = hasUnreadNotifications;
  }

  onClickDsoLogo() {
    this.handleMatomoClickLeftEvent(NAME_CLICK_DSO_LOGO);
  }

  onClickCollapseIcon() {
    this.isCollapsed = !this.isCollapsed;
    this.handleMatomoClickLeftEvent(this.isCollapsed ? 'Fold Menu' : 'Expand');
  }

  handleMatomoClickTopEvent(name: string) {
    this.matomoTracker.trackEvent(CATEGORY_NAVIGATION_TOP, ACTION_CLICK, name);
  }

  handleMatomoClickLeftEvent(name: string) {
    this.matomoTracker.trackEvent(CATEGORY_NAVIGATION_LEFT, ACTION_CLICK, name);
  }
}
