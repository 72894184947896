<div class="info-edit-selector">
  <nz-select
    nzAllowClear
    nzSize="small"
    [(ngModel)]="selectedValue"
    [nzSuffixIcon]="selectIcon"
    [nzClearIcon]="clearIcon"
    [nzOptionHeightPx]="24"
    [nzDropdownMatchSelectWidth]="false"
  >
    <nz-option *ngFor="let option of optionList" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
  </nz-select>
  <app-icon-text class="save-contract" [className]="'icon-save-contract'" (click)="callSave($event)"></app-icon-text>
</div>
<ng-template #selectIcon>
  <img src="/assets/svg/arrow.svg" alt="arrow" />
</ng-template>
<ng-template #clearIcon>
  <i nz-icon nzType="close-circle" nzTheme="outline"></i>
</ng-template>
