import { Injectable } from '@angular/core';
import { DSOMilestone, DSOMilestonePayload } from '@interfaces/Milestone';
import { BehaviorSubject, Observable } from 'rxjs';
import { MilestoneActionType } from '@constants/milestone';
import { MilestoneStatus } from '@constants/opportunity';
import { formatDate } from '@constants/dateFormat';
import { MilestoneApi } from '@apis/milestone.api';
import { OpportunityDetail } from '@interfaces/OpportunityDetail';
import { findSameValueIndexes, isSameTotalAmount, trimOrReturnNull } from '@utils/utils';

@Injectable()
export class DsoMilestoneService {
  milestoneSubject = new BehaviorSubject<DSOMilestonePayload>({ milestones: [] });
  initMilestones: DSOMilestone[] = [];
  milestones: DSOMilestone[] = [];
  opportunityDetail: OpportunityDetail;
  lastSubmitMilestones: DSOMilestone[] = [];

  constructor(private milestoneApi: MilestoneApi) {}

  listenMilestones(): Observable<DSOMilestonePayload> {
    return this.milestoneSubject.asObservable();
  }

  init(params) {
    const { milestones, opportunityDetail, lastSubmitMilestones } = params;
    if (milestones !== undefined) {
      this.initMilestones = milestones.length ? milestones : [this.defaultMilestone];
      this.milestones = [...this.initMilestones];
    }
    if (opportunityDetail !== undefined) {
      this.opportunityDetail = opportunityDetail;
    }
    if (lastSubmitMilestones !== undefined) {
      this.lastSubmitMilestones = lastSubmitMilestones;
    }
    this.sendData({ actionType: MilestoneActionType.Init });
  }

  addMilestone() {
    this.milestones.push(this.defaultMilestone);
    this.sendData({ actionType: MilestoneActionType.Add });
  }

  allowDelete(index) {
    return this.milestones.length > 1 && this.milestones[index].status !== MilestoneStatus.done;
  }

  deleteMilestone(index) {
    if (this.allowDelete(index)) {
      this.milestones.splice(index, 1);
      this.sendData({ actionType: MilestoneActionType.Delete, payload: { deletedIndex: index } });
    }
  }

  updateMilestones(milestones: DSOMilestone[]) {
    this.milestones = milestones;
    this.sendData({ actionType: MilestoneActionType.Update });
  }

  resetMilestones() {
    this.milestones = [...this.initMilestones];
    this.sendData({ actionType: MilestoneActionType.Init });
  }

  saveMilestones(): Observable<any> {
    const formattedMilestones = this.milestones.map((milestone) => this.formatMilestone(milestone, !this.hasMilestoneSubmitted(milestone)));
    return this.milestoneApi.saveMilestonesAsDraft(this.opportunityDetail.id, formattedMilestones);
  }

  submitMilestones(): Observable<any> {
    const formattedMilestones = this.milestones.map((milestone) =>
      this.formatMilestone(milestone, this.needIgnoreSubmitDueInfo(milestone)),
    );
    return this.milestoneApi.submitMilestones(this.opportunityDetail.id, formattedMilestones);
  }

  getIgnoreDueInfoMilestones() {
    return this.milestones.filter((milestone) => {
      return this.hasMilestoneSubmitted(milestone) && this.needIgnoreSubmitDueInfo(milestone);
    });
  }

  getMilestoneChangedFields(milestone: DSOMilestone) {
    const newMilestone = milestone;
    const oldMilestone = this.lastSubmitMilestones.find((m) => m.milestoneEditId === milestone.milestoneEditId);
    if (!oldMilestone || !newMilestone) {
      return {};
    }
    const newMilestoneInfo = this.formatMilestone(newMilestone);
    const oldMilestoneInfo = this.formatMilestone(oldMilestone);
    return {
      dueDate: oldMilestoneInfo.milestoneDueDate !== newMilestoneInfo.milestoneDueDate,
      amount: oldMilestoneInfo.milestoneAmount !== newMilestoneInfo.milestoneAmount,
      demandActionNeeded: oldMilestoneInfo.demandActionNeeded !== newMilestoneInfo.demandActionNeeded,
    };
  }

  hasMilestoneSubmitted(milestone: DSOMilestone) {
    return milestone.hasSubmitted;
  }

  hasDueInfoSubmitted(milestone: DSOMilestone) {
    return this.hasMilestoneSubmitted(milestone) && !!milestone.deliveryActionOwner;
  }

  hasSubmitted() {
    return this.milestones.some((milestone) => milestone.hasSubmitted);
  }

  getCurrency() {
    return this.opportunityDetail.currency;
  }

  getDefaultDemandActionOwnerDropdownList() {
    const defaultList = [];
    if (this.opportunityDetail.ownerId) {
      defaultList.push({ employeeId: this.opportunityDetail.ownerId, name: this.opportunityDetail.ownerName });
    }
    return defaultList;
  }

  getDefaultDeliveryActionOwnerDropdownList() {
    const defaultList = [];
    if (this.opportunityDetail.projectManagerId) {
      defaultList.push({ employeeId: this.opportunityDetail.projectManagerId, name: this.opportunityDetail.projectManagerName });
    }
    if (this.opportunityDetail.delegatedProjectManagerId) {
      defaultList.push({
        employeeId: this.opportunityDetail.delegatedProjectManagerId,
        name: this.opportunityDetail.delegatedProjectManagerName,
      });
    }
    return defaultList;
  }

  getSameDueDateIndexes() {
    const milestoneDueDates = this.milestones.map((milestone) => this.formatMilestone(milestone, false).milestoneDueDate);
    return findSameValueIndexes(milestoneDueDates, ['']);
  }

  hasSameTotalAmount() {
    return isSameTotalAmount(this.opportunityDetail.contractAmount, this.calcTotalAmount());
  }

  calcTotalAmount() {
    return this.milestones.reduce((acc, cur) => {
      acc += cur.milestoneAmount || 0;
      return acc;
    }, 0);
  }

  private sendData(params) {
    const { actionType, payload } = params || {};
    this.milestoneSubject.next({ milestones: this.milestones, actionType, payload });
  }

  private get defaultMilestone() {
    return {
      name: '',
      milestoneDueDate: '',
      milestoneAmount: null,
      status: null,
      hasSubmitted: false,
    };
  }

  private needIgnoreSubmitDueInfo(milestone: DSOMilestone) {
    if (!this.hasMilestoneSubmitted(milestone)) {
      return true;
    }
    const lastMilestone = this.lastSubmitMilestones.find((m) => m.milestoneEditId === milestone.milestoneEditId);
    if (milestone.status === MilestoneStatus.saved && !this.hasDueInfoSubmitted(lastMilestone)) {
      const changed = this.getMilestoneChangedFields(milestone);
      return !changed.dueDate && !changed.amount && !this.isDueInfoEmpty(milestone);
    }
    return false;
  }

  private isDueInfoEmpty(milestone: DSOMilestone) {
    return (
      !milestone.deliveryActionOwner &&
      !milestone.demandActionNeeded &&
      !milestone.demandActionOwner &&
      !milestone.hasDeliveryRisk &&
      !milestone.reason
    );
  }

  private formatMilestone(milestone, ignoreDueInfo = false): DSOMilestone {
    const formatted = {
      id: milestone.id,
      milestoneEditId: milestone.milestoneEditId,
      name: trimOrReturnNull(milestone.name),
      milestoneDueDate: milestone.milestoneDueDate ? formatDate(milestone.milestoneDueDate) : '',
      milestoneAmount: milestone.milestoneAmount === '' ? null : milestone.milestoneAmount,
      status: milestone.status,
      hasSubmitted: !!milestone.hasSubmitted,
    };
    if (ignoreDueInfo) {
      return formatted;
    }
    return {
      ...formatted,
      deliveryActionOwner: trimOrReturnNull(milestone.deliveryActionOwner),
      demandActionNeeded: milestone.demandActionNeeded,
      demandActionOwner: trimOrReturnNull(milestone.demandActionOwner),
      hasDeliveryRisk: milestone.hasDeliveryRisk,
      reason: trimOrReturnNull(milestone.reason),
    };
  }
}
