import { NgModule } from '@angular/core';
import { EmptyFormatPipe } from './empty-format.pipe';
import { CurrencyFormatPipe } from './currency-format.pipe';
import { LowercaseExceptMuPipe } from './lowercase-except-mu.pipe';
import { CapsuleTagPipe } from './capsule-tag.pipe';
import { FormatPeopleNamePipe } from './format-people-name.pipe';
import { PositiveNumberPipe } from '@pipes/positive-number-format';
import { AddSignPipe } from '@pipes/add-sign.pip';
import { CommonModule } from '@angular/common';
import { CalculatePercentagePipe } from '@pipes/calculate-percentage.pipe';
import { BooleanFormatPipe } from '@pipes/boolean-format.pipe';
import { IntegerFormatPipe } from '@pipes/integer-format.pipe';
import { HideDefaultNullValuePipe } from '@pipes/hide-default-null-value.pipe';

@NgModule({
  declarations: [
    CurrencyFormatPipe,
    EmptyFormatPipe,
    LowercaseExceptMuPipe,
    CapsuleTagPipe,
    FormatPeopleNamePipe,
    PositiveNumberPipe,
    CalculatePercentagePipe,
    BooleanFormatPipe,
    AddSignPipe,
    IntegerFormatPipe,
    HideDefaultNullValuePipe,
  ],
  exports: [
    CommonModule,
    CurrencyFormatPipe,
    EmptyFormatPipe,
    LowercaseExceptMuPipe,
    CapsuleTagPipe,
    FormatPeopleNamePipe,
    PositiveNumberPipe,
    CalculatePercentagePipe,
    BooleanFormatPipe,
    AddSignPipe,
    IntegerFormatPipe,
    HideDefaultNullValuePipe,
  ],
})
export class PipeModule {}
