<app-opportunity-card cardTitle="Milestone" cardIcon="/assets/svg/milestone.svg">
  <div name="header" class="date" *ngIf="lastSubmitMilestones.length > 0">
    <app-as-off-date class="last-submit-date" content="Last Submit Date" [date]="lastSubmitDate"></app-as-off-date>
    <app-icon-text [className]="'icon-edit-milestone'" (click)="isHistoryDialogVisible = true"></app-icon-text>
  </div>
  <div name="content">
    <div class="milestone">
      <div class="milestone-top-bar">
        <div class="milestone-amount">
          <span class="milestone-amount-label">
            Opportunity Amount {{ opportunityDetail.contractAmount | currencyFormat : opportunityDetail.currency : false }}
          </span>
          <span
            class="milestone-amount-label"
            [ngClass]="{ 'is-different': !isSameTotalAmount(opportunityDetail.contractAmount, opportunityDetail.totalMilestoneAmount) }"
          >
            Submitted Milestone Total Amount
            {{ opportunityDetail.totalMilestoneAmount | currencyFormat : opportunityDetail.currency : false }}
          </span>
        </div>
      </div>
      <nz-table
        #milestoneListTable
        class="milestone-list"
        [nzScroll]="{ x: '1346px' }"
        [nzNoResult]="'No Data Found'"
        [nzFrontPagination]="false"
        [nzShowPagination]="false"
        [nzData]="lastSubmitMilestones"
      >
        <thead>
          <tr>
            <th nzLeft nzWidth="160px">Milestone Name</th>
            <th nzWidth="120px">Milestone Due Date</th>
            <th nzWidth="120px">Milestone Amount</th>
            <th nzWidth="90px">Overdue Days</th>
            <th nzWidth="130px">Has Delivery Risk</th>
            <th nzWidth="130px">Demand Action Needed</th>
            <th nzWidth="130px">Demand Action Owner</th>
            <th nzRight nzAlign="center" [nzWidth]="milestoneHasUploadFile ? '170px' : '120px'">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let item of lastSubmitMilestones; let i = index"
            [class]="item.status === MilestoneStatus.done ? '' : getOverDueClass(item.overdueDays)"
            [attr.data-index]="i"
          >
            <td nzLeft>
              <div class="name-container">
                <div
                  class="name-text"
                  nz-tooltip
                  [nzTooltipTitle]="item.name"
                  nzTooltipOverlayClassName="custom-tooltip"
                  nzTooltipPlacement="bottomRight"
                >
                  {{ item.name }}
                </div>
                <div *ngIf="item.status === MilestoneStatus.done" class="status-tag green-done">Done</div>
              </div>
            </td>
            <td>
              {{ item.milestoneDueDate }}
              <span
                *ngIf="item.originMilestoneDueDate"
                [nzTooltipTitle]="milestoneDueDateToolTips"
                nzTooltipPlacement="bottomLeft"
                nz-tooltip
                data-testid="milestone-due-date-icon"
              >
                <i nz-icon nzType="info-circle" nzTheme="fill" class="milestone-due-date-tips-icon"></i>
              </span>
              <ng-template #milestoneDueDateToolTips>
                <div class="milestone-due-date-tips">
                  <div class="tips-content">
                    <div>{{ item.originMilestoneDueDate }}</div>
                    <div class="tips-content-title">Original Milestone Due Date</div>
                  </div>
                  <div class="divider"></div>
                  <div class="tips-content">
                    <div [ngClass]="item.originOverdueDays && item.originOverdueDays > 0 && 'is-overdue'">
                      {{ item.originOverdueDays && item.originOverdueDays > 0 ? item.originOverdueDays : '--' }}
                    </div>
                    <div class="tips-content-title">Original Overdue Days</div>
                  </div>
                </div>
              </ng-template>
            </td>
            <td>{{ item.milestoneAmount | currencyFormat : opportunityDetail.currency : false }}</td>
            <td [ngClass]="item.overdueDays && item.overdueDays > 0 && 'is-overdue'">
              {{ item.overdueDays && item.overdueDays > 0 ? item.overdueDays : '--' }}
            </td>
            <td>{{ item.hasDeliveryRisk | booleanFormat }}</td>
            <td>{{ item.demandActionNeeded | booleanFormat }}</td>
            <td>{{ item.demandActionOwnerFullName || '--' }}</td>
            <td nzRight [nzAlign]="milestoneHasUploadFile ? 'right' : 'center'" class="action-column">
              <div class="mark-milestone-done-tooltips action-item" style="vertical-align: top" *ngIf="item.status === 'done'">
                <span
                  class="reupload"
                  [ngClass]="{ disabled: disableReupload(item) }"
                  (click)="handleReUploadMilestone(item)"
                  [attr.data-testid]="'re-upload-' + i"
                  nz-tooltip
                  nzTooltipOverlayClassName="custom-tooltip download-button"
                  [nzTooltipTitle]="
                    this.aclService.hasReuploadAndDownloadAcceptanceMaterialPermission()
                      ? null
                      : 'No permission to reupload the client acceptance materials'
                  "
                  [nzTooltipPlacement]="['bottomLeft', 'bottomRight']"
                >
                  Reupload
                </span>
              </div>
              <app-icon-text
                class="action-item"
                className="icon-download"
                data-testid="icon-download"
                [isDisabled]="disableDownload(item)"
                nz-tooltip
                nzTooltipOverlayClassName="custom-tooltip download-button"
                [nzTooltipTitle]="
                  this.aclService.hasReuploadAndDownloadAcceptanceMaterialPermission()
                    ? null
                    : 'No permission to download the client acceptance materials'
                "
                [nzTooltipPlacement]="['bottomLeft', 'bottomRight']"
                (click)="downloadMilestoneFile(item)"
              ></app-icon-text>
              <app-record-history-popover
                class="action-item"
                [milestoneId]="item.milestoneEditId"
                [isDisabled]="!item.hasSubmitted"
              ></app-record-history-popover>
              <app-note
                class="action-item"
                [page]="pageName"
                [disableEditNote]="!aclService.isPermission(aclService.features.milestoneEditNote)"
                [type]="milestoneNoteType"
                [lookupId]="item.id.toString()"
                [opportunityName]="opportunityDetail?.name"
                (changed)="handleNotesChanged()"
              ></app-note>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
  <app-mark-milestone-done-dialog
    title="Client Confirmation of Acceptance"
    [isReupload]="reupload"
    [pageName]="pageName"
    [isVisible]="showMarkMilestoneDoneDialog"
    [currency]="opportunityDetail.currency"
    [milestone]="selectedMilestone"
    (cancelled)="handleCloseDialog()"
    (saved)="handleChangedDialog()"
  ></app-mark-milestone-done-dialog>
  <app-milestone-history
    [isVisible]="isHistoryDialogVisible"
    [opportunityId]="opportunityDetail.id"
    (closeDialog)="handleCloseHistoryDialog()"
  ></app-milestone-history>
</app-opportunity-card>
