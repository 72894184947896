import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';
import moment from 'moment';
import { AclService } from '@services/acl.service';
import { DateType, MomentFormat } from '@constants/dateFormat';

@Component({
  selector: 'app-expected-collection-due-date',
  templateUrl: './expected-collection-due-date.component.html',
  styleUrls: ['./expected-collection-due-date.component.scss'],
})
export class ExpectedCollectionDueDateComponent {
  @Input() expectedCollectionDueDate: string;
  @Input() dateInvoiceSent: string;
  @Output() emitEditExpectedCollectionDueDate = new EventEmitter<string>();
  @ViewChild('datePicker') private datePickerEle: NzDatePickerComponent;
  @ViewChild('dueDateButton', { read: ElementRef }) private dueDateButton: ElementRef;
  dateType = DateType;
  popoverVisible = false;

  constructor(public aclService: AclService) {}

  disabledInvoiceDueDate = (startValue: Date): boolean => {
    if (this.dateInvoiceSent) {
      return new Date(moment(startValue).format(MomentFormat.DATE)).getTime() < new Date(this.dateInvoiceSent).getTime();
    }

    return false;
  };

  onDateChange() {
    this.emitEditExpectedCollectionDueDate.emit(moment(this.expectedCollectionDueDate).format(MomentFormat.DATE));
    this.popoverVisible = false;
    this.dueDateButton.nativeElement.blur();
  }

  onClickClear() {
    this.expectedCollectionDueDate = null;
    this.emitEditExpectedCollectionDueDate.emit(null);
    this.popoverVisible = false;
  }
}
