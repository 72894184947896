import { Injectable } from '@angular/core';
import { MuInfo } from '../interfaces/MuList';

@Injectable({
  providedIn: 'root',
})
export class MuPageService {
  muList: MuInfo[] = [];

  set(page: MuInfo[]) {
    this.muList = page;
  }

  get() {
    return this.muList;
  }
}
