import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MilestoneApi } from '@apis/milestone.api';
import { finalize, takeUntil } from 'rxjs/operators';
import { AutoUnsubscribe } from '@app/decorators/autoUnsubscribe';
import { Subject } from 'rxjs';
import { DSOMilestoneFromToRelation, DSOMilestoneHistory } from '@interfaces/Milestone';
import { DateType } from '@constants/dateFormat';
import { HistoryTagType } from '@constants/milestoneEditHistory';

interface ExpandedRelation {
  from: DSOMilestoneHistory;
  to: DSOMilestoneHistory;
  milestoneId: number;
  tag: string;
  groupId: string;
}

interface Group {
  operatorEmployeeName: string;
  createTime: string;
  groupId: string;
  fromSnapshots: { value: DSOMilestoneHistory }[];
  toSnapshots: { value: DSOMilestoneHistory; tag?: string }[];
}

@AutoUnsubscribe()
@Component({
  selector: 'app-milestone-history',
  templateUrl: './milestone-history.component.html',
  styleUrls: ['./milestone-history.component.scss'],
})
export class MilestoneHistoryComponent {
  @Input() isVisible = false;
  @Input() opportunityId;
  @Output() closeDialog = new EventEmitter<any>();
  dateType = DateType;
  unsubscribe = new Subject();
  isLoading = false;
  groups: Group[] = [];

  constructor(private milestoneApi: MilestoneApi) {}

  handleCancel() {
    this.closeDialog.emit();
  }

  handleAfterOpen() {
    this.isLoading = true;
    this.milestoneApi
      .getMilestoneFromToHistory(this.opportunityId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe((data) => {
        const snapshots: DSOMilestoneHistory[] = data?.snapshots || [];
        const relations: DSOMilestoneFromToRelation[] = data?.relations || [];
        relations.forEach((relation) => {
          const expandedRelation: ExpandedRelation = {
            from: snapshots.find((s) => s.id === relation.fromId) as DSOMilestoneHistory,
            to: snapshots.find((s) => s.id === relation.toId) as DSOMilestoneHistory,
            milestoneId: relation.milestoneId,
            tag: relation.tag,
            groupId: relation.groupId,
          };
          const group = this.groups.find((g) => g.groupId === expandedRelation.groupId);
          if (group) {
            if (expandedRelation.from) {
              group.fromSnapshots.push({ value: expandedRelation.from });
            }
            if (expandedRelation.to) {
              group.toSnapshots.push({ value: expandedRelation.to, tag: expandedRelation.tag });
            }
          } else {
            this.groups.push({
              operatorEmployeeName: expandedRelation.to.operatorEmployeeName,
              createTime: expandedRelation.to.createTime,
              groupId: expandedRelation.groupId,
              fromSnapshots: expandedRelation.from ? [{ value: expandedRelation.from }] : [],
              toSnapshots: expandedRelation.to ? [{ value: expandedRelation.to, tag: expandedRelation.tag }] : [],
            });
          }
        });
        const snapshotDateSortFn = (s1, s2) =>
          new Date(s1.value.milestoneDueDate).getTime() - new Date(s2.value.milestoneDueDate).getTime();
        this.groups.forEach((group) => {
          group.fromSnapshots.sort(snapshotDateSortFn);
          group.toSnapshots.sort(snapshotDateSortFn);
        });
      });
  }

  handleAfterClose() {
    this.isLoading = false;
    this.groups = [];
  }

  getTagClass(tag: string) {
    if (!tag) {
      return '';
    }
    const tagClassMap = {
      [HistoryTagType.NEW]: 'new-tag',
      [HistoryTagType.EDITED]: 'edited-tag',
      [HistoryTagType.DELETED]: 'deleted-tag',
      [HistoryTagType.DONE]: 'done-tag',
      [HistoryTagType.NO_CHANGED]: 'no-changed-tag',
    };
    return tagClassMap[tag];
  }

  getTagText(tag: string) {
    if (!tag) {
      return '';
    }
    const tagTextMap = {
      [HistoryTagType.NEW]: 'new',
      [HistoryTagType.EDITED]: 'edited',
      [HistoryTagType.DELETED]: '',
      [HistoryTagType.DONE]: 'done',
      [HistoryTagType.NO_CHANGED]: '',
    };
    return tagTextMap[tag];
  }
}
