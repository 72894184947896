<div class="opportunity-status-container">
  <div class="header-title contract-title">
    <div>
      <img src="/assets/svg/contract.svg" alt="" />
      <span class="blue size16">Opportunity Status</span>
    </div>
    <app-as-off-date class="last-edit-date" [content]="'Last Edited Date'" [date]="opportunityStatusInfo.lastEditedDate"></app-as-off-date>
  </div>
  <div class="information-detail" *ngIf="opportunityStatusInfo">
    <div *ngIf="showWithoutContractBlock" class="without-po-tag-container">
      <div class="without-contract-tag" [ngClass]="{ deactive: opportunityStatusInfo.usedTobeWithoutContract }">
        <div class="without">Without Contract</div>
        <div class="days">{{ opportunityStatusInfo.withoutContractDays }}</div>
        <div class="days-text">Days</div>
      </div>
      <div *ngIf="displayedTag" class="application-status-tag">{{ displayedTag }}</div>
    </div>
    <div>
      <div class="opportunity-status-info" *ngFor="let opportunityStatusField of opportunityStatusFields">
        <label>{{ opportunityStatusField.label }}</label>
        <span>{{ opportunityStatusInfo[opportunityStatusField.key] | emptyFormat }}</span>
      </div>
    </div>
    <div class="opportunity-status-note">
      <div class="note-container">
        <p>Note</p>
        <app-error-tooltip
          *ngIf="!aclService.isPermission(aclService.features.opportunityStatusNoteEdit)"
          [message]="'No permission to edit opportunity status note'"
        ></app-error-tooltip>
      </div>
      <textarea
        maxlength="500"
        appMyAutofocus
        [disabled]="!aclService.isPermission(aclService.features.opportunityStatusNoteEdit)"
        (change)="editStatusNote($event.target.value)"
        placeholder="comments here..."
        >{{ opportunityStatusInfo.note?.content }}</textarea
      >
    </div>
  </div>
  <div class="information-cards-container">
    <app-opportunity-application
      *ngIf="!!opportunityId"
      [opportunityId]="opportunityId"
      [applicationInfo]="opportunityStatusInfo.applicationInfo"
      (updateApplication)="getOpportunityStatus()"
      class="information-card gray-card"
    ></app-opportunity-application>
    <div *ngIf="showDifference" class="information-card white-card space-between">
      <div class="information-item circle-item" [ngClass]="amountColor">
        <label>Amount Difference</label>
        <span>
          {{ opportunityPropertiesDifference?.amountDifference > 0 ? '+' : ''
          }}{{
            opportunityPropertiesDifference?.amountDifference | currencyFormat : opportunityStatusInfo.recognizedRevenueCurrency : false
          }}
        </span>
      </div>
      <div class="information-item circle-item" [ngClass]="manDayColor">
        <label>Man Day Difference</label>
        <span>{{ opportunityPropertiesDifference?.manDayDifference | positiveNumber : true | emptyFormat }}</span>
      </div>
      <div class="information-item circle-item" [ngClass]="countdownColor">
        <label>Countdown</label>
        <span>
          {{ opportunityPropertiesDifference?.countdown | positiveNumber : false : 0 : ['Day', 'Days'] | emptyFormat }}
        </span>
      </div>
    </div>
    <div class="information-card gray-card space-between" [ngClass]="{ 'have-contract-information-card': !showDifference }">
      <div class="information-item">
        <label>Recognized Revenue</label>
        <span>
          {{ opportunityStatusInfo.recognizedRevenue | currencyFormat : opportunityStatusInfo.recognizedRevenueCurrency : false }}
        </span>
      </div>
      <div class="information-item">
        <label>Actual Man Day</label>
        <span>{{ opportunityStatusInfo.actualManDay | number : '1.0-2' : 'en-US' | emptyFormat }}</span>
      </div>
      <div class="information-item">
        <label>Today</label>
        <span>{{ today | date : DateType.DATE }}</span>
      </div>
    </div>
  </div>
</div>
