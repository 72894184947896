<div class="info-edit-date">
  <button
    nz-button
    nzType="link"
    nz-popover
    [nzPopoverContent]="infoEditDatePopoverContent"
    [ngClass]="{ 'add-date': !initValue }"
    nzPopoverTrigger="click"
    nzPopoverOverlayClassName="info-edit-date-popover"
    class="info-edit-date-btn"
    [class.bold]="popoverVisible"
    [(nzPopoverVisible)]="popoverVisible"
    [disabled]="disableEdit"
    #infoEditDateButton
  >
    {{ initValue }}
  </button>
</div>

<ng-template #infoEditDatePopoverContent>
  <nz-date-picker
    [(ngModel)]="initValue"
    nzAllowClear="false"
    nzFormat="yyyy-MM-dd"
    (ngModelChange)="onDateChange()"
    [nzRenderExtraFooter]="clearDate"
    [nzBorderless]="true"
    [nzSuffixIcon]="null"
    nzInline
    #datePicker
  ></nz-date-picker>
</ng-template>

<ng-template #clearDate>
  <app-icon-text [className]="'icon-delete'" [text]="'Clear the Date'" (click)="onClickClear()"></app-icon-text>
</ng-template>
