export const HIGH_RISK_CATEGORY_MAP = {
  OPPORTUNITY_WITHOUT_CONTRACT: 'Without Contract',
  BILLING_OVERDUE: 'Billing Overdue',
  MILESTONE_OVERDUE: 'Milestone Overdue',
  INVOICE_OVERDUE: 'Invoice Overdue',
  OTHER_INVOICE_RISK: 'Other Invoice Risk',
  INTERNAL_CONTROL_RELATED: 'Internal Control Related',
  OTHERS: 'Others',
};

export const HIGH_RISK_CATEGORY_OPTION_LIST = [
  { label: 'Without PO', value: 'OPPORTUNITY_WITHOUT_PO' },
  { label: 'Milestone Overdue', value: 'MILESTONE_OVERDUE' },
  { label: 'Invoice Overdue', value: 'INVOICE_OVERDUE' },
];

export const HIGH_RISK_REVENUE_REVERSAL_TYPE_MAP = {
  BAD_DEBT: 'Bad Debt',
  REVENUE_REVERSAL: 'Revenue Reversal',
  CASH_RECEIVED: 'Cash Received',
};

export const HIGH_RISK_CLIENT_CREDIT_ASSESSMENT_IMPACT_MAP = {
  true: 'Y',
  false: 'N',
};

export const HIGH_RISK_CLIENT_CREDIT_ASSESSMENT_OPTION_LIST = [
  { label: 'Y', value: true },
  { label: 'N', value: false },
];

export const HIGH_RISK_CLIENT_CREDIT_ASSESSMENT_IMPACT_OPTION_LIST = [
  { label: 'Bad Debt', value: 'BAD_DEBT' },
  { label: 'Revenue Reversal', value: 'REVENUE_REVERSAL' },
  { label: 'Cash Received', value: 'CASH_RECEIVED' },
];
