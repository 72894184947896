<div class="opportunity-card">
  <div class="opportunity-card-header">
    <div class="header-title">
      <img [src]="cardIcon" alt="" />
      <span class="blue size16">{{ cardTitle }}</span>
    </div>
    <ng-content select="[name=header]"></ng-content>
  </div>
  <ng-content select="[name=content]"></ng-content>
</div>
