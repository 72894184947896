<div class="selectors">
  <i
    nz-icon
    nzType="left"
    class="selector-icon left"
    (selectstart)="onSelector()"
    [class.selector-icon-disabled]="pageIndex <= 0"
    (click)="turnToPreviousPage()"
  ></i>
  <span *ngIf="showPageIndex && pageNumber !== 0">{{ pageIndex + 1 + ' / ' + pageNumber }}</span>
  <i
    nz-icon
    nzType="right"
    class="selector-icon"
    (selectstart)="onSelector()"
    [class.selector-icon-disabled]="pageIndex + 1 >= pageNumber"
    (click)="turnToNextPage()"
  ></i>
</div>
