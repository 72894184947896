import { Component, Input, OnInit } from '@angular/core';
import { BackToDashboardService } from '@services/back-to-dashboard.service';
import { Router } from '@angular/router';
import { last, startCase } from 'lodash';
import { MatomoTracker } from 'ngx-matomo';
import { ACTION_CLICK, ACTION_EDIT } from '@constants/matomo';
import { OpportunityCardStatus } from '@constants/opportunity';
import { getMuColor } from '@utils/dso-mu';

export const tagClassNameMapper = {
  [OpportunityCardStatus.NO_APPLICATION]: 'no-application',
  [OpportunityCardStatus.APPLICATION_OVERDUE]: 'application-overdue',
};

export interface TagConfig {
  label: string;
  className: string;
}

@Component({
  selector: 'app-small-mu-tag-combination',
  templateUrl: './small-mu-tag-combination.component.html',
  styleUrls: ['./small-mu-tag-combination.component.scss'],
})
export class SmallMuTagCombinationComponent implements OnInit {
  @Input() isDeactivated = false;
  @Input() isShowProjectCode = false;
  @Input() accountName: string;
  @Input() muName: string;
  @Input() opportunityId: number;
  @Input() opportunityName: string;
  @Input() projectCode: string;
  @Input() noContracts = false;
  @Input() page: string;
  @Input() isHighRisk: boolean;
  @Input() riskCategories: string[];
  @Input() tagConfig: TagConfig[];
  opportunityLink = '';

  constructor(private backToDashboardService: BackToDashboardService, private route: Router, private matomoTracker: MatomoTracker) {}

  ngOnInit(): void {
    const path = last(this.route.url.split('/'));
    this.opportunityLink = `/dso/${path}/opportunity/${this.opportunityId}`;
  }

  getMUColor(mu: string) {
    return getMuColor(mu);
  }

  setBackButtonStatus(disabled: boolean) {
    this.backToDashboardService.disable = disabled;
    this.matomoTracker.trackEvent(this.page, ACTION_CLICK, `Redirect to ${startCase(this.opportunityName)}`);
  }

  handleRiskLevelChanged(isHighLevel: boolean) {
    const tip = isHighLevel ? `Mark high risk for ${this.opportunityName}` : `Cancel high risk for ${this.opportunityName}`;
    this.matomoTracker.trackEvent(this.page, ACTION_EDIT, tip);
  }
}
