import { Component, Input } from '@angular/core';
import { DateType } from '@constants/dateFormat';

@Component({
  selector: 'app-as-off-date',
  templateUrl: './as-of-date.component.html',
  styleUrls: ['./as-of-date.component.scss'],
})
export class AsOfDateComponent {
  @Input() content = 'As of Date';
  @Input() date: string = new Date().toDateString();
  dateType = DateType;
}
