<app-right-drawer [isDataTipsVisible]="isDataTipsVisible" (closeTips)="closeTips()">
  <div class="info-area">
    <div class="info-world">Currency</div>
    <div>The currency of the Contract Amount, Amount Without Contract is unified as CNY.</div>
  </div>
  <div class="info-area">
    <div class="info-world">Contract Amount</div>
    <div>It indicates the opportunity amount from the Sales Funnel.</div>
  </div>
  <div class="info-area">
    <div class="info-world">Amount Without Contract</div>
    <div>
      Amount Without Contract = Accumulated recognized revenue - Total allocated amount(excluding tax) from all the legal documents.
    </div>
  </div>
  <div class="info-area">
    <div class="info-world">Days Without Contract</div>
    <ul class="invoice-info top16 countdown-info">
      <li class="bold">
        <div></div>
        <div>Update Frequency</div>
        <div>Calculation Logic</div>
      </li>
      <li>
        <div><p class="bold ita">Days Without Contract</p></div>
        <div>Daily</div>
        <div>
          <p>
            <span class="bold">(1) Scenario 1:</span>
            There is no legal document added but have a first billable timecard date
          </p>
          <p>Days Without Contract = Date Today - First Billable Timecard Date</p>
          <p>
            <span class="bold">(2) Scenario 2:</span>
            There is at least one legal document in the document list that have not been received
          </p>
          <p>Days Without Contract = Date Today - Earliest Start Date of the legal documents that not received</p>
        </div>
      </li>
    </ul>
  </div>
  <div class="info-area">
    <p class="bold ita">* Default sorting</p>
    <span>The default sorting of the dashboard is Days Without Contract descending.</span>
  </div>
</app-right-drawer>
