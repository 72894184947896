<app-right-drawer [isDataTipsVisible]="isDataTipsVisible" (closeTips)="closeTips()">
  <div class="info-area">
    <div class="info-world">As of Date</div>
    <div>
      It indicates the date range of the AR (Account Receivable) and WIP (Work in Progress) that was used to calculate DSO, usually at the
      end of last month.
    </div>
  </div>
  <div class="info-area">
    <div class="info-world">Deactive Opportunity</div>
    <p>Deactivate opportunity means an opportunity which meets all conditions:</p>
    <ul class="deactive-info">
      <li>AR (Accumulated to the end of last month end) equals 0</li>
      <li>WIP (Accumulated to the end of last month end) equals 0</li>
    </ul>
  </div>
  <div class="info-area">
    <div class="info-world">AR, WIP, Recognized Revenue</div>
    <ul class="invoice-info top16">
      <li class="bold">
        <div></div>
        <div>Duration</div>
        <div>Update Frequency</div>
        <div>Currency</div>
        <div>Calculation Logic</div>
      </li>
      <li>
        <div>
          <p class="bold ita">AR</p>
          (exclude Tax)
        </div>
        <div>Accumulated to the “As of Date”</div>
        <div>Monthly</div>
        <div>CNY</div>
        <div>Accumulated invoice amount (exclude Tax) - Accumulated payment amount (exclude Tax)</div>
      </li>
      <li>
        <div>
          <p class="bold ita">WIP</p>
          (exclude Tax)
        </div>
        <div>Accumulated to the “As of Date”</div>
        <div>Monthly</div>
        <div>CNY</div>
        <div>Accumulated Recognized Revenue- Accumulated invoice amount (exclude Tax)</div>
      </li>
      <li>
        <div class="ita">
          <p class="bold">AR+WIP</p>
          (exclude Tax;in UI: under China overall DSO)
        </div>
        <div>Accumulated to the “As of Date”</div>
        <div>Monthly</div>
        <div>CNY</div>
        <div>China overall AR+WIP</div>
      </li>
      <li>
        <div><p class="bold ita">Recognized Revenue</p></div>
        <div>
          <p class="bold">In Opportunity Dashboard:</p>
          Accumulated to the as of date
          <p class="bold top16">In China Overall DSO popup/Account/Market/Opportunity Owner Dashboard:</p>
          Accumulated from Jan 1 of the current year to as of date
        </div>
        <div>Monthly</div>
        <div>CNY</div>
        <div>
          <p class="bold">In Opportunity Dashboard:</p>
          Accumulated monthly recognized revenue of the opportunity until as of date
          <p class="bold top16">In Account/Market/</p>
          <p class="bold">Opportunity Owner Dashboard:</p>
          Accumulated monthly recognized revenue of each opportunity affiliated to the Account/Market/Opportunity Owner until as of date
        </div>
      </li>
    </ul>
  </div>
  <div class="info-area">
    <div class="info-world">DSO/Billed DSO/Unbilled DSO</div>
    <div class="top24">
      <p class="bold">1. Update Frequency</p>
      <span>Monthly, normally updated in the middle of the month (after month end).</span>
    </div>
    <div class="top24">
      <p class="bold">2. Opportunity DSO Calculation Logic</p>
    </div>
    <div class="top24">
      <p class="bold tab12">(1) DSO is calculated by countback method</p>
      <span>
        The DSO value is the total number of days that recognized revenue can be deducted from the outstanding total AR+WIP (Accumulated
        until as of date) without leaving a remainder.
      </span>
    </div>
    <div class="top24">
      <p class="bold tab12">(2) Unbilled DSO is also calculated by countback method</p>
      <span>
        The DSO value is the total number of days that recognized revenue can be deducted from the total WIP (Accumulated until as of date)
        without leaving a remainder.
      </span>
    </div>
    <div class="bold top24 tab12">(3) Billed DSO = DSO - Unbilled DSO</div>
    <div class="top24">
      <div class="ita unbilled-info">
        <p>* Opportunity DSO/Billed DSO/Unbilled DSO will not be calculated when either of the following conditions have been met:</p>
        <p>
          (1) Look back upon 24 months, all monthly recognized revenue is 0 or Accumulated AR+WIP is not positive or Accumulated AR <0.
          (Opportunity which meets this condition means that this opportunity has received the payment before it starts to work. Unnecessary
          to show its DSO)
        </p>
        <p>
          (2) Accumulated AR+WIP is larger than the sum up of all monthly recognized revenue or Accumulated AR is larger than the sum up of
          all monthly recognized revenue. (Opportunity which meets this condition means that the data is abnormal. Unnecessary to show its
          DSO)
        </p>
        <p>
          (3) Accumulated AR+WIP is equal to or less than 10(Opportunity which meets this condition means that this opportunity has a small
          balance of AR/WIP.Unnecessary to show its DSO)
        </p>
      </div>
    </div>
    <div>
      <p class="bold top24">3. Other DSO Calculation Logic</p>
      <span>
        China Overall DSO/Account DSO/Market DSO/Opportunity Owner DSO: Sum up AR, WIP, Recognized Revenue
        <b>for every opportunity affiliated to each level (supervised by China/Account/Market/Opportunity Owner)</b>
        first and then calculate the DSO/Billed DSO/Unbilled DSO through the countback method.
      </span>
    </div>
    <div>
      <p class="bold top24">4. Highlight DSO larger than these benchmarks:</p>
      <p>DSO equals to or more than 150 days</p>
      <p>Billed DSO equals to or more than 55 days</p>
      <p>Unbilled DSO equals to or more than 90 days</p>
    </div>
  </div>
  <div class="info-area">
    <p class="bold ita">* Default sorting</p>
    <span>The default sorting of the dashboard is DSO descending.</span>
  </div>
</app-right-drawer>
