<div [nzTooltipTitle]="invoiceToolTips" class="invoiceDueDateToolTip" nzTooltipPlacement="topLeft" nz-tooltip>
  <span [ngClass]="className" style="font-weight: bold">{{ countDown | emptyFormat }}</span>
  <img class="stop-icon" *ngIf="invoiceStop" src="/assets/svg/invoice-stop.svg" alt="" />
</div>

<ng-template #invoiceToolTips>
  <div class="contract-tool-tips">
    <div class="contract-amount-gap">
      <label>Invoice Due Date：</label>
      <span class="amount">{{ invoiceDueDate | date : dateType.DATE | emptyFormat }}</span>
    </div>
  </div>
</ng-template>
