import { Component } from '@angular/core';
import { environment } from '@environments/environment';
import { replace } from 'lodash';

@Component({
  template:
    '<div class="error-page">\n' +
    '  <img src="/assets/svg/no-permission.svg" alt="error-logo">\n' +
    '  <h1>Opportunity No Permission</h1>\n' +
    "  <p>Sorry, you don't have permission to view the detail of this opportunity</p>\n" +
    '  <a [href]="url">Redirect to DSO home page</a>\n' +
    '</div>',
  styleUrls: ['./error-page.component.scss'],
})
export class OpportunityNoPermissionComponent {
  url = replace(environment.authConfig.redirectUri, '/implicit/callback', '');
}
