<div *ngIf="inputValue?.length >= 2 || showDropdown" [ngClass]="pagesMap[page].dropdownClassName">
  <app-item-border
    [title]="'Market'"
    [data]="data?.mus"
    [loading]="loading"
    [pageSize]="dataInfo.mus.pageSize"
    [pageIndex]="dataInfo.mus.pageIndex"
    (pageChange)="handlePageChange($event, 'mus')"
  >
    <div *ngFor="let item of dataInfo.mus.data" class="item" [class.hidden]="loading" (click)="onClickItem(item, 'mu')">
      {{ item?.name }}
    </div>
  </app-item-border>
  <app-item-border
    [title]="'Account'"
    [data]="data?.accounts"
    [loading]="loading"
    class="column-item"
    [pageSize]="dataInfo.accounts.pageSize"
    [pageIndex]="dataInfo.accounts.pageIndex"
    (pageChange)="handlePageChange($event, 'accounts')"
  >
    <div *ngFor="let item of dataInfo.accounts.data" class="item" [class.hidden]="loading" (click)="onClickItem(item, 'account')">
      <div class="account-tag">
        <div class="mu" [ngStyle]="{ backgroundColor: item?.mu?.color }">{{ item?.mu?.name | emptyFormat : 'tag' }}</div>
        <div *ngIf="item?.hasChild" class="parent">parent account</div>
      </div>
      <div>{{ item?.name }}</div>
    </div>
  </app-item-border>
  <app-item-border
    [title]="'Opportunity'"
    [data]="data?.opportunities"
    [loading]="loading"
    class="column-item"
    [pageSize]="dataInfo.opportunities.pageSize"
    [pageIndex]="dataInfo.opportunities.pageIndex"
    (pageChange)="handlePageChange($event, 'opportunities')"
  >
    <div class="item" [class.hidden]="loading" *ngFor="let item of dataInfo.opportunities.data" (click)="onClickItem(item, 'opportunity')">
      <div class="flex-layout">
        <p class="opportunity-item-account" [ngStyle]="{ color: item?.account?.color }">
          {{ item?.account?.name | uppercase }}
        </p>
        <div class="deactivated-tag" *ngIf="item?.status === 'DEACTIVATED'">deactive</div>
      </div>
      <div>{{ item?.name }}</div>
    </div>
  </app-item-border>
  <app-item-border
    [title]="'Opportunity Owner'"
    [data]="data?.opportunityOwners"
    [loading]="loading"
    class="column-item"
    [pageSize]="dataInfo.opportunityOwners.pageSize"
    [pageIndex]="dataInfo.opportunityOwners.pageIndex"
    (pageChange)="handlePageChange($event, 'opportunityOwners')"
  >
    <div
      *ngFor="let item of dataInfo.opportunityOwners.data"
      [class.hidden]="loading"
      class="item owner-item"
      (click)="onClickItem(item, 'owner')"
    >
      <div>{{ item?.name }}</div>
      <div class="owner-item-email">{{ item?.id }}</div>
    </div>
  </app-item-border>
  <app-item-border
    *ngIf="page === 'Legal Documents Project Dashboard'"
    [title]="'Legal Document'"
    [data]="data?.legalDocuments"
    [loading]="loading"
    class="column-item"
    [pageSize]="dataInfo.legalDocuments.pageSize"
    [pageIndex]="dataInfo.legalDocuments.pageIndex"
    (pageChange)="handlePageChange($event, 'legalDocuments')"
  >
    <div *ngFor="let item of dataInfo.legalDocuments.data" [class.hidden]="loading" class="item" (click)="onClickItem(item, 'document')">
      <div class="documents-referenceNo">{{ item?.referenceNumber | emptyFormat }}</div>
      <div class="documents-name">{{ item?.name | emptyFormat }}</div>
    </div>
  </app-item-border>
</div>
