import { Inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastMessageService } from '@services/toast-message.service';
import { defaultErrorMessage, errorCodes, forbiddenRequestMap, requestMap } from '@constants/requestMapping';
import { find, indexOf, isEqual, isNil } from 'lodash';

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {
  constructor(@Inject(OKTA_AUTH) private oktaAuth: OktaAuth, private router: Router, private toastMessageService: ToastMessageService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { method, url } = request;
    const toastRequest = find(requestMap, (req) => {
      return indexOf(url.replace(/\?+.+/, '').split('/'), req.url) > -1 && isEqual(req.method, method);
    });

    return next.handle(request).pipe(
      tap((evt) => {
        if (evt instanceof HttpResponse && !isNil(toastRequest)) {
          this.toastMessageService.success(toastRequest.message.success);
        }
      }),
      catchError((response: any) => {
        if (response instanceof HttpErrorResponse) {
          if (response.status === 401 && response.statusText === 'Unauthorized') {
            this.oktaAuth.signInWithRedirect();
          } else if (response.status === 403) {
            if (response?.error?.code && errorCodes[response?.error?.code]) {
              this.toastMessageService.error(errorCodes[response?.error?.code]);
            } else {
              const forbiddenRequest = find(forbiddenRequestMap, (req) => {
                return indexOf(request.url.replace(/\?+.+/, '').split('/'), req.url) > -1 && isEqual(req.method, method);
              });

              if (isNil(forbiddenRequest)) {
                this.router.navigateByUrl('/forbidden');
              } else if (forbiddenRequest.url === 'opportunities') {
                this.router.navigateByUrl('opportunity-no-permission');
              } else {
                this.toastMessageService.error(forbiddenRequest.message.error);
              }
            }
          } else if (response.status === 400 && response?.error?.code && errorCodes[response?.error?.code]) {
            this.toastMessageService.error(errorCodes[response?.error?.code]);
          } else if (response.status !== 422) {
            const errorMessage = isNil(toastRequest) ? defaultErrorMessage : toastRequest.message.error;
            this.toastMessageService.error(errorMessage);
          }

          return throwError(response);
        }
      }),
    );
  }
}
