import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AclService } from '@services/acl.service';
import { DSOMilestone } from '@interfaces/Milestone';
import { NoteType } from '@constants/note';
import { ACTION_CLICK, CATEGORY_DETAIL_PAGE, NAME_ACCEPTANCE_MATERIALS_LINK_ICON } from '@constants/matomo';
import { OpportunityDetail } from '@interfaces/OpportunityDetail';
import { MilestoneApi } from '@apis/milestone.api';
import { MilestoneStatus } from '@constants/opportunity';
import { isEmpty } from 'lodash';
import { MatomoTracker } from 'ngx-matomo';
import { isSameTotalAmount } from '@utils/utils';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { saveAs } from 'file-saver';
import moment from 'moment/moment';
import { MomentFormat } from '@constants/dateFormat';
import { ToastMessageService } from '@services/toast-message.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-opportunity-milestone',
  templateUrl: './opportunity-milestone.component.html',
  styleUrls: ['./opportunity-milestone.component.scss'],
})
export class OpportunityMilestoneComponent implements OnChanges {
  @Input() opportunityDetail: OpportunityDetail;
  @Input() lastSubmitMilestones: DSOMilestone[];
  @Output() changed = new EventEmitter();
  milestoneNoteType = NoteType.MILESTONE;
  pageName = CATEGORY_DETAIL_PAGE;
  MilestoneStatus = MilestoneStatus;
  isHistoryDialogVisible = false;
  isSameTotalAmount = isSameTotalAmount;
  showMarkMilestoneDoneDialog: boolean;
  reupload = false;
  selectedMilestone: DSOMilestone;
  private unsubscribe = new Subject();
  milestoneHasUploadFile = false;

  constructor(
    public aclService: AclService,
    private milestoneApi: MilestoneApi,
    private toastService: ToastMessageService,
    private message: NzMessageService,
    private matomoTracker: MatomoTracker,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.lastSubmitMilestones) {
      this.lastSubmitMilestones.forEach((milestone) => {
        if (milestone.status === 'done') {
          this.milestoneHasUploadFile = true;
          return false;
        }
      });
    }
  }

  get lastSubmitDate() {
    return this.lastSubmitMilestones[0]?.updateTime;
  }

  getOverDueClass(overdueDays) {
    if (!overdueDays || overdueDays <= 0) {
      return '';
    }
    return overdueDays > 60 ? 'highlight-range-3' : overdueDays > 30 ? 'highlight-range-2' : 'highlight-range-1';
  }

  disabledLink(milestone: DSOMilestone) {
    return isEmpty(milestone.googleDriveLink);
  }

  disableDownload(milestone: DSOMilestone) {
    return !(
      milestone.fileSubmitted &&
      milestone.status === 'done' &&
      this.aclService.hasReuploadAndDownloadAcceptanceMaterialPermission()
    );
  }

  disableReupload(milestone: DSOMilestone) {
    return !(milestone.status === 'done' && this.aclService.hasReuploadAndDownloadAcceptanceMaterialPermission());
  }

  openDriveLink(milestone: DSOMilestone) {
    if (!this.disabledLink(milestone)) {
      window.open(milestone.googleDriveLink, '_blank');
      this.matomoTracker.trackEvent(this.pageName, ACTION_CLICK, NAME_ACCEPTANCE_MATERIALS_LINK_ICON);
    }
  }

  downloadMilestoneFile(milestone: DSOMilestone) {
    if (!this.disableDownload(milestone)) {
      const id = this.message.loading('Download in progress..', { nzDuration: 0 }).messageId;

      this.milestoneApi
        .downloadFile(milestone.milestoneEditId)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (data) => {
            const blob = new Blob([data], { type: 'application/octet-stream' });
            this.message.remove(id);
            saveAs(
              blob,
              `acceptance-materials-${this.opportunityDetail.projectCode}-${milestone.name}-${moment(Date.now()).format(
                MomentFormat.DATE,
              )}.zip`,
            );
            this.toastService.success('Acceptance Materials Downloaded Successfully');
          },
          () => {
            this.message.remove(id);
          },
        );
    }
  }

  handleNotesChanged() {
    this.changed.emit();
  }

  handleCloseHistoryDialog() {
    this.isHistoryDialogVisible = false;
  }

  handleReUploadMilestone(milestone: DSOMilestone) {
    if (!this.disableReupload(milestone)) {
      this.showMarkMilestoneDoneDialog = true;
      this.reupload = true;
      this.selectedMilestone = milestone;
    }
  }

  handleCloseDialog() {
    this.showMarkMilestoneDoneDialog = false;
  }

  handleChangedDialog() {
    this.changed.emit();
    this.handleCloseDialog();
  }
}
