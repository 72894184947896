import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ControlVisibleService {
  visibleSubject = new Subject();

  triggerVisible(index) {
    this.visibleSubject.next(index);
  }

  listenVisible() {
    return this.visibleSubject.asObservable();
  }
}
