<div class="opportunity-list">
  <div class="radio">
    <nz-radio-group [(ngModel)]="status" [nzButtonStyle]="'solid'" nzSize="small" (ngModelChange)="switchRadioButton($event)">
      <label nz-radio-button class="ongoing" nzValue="ONGOING">Ongoing</label>
      <label nz-radio-button class="deactive" nzValue="DEACTIVATED">Deactive</label>
    </nz-radio-group>
  </div>
  <nz-table
    #opportunityDashboard
    [nzData]="opportunityList"
    [nzWidthConfig]="columnsWidth"
    [nzScroll]="{ y: 'calc(100vh - 287px)', x: '100%ß' }"
    [nzFrontPagination]="false"
    [nzShowPagination]="false"
    [nzNoResult]="noData"
  >
    <thead>
      <tr>
        <th nzShowSort class="account" [nzSortOrder]="mapOfSort['account']" [nzSortFn]="true" (nzSortOrderChange)="sort($event, 'account')">
          Account
        </th>
        <th
          nzShowSort
          class="opportunity"
          [nzSortOrder]="mapOfSort['opportunity']"
          [nzSortFn]="true"
          (nzSortOrderChange)="sort($event, 'opportunity')"
        >
          Opportunity
        </th>
        <th nzShowSort [nzSortFn]="true" [nzSortOrder]="mapOfSort['projectCode']" (nzSortOrderChange)="sort($event, 'projectCode')">
          Project Code
        </th>
        <th nzShowSort [nzSortFn]="true" [nzSortOrder]="mapOfSort['market']" (nzSortOrderChange)="sort($event, 'market')">Market</th>
        <th nzShowSort [nzSortFn]="true" [nzSortOrder]="mapOfSort['dso']" (nzSortOrderChange)="sort($event, 'dso')">DSO</th>
        <th
          class="billed"
          nzShowSort
          [nzSortOrder]="mapOfSort['billedDso']"
          [nzSortFn]="true"
          (nzSortOrderChange)="sort($event, 'billedDso')"
        >
          Billed DSO
        </th>
        <th
          class="unbilled"
          nzShowSort
          [nzSortOrder]="mapOfSort['unbilledDso']"
          [nzSortFn]="true"
          (nzSortOrderChange)="sort($event, 'unbilledDso')"
        >
          Unbilled DSO
        </th>
        <th nzShowSort [nzSortOrder]="mapOfSort['totalArRevenue']" [nzSortFn]="true" (nzSortOrderChange)="sort($event, 'totalArRevenue')">
          AR
        </th>
        <th nzShowSort [nzSortOrder]="mapOfSort['totalWipRevenue']" [nzSortFn]="true" (nzSortOrderChange)="sort($event, 'totalWipRevenue')">
          WIP
        </th>
        <th
          class="RR"
          nzShowSort
          [nzSortOrder]="mapOfSort['totalRecognizedRevenue']"
          [nzSortFn]="true"
          (nzSortOrderChange)="sort($event, 'totalRecognizedRevenue')"
        >
          Recognized Revenue
        </th>
        <th
          class="opportunity-owner"
          nzShowSort
          [nzSortOrder]="mapOfSort['ownerName']"
          [nzSortFn]="true"
          (nzSortOrderChange)="sort($event, 'ownerName')"
        >
          Opportunity Owner
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of opportunityList">
        <td colspan="2">
          <app-small-mu-tag-combination
            [opportunityId]="item?.id"
            [opportunityName]="item?.name"
            [muName]="item?.market"
            [accountName]="item?.account?.name"
            [isDeactivated]="isDeactivated(item?.status)"
            [isHighRisk]="item?.isHighRisk"
            [riskCategories]="item?.riskCategories"
            [page]="pageName"
          ></app-small-mu-tag-combination>
        </td>
        <td>
          <span [class.deactivated-gray]="isDeactivated(item.status)">{{ item?.projectCode | emptyFormat }}</span>
        </td>
        <td class="opportunity-item">
          <span [class.deactivated-gray]="isDeactivated(item.status)">{{ item?.market | emptyFormat }}</span>
        </td>
        <td>
          <app-dso-format dsoType="dso" [dsoValue]="item?.dso" [isDeactivated]="isDeactivated(item.status)"></app-dso-format>
        </td>
        <td>
          <app-dso-format dsoType="billed" [dsoValue]="item?.billedDso" [isDeactivated]="isDeactivated(item.status)"></app-dso-format>
        </td>
        <td>
          <app-dso-format dsoType="unbilled" [dsoValue]="item?.unBilledDso" [isDeactivated]="isDeactivated(item.status)"></app-dso-format>
        </td>
        <td class="break-all">
          <span [class.deactivated-gray]="isDeactivated(item.status)">
            {{ item?.totalArRevenue | currencyFormat : 'CNY' }}
          </span>
        </td>
        <td class="break-all">
          <span [class.deactivated-gray]="isDeactivated(item.status)">
            {{ item?.totalWipRevenue | currencyFormat : 'CNY' }}
          </span>
        </td>
        <td class="break-all">
          <span [class.deactivated-gray]="isDeactivated(item.status)">
            {{ item?.totalRecognizedRevenue | currencyFormat : 'CNY' }}
          </span>
        </td>
        <td>
          <span [class.deactivated-gray]="isDeactivated(item.status)">
            {{ item?.ownerName | emptyFormat }}
          </span>
        </td>
      </tr>
      <tr *ngIf="opportunityList?.length > 0">
        <td colspan="11" style="border-bottom: none; background-color: white">
          <app-table-loading-more [isLastPage]="isLastPage" [loadingMore]="loadingMore"></app-table-loading-more>
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>

<ng-template #noData>
  <app-table-no-data [loadingFirst]="loadingFirst"></app-table-no-data>
</ng-template>
