export const CATEGORY_NAVIGATION_TOP = 'Top Navigation Bar';
export const CATEGORY_NAVIGATION_LEFT = 'Left Navigation Bar';
export const CATEGORY_DASHBOARD_DSO = 'DSO Dashboard';
export const CATEGORY_DASHBOARD_ACCOUNT = 'DSO Account Dashboard';
export const CATEGORY_DASHBOARD_OPPORTUNITY = 'DSO Opportunity Dashboard';
export const CATEGORY_DASHBOARD_OPPORTUNITY_OWNER = 'DSO Opportunity Owner Dashboard';
export const CATEGORY_SEARCH = 'Search';
export const CATEGORY_DETAIL_PAGE = 'Opportunity Detail Page';
export const CATEGORY_DATE_TIPS = 'Data Specification';
export const CATEGORY_OPPORTUNITY_DASHBOARD = 'Opportunity Dashboard';

export const ACTION_CLICK = 'Click';
export const ACTION_HOVER = 'Hover';
export const ACTION_FILTER = 'Filter';
export const ACTION_EDIT = 'Edit';
export const ACTION_BACK = 'Back';
export const ACTION_EXPORT = 'Export';
export const ACTION_SORT = 'Sort';
export const ACTION_SEARCH = 'Search';
export const ACTION_SAVE = 'Save';

export const NAME_NOTIFICATION_MENU = 'Notification Icon';
export const NAME_EXPORT_MENU = 'Export Dashboard Icon';
export const NAME_ENTER_ROLE_PAGE = 'Enter role control page';
export const NAME_ENTER_INFO = 'Show user info';
export const NAME_OPPORTUNITY_DASHBOARD = 'Opportunity Dashboard';
export const NAME_DASHBOARD_DSO = 'DSO Dashboard';
export const NAME_DASHBOARD_PO = 'Opportunity Without PO Dashboard';
export const NAME_DASHBOARD_MILESTONE = 'Milestone Overdue Dashboard';
export const NAME_DASHBOARD_INVOICE = 'Invoice Overdue Dashboard';
export const NAME_DASHBOARD_HIGH_RISK = 'High Risk Project Dashboard';
export const NAME_DASHBOARD_LEGAL_DOCUMENTS = 'Legal Documents Project Dashboard';
export const NAME_TOOLTIP_CHINA_OVERALL_DSO = 'China Overall DSO';
export const NAME_SEARCH_BOX = 'Search Box';
export const NAME_SEARCH_FOR_MU = 'Search for MU';
export const NAME_SEARCH_FOR_ACCOUNT = 'Search for Account';
export const NAME_SEARCH_FOR_OPPORTUNITY = 'Search for Opportunity';
export const NAME_SEARCH_FOR_OPPORTUNITY_OWNER = 'Search for Opportunity Owner';
export const NAME_SEARCH_FOR_LEGAL_DOCUMENTS = 'Search for Legal Documents';
export const NAME_CLICK_DSO_LOGO = 'Click DSO logo';
export const NAME_CLICK_MILESTONE_EDIT_HISTORY = 'Click DSO logo';
export const NAME_ADD_MILESTONE_ICON = 'Add milestone icon';
export const NAME_MILESTONE_ADD_ITEM_ICON = 'Add item icon';
export const NAME_MILESTONE_DELETE_ITEM_ICON = 'Delete item icon';
export const NAME_SAVE_MILESTONES = 'Save button';
export const NAME_RECORD_HISTORY_ICON = 'Record history icon';
export const NAME_ACCEPTANCE_MATERIALS_LINK_ICON = 'Acceptance materials link icon';
export const NAME_YES_FOR_SUBMIT = 'Yes for submit';
export const NAME_CANCEL_FOR_SUBMIT = 'Cancel for submit';
export const NAME_CANCEL_BUTTON = 'Cancel';

export const NAME_FILTER_EVENT_MAPPING = {
  accountId: 'account',
  market: 'Market',
  governanceUnit: 'Governance Unit',
  ownerEmail: 'Opportunity Owner',
  projectManagerId: 'PM',
  isHighRisk: 'Risk',
  invoiceStatus: 'Invoice Status',
  invoiceOverdueDays: 'Invoice Overdue Days',
  invoiceExpectedCollectionDueDate: 'Invoice Expected Collection Due Date',
  overdueDays: 'Milestone Overdue Days',
  milestoneDueDate: 'Milestone start and end Date',
  demandActionNeeded: 'Demand Action Needed',
  withoutPoDays: 'Without Contract Days',
  withoutContractStatus: 'Without Contract Status',
  expectedContractReceivedDate: 'Expected Contract Receiving Date',
  totalAmountNotMatch: 'Milestone status',
  totalMilestoneAmount: 'Milestone status',
  status: 'is Ongoing',
};
