import { Component } from '@angular/core';

@Component({
  template:
    '<div class="error-page">\n' +
    '  <img src="/assets/svg/error-page.svg" alt="error-logo">\n' +
    '  <h1>Sorry, the server is wrong.</h1>\n' +
    '  <p>Please try again later.</p>\n' +
    '</div>',
  styleUrls: ['./error-page.component.scss'],
})
export class InternalServerErrorComponent {}
