import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DSOMilestone, MilestoneList } from '@interfaces/Milestone';
import { SearchItem } from '@interfaces/Filter';
import { SortOrder } from '@interfaces/OpportunityDashboard';

@Injectable({ providedIn: 'root' })
export class MilestoneApi {
  constructor(private http: HttpClient) {}

  getMilestoneList = (page: number, size: number, filters: Array<SearchItem>, orders: SortOrder): Observable<MilestoneList> => {
    return this.http.post<MilestoneList>(`/dso-milestone/overdue`, {
      page,
      size,
      filters,
      orders,
    });
  };

  getMilestones(projectId: string): Observable<any> {
    // return this.http.get(`/dso-milestone?sf-project-id=` + projectId);
    return this.http.get(`/dso-milestone?opportunity-id=` + projectId);
  }

  getLastSubmitMilestones(projectId: number): Observable<any> {
    return this.http.get(`/dso-milestone/latest-submitted-milestone?opportunity-id=${projectId}`);
  }

  saveMilestonesAsDraft(opportunityId: number, milestones: DSOMilestone[]): Observable<any> {
    return this.http.post(`/dso-milestone/save?opportunity-id=${opportunityId}`, milestones);
  }

  submitMilestones(opportunityId: number, milestones: DSOMilestone[]): Observable<any> {
    return this.http.post(`/dso-milestone/submit?opportunity-id=${opportunityId}`, milestones);
  }

  markMilestoneAsDone(milestone: DSOMilestone, Params: any) {
    return this.http.put(`/dso-milestone/${milestone.milestoneEditId}`, milestone, { params: Params });
  }

  reuploadFile(fileIds, milestoneId): Observable<any> {
    const formData = new FormData();
    formData.append('fileIds', fileIds.toString());
    formData.append('milestoneEditId', milestoneId.toString());
    return this.http.post(`/dso-milestone/reupload`, formData);
  }

  getMilestoneFromToHistory(opportunityId: number): Observable<any> {
    return this.http.get(`/dso-milestone/history`, { params: { 'opportunity-id': opportunityId } });
  }

  getMilestoneHistory(milestoneId: number): Observable<any> {
    return this.http.get(`/dso-milestone/${milestoneId}/record`);
  }

  submitFile(file: File, milestoneId: number): Observable<any> {
    const formData = new FormData();
    formData.append('file', file as File);
    formData.append('milestoneEditId', milestoneId.toString());
    return this.http.post('/dso-milestone/upload', formData);
  }

  downloadFile(milestoneId: number): Observable<any> {
    const formData = new FormData();
    formData.append('milestoneEditId', milestoneId.toString());
    return this.http.post('/dso-milestone/download-file', formData, { responseType: 'blob' });
  }

  cancelSubmit(milestoneId: number): Observable<any> {
    const params = new HttpParams().set('milestoneEditId', milestoneId.toString());
    return this.http.get(`/dso-milestone/cancel-submitted-file`, { params });
  }

  getLastEditDate(projectId: string): Observable<any> {
    return this.http.get(`/milestone/milestone-snapshots/last-modified-date?sf-project-id=` + projectId);
  }

  getEditHistory(projectId: string): Observable<any> {
    return this.http.get(`/milestone/milestone-snapshots?sf-project-id=` + projectId);
  }

  createExpectedMilestoneDueDate(lookupId, employeeId, expectedMilestoneDueDate, comment): Observable<any> {
    return this.http.post(`/milestone/expected-milestone-due-date`, {
      lookupId,
      employeeId,
      expectedTargetDate: expectedMilestoneDueDate,
      comment,
    });
  }

  updateRecordComment(id, comment): Observable<any> {
    return this.http.put(`/milestone/records/${id}`, { comment });
  }

  getUploadedFiles(milestoneEditId: number) {
    return this.http.get(`/dso-milestone/uploaded-files`, { params: { milestoneEditId: milestoneEditId.toString() } });
  }
}
