import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Acl, RegularRole, Role } from '@interfaces/Acl';
import { UserInfo } from '@interfaces/People';

@Injectable({ providedIn: 'root' })
export class AclApi {
  constructor(private http: HttpClient) {}

  getUserAcl(): Observable<Acl> {
    return this.http.get(`/security/acl`);
  }

  getRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(`/security/role`);
  }

  getAllPermissions(): Observable<RegularRole[]> {
    return this.http.get<RegularRole[]>(`/security/permission`);
  }

  addNewRole(roleRequest: Role) {
    return this.http.post(`/security/role`, {
      ...roleRequest,
    });
  }

  updateRole(roleRequest: Role, id: string) {
    return this.http.put(`/security/role/${id}`, {
      ...roleRequest,
    });
  }

  deleteRole(id: string) {
    return this.http.delete(`/security/role/${id}`);
  }

  getUsers(roleId: string): Observable<UserInfo[]> {
    return this.http.get<UserInfo[]>(`/security/users?roleId=${roleId}`);
  }

  addUserToRole(roleId: string, employeeIds: string) {
    return this.http.post(`/security/user-role?roleId=${roleId}&employeeIds=${employeeIds}`, {});
  }

  deleteUserToRole(roleId: string, employeeId: string) {
    return this.http.delete(`/security/user-role?roleId=${roleId}&employeeId=${employeeId}`);
  }
}
