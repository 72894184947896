import { ElementRef } from '@angular/core';

export const getElementStyle = (nativeElement: Element) => {
  const style = window.getComputedStyle(nativeElement, '').getPropertyValue('font-size');
  const fontFamily = window.getComputedStyle(nativeElement, '').getPropertyValue('font-family');
  const fontSize = parseFloat(style);

  return { fontFamily, fontSize };
};

export const calculateTextWidth = (nativeElement: Element, value: string) => {
  const style = getElementStyle(nativeElement);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  ctx.font = `${style.fontSize}px ${style.fontFamily}`;

  return ctx.measureText(value).width;
};
export const scrollToFirstInvalid = (elementRef: ElementRef) => {
  const invalidFormControl = elementRef.nativeElement.querySelector('.ng-invalid');
  invalidFormControl.scrollIntoView({ block: 'center' });
};
