<div class="owner-list">
  <nz-table
    #ownerDashboard
    [nzData]="ownerList"
    [nzWidthConfig]="columnsWidth"
    [nzScroll]="{ y: 'calc(100vh - 304px)', x: '100%' }"
    [nzFrontPagination]="false"
    [nzShowPagination]="false"
    [nzNoResult]="noData"
  >
    <thead>
      <tr>
        <th
          class="opportunity-owner"
          nzShowSort
          [nzSortOrder]="mapOfSort['ownerName']"
          [nzSortFn]="true"
          (nzSortOrderChange)="sort($event, 'ownerName')"
        >
          Opportunity Owner
        </th>
        <th class="dso" nzShowSort [nzSortOrder]="mapOfSort['dso']" [nzSortFn]="true" (nzSortOrderChange)="sort($event, 'dso')">DSO</th>
        <th nzShowSort [nzSortOrder]="mapOfSort['billedDso']" [nzSortFn]="true" (nzSortOrderChange)="sort($event, 'billedDso')">
          Billed DSO
        </th>
        <th
          class="unbilledDso"
          nzShowSort
          [nzSortOrder]="mapOfSort['unbilledDso']"
          [nzSortFn]="true"
          (nzSortOrderChange)="sort($event, 'unbilledDso')"
        >
          Unbilled DSO
        </th>
        <th
          class="width-76"
          nzShowSort
          [nzSortOrder]="mapOfSort['totalArRevenue']"
          [nzSortFn]="true"
          (nzSortOrderChange)="sort($event, 'totalArRevenue')"
        >
          AR
        </th>
        <th
          class="width-76"
          nzShowSort
          [nzSortOrder]="mapOfSort['totalWipRevenue']"
          [nzSortFn]="true"
          (nzSortOrderChange)="sort($event, 'totalWipRevenue')"
        >
          WIP
        </th>
        <th
          nzShowSort
          [nzSortOrder]="mapOfSort['currentYearTotalRecognizedRevenue']"
          class="ytd-rr"
          [nzSortFn]="true"
          (nzSortOrderChange)="sort($event, 'currentYearTotalRecognizedRevenue')"
        >
          YTD Recognized Revenue
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of ownerList">
        <td>
          <span class="owner-name" (click)="filterOpportunityByOpportunityOwner(item?.email, item?.name)">
            {{ item?.name | emptyFormat }}
          </span>
        </td>
        <td>
          <app-dso-format dsoType="dso" [dsoValue]="item?.dso"></app-dso-format>
        </td>
        <td>
          <app-dso-format dsoType="billed" [dsoValue]="item?.billedDso"></app-dso-format>
        </td>
        <td>
          <app-dso-format dsoType="unbilled" [dsoValue]="item?.unBilledDso"></app-dso-format>
        </td>
        <td>
          <span>{{ item?.totalArRevenue | currencyFormat : 'CNY' }}</span>
        </td>
        <td>
          <span>{{ item?.totalWipRevenue | currencyFormat : 'CNY' }}</span>
        </td>
        <td class="days">
          <span>{{ item?.currentYearTotalRecognizedRevenue | currencyFormat : 'CNY' }}</span>
        </td>
      </tr>
      <tr *ngIf="ownerList?.length > 0" class="all-data">
        <td colspan="7" style="border-bottom: none; background-color: white">
          <app-table-loading-more [isLastPage]="isLastPage" [loadingMore]="loadingMore"></app-table-loading-more>
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>

<ng-template #noData>
  <app-table-no-data [loadingFirst]="loadingFirst"></app-table-no-data>
</ng-template>
