<div class="upload-revenue-container">
  <div><h1>Upload Revenue</h1></div>
  <div>
    <input
      id="upload"
      type="file"
      accept=".xls, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ng2FileSelect
      [uploader]="fileUploader"
      multiple
      style="display: none"
    />
    <label for="upload"><span>Click me to upload files</span></label>
  </div>
  <br />
  <div class="uploading-actions">
    <p>
      Queue progress:
      <span>{{ fileUploader.progress }}%</span>
    </p>
    <div>
      <ul *ngIf="fileUploader.queue.length">
        <li *ngFor="let item of fileUploader.queue">{{ item.file.name }} - {{ item.progress }}%</li>
      </ul>
    </div>
    <button type="button" (click)="fileUploader.uploadAllFiles()" [disabled]="!fileUploader.getNotUploadedItems().length">
      Upload all
    </button>
    <button type="button" (click)="fileUploader.cancelAll()" [disabled]="!fileUploader.isUploading">Cancel all</button>
    <button type="button" (click)="fileUploader.clearQueue()" [disabled]="!fileUploader.queue.length">Remove all</button>
  </div>
</div>
