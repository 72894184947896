import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { OpportunityInfo } from '@interfaces/OpportunityDetail';
import { OpportunityApi } from '@apis/opportunity.api';
import { NzTableComponent } from 'ng-zorro-antd/table';
import { exhaustMap, filter, map, takeUntil, throttleTime } from 'rxjs/operators';
import { fromEvent, Observable, Subject } from 'rxjs';
import { OpportunityList } from '@interfaces/OpportunityDashboard';
import { concat, isEmpty } from 'lodash';
import { DEFAULT_OPPORTUNITY_SORTING, sorting } from '@constants/sortMapping';
import { getAdvancedFilterConditions, getMileStoneTotalAmountFilter, getSearchFilter } from '@utils/filter';
import { FILTER_MILESTONE_TOTAL_AMOUNT_STATUS, FILTER_STATUS_DEACTIVATED } from '@constants/filterType';
import { ACTION_FILTER, ACTION_SORT, CATEGORY_OPPORTUNITY_DASHBOARD, NAME_FILTER_EVENT_MAPPING } from '@constants/matomo';
import { AutoUnsubscribe } from '@app/decorators/autoUnsubscribe';
import { OpportunityStatusLabel } from '@constants/opportunity';
import { MatomoTracker } from 'ngx-matomo';
import moment from 'moment/moment';
import { MomentFormat } from '@constants/dateFormat';
import { NzMessageService } from 'ng-zorro-antd/message';
import { saveAs } from 'file-saver';
import { ToastMessageService } from '@services/toast-message.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-opportunity-page',
  templateUrl: './opportunity-page.component.html',
  styleUrls: ['./opportunity-page.component.scss'],
})
export class OpportunityPageComponent implements OnInit, AfterViewInit {
  @ViewChild('opportunityDashboard') tableCom: NzTableComponent<any>;
  opportunityList: OpportunityInfo[] = [];
  loadingFirst = false;
  loadingMore = false;
  isLastPage = false;
  opportunityFilterSelectedOption:
    | FILTER_MILESTONE_TOTAL_AMOUNT_STATUS.ALL
    | FILTER_MILESTONE_TOTAL_AMOUNT_STATUS.MILESTONE_TOTAL_AMOUNT_NOT_MATCHED
    | FILTER_MILESTONE_TOTAL_AMOUNT_STATUS.MILESTONE_TOTAL_AMOUNT_NOT_MATCHED = FILTER_MILESTONE_TOTAL_AMOUNT_STATUS.ALL;
  milestoneFilterSelectedOption:
    | FILTER_MILESTONE_TOTAL_AMOUNT_STATUS.ALL
    | FILTER_MILESTONE_TOTAL_AMOUNT_STATUS.MILESTONE_NOT_SUBMITTED
    | FILTER_MILESTONE_TOTAL_AMOUNT_STATUS.MILESTONE_TOTAL_AMOUNT_NOT_MATCHED;
  searchItem = {};
  mapOfSort = {};
  pageName = CATEGORY_OPPORTUNITY_DASHBOARD;
  totalElements: number;
  opportunityFilterOptions = [
    {
      label: OpportunityStatusLabel.MILESTONE_TOTAL_AMOUNT_NOT_MATCHED,
      value: FILTER_MILESTONE_TOTAL_AMOUNT_STATUS.MILESTONE_TOTAL_AMOUNT_NOT_MATCHED,
    },
    { label: OpportunityStatusLabel.MILESTONE_NOT_SUBMITTED, value: FILTER_MILESTONE_TOTAL_AMOUNT_STATUS.MILESTONE_NOT_SUBMITTED },
    { label: OpportunityStatusLabel.ALL, value: FILTER_MILESTONE_TOTAL_AMOUNT_STATUS.ALL },
  ];
  milestoneFilterOptions = [
    {
      label: OpportunityStatusLabel.MILESTONE_TOTAL_AMOUNT_NOT_MATCHED,
      value: FILTER_MILESTONE_TOTAL_AMOUNT_STATUS.MILESTONE_TOTAL_AMOUNT_NOT_MATCHED,
    },
    { label: OpportunityStatusLabel.MILESTONE_NOT_SUBMITTED, value: FILTER_MILESTONE_TOTAL_AMOUNT_STATUS.MILESTONE_NOT_SUBMITTED },
  ];
  selectedMilestone: string[] = [];
  isOnGoingOptions = [
    {
      label: 'Ongoing',
      value: true,
    },
    {
      label: 'Closed',
      value: false,
    },
  ];
  previewFilter = [];
  opportunityStatusDisabled = false;
  selectedIsOnGoing: boolean[] = [true];
  private pageIndex = 0;
  private pageSize = 100;
  private scrollEle: HTMLElement;
  private currentScrollTop: number;
  private unsubscribe = new Subject();
  filterItem = { status: [true] };
  NAME_FILTER_EVENT_MAPPING = NAME_FILTER_EVENT_MAPPING;

  constructor(
    private opportunityApi: OpportunityApi,
    private matomoTracker: MatomoTracker,
    private message: NzMessageService,
    private toastService: ToastMessageService,
  ) {}

  ngOnInit() {
    this.initFilterAndSort();
    this.initPageData();
  }

  ngAfterViewInit(): void {
    this.listenScrollAndLoadMore();
  }

  private initFilterAndSort() {
    this.selectedIsOnGoing = [true];
    this.mapOfSort = {};
    this.searchItem = {};
  }

  private initPageData(tag = '') {
    this.loadingFirst = true;
    this.opportunityList = [];
    this.pageIndex = -1;
    this.isLastPage = false;
    this.postForOpportunityList()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: OpportunityList) => {
        this.loadingFirst = false;
        this.scrollEle = null;
        this.setPageData(data);
      });
    this.recordMatomoWhenClickViewOrResetOrDownload(tag);
  }

  private recordMatomoWhenClickViewOrResetOrDownload(tag: string) {
    if (tag === 'clickView' && this.previewFilter.length > 0) {
      const filters = this.previewFilter
        .filter((item) => Object.keys(NAME_FILTER_EVENT_MAPPING).includes(item.name))
        .map((item) => NAME_FILTER_EVENT_MAPPING[item.name] || item.name);
      const deduplicateFilters = Array.from(new Set(filters)).join('&');
      this.matomoTracker.trackEvent(this.pageName, ACTION_FILTER, `View filters ${deduplicateFilters} result`);
    }
    if (tag === 'clickReset') {
      this.matomoTracker.trackEvent(this.pageName, ACTION_FILTER, 'Reset all filters');
    }
    if (tag === 'clickDownload') {
      this.matomoTracker.trackEvent(this.pageName, ACTION_FILTER, 'click download');
    }
  }

  private setPageData(data: OpportunityList) {
    this.opportunityList = data.content;
    this.pageIndex = data.number;
    this.isLastPage = data.last;
    this.totalElements = data.totalElements;
  }

  private listenScrollAndLoadMore() {
    const nativeElement = this.tableCom.nzTableInnerScrollComponent.tableBodyElement.nativeElement;
    this.listenScroll(nativeElement)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: OpportunityList) => {
        this.loadingMore = false;
        this.setPageData({
          ...data,
          content: concat(this.opportunityList, data.content),
        });
        this.scrollEle.scrollTop = this.currentScrollTop;
      });
  }

  private listenScroll(nativeElement) {
    return fromEvent(nativeElement, 'scroll').pipe(
      map((event) => this.scrollEle || (event as Event).target),
      filter((ele: HTMLElement) => {
        const isTriggerBottom = ele.scrollTop + ele.offsetHeight + 20 >= ele.scrollHeight;

        return !this.isLastPage && ele.scrollHeight !== 0 && isTriggerBottom;
      }),
      exhaustMap((ele: HTMLElement) => {
        this.scrollEle = ele;
        this.currentScrollTop = ele.scrollHeight - ele.offsetHeight + 40;

        this.loadingMore = true;

        return this.postForOpportunityList();
      }),
      throttleTime(1000),
    );
  }

  private postForOpportunityList(): Observable<OpportunityList> {
    const orders = this.getSortedOrders(this.mapOfSort, sorting);
    const filters = this.getFilters();
    this.previewFilter = filters;
    return this.opportunityApi.getOpportunityList(++this.pageIndex, this.pageSize, orders, filters);
  }
  getFilters(): any[] {
    const filters = [];
    if (!isEmpty(this.searchItem)) {
      filters.push(getSearchFilter(this.searchItem));
    }
    filters.push(...getMileStoneTotalAmountFilter(this.milestoneFilterSelectedOption));
    filters.push(...getAdvancedFilterConditions(this.filterItem));
    const index = filters.findIndex((item) => item.name === 'opportunityId');
    if (index != -1) {
      const statusIndex = filters.findIndex((item) => item.name === 'status');
      this.opportunityStatusDisabled = true;
      if (statusIndex != -1) {
        filters.splice(statusIndex, 1);
      }
    } else {
      this.opportunityStatusDisabled = false;
    }
    return filters;
  }
  getSortedOrders(mapOfSort, sorting) {
    const isEmptyMapOfSort = isEmpty(mapOfSort);
    if (isEmptyMapOfSort) {
      return DEFAULT_OPPORTUNITY_SORTING;
    }
    const sortedOrders = Object.keys(mapOfSort)
      .map((field) => ({ field, value: sorting[mapOfSort[field]] }))
      .reduce((acc, cur) => {
        acc[cur.field] = cur.value;
        return acc;
      }, {});

    return sortedOrders;
  }

  sort(sortingType, sortField: string) {
    if (!isEmpty(sortingType)) {
      this.matomoTracker.trackEvent(this.pageName, ACTION_SORT, `Sort by ${sortField} ${sortingType}`);
      this.mapOfSort = { [sortField]: sortingType };
      this.initPageData();
    } else {
      const currentSortField = Object.keys(this.mapOfSort)[0];
      if (sortField && currentSortField === sortField) {
        this.mapOfSort = {};
        this.initPageData();
      }
    }
  }

  isDeactivated(status: string) {
    return status ? status === FILTER_STATUS_DEACTIVATED : true;
  }

  onSearchChange(searchItem) {
    this.searchItem = searchItem;
    this.initPageData();
  }

  handleOpportunityChange() {
    if (this.selectedMilestone.length == 0 || this.selectedMilestone.length > 1) {
      this.milestoneFilterSelectedOption = FILTER_MILESTONE_TOTAL_AMOUNT_STATUS.ALL;
    } else {
      if (this.selectedMilestone[0] == FILTER_MILESTONE_TOTAL_AMOUNT_STATUS.MILESTONE_TOTAL_AMOUNT_NOT_MATCHED) {
        this.milestoneFilterSelectedOption = FILTER_MILESTONE_TOTAL_AMOUNT_STATUS.MILESTONE_TOTAL_AMOUNT_NOT_MATCHED;
      } else {
        this.milestoneFilterSelectedOption = FILTER_MILESTONE_TOTAL_AMOUNT_STATUS.MILESTONE_NOT_SUBMITTED;
      }
    }
  }

  handleFilterChanged(event: any) {
    this.filterItem = {
      ...event,
      status: this.selectedIsOnGoing,
    };
    this.initPageData('clickView');
  }

  handleFilterReset(event: any) {
    this.selectedMilestone = [];
    this.selectedIsOnGoing = [true];
    this.filterItem = {
      ...event,
      status: this.selectedIsOnGoing,
    };
    this.initPageData('clickReset');
  }

  download() {
    const sortedOrders = this.getSortedOrders(this.mapOfSort, sorting);
    const filters = this.previewFilter;
    const id = this.message.loading('Action in progress..', { nzDuration: 0 }).messageId;
    this.opportunityApi
      .downloadOpportunityList(sortedOrders, filters)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (data) => {
          const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
          this.message.remove(id);
          saveAs(blob, 'Opportunity Dashboard' + '(Download date ' + moment(Date.now()).format(MomentFormat.DATE) + ')');
          this.toastService.success('Export finished');
        },
        () => {
          this.message.remove(id);
        },
      );
    this.recordMatomoWhenClickViewOrResetOrDownload('clickDownload');
  }

  handleSelectedCommonFilterChangeForMatomoRecord(event: any) {
    this.matomoTracker.trackEvent(this.pageName, ACTION_FILTER, event);
  }

  handleSelectedAdvancedFilterChangeForMatomoRecord(filterName: string) {
    this.matomoTracker.trackEvent(this.pageName, ACTION_FILTER, filterName);
  }
}
