import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { RoleTitleContainerComponent } from '@shared/role-title-container/role-title-container.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RegularRole, Role, TableRole } from '@interfaces/Acl';
import { Subject } from 'rxjs';
import { AclApi } from '@apis/acl.api';
import { ActivatedRoute } from '@angular/router';
import { AclService } from '@services/acl.service';
import { first, takeUntil } from 'rxjs/operators';
import { dropRightWhile, find, isEmpty, join, map } from 'lodash';
import { conversionFromChineseToEnglish, formatRole } from '@utils/utils';
import { FEATURE_PERMISSION_WITH_FIXED_ORDER } from '@constants/featurePermission';

const ADMIN_ROLES = ['ROLE_SYSTEM_ADMIN', 'ROLE_ADMIN'];

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss'],
})
export class PermissionsComponent implements OnInit, OnDestroy {
  @ViewChild('newRoleComponent') private newRoleControlCom: RoleTitleContainerComponent;
  myGroup: UntypedFormGroup = new UntypedFormGroup({});
  permission: RegularRole[];
  focusIndex = -1;
  showEditRole = false;
  role: TableRole[] = [];
  showNewRole = false;
  adminRole;
  private modifyComplete = true;
  private unsubscribe = new Subject();

  constructor(private aclApi: AclApi, private router: ActivatedRoute, private aclService: AclService) {}

  ngOnInit(): void {
    this.initPermissionsAndRole();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.updatePermissionInfo();
  }

  private updatePermissionInfo() {
    this.aclApi
      .getUserAcl()
      .pipe(first())
      .subscribe((permissionInfo) => this.aclService.updatePermissionInfo(permissionInfo));
  }

  private initPermissionsAndRole() {
    this.router.data.pipe(takeUntil(this.unsubscribe)).subscribe(({ roleAndPermissions }) => {
      this.adminRole = find(this.aclService.getRoles(), (role) => ADMIN_ROLES.includes(role.name));
      const permissions = roleAndPermissions[1].sort((p1, p2) => {
        const frontOrder = Object.values(FEATURE_PERMISSION_WITH_FIXED_ORDER).indexOf(p1.name);
        const behindOrder = Object.values(FEATURE_PERMISSION_WITH_FIXED_ORDER).indexOf(p2.name);
        return frontOrder - behindOrder;
      });
      if (!isEmpty(this.adminRole)) {
        this.permission = permissions;
      } else {
        this.permission = dropRightWhile(permissions, (permission) => {
          return permission.name === 'admin.access';
        });
      }

      this.role = formatRole(roleAndPermissions[0]);
    });
  }

  private updateRoleInfo() {
    return this.aclApi.getRoles().pipe(takeUntil(this.unsubscribe));
  }

  onClickAddRole() {
    if (this.focusIndex === -1) {
      this.focusIndex = this.role.length;
      this.initFromGroupValue();
      this.showNewRole = true;
    }
  }

  onClickEditBtn(role: TableRole, index: number) {
    if (this.focusIndex === -1 && (!role.isAdmin || this.adminRole)) {
      this.initFromGroupValue();
      role.permissions.forEach((item) => {
        this.myGroup.setControl(item.name, new UntypedFormControl(true));
      });
      this.focusIndex = index;
      this.showEditRole = true;
    }
  }

  private initFromGroupValue() {
    this.myGroup = new UntypedFormGroup({});
    this.permission.forEach((permissionItem: RegularRole) => {
      this.myGroup.setControl(permissionItem.name, new UntypedFormControl(false));
    });
  }

  onClickSaveBtn(description: string, role: Role) {
    if (this.showNewRole && this.modifyComplete) {
      this.modifyComplete = false;
      this.addNewRole(description);
    } else if (this.showEditRole && this.modifyComplete) {
      this.modifyComplete = false;
      this.updateRole(description, role);
    }
  }

  private updateRole(description: string, role: Role) {
    this.aclApi
      .updateRole(
        {
          name: role.name,
          description,
          permissions: this.permission.filter((item) => {
            return this.myGroup.getRawValue()[item.name];
          }),
        },
        role.id,
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.updateRoleInfo().subscribe((data) => {
          this.role = formatRole(data);
          this.focusIndex = -1;
          this.showEditRole = false;
          this.modifyComplete = true;
        });
      });
  }

  private addNewRole(description: string) {
    this.aclApi
      .addNewRole({
        name: 'ROLE_' + conversionFromChineseToEnglish(description),
        description,
        permissions: this.permission.filter((item) => {
          return this.myGroup.getRawValue()[item.name];
        }),
      })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.updateRoleInfo().subscribe((data) => {
          this.role = formatRole(data);
          this.addUsersToNewRole(description);
          this.focusIndex = -1;
          this.showNewRole = false;
          this.modifyComplete = true;
        });
      });
  }

  private addUsersToNewRole(description: string) {
    if (!isEmpty(this.newRoleControlCom.controlCom.users)) {
      this.newRoleControlCom.controlCom.role = find(this.role, { description });
      const employeeIds = join(map(this.newRoleControlCom.controlCom.users, 'employeeId'), ',');
      this.newRoleControlCom.controlCom.addUserToExistedRole(employeeIds, false);
    }
  }

  isActive(permission: RegularRole, role: Role) {
    return find(role.permissions, permission);
  }

  getTableWidth() {
    return (this.role.length + 1 + (this.showNewRole ? 1 : 0)) * 160 + 300;
  }

  DeleteRole() {
    if (this.showNewRole) {
      this.showNewRole = false;
      this.focusIndex = -1;
    } else {
      this.updateRoleInfo().subscribe((data) => {
        this.role = formatRole(data);
        this.focusIndex = -1;
        this.showEditRole = false;
      });
    }
  }

  adjustTable() {
    return this.getTableWidth() >= document.getElementsByClassName('title').item(0).clientWidth;
  }
}
