<div>
  <app-search-input [page]="pageName"></app-search-input>
  <div class="invoice-overdue">
    <div class="total-info">
      <div class="title">
        <div>Total Amount</div>
        <div class="total-number">
          <span>¥</span>
          <span class="integer">{{ invoiceTotalAmountIntegerPart }}</span>
        </div>
      </div>
    </div>
    <div class="invoice-info">
      <div class="title">
        <div>Invoice Overdue</div>
        <div class="total-number">
          <span class="total">Total:</span>
          <span>{{ totalElements }}</span>
        </div>
      </div>
    </div>
    <app-advanced-filter
      (filterChanged)="handleFilterChanged($event)"
      (filterReset)="handleFilterReset($event)"
      (selectedFilterChanged)="handleSelectedCommonFilterChangeForMatomoRecord($event)"
      (downloadEvent)="download()"
    >
      <div class="hidden-filter">
        <nz-select
          [(ngModel)]="selectedInvoiceStatus"
          (ngModelChange)="handleSelectedAdvancedFilterChangeForMatomoRecord(NAME_FILTER_EVENT_MAPPING.invoiceStatus)"
          [nzOptionHeightPx]="24"
          class="invoice-status-select gray-light-placeholder gap"
          nzPlaceHolder="Invoice Status"
          nzMode="multiple"
          nzMaxTagCount="1"
          data-testid="invoice-status-select"
          nzShowArrow="true"
        >
          <nz-option
            *ngFor="let invoiceFilterOption of invoiceFilterOptions"
            [nzLabel]="invoiceFilterOption.label"
            [nzValue]="invoiceFilterOption.value"
          ></nz-option>
        </nz-select>
        <nz-select
          [(ngModel)]="selectedInvoiceOverdueDays"
          (ngModelChange)="handleSelectedAdvancedFilterChangeForMatomoRecord(NAME_FILTER_EVENT_MAPPING.invoiceOverdueDays)"
          [nzOptionHeightPx]="24"
          class="invoice-overdue-select gray-light-placeholder gap"
          nzPlaceHolder="Invoice Overdue Days"
          nzMaxTagCount="1"
          data-testid="invoice-overdue-select"
          nzAllowClear="true"
          nzShowArrow="true"
        >
          <nz-option
            *ngFor="let invoiceOverDueDaysOption of invoiceOverDueDaysOptions"
            [nzLabel]="invoiceOverDueDaysOption.label"
            [nzValue]="invoiceOverDueDaysOption.value"
          ></nz-option>
        </nz-select>
        <nz-range-picker
          class="expected-collection-due-date-picker"
          [(ngModel)]="selectedExpectedCollectionDueDate"
          (ngModelChange)="handleSelectedAdvancedFilterChangeForMatomoRecord(NAME_FILTER_EVENT_MAPPING.invoiceExpectedCollectionDueDate)"
          [nzPlaceHolder]="['Expected Collection Due Date', 'End Date']"
          nzFormat="yyyy-MM-dd"
          data-testid="invoice-expected-due-day-select"
        ></nz-range-picker>
      </div>
    </app-advanced-filter>
    <nz-table
      #invoiceDashboard
      [nzData]="invoiceList"
      [nzScroll]="{ x: '1350px', y: 'calc(100vh - 238px)' }"
      [nzFrontPagination]="false"
      [nzShowPagination]="false"
      [nzNoResult]="noData"
    >
      <thead>
        <tr>
          <th
            nzLeft
            class="account"
            nzShowSort
            [nzSortOrder]="mapOfSort['account']"
            [nzSortFn]="true"
            (nzSortOrderChange)="sort($event, 'account')"
          >
            Account
          </th>
          <th
            nzLeft
            class="opportunity"
            nzShowSort
            [nzSortOrder]="mapOfSort['opportunity']"
            [nzSortFn]="true"
            (nzSortOrderChange)="sort($event, 'opportunity')"
          >
            Opportunity
          </th>
          <th nzShowSort [nzSortOrder]="mapOfSort['projectCode']" [nzSortFn]="true" (nzSortOrderChange)="sort($event, 'projectCode')">
            Project Code
          </th>
          <th
            nzShowSort
            [nzSortOrder]="mapOfSort['opportunityMarket']"
            [nzSortFn]="true"
            (nzSortOrderChange)="sort($event, 'opportunityMarket')"
          >
            Market
          </th>
          <th nzShowSort [nzSortOrder]="mapOfSort['deliveryUnit']" [nzSortFn]="true" (nzSortOrderChange)="sort($event, 'deliveryUnit')">
            Delivery Unit
          </th>
          <th nzShowSort [nzSortOrder]="mapOfSort['amount']" [nzSortFn]="true" (nzSortOrderChange)="sort($event, 'amount')">Amount</th>
          <th
            class="date-invoice-sent"
            nzShowSort
            [nzSortOrder]="mapOfSort['dateInvoiceSent']"
            [nzSortFn]="true"
            (nzSortOrderChange)="sort($event, 'dateInvoiceSent')"
          >
            Date Invoice Sent
          </th>
          <th
            class="countdown"
            nzShowSort
            [nzSortOrder]="mapOfSort['countDown']"
            [nzSortFn]="true"
            (nzSortOrderChange)="sort($event, 'countDown')"
          >
            Countdown
          </th>
          <th
            class="expected-due-date"
            nzShowSort
            [nzSortOrder]="mapOfSort['invoiceDueDate']"
            [nzSortFn]="true"
            (nzSortOrderChange)="sort($event, 'invoiceDueDate')"
          >
            Invoice Due Date
          </th>
          <th
            class="expected-due-date"
            nzShowSort
            [nzSortOrder]="mapOfSort['expectedDueDate']"
            [nzSortFn]="true"
            (nzSortOrderChange)="sort($event, 'expectedDueDate')"
          >
            Expected Collection Due Date
            <app-error-tooltip
              *ngIf="!aclService.isPermission(aclService.features.invoiceEditExpectedDueDate)"
              [message]="'No permission to edit invoice expected due date'"
            ></app-error-tooltip>
          </th>
          <th nzShowSort [nzSortOrder]="mapOfSort['invoiceId']" [nzSortFn]="true" (nzSortOrderChange)="sort($event, 'invoiceId')">
            Invoice ID
          </th>
          <th
            class="owner"
            nzShowSort
            [nzSortOrder]="mapOfSort['ownerName']"
            [nzSortFn]="true"
            (nzSortOrderChange)="sort($event, 'ownerName')"
          >
            Opportunity Owner
          </th>
          <th nzRight nzAlign="center" class="note">
            Note
            <app-error-tooltip
              *ngIf="!aclService.isPermission(aclService.features.invoiceEditNote)"
              [message]="'No permission to edit invoice note'"
            ></app-error-tooltip>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of invoiceList; index as i">
          <td nzLeft nzWidth="280px" colspan="2">
            <app-small-mu-tag-combination
              [page]="pageName"
              [opportunityId]="item?.opportunityId"
              [opportunityName]="item?.opportunityName"
              [muName]="item?.opportunityMarket"
              [accountName]="item?.accountName"
              [isHighRisk]="item?.isHighRisk"
              [riskCategories]="item?.riskCategories"
            ></app-small-mu-tag-combination>
          </td>
          <td>
            <span>{{ item?.projectCode | emptyFormat }}</span>
          </td>
          <td>
            <span>{{ item?.opportunityMarket | emptyFormat }}</span>
          </td>
          <td>
            <span>{{ item?.deliveryUnit | emptyFormat }}</span>
          </td>
          <td>
            <span>{{ item?.invoiceAmount | currencyFormat : 'CNY' }}</span>
          </td>
          <td>
            <span>{{ item?.invoiceSentDate | date : dateType.DATE | emptyFormat }}</span>
          </td>
          <td class="countdown-column" appInvoiceHighlight [countDown]="item?.countdown" [invoiceStatus]="invoiceStatus">
            <app-countdown-format [countDown]="item?.countdown"></app-countdown-format>
          </td>
          <td>
            <span>{{ item?.invoiceDueDate | date : dateType.DATE | emptyFormat }}</span>
          </td>
          <td>
            <app-expected-collection-due-date
              [expectedCollectionDueDate]="item?.expectedCollectionDueDate"
              [dateInvoiceSent]="item?.invoiceSentDate"
              (emitEditExpectedCollectionDueDate)="onChangeExpectedDueDate($event, item, i)"
            ></app-expected-collection-due-date>
          </td>
          <td>
            <span>{{ item?.congaInvoiceNumber | emptyFormat }}</span>
          </td>
          <td>
            <span>{{ item?.opportunityOwnerName | emptyFormat }}</span>
          </td>
          <td nzRight nzAlign="center">
            <app-note
              [disableEditNote]="!aclService.isPermission(aclService.features.invoiceEditNote)"
              [page]="pageName"
              [type]="invoiceNoteType"
              [lookupId]="item.id.toString()"
              [opportunityName]="item?.opportunityName"
            ></app-note>
          </td>
        </tr>
        <tr *ngIf="invoiceList?.length > 0">
          <td colspan="11" style="border-bottom: none; background-color: white">
            <app-table-loading-more [isLastPage]="isLastPage" [loadingMore]="loadingMore"></app-table-loading-more>
          </td>
        </tr>
      </tbody>
    </nz-table>
    <app-info-tag (openDataTips)="openDataTips()"></app-info-tag>
  </div>
</div>

<app-invoice-data-tips [isDataTipsVisible]="isDataTipsVisible" (closeDataTips)="closeDataTips()"></app-invoice-data-tips>

<ng-template #noData>
  <app-table-no-data [loadingFirst]="loadingFirst"></app-table-no-data>
</ng-template>
