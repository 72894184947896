import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-opportunity-card',
  templateUrl: './opportunity-card.component.html',
  styleUrls: ['./opportunity-card.component.scss'],
})
export class OpportunityCardComponent {
  @Input() cardTitle: string;
  @Input() cardIcon: string;
}
