import { Component, EventEmitter, Input, Output } from '@angular/core';

const DefaultPageSize = 4;
@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss'],
})
export class SelectorComponent {
  @Input() showPageIndex = false;
  @Input() pageSize = DefaultPageSize;
  @Input() pageIndex = 0;
  @Input() pageNumber = 0;
  @Output() pageChange = new EventEmitter<number>();

  turnToPreviousPage() {
    if (this.pageIndex !== 0) {
      this.pageChange.emit(this.pageIndex - 1);
    }
  }

  turnToNextPage() {
    if (this.pageIndex + 1 <= this.pageNumber - 1) {
      this.pageChange.emit(this.pageIndex + 1);
    }
  }

  onSelector() {
    return false;
  }
}
