<div>
  <app-search-input [page]="pageName"></app-search-input>
  <div class="without-po-page">
    <div class="without-po-info">
      <div class="title">
        <div>Opportunity Without Contract</div>
        <div class="total-number">
          <span class="total">Total:</span>
          <span>{{ totalElements }}</span>
        </div>
      </div>
    </div>
    <app-advanced-filter
      (filterChanged)="handleFilterChanged($event)"
      (filterReset)="handleFilterReset($event)"
      (selectedFilterChanged)="handleSelectedCommonFilterChangeForMatomoRecord($event)"
      (downloadEvent)="handleDownload()"
    >
      <div class="hidden-filter">
        <nz-select
          [(ngModel)]="selectedWithoutContractStatus"
          (ngModelChange)="handleSelectedAdvancedFilterChangeForMatomoRecord(NAME_FILTER_EVENT_MAPPING.withoutContractStatus)"
          [nzOptionHeightPx]="24"
          class="without-contract-status-select gray-light-placeholder gap"
          nzPlaceHolder="Without Contract Status"
          nzMode="multiple"
          nzShowArrow="true"
          nzMaxTagCount="1"
          data-testid="without-contract-status-select"
        >
          <nz-option
            *ngFor="let withoutContractStatusFilterOption of withoutContractStatusFilterOptions"
            [nzLabel]="withoutContractStatusFilterOption.label"
            [nzValue]="withoutContractStatusFilterOption.value"
          ></nz-option>
        </nz-select>
        <nz-select
          [(ngModel)]="selectedWithoutContractDays"
          (ngModelChange)="handleSelectedAdvancedFilterChangeForMatomoRecord(NAME_FILTER_EVENT_MAPPING.withoutPoDays)"
          [nzOptionHeightPx]="24"
          class="without-contract-days-select gray-light-placeholder gap"
          nzPlaceHolder="Without Contract Days"
          nzShowArrow="true"
          nzAllowClear="true"
          data-testid="without-contract-days-select"
        >
          <nz-option
            *ngFor="let withoutContractDaysFilterOption of withoutContractDaysFilterOptions"
            [nzLabel]="withoutContractDaysFilterOption.label"
            [nzValue]="withoutContractDaysFilterOption.value"
          ></nz-option>
        </nz-select>
        <nz-range-picker
          class="date-range-picker"
          nzMode="date"
          [nzPlaceHolder]="['Expected Contract Receiving Date', 'End Date']"
          [(ngModel)]="selectedContractReceivedDateRange"
          (ngModelChange)="handleSelectedAdvancedFilterChangeForMatomoRecord(NAME_FILTER_EVENT_MAPPING.expectedContractReceivedDate)"
        ></nz-range-picker>
      </div>
    </app-advanced-filter>
    <nz-table
      #withoutContractDashboard
      [nzData]="withoutPoList"
      [nzWidthConfig]="columnsWidth"
      [nzScroll]="{ y: 'calc(100vh - 238px)', x: '1450px' }"
      [nzFrontPagination]="false"
      [nzShowPagination]="false"
      [nzNoResult]="noData"
    >
      <thead>
        <tr>
          <th
            nzLeft
            class="account"
            nzShowSort
            [nzSortOrder]="mapOfSort['account']"
            (nzSortOrderChange)="sort($event, 'account')"
            [nzSortFn]="true"
          >
            Account
          </th>
          <th
            nzLeft
            class="opportunity"
            nzShowSort
            [nzSortOrder]="mapOfSort['opportunity']"
            (nzSortOrderChange)="sort($event, 'opportunity')"
            [nzSortFn]="true"
          >
            Opportunity
          </th>
          <th nzShowSort [nzSortOrder]="mapOfSort['projectCode']" (nzSortOrderChange)="sort($event, 'projectCode')" [nzSortFn]="true">
            Project Code
          </th>
          <th nzShowSort [nzSortOrder]="mapOfSort['market']" (nzSortOrderChange)="sort($event, 'market')" [nzSortFn]="true">Market</th>
          <th nzShowSort [nzSortOrder]="mapOfSort['deliveryUnit']" (nzSortOrderChange)="sort($event, 'deliveryUnit')" [nzSortFn]="true">
            Delivery Unit
          </th>
          <th
            class="width-76"
            nzShowSort
            [nzSortOrder]="mapOfSort['contractAmount']"
            (nzSortOrderChange)="sort($event, 'contractAmount')"
            [nzSortFn]="true"
          >
            Sales Funnel Amount
          </th>
          <th
            class="width-76"
            nzShowSort
            [nzSortOrder]="mapOfSort['amountWithoutPo']"
            (nzSortOrderChange)="sort($event, 'amountWithoutPo')"
            [nzSortFn]="true"
          >
            Amount Without Contract
          </th>
          <th
            class="width-76"
            nzShowSort
            [nzSortOrder]="mapOfSort['daysWithoutPo']"
            (nzSortOrderChange)="sort($event, 'daysWithoutPo')"
            [nzSortFn]="true"
          >
            Days Without Contract
          </th>
          <th
            class="width-76"
            nzShowSort
            [nzSortOrder]="mapOfSort['applicationOverdueCountdown']"
            (nzSortOrderChange)="sort($event, 'applicationOverdueCountdown')"
            [nzSortFn]="true"
          >
            Application Overdue Countdown
          </th>
          <th
            class="width-50"
            nzShowSort
            [nzSortOrder]="mapOfSort['startDate']"
            (nzSortOrderChange)="sort($event, 'startDate')"
            [nzSortFn]="true"
          >
            Start Date
          </th>
          <th
            class="width-50"
            nzShowSort
            [nzSortOrder]="mapOfSort['endDate']"
            (nzSortOrderChange)="sort($event, 'endDate')"
            [nzSortFn]="true"
          >
            End Date
          </th>
          <th
            class="owner"
            nzShowSort
            [nzSortOrder]="mapOfSort['ownerName']"
            (nzSortOrderChange)="sort($event, 'ownerName')"
            [nzSortFn]="true"
          >
            Opportunity Owner
          </th>
          <th nzRight nzAlign="center" class="note">
            Note
            <app-error-tooltip
              *ngIf="!aclService.isPermission(aclService.features.contractInfo)"
              [message]="'No Permission to edit without contract Note'"
            ></app-error-tooltip>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of withoutPoList">
          <td nzLeft colspan="2">
            <app-small-mu-tag-combination
              [page]="pageName"
              [tagConfig]="getTagConfig(item)"
              [opportunityId]="item?.id"
              [opportunityName]="item?.name"
              [muName]="item?.accountResponse?.mu"
              [accountName]="item?.accountResponse.name"
              [noContracts]="item?.noContracts"
              [isHighRisk]="item?.isHighRisk"
              [riskCategories]="item?.riskCategories"
            ></app-small-mu-tag-combination>
          </td>
          <td>
            <span>{{ item?.projectCode | emptyFormat }}</span>
          </td>
          <td>
            <span>{{ item?.market | emptyFormat }}</span>
          </td>
          <td>
            <span>{{ item?.deliveryUnit | emptyFormat }}</span>
          </td>
          <td>
            <span>{{ item?.contractAmount | currencyFormat : 'CNY' }}</span>
          </td>
          <td>
            <span>{{ item?.withoutPoAmount | currencyFormat : 'CNY' }}</span>
          </td>
          <td class="days">
            <span [ngClass]="getDaysColor(item?.withoutPoDays)">{{ item?.withoutPoDays | emptyFormat }}</span>
          </td>
          <td>
            <div
              [nzTooltipTitle]="item?.expectedContractReceivedDate ? expectedDueDateTip : null"
              class="days"
              nz-tooltip
              nzTooltipPlacement="bottomRight"
              style="cursor: default"
            >
              <span [ngClass]="getApplicationOverdueCountdownColor(item?.applicationOverdueCountdown)">
                {{ item?.applicationOverdueCountdown | addSign | emptyFormat }}
              </span>
            </div>
            <ng-template #expectedDueDateTip>
              <div class="milestone-due-date-tip">
                <div class="due-date">{{ item?.expectedContractReceivedDate | emptyFormat }}</div>
                <div class="tip-text">Expected Contract Receiving Date</div>
              </div>
            </ng-template>
          </td>
          <td>
            <span>{{ item?.startDate | emptyFormat }}</span>
          </td>
          <td>
            <span>{{ item?.endDate | emptyFormat }}</span>
          </td>
          <td>
            <span>{{ item?.opportunityOwnerName | emptyFormat }}</span>
          </td>
          <td nzRight nzAlign="center">
            <app-note
              [disableEditNote]="!aclService.isPermission(aclService.features.contractInfo)"
              [page]="pageName"
              [type]="withoutPoNoteType"
              [lookupId]="item.id.toString()"
              [opportunityName]="item?.name"
            ></app-note>
          </td>
        </tr>
        <tr *ngIf="withoutPoList?.length > 0">
          <td colspan="11" style="border-bottom: none; background-color: white">
            <app-table-loading-more [isLastPage]="isLastPage" [loadingMore]="loadingMore"></app-table-loading-more>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
  <app-info-tag (openDataTips)="openDataTips()"></app-info-tag>
</div>

<app-without-contract-data-tips [isDataTipsVisible]="isDataTipsVisible" (closeDataTips)="closeDataTips()"></app-without-contract-data-tips>

<ng-template #noData>
  <app-table-no-data [loadingFirst]="loadingFirst"></app-table-no-data>
</ng-template>
