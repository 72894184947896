<a
  class="login"
  nz-dropdown
  nzPlacement="bottomRight"
  nzTrigger="click"
  (click)="onClickMatomoEvent('Profile & Roles')"
  [nzDropdownMenu]="roleAndPermissions"
>
  <i nz-icon nzType="user" nzTheme="outline"></i>
  <p *ngIf="aclService.getUser()?.name">Hi，{{ aclService.getUser()?.name }}</p>
</a>

<nz-dropdown-menu #roleAndPermissions>
  <ul nz-menu class="role-menu">
    <li nz-menu-item>
      <a class="normal-color-link" [routerLink]="roleLink" (click)="setBackButtonStatus()">Enter role control page</a>
    </li>
    <li nz-menu-item (click)="showAllRoles()">Show my roles</li>
  </ul>
</nz-dropdown-menu>

<ng-template #userInfo>
  <p class="user-info-title">My roles info</p>
  <ul class="user-info-container">
    <li *ngFor="let role of aclService.getRoles()" class="user-info-content">
      <app-icon-text [className]="role.isAdmin ? 'admin-user' : 'normal-user'"></app-icon-text>
      <p>{{ role?.description }}</p>
    </li>
    <li *ngIf="aclService.getRoles()?.length === 0" class="no-data">No roles</li>
  </ul>
</ng-template>
