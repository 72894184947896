import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'positiveNumber',
})
export class PositiveNumberPipe implements PipeTransform {
  transform(value: number, hasSeparator: boolean, fractionDigits = 2, unit?: string[]): string {
    if (value === null || value === undefined) {
      return null;
    }
    let result = formatNumber(value, 'en-US', `1.0-${fractionDigits}`);
    result = result.startsWith('-') ? result.substring(1) : result;
    if (!hasSeparator) {
      result = result.replace(',', '');
    }
    if (value > 0) {
      result = '+ ' + result;
    } else if (value < 0) {
      result = '- ' + result;
    }
    if (!unit) {
      return result;
    }
    if (value === 1 || value === -1) {
      result = result + ' ' + unit[0];
    } else {
      result = result + ' ' + unit[1];
    }
    return result;
  }
}
