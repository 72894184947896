<div>
  <app-search-input [page]="pageName"></app-search-input>
  <div class="high-risk-opportunity">
    <div class="invoice-info">
      <div class="title">
        <div>Risk Project</div>
        <div class="total-number">
          <span class="total">Total:</span>
          <span>{{ totalElements }}</span>
        </div>
      </div>
    </div>
    <nz-table
      #highRiskDashboard
      [nzData]="highRiskOpportunityList"
      [nzWidthConfig]="columnsWidth"
      [nzScroll]="{ x: '2000px', y: 'calc(100vh - 238px)' }"
      [nzFrontPagination]="false"
      [nzShowPagination]="false"
      [nzNoResult]="noData"
    >
      <thead>
        <tr>
          <th
            nzLeft
            class="account"
            nzShowSort
            [nzSortOrder]="mapOfSort['account']"
            [nzSortFn]="true"
            (nzSortOrderChange)="sort($event, 'account')"
          >
            Account Name
          </th>
          <th
            nzLeft
            class="opportunity"
            nzShowSort
            [nzSortOrder]="mapOfSort['opportunity']"
            [nzSortFn]="true"
            (nzSortOrderChange)="sort($event, 'opportunity')"
          >
            Opportunity Name
          </th>
          <th
            nzShowSort
            [nzSortOrder]="mapOfSort['opportunityOwnerName']"
            [nzSortFn]="true"
            (nzSortOrderChange)="sort($event, 'opportunityOwnerName')"
          >
            Opportunity Owner
          </th>
          <th
            nzShowSort
            [nzSortOrder]="mapOfSort['projectManagerName']"
            [nzSortFn]="true"
            (nzSortOrderChange)="sort($event, 'projectManagerName')"
          >
            Project Manager
          </th>
          <th
            nzShowSort
            [nzSortOrder]="mapOfSort['opportunityMarket']"
            [nzSortFn]="true"
            (nzSortOrderChange)="sort($event, 'opportunityMarket')"
          >
            Market
          </th>
          <th nzShowSort [nzSortOrder]="mapOfSort['deliveryUnit']" [nzSortFn]="true" (nzSortOrderChange)="sort($event, 'deliveryUnit')">
            Delivery Unit
          </th>
          <th
            nzShowSort
            [nzSortOrder]="mapOfSort['actionDepartment']"
            [nzSortFn]="true"
            (nzSortOrderChange)="sort($event, 'actionDepartment')"
          >
            Action Department
            <app-error-tooltip
              *ngIf="!aclService.isPermission(aclService.features.highRiskEdit)"
              [message]="'No permission to edit action department'"
            ></app-error-tooltip>
          </th>
          <th nzShowSort [nzSortOrder]="mapOfSort['category']" [nzSortFn]="true" (nzSortOrderChange)="sort($event, 'category')">
            Category
            <app-error-tooltip
              *ngIf="!aclService.isPermission(aclService.features.highRiskEdit)"
              [message]="'No permission to edit category'"
            ></app-error-tooltip>
          </th>
          <th nzShowSort [nzSortOrder]="mapOfSort['countdown']" [nzSortFn]="true" (nzSortOrderChange)="sort($event, 'countdown')">
            Countdown
            <app-error-tooltip
              *ngIf="!aclService.isPermission(aclService.features.highRiskEdit)"
              [message]="'No permission to edit countdown'"
            ></app-error-tooltip>
          </th>
          <th nzShowSort [nzSortOrder]="mapOfSort['amountType']" [nzSortFn]="true" (nzSortOrderChange)="sort($event, 'amountType')">
            Amount Type
            <app-error-tooltip
              *ngIf="!aclService.isPermission(aclService.features.highRiskEdit)"
              [message]="'No permission to edit amount type'"
            ></app-error-tooltip>
          </th>
          <th
            nzShowSort
            [nzSortOrder]="mapOfSort['impactAmount']"
            [nzSortFn]="true"
            class="impactAmount"
            (nzSortOrderChange)="sort($event, 'impactAmount')"
          >
            Impact Amount
            <app-error-tooltip
              *ngIf="!aclService.isPermission(aclService.features.highRiskEdit)"
              [message]="'No permission to edit impact amount'"
            ></app-error-tooltip>
          </th>
          <th
            nzShowSort
            [nzSortOrder]="mapOfSort['expectedDueDate']"
            [nzSortFn]="true"
            (nzSortOrderChange)="sort($event, 'expectedDueDate')"
          >
            Expected Due Date
            <app-error-tooltip
              *ngIf="!aclService.isPermission(aclService.features.highRiskEdit)"
              [message]="'No permission to edit expected due date'"
            ></app-error-tooltip>
          </th>
          <th
            nzShowSort
            [nzSortOrder]="mapOfSort['recognizedRevenue']"
            [nzSortFn]="true"
            (nzSortOrderChange)="sort($event, 'recognizedRevenue')"
          >
            Recognized Revenue
          </th>
          <th nzShowSort [nzSortOrder]="mapOfSort['riskLevel']" [nzSortFn]="true" (nzSortOrderChange)="sort($event, 'riskLevel')">
            Risk Level
            <app-error-tooltip
              *ngIf="!aclService.isPermission(aclService.features.highRiskEdit)"
              [message]="'No permission to edit risk level'"
            ></app-error-tooltip>
          </th>
          <th
            nzShowSort
            [nzSortOrder]="mapOfSort['expectedDateImpactToNoi']"
            [nzSortFn]="true"
            (nzSortOrderChange)="sort($event, 'expectedDateImpactToNoi')"
          >
            Expected Date Impact to NOI
            <app-error-tooltip
              *ngIf="!aclService.isPermission(aclService.features.highRiskEdit)"
              [message]="'No permission to edit expected date impact to NOI'"
            ></app-error-tooltip>
          </th>
          <th
            nzShowSort
            [nzSortOrder]="mapOfSort['expectedAmountImpactToNoi']"
            [nzSortFn]="true"
            (nzSortOrderChange)="sort($event, 'expectedAmountImpactToNoi')"
          >
            Expected Amount Impact to NOI
            <app-error-tooltip
              *ngIf="!aclService.isPermission(aclService.features.highRiskEdit)"
              [message]="'No permission to edit expected amount impact to NOI'"
            ></app-error-tooltip>
          </th>
          <th
            nzShowSort
            [nzSortOrder]="mapOfSort['impactToNoiDate']"
            [nzSortFn]="true"
            (nzSortOrderChange)="sort($event, 'impactToNoiDate')"
          >
            Impact to NOI(Date)
            <app-error-tooltip
              *ngIf="!aclService.isPermission(aclService.features.highRiskEdit)"
              [message]="'No permission to edit impact to NOI(Date)'"
            ></app-error-tooltip>
          </th>
          <th
            nzShowSort
            [nzSortOrder]="mapOfSort['impactToNoiAmount']"
            [nzSortFn]="true"
            (nzSortOrderChange)="sort($event, 'impactToNoiAmount')"
          >
            Impact to NOI(Amount)
            <app-error-tooltip
              *ngIf="!aclService.isPermission(aclService.features.highRiskEdit)"
              [message]="'No permission to edit impact to NOI(Amount)'"
            ></app-error-tooltip>
          </th>
          <th
            nzShowSort
            [nzSortOrder]="mapOfSort['actualBadDebtDate']"
            [nzSortFn]="true"
            (nzSortOrderChange)="sort($event, 'actualBadDebtDate')"
          >
            Actual Bad Debt Date
            <app-error-tooltip
              *ngIf="!aclService.isPermission(aclService.features.highRiskEdit)"
              [message]="'No permission to edit actual bad debt date'"
            ></app-error-tooltip>
          </th>
          <th
            nzShowSort
            [nzSortOrder]="mapOfSort['actualBadDebtAmount']"
            [nzSortFn]="true"
            (nzSortOrderChange)="sort($event, 'actualBadDebtAmount')"
          >
            Actual Bad Debt Amount
            <app-error-tooltip
              *ngIf="!aclService.isPermission(aclService.features.highRiskEdit)"
              [message]="'No permission to edit actual bad debt amount'"
            ></app-error-tooltip>
          </th>
          <th nzRight nzAlign="center">
            Note
            <app-error-tooltip
              *ngIf="!aclService.isPermission(aclService.features.highRiskEdit)"
              [message]="'No permission to edit note'"
            ></app-error-tooltip>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of highRiskOpportunityList; index as i">
          <td nzLeft colspan="2">
            <app-small-mu-tag-combination
              [page]="pageName"
              [opportunityId]="item?.opportunityId"
              [opportunityName]="item?.opportunityName"
              [muName]="item?.opportunityMarket"
              [accountName]="item?.accountName"
              [projectCode]="item?.projectCode"
              [isShowProjectCode]="true"
              [riskCategories]="item?.riskCategories"
              isHighRisk="true"
            ></app-small-mu-tag-combination>
          </td>
          <td>
            <span>{{ item?.opportunityOwnerName | emptyFormat }}</span>
          </td>
          <td>
            <span>{{ item?.projectManagerName | emptyFormat }}</span>
          </td>
          <td>
            <span>{{ item?.opportunityMarket | emptyFormat }}</span>
          </td>
          <td>
            <span>{{ item?.deliveryUnit | emptyFormat }}</span>
          </td>
          <td
            [className]="aclService.isPermission(aclService.features.highRiskEdit) ? 'editable-cell' : 'disable'"
            (click)="aclService.isPermission(aclService.features.highRiskEdit) ? startEdit(i + 'actionDepartment') : false"
          >
            <button
              [ngClass]="{ 'add-date': '+' }"
              *ngIf="item?.actionDepartment === null || item?.actionDepartment === ''"
              [hidden]="this.editId === i + 'actionDepartment'"
            ></button>
            <app-info-edit-selector
              *ngIf="this.editId === i + 'actionDepartment'"
              [optionList]="actionDepartmentOptionList"
              [initValue]="item?.actionDepartment"
              (save)="saveEdit($event, i, 'actionDepartment')"
            ></app-info-edit-selector>
            <span [hidden]="this.editId === i + 'actionDepartment'">
              {{ actionDepartmentMap[item?.actionDepartment] }}
            </span>
          </td>
          <td>
            <span>{{ highRiskCategoryMap[item?.category] | emptyFormat }}</span>
          </td>
          <td
            [className]="aclService.isPermission(aclService.features.highRiskEdit) ? 'editable-cell' : 'disable'"
            (click)="aclService.isPermission(aclService.features.highRiskEdit) ? startEdit(i + 'countdown') : false"
          >
            <button
              [ngClass]="{ 'add-date': '+' }"
              *ngIf="item?.countdown === null || item?.countdown === ''"
              [hidden]="this.editId === i + 'countdown'"
            ></button>
            <app-info-edit-input
              *ngIf="this.editId === i + 'countdown'"
              [initValue]="item?.countdown"
              (save)="saveEdit($event, i, 'countdown')"
            ></app-info-edit-input>
            <span [hidden]="this.editId === i + 'countdown'">
              {{ item?.countdown }}
            </span>
          </td>
          <td
            [className]="aclService.isPermission(aclService.features.highRiskEdit) ? 'editable-cell' : 'disable'"
            (click)="aclService.isPermission(aclService.features.highRiskEdit) ? startEdit(i + 'amountType') : false"
          >
            <button
              [ngClass]="{ 'add-date': '+' }"
              *ngIf="item?.amountType === null || item?.amountType === ''"
              [hidden]="this.editId === i + 'amountType'"
            ></button>
            <app-info-edit-selector
              *ngIf="this.editId === i + 'amountType'"
              [optionList]="highRiskAmountTypeOptionList"
              [initValue]="item?.amountType"
              (save)="saveEdit($event, i, 'amountType')"
            ></app-info-edit-selector>
            <span [hidden]="this.editId === i + 'amountType'">
              {{ highRiskAmountMap[item?.amountType] }}
            </span>
          </td>
          <td
            [className]="aclService.isPermission(aclService.features.highRiskEdit) ? 'editable-cell' : 'disable'"
            (click)="aclService.isPermission(aclService.features.highRiskEdit) ? startEdit(i + 'impactAmount') : false"
          >
            <button
              [ngClass]="{ 'add-date': '+' }"
              *ngIf="item?.impactAmount === null || item?.impactAmount === ''"
              [hidden]="this.editId === i + 'impactAmount'"
            ></button>
            <app-info-edit-currency-input
              *ngIf="this.editId === i + 'impactAmount'"
              [initValue]="item?.impactAmount"
              (save)="saveEdit($event, i, 'impactAmount')"
            ></app-info-edit-currency-input>
            <span [hidden]="this.editId === i + 'impactAmount'">
              {{ item?.impactAmount | currencyFormat : 'CNY' : false | HideDefaultNullValue }}
            </span>
          </td>
          <td [className]="aclService.isPermission(aclService.features.highRiskEdit) ? 'editable-cell' : 'disable'">
            <app-info-edit-date-picker
              [initValue]="item?.expectedDueDate"
              [disableEdit]="!aclService.isPermission(aclService.features.highRiskEdit)"
              (emitDate)="saveEdit($event, i, 'expectedDueDate')"
            ></app-info-edit-date-picker>
          </td>
          <td>
            <span>{{ item?.recognizedRevenue | currencyFormat : 'CNY' : false | emptyFormat }}</span>
          </td>
          <td
            [className]="aclService.isPermission(aclService.features.highRiskEdit) ? 'editable-cell' : 'disable'"
            (click)="aclService.isPermission(aclService.features.highRiskEdit) ? startEdit(i + 'riskLevel') : false"
          >
            <button
              [ngClass]="{ 'add-date': '+' }"
              *ngIf="item?.riskLevel === null || item?.riskLevel === ''"
              [hidden]="this.editId === i + 'riskLevel'"
            ></button>
            <app-info-edit-selector
              *ngIf="this.editId === i + 'riskLevel'"
              [optionList]="highRiskRiskLevelOptionList"
              [initValue]="item?.riskLevel"
              (save)="saveEdit($event, i, 'riskLevel')"
            ></app-info-edit-selector>
            <span [hidden]="this.editId === i + 'riskLevel'">
              {{ highRiskRiskLevelMap[item?.riskLevel] | HideDefaultNullValue }}
            </span>
          </td>
          <td [className]="aclService.isPermission(aclService.features.highRiskEdit) ? 'editable-cell' : 'disable'">
            <app-info-edit-date-picker
              [initValue]="item?.expectedDateImpactToNoi"
              [disableEdit]="!aclService.isPermission(aclService.features.highRiskEdit)"
              (emitDate)="saveEdit($event, i, 'expectedDateImpactToNoi')"
            ></app-info-edit-date-picker>
          </td>
          <td
            [className]="aclService.isPermission(aclService.features.highRiskEdit) ? 'editable-cell' : 'disable'"
            (click)="aclService.isPermission(aclService.features.highRiskEdit) ? startEdit(i + 'expectedAmountImpactToNoi') : false"
          >
            <button
              [ngClass]="{ 'add-date': '+' }"
              *ngIf="item?.expectedAmountImpactToNoi === null || item?.expectedAmountImpactToNoi === ''"
              [hidden]="this.editId === i + 'expectedAmountImpactToNoi'"
            ></button>
            <app-info-edit-currency-input
              *ngIf="this.editId === i + 'expectedAmountImpactToNoi'"
              [initValue]="item?.expectedAmountImpactToNoi"
              (save)="saveEdit($event, i, 'expectedAmountImpactToNoi')"
            ></app-info-edit-currency-input>
            <span [hidden]="this.editId === i + 'expectedAmountImpactToNoi'">
              {{ item?.expectedAmountImpactToNoi | currencyFormat : 'CNY' : false | HideDefaultNullValue }}
            </span>
          </td>
          <td [className]="aclService.isPermission(aclService.features.highRiskEdit) ? 'editable-cell' : 'disable'">
            <app-info-edit-date-picker
              [initValue]="item?.impactToNoiDate"
              [disableEdit]="!aclService.isPermission(aclService.features.highRiskEdit)"
              (emitDate)="saveEdit($event, i, 'impactToNoiDate')"
            ></app-info-edit-date-picker>
          </td>
          <td
            [className]="aclService.isPermission(aclService.features.highRiskEdit) ? 'editable-cell' : 'disable'"
            (click)="aclService.isPermission(aclService.features.highRiskEdit) ? startEdit(i + 'impactToNoiAmount') : false"
          >
            <button
              [ngClass]="{ 'add-date': '+' }"
              *ngIf="item?.impactToNoiAmount === null || item?.impactToNoiAmount === ''"
              [hidden]="this.editId === i + 'impactToNoiAmount'"
            ></button>
            <app-info-edit-currency-input
              *ngIf="this.editId === i + 'impactToNoiAmount'"
              [initValue]="item?.impactToNoiAmount"
              (save)="saveEdit($event, i, 'impactToNoiAmount')"
            ></app-info-edit-currency-input>
            <span [hidden]="this.editId === i + 'impactToNoiAmount'">
              {{ item?.impactToNoiAmount | currencyFormat : 'CNY' : false | HideDefaultNullValue }}
            </span>
          </td>
          <td [className]="aclService.isPermission(aclService.features.highRiskEdit) ? 'editable-cell' : 'disable'">
            <app-info-edit-date-picker
              [initValue]="item?.actualBadDebtDate"
              [disableEdit]="!aclService.isPermission(aclService.features.highRiskEdit)"
              (emitDate)="saveEdit($event, i, 'actualBadDebtDate')"
            ></app-info-edit-date-picker>
          </td>
          <td
            [className]="aclService.isPermission(aclService.features.highRiskEdit) ? 'editable-cell' : 'disable'"
            (click)="aclService.isPermission(aclService.features.highRiskEdit) ? startEdit(i + 'actualBadDebtAmount') : false"
          >
            <button
              [ngClass]="{ 'add-date': '+' }"
              *ngIf="item?.actualBadDebtAmount === null || item?.actualBadDebtAmount === ''"
              [hidden]="this.editId === i + 'actualBadDebtAmount'"
            ></button>
            <app-info-edit-currency-input
              *ngIf="this.editId === i + 'actualBadDebtAmount'"
              [initValue]="item?.actualBadDebtAmount"
              (save)="saveEdit($event, i, 'actualBadDebtAmount')"
            ></app-info-edit-currency-input>
            <span [hidden]="this.editId === i + 'actualBadDebtAmount'">
              {{ item?.actualBadDebtAmount | currencyFormat : 'CNY' : false | HideDefaultNullValue }}
            </span>
          </td>

          <td nzRight nzAlign="center">
            <app-note
              [page]="pageName"
              [type]="highRiskNoteType"
              [disableEditNote]="!aclService.isPermission(aclService.features.highRiskEdit)"
              [supportEmailInfo]="item?.supportEmailInfo"
              (emitEditSupportEmailInfo)="onChangeSupportEmailInfo($event)"
              [lookupId]="item.id.toString()"
              [opportunityName]="item?.opportunityName"
            ></app-note>
          </td>
        </tr>

        <tr *ngIf="highRiskOpportunityList?.length > 0">
          <td align="center" colspan="15" style="border-bottom: none; background-color: white">
            <app-table-loading-more [isLastPage]="isLastPage" [loadingMore]="loadingMore"></app-table-loading-more>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>

<ng-template #noData>
  <app-table-no-data [loadingFirst]="loadingFirst"></app-table-no-data>
</ng-template>
