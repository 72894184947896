import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-dashboard-data-tips',
  templateUrl: './data-tips.component.html',
  styleUrls: ['./data-tips.component.scss'],
})
export class DashboardDataTipsComponent {
  @Input() isDataTipsVisible = false;
  @Output() closeDataTips = new EventEmitter<any>();

  closeTips() {
    this.closeDataTips.emit();
  }
}
