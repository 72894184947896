import { Injectable } from '@angular/core';
import { MilestoneList } from '../interfaces/Milestone';

@Injectable({
  providedIn: 'root',
})
export class MilestonePageService {
  milestonePage: MilestoneList = {};

  set(page: MilestoneList) {
    this.milestonePage = page;
  }

  get() {
    return this.milestonePage;
  }
}
