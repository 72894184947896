import { Component, Input, OnInit } from '@angular/core';
import { stoppedStatus } from '@constants/invoiceStatus';
import { includes, isEmpty, isNumber } from 'lodash';
import { COUNTDOWN_L0_MILESTONE, COUNTDOWN_L1_MILESTONE } from '@constants/countdown';

@Component({
  selector: 'app-countdown-format',
  templateUrl: './countdown-format.component.html',
})
export class CountdownFormatComponent implements OnInit {
  @Input() set countDown(countDown: number | string) {
    if (isEmpty(countDown) && !isNumber(countDown)) {
      return;
    }
    if (countDown > COUNTDOWN_L1_MILESTONE) {
      this.className = 'red';
    } else if (countDown > COUNTDOWN_L0_MILESTONE) {
      this.className = 'yellow';
    }
    this.countDownStr = countDown > 0 ? `+ ${countDown}` : `- ${Math.abs(Number(countDown))}`;
    if (countDown === 0) {
      this.countDownStr = countDown;
    }
  }

  get countDown(): number | string {
    return this.countDownStr;
  }

  @Input() invoiceStatus: string;
  countDownStr: number | string;
  className = '';
  invoiceStop = false;

  ngOnInit() {
    const invoiceStatus = this.invoiceStatus;
    this.invoiceStop = includes(stoppedStatus, invoiceStatus);
  }
}
