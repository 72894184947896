import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { get } from 'lodash';
import { Filter } from '../interfaces/Filter';
import { FILTER_STATUS_ONGOING, FILTER_WITHOUT_CONTRACT_STATUS } from '../constants/filterType';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  filterSubject = new Subject<Filter>();
  private searchItem = {};
  private opportunityStatus: 'ONGOING' | 'DEACTIVATED' = FILTER_STATUS_ONGOING;
  private countDown: any = {};
  private withoutPoDays: any = {};
  private withoutContractStatus = FILTER_WITHOUT_CONTRACT_STATUS.ALL;

  setSearchItem(searchItem) {
    this.searchItem = searchItem;
  }

  setOpportunityStatus(opportunityStatus: 'ONGOING' | 'DEACTIVATED') {
    this.opportunityStatus = opportunityStatus;
  }

  setCountdown(countDown: any) {
    this.countDown = countDown;
  }

  setWithoutPoDays(withoutPoDays: any) {
    this.withoutPoDays = withoutPoDays;
  }

  setWithoutContractStatus(withoutContractStatus: FILTER_WITHOUT_CONTRACT_STATUS) {
    this.withoutContractStatus = withoutContractStatus;
  }

  getSearchItem() {
    return this.searchItem;
  }

  getOpportunityStatus() {
    return this.opportunityStatus;
  }

  getCountdown() {
    return this.countDown;
  }

  getWithoutPoDays() {
    return this.withoutPoDays;
  }

  getWithoutContractStatus() {
    return this.withoutContractStatus;
  }

  getFilterItem(): Filter {
    return {
      searchItem: this.getSearchItem(),
      status: this.getOpportunityStatus(),
      countDown: this.getCountdown(),
      withoutPoDays: this.getWithoutPoDays(),
      withoutContractStatus: this.getWithoutContractStatus(),
    };
  }

  resetFilter(filterItem: Filter) {
    this.setSearchItem(get(filterItem, 'searchItem', this.searchItem));
    this.setOpportunityStatus(get(filterItem, 'status', this.opportunityStatus));
    this.setCountdown(get(filterItem, 'countDown', this.countDown));
    this.setWithoutContractStatus(get(filterItem, 'withoutContractStatus', this.withoutContractStatus));
    this.setWithoutPoDays(get(filterItem, 'withoutPoDays', this.withoutPoDays));
  }

  triggerFilter(filterItem: Filter) {
    this.resetFilter(filterItem);
    this.filterSubject.next(this.getFilterItem());
  }

  listenFilter(): Observable<Filter> {
    return this.filterSubject.asObservable();
  }
}
