<div class="mu-block-container">
  <app-mu-tag class="top" [mu]="muList.name" [muId]="muList.id"></app-mu-tag>
  <div class="left">
    <div class="dso-container">
      <app-dso-format dsoType="dso" class="dso-legend" [dsoValue]="muList?.dso"></app-dso-format>
      <div class="chart" echarts [options]="options"></div>
      <div class="dso-text">
        <div>
          <label>Billed</label>
          <app-dso-format class="billed" dsoType="billed" [dsoValue]="muList?.billedDso"></app-dso-format>
        </div>
        <div>
          <label>Unbilled</label>
          <app-dso-format class="unbilled" dsoType="unbilled" [dsoValue]="muList?.unBilledDso"></app-dso-format>
        </div>
      </div>
    </div>
  </div>
  <div class="right">
    <div class="money-container">
      <div>
        <label>AR</label>
        <span>{{ muList?.totalArRevenue | currencyFormat : 'CNY' }}</span>
      </div>
      <div>
        <label>WIP</label>
        <span>{{ muList?.totalWipRevenue | currencyFormat : 'CNY' }}</span>
      </div>
      <div>
        <label>YTD Recognized Revenue</label>
        <span>{{ muList?.currentYearTotalRecognizedRevenue | currencyFormat : 'CNY' }}</span>
      </div>
    </div>
  </div>
</div>
