import { Injectable } from '@angular/core';
import { Note } from '../interfaces/Milestone';
import { NoteType } from '../constants/note';

@Injectable({
  providedIn: 'root',
})
export class NoteService {
  private notesMap = new Map();

  constructor() {
    for (const type in NoteType) {
      if (Object.prototype.hasOwnProperty.call(NoteType, type)) {
        this.notesMap.set(NoteType[type], []);
      }
    }
  }

  findNotesByTypeAndLookupId(type: string, lookupId: string) {
    return this.notesMap.get(type).filter((note) => note.lookupId === lookupId);
  }

  findNoteIndexByTypeAndId(noteType: string, id: number) {
    return this.notesMap.get(noteType).findIndex((note) => note.id === id);
  }

  storeNotes(noteType: string, notes: Note[]) {
    this.notesMap.set(
      noteType,
      this.notesMap
        .get(noteType)
        .filter((note) => notes.find((e) => e.id === note.id) === undefined)
        .concat(notes),
    );
  }

  addNote(noteType: string, note: Note) {
    if (this.findNoteIndexByTypeAndId(noteType, note.id) === -1) {
      this.notesMap.get(noteType).push(note);
    } else {
      this.updateNote(noteType, note);
    }
  }

  updateNote(noteType: string, note: Note) {
    const noteIndex = this.findNoteIndexByTypeAndId(noteType, note.id);
    if (noteIndex > -1) {
      this.notesMap.get(noteType)[noteIndex] = note;
    }
  }

  deleteNote(noteType: string, noteId: number) {
    const filteredNotes = this.notesMap.get(noteType).filter((e) => e.id !== noteId);
    this.notesMap.set(noteType, filteredNotes);
  }
}
