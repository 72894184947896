import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { OpportunityApi } from '@app/apis/opportunity.api';
import { CURRENCYS } from '@app/constants/currency';
import { MomentFormat } from '@app/constants/dateFormat';
import { ACTION_EDIT, ACTION_SAVE, CATEGORY_DETAIL_PAGE } from '@app/constants/matomo';
import { OpportunityApplication } from '@app/interfaces/OpportunityDetail';
import { AclService } from '@app/services/acl.service';
import moment from 'moment';
import { MatomoTracker } from 'ngx-matomo';
import { finalize } from 'rxjs/operators';
import { isEqual } from 'lodash';
import { formatterMoney, preventTypingWhenExceed } from '@utils/money';
import { GOOGLE_DRIVE_LINK_PATTERN } from '@constants/regexp-pattern';

@Component({
  selector: 'app-opportunity-application',
  templateUrl: './opportunity-application.component.html',
  styleUrls: ['./opportunity-application.component.scss'],
})
export class OpportunityApplicationComponent implements OnChanges {
  readonly CURRENCYS = CURRENCYS;
  @Input() applicationInfo: OpportunityApplication;
  @Input() opportunityId: number;
  @Output() updateApplication = new EventEmitter();

  showAll = false;
  isShowEditModal = false;
  isSaving = false;
  isCreate = true;
  applicationForm!: UntypedFormGroup;
  formatterMoney = formatterMoney;
  preventTypingWhenExceed = preventTypingWhenExceed;

  constructor(
    private opportunityApi: OpportunityApi,
    private fb: UntypedFormBuilder,
    public matomoTracker: MatomoTracker,
    public aclService: AclService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const applicationInfo = changes.applicationInfo;
    if (applicationInfo && !isEqual(applicationInfo.currentValue, applicationInfo.previousValue)) {
      this.isCreate = !this.applicationInfo.emailSubject;
      this.initForm();
    }
  }

  private initForm(): void {
    const { emailSubject, expectedContractReceivingDate, applicationDate, currency, committedAmount, expectedManDay, googleDriveLink } =
      this.applicationInfo || {};
    this.applicationForm = this.fb.group({
      emailSubject: [emailSubject, [Validators.required, Validators.maxLength(255)]],
      expectedContractReceivingDate: [expectedContractReceivingDate && new Date(expectedContractReceivingDate), [Validators.required]],
      applicationDate: [applicationDate && new Date(applicationDate)],
      currency: [currency || 'CNY'],
      committedAmount: [committedAmount, [Validators.max(1.0e9)]],
      expectedManDay: [expectedManDay],
      googleDriveLink: [googleDriveLink, [Validators.pattern(GOOGLE_DRIVE_LINK_PATTERN)]],
    });
  }

  handleCancelEdit(): void {
    this.isShowEditModal = false;
    this.initForm();
  }

  handleOpenEdit(): void {
    this.isShowEditModal = true;
    this.matomoTracker.trackEvent(CATEGORY_DETAIL_PAGE, ACTION_EDIT, 'Edit Opportunity Application Information');
  }

  submitForm(): void {
    if (!this.applicationForm.valid) {
      Object.values(this.applicationForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return;
    }
    this.matomoTracker.trackEvent(CATEGORY_DETAIL_PAGE, ACTION_SAVE, `Save Opportunity Application Information`);
    this.isSaving = true;
    const { value } = this.applicationForm;
    if (value.expectedContractReceivingDate) {
      value.expectedContractReceivingDate = moment(value.expectedContractReceivingDate).format(MomentFormat.DATE);
    }
    if (value.applicationDate) {
      value.applicationDate = moment(value.applicationDate).format(MomentFormat.DATE);
    }
    if (value.googleDriveLink) {
      value.googleDriveLink = value.googleDriveLink.trim();
    }
    this.opportunityApi
      .createOpportunityApplication(this.opportunityId, value)
      .pipe(
        finalize(() => {
          this.isShowEditModal = false;
          this.isSaving = false;
        }),
      )
      .subscribe(() => {
        this.updateApplication.emit();
      });
  }
}
