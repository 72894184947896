<div>
  <nz-upload
    nzType="drag"
    class="upload"
    [nzMultiple]="true"
    [nzAccept]="['.jpg', '.jpeg', '.png', '.zip', '.rar', '.pdf']"
    [nzBeforeUpload]="beforeUpload"
    [(nzFileList)]="fileList"
    [nzCustomRequest]="submitFiles"
    (nzChange)="handleChange($event)"
    [nzRemove]="removeFile"
    [nzShowUploadList]="{ showPreviewIcon: false, showRemoveIcon: true, showDownloadIcon: false }"
  >
    <p class="ant-upload-drag-icon">
      <span nz-icon nzType="inbox"></span>
    </p>
    <p class="ant-upload-text">Click or drag file to this area to upload</p>
    <p class="ant-upload-hint">Support .pdf .zip .rar .jpeg .jgp .png file types and no more than 5 file. File size should not over 30M.</p>
  </nz-upload>
  <nz-spin nzSimple *ngIf="isLoading" class="loading-spin"></nz-spin>
  <ng-content #customTemplate></ng-content>
</div>
