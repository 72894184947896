import { NgModule } from '@angular/core';

import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzNoAnimationModule } from 'ng-zorro-antd/core/no-animation';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';

@NgModule({
  exports: [
    NzTableModule,
    NzToolTipModule,
    NzRadioModule,
    NzTabsModule,
    NzLayoutModule,
    NzMenuModule,
    NzIconModule,
    NzPopoverModule,
    NzPopconfirmModule,
    NzInputModule,
    NzButtonModule,
    NzSpinModule,
    NzModalModule,
    NzTimelineModule,
    NzDatePickerModule,
    NzSelectModule,
    NzCheckboxModule,
    NzNoAnimationModule,
    NzDividerModule,
    NzDropDownModule,
    NzDrawerModule,
    NzAlertModule,
    NzInputNumberModule,
    NzFormModule,
  ],
})
export class NgZorroAntdModule {}
