import { find, findIndex, isNil, sortBy } from 'lodash';
import { tagClassNameMapper, TagConfig } from '@shared/small-mu-tag-combination/small-mu-tag-combination.component';
import { FILTER_WITHOUT_CONTRACT_STATUS } from '@constants/filterType';
import { OpportunityStatusLabel } from '@constants/opportunity';
import { DEFAULT_COLOR_DARK, MU_COLOR } from '@constants/muColor';

export const ORDER_BY_MU_NAME = [
  'China Auto',
  'China Retail',
  'China Mfg',
  'China BFSI',
  'China X',
  'China HK/Macau',
  'China EU',
  'China AUS',
  'China NA',
  'China SEA',
  'China East',
  'China North',
  'China South',
  'China Telecom Digital',
];

export const transformMuList = (muList: any[]) => {
  return sortBy(muList, (mu) => findIndex(ORDER_BY_MU_NAME, (item) => item === mu.name));
};

interface MuTagItem {
  withoutContractStatus: string[];
  isHighRisk?: boolean;
  noContract?: boolean;
  status?: boolean;
}

export const getTagConfig = <T extends MuTagItem>(item: T): TagConfig[] => {
  const tagConfig: TagConfig[] = [];
  if (item.withoutContractStatus?.includes(FILTER_WITHOUT_CONTRACT_STATUS.NO_APPLICATION)) {
    tagConfig.push({
      label: OpportunityStatusLabel.NO_APPLICATION,
      className: tagClassNameMapper[FILTER_WITHOUT_CONTRACT_STATUS.NO_APPLICATION],
    });
  }

  if (item.withoutContractStatus?.includes(FILTER_WITHOUT_CONTRACT_STATUS.APPLICATION_OVERDUE)) {
    tagConfig.push({
      label: OpportunityStatusLabel.APPLICATION_OVERDUE,
      className: tagClassNameMapper[FILTER_WITHOUT_CONTRACT_STATUS.APPLICATION_OVERDUE],
    });
  }

  return tagConfig;
};

export const getMuColor = (mu) => {
  const muCorrectColor = find(MU_COLOR, { mu });

  return !isNil(muCorrectColor) ? muCorrectColor.color : DEFAULT_COLOR_DARK;
};
