<app-search-input [page]="pageName"></app-search-input>
<div class="dashboard">
  <app-as-off-date [date]="overallDSO?.refreshDate"></app-as-off-date>
  <div class="overall-dso">
    <div
      class="tips-area"
      [nzTooltipTitle]="overallToolTips"
      nzTooltipPlacement="left"
      nz-tooltip
      (nzTooltipVisibleChange)="onVisibleChange($event)"
    >
      <label>China DSO</label>
      <span>
        <app-dso-format dsoType="dso" [dsoValue]="overallDSO?.regionalDso"></app-dso-format>
      </span>
      <p class="dailyRR">{{ totalRR | currencyFormat : 'CNY' }}</p>
    </div>
  </div>
  <div class="tabs">
    <nz-tabset [nzSelectedIndex]="tabIndexService.index" (nzSelectedIndexChange)="changeTabIndex($event)">
      <nz-tab nzTitle="Market">
        <ng-template nz-tab>
          <app-mu-dashboard></app-mu-dashboard>
        </ng-template>
      </nz-tab>
      <nz-tab nzTitle="Account">
        <ng-template nz-tab>
          <app-account-list></app-account-list>
        </ng-template>
      </nz-tab>
      <nz-tab nzTitle="Opportunity">
        <ng-template nz-tab>
          <app-opportunity-list></app-opportunity-list>
        </ng-template>
      </nz-tab>
      <nz-tab nzTitle="Opportunity Owner">
        <ng-template nz-tab>
          <app-opportunity-owner-list></app-opportunity-owner-list>
        </ng-template>
      </nz-tab>
    </nz-tabset>
  </div>
  <app-info-tag (openDataTips)="openDataTips()"></app-info-tag>
</div>

<app-dashboard-data-tips [isDataTipsVisible]="isDataTipsVisible" (closeDataTips)="closeDataTips()"></app-dashboard-data-tips>

<ng-template #overallToolTips>
  <div class="overall-tooltip">
    <div class="column">
      <div>
        <label>Overall DSO</label>
        <app-dso-format dsoType="dso" [dsoValue]="overallDSO?.regionalDso"></app-dso-format>
      </div>
      <div>
        <label>Billed DSO</label>
        <app-dso-format dsoType="billed" [dsoValue]="overallDSO?.regionalBilledDso"></app-dso-format>
      </div>
      <div>
        <label>Unbilled DSO</label>
        <app-dso-format dsoType="unbilled" [dsoValue]="overallDSO?.regionalUnBilledDso"></app-dso-format>
      </div>
    </div>
    <div class="column">
      <div>
        <label>AR</label>
        <span>{{ overallDSO?.regionalArRevenue | currencyFormat : 'CNY' }}</span>
      </div>
      <div>
        <label>WIP</label>
        <span>{{ overallDSO?.regionalWipRevenue | currencyFormat : 'CNY' }}</span>
      </div>
      <div>
        <label>YTD Recognized Revenue</label>
        <span>{{ overallDSO?.regionalRecognizedRevenue | currencyFormat : 'CNY' }}</span>
      </div>
    </div>
  </div>
</ng-template>
