import { SearchList, SearchResponse } from '@interfaces/SearchResult';
import { Account } from '@interfaces/Account';
import { FILTER_STATUS_DEACTIVATED, FILTER_STATUS_ONGOING } from '@constants/filterType';
import { get, map } from 'lodash';
import { getMuColor } from '@utils/dso-mu';

export const search = (response: SearchResponse): SearchList => {
  const result = { mus: [], accounts: [], opportunities: [], opportunityOwners: [], legalDocuments: [] };

  result.mus = response.mus.map(({ id, name }) => ({
    id,
    name,
    status: FILTER_STATUS_ONGOING,
  }));

  result.accounts = map(response.accounts, ({ id, name, mu, hasChild }: Account) => ({
    id,
    name,
    mu: {
      name: mu,
      color: getMuColor(mu),
    },
    status: FILTER_STATUS_ONGOING,
    hasChild,
  }));

  result.opportunities = map(response.opportunities, ({ id, name, account, projectActive, totalArRevenue, totalWipRevenue }) => ({
    id,
    name,
    account: {
      id: get(account, 'id', undefined),
      name: get(account, 'name', undefined),
      color: getMuColor(get(account, 'mu', undefined)),
    },
    totalArRevenue,
    totalWipRevenue,
    projectActive,
    status: projectActive ? FILTER_STATUS_ONGOING : FILTER_STATUS_DEACTIVATED,
  }));

  result.opportunityOwners = map(response.opportunityOwners, ({ ownerName, ownerEmail }) => ({
    name: ownerName,
    id: ownerEmail,
    status: FILTER_STATUS_ONGOING,
  }));

  result.legalDocuments = map(response.legalDocuments, (document) => ({
    ...document,
    status: FILTER_STATUS_ONGOING,
  }));

  return result;
};
