import { Injectable } from '@angular/core';
import { findIndex, get } from 'lodash';
import { FEATURE_PERMISSION_WITH_FIXED_ORDER, ROLES } from '@constants/featurePermission';
import { TableRole } from '@interfaces/Acl';
import { formatRole } from '@utils/utils';

@Injectable({
  providedIn: 'root',
})
export class AclService {
  private permissionInfo;
  features = FEATURE_PERMISSION_WITH_FIXED_ORDER;

  updatePermissionInfo(permissionInfo) {
    this.permissionInfo = permissionInfo;
  }

  getRoles(): TableRole[] {
    return formatRole(get(this.permissionInfo, 'roles', null));
  }

  getUser() {
    return get(this.permissionInfo, 'user', null);
  }

  getCurrentUserEmail() {
    return this.getUser().email;
  }

  private getPermissions() {
    return get(this.permissionInfo, 'permissions', null);
  }

  isPermission(featureAccess) {
    return findIndex(this.getPermissions(), { name: featureAccess }) > -1;
  }

  hasAdminRole() {
    return !!this.getRoles().find((role) => role.name === ROLES.ROLE_ADMIN);
  }

  hasDefaultPMRole() {
    return !!this.getRoles().find((role) => role.name === ROLES.ROLE_DEFAULT_PM);
  }

  hasReuploadAndDownloadAcceptanceMaterialPermission() {
    return !!this.getPermissions().find(
      (permission) => permission.name === FEATURE_PERMISSION_WITH_FIXED_ORDER.milestoneAcceptanceMaterialReuploadAndDownload,
    );
  }
}
