import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { includes, isEmpty, isEqual, toNumber } from 'lodash';
import { Invoice } from '../../../../interfaces/Invoice';
import { FLOATING_NUMBER_PATTERN, INTEGER_PATTERN } from '../../../../constants/regexp-pattern';
import { badDebtStatus } from '../../../../constants/invoiceStatus';
import { InvoiceApi } from '../../../../apis/invoice.api';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AclService } from '../../../../services/acl.service';
import { ACTION_EDIT, CATEGORY_DETAIL_PAGE } from '../../../../constants/matomo';
import { MatomoTracker } from 'ngx-matomo';
import { DateType } from '../../../../constants/dateFormat';

@Component({
  selector: 'app-bad-debt',
  templateUrl: './bad-debt.component.html',
  styleUrls: ['./bad-debt.component.scss'],
})
export class BadDebtComponent implements OnInit, OnDestroy {
  @Input() invoice: Invoice;
  @Input() opportunityName: string;
  @Output() badDebt = new EventEmitter<Invoice>();
  dateType = DateType;
  badDebtAmount: string | number;
  badDebtDescription: string;
  popoverTrigger = 'click';
  showPopover = false;
  isRequesting = false;
  originalBadDebt: string;
  removeTrigger = 'null';
  private unsubscribe = new Subject();

  constructor(private invoiceApi: InvoiceApi, public aclService: AclService, private matomoTracker: MatomoTracker) {}

  ngOnInit() {
    const amount = this.invoice.badDebtAmount;
    this.originalBadDebt = amount ? toNumber(amount).toFixed(2) : null;
    this.badDebtAmount = this.originalBadDebt;
    this.badDebtDescription = this.invoice.badDebtDescription;
    this.popoverTrigger = includes(badDebtStatus, this.invoice.invoiceStatus) ? 'click' : 'null';
    this.removeTrigger = amount <= 0 || !this.aclService.isPermission(this.aclService.features.invoiceEditBadDebt) ? 'null' : 'click';
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onInputAmount(value: string) {
    let formatValue = value
      .replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
      .replace(/[A-Za-z]/g, '')
      .replace(/[`~!@$%^&*()_+\-=[\]{}|;:'"<,>#?/\\]/, '');
    if (!new RegExp(/[.]/).exec(value)) {
      formatValue = value.slice(0, 10);
    } else {
      formatValue = value.slice(0, 13);
    }

    this.badDebtAmount = toNumber(formatValue);
  }

  onBlur(value: string) {
    let formatValue = '';
    if (!isEmpty(value) && toNumber(value) <= 0) {
      formatValue = '0.01';
    }
    if (INTEGER_PATTERN.exec(value) || FLOATING_NUMBER_PATTERN.exec(value)) {
      formatValue = toNumber(value).toFixed(2);
    }
    if (toNumber(formatValue) > this.invoice.maxBadDebtAmount) {
      formatValue = this.invoice.maxBadDebtAmount.toFixed(2);
    }
    this.badDebtAmount = formatValue;
  }

  save() {
    if (this.aclService.isPermission(this.aclService.features.invoiceEditBadDebt)) {
      const notChanged =
        isEqual(this.badDebtAmount, this.originalBadDebt) && isEqual(this.badDebtDescription, this.invoice.badDebtDescription);
      if (notChanged) {
        this.showPopover = false;

        return;
      }
      this.isRequesting = true;
      this.invoiceApi
        .updateBadDebt(this.invoice.id, toNumber(this.badDebtAmount), this.badDebtDescription)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          this.updateInvoice();
          this.isRequesting = false;
          this.matomoTracker.trackEvent(CATEGORY_DETAIL_PAGE, ACTION_EDIT, `Edit Bad Debt for  ${this.opportunityName}`);
        });
    }
  }

  deleteBadDebt() {
    if (this.invoice.badDebtAmount && this.aclService.isPermission(this.aclService.features.invoiceEditBadDebt)) {
      this.invoiceApi
        .deleteBadDebt(this.invoice.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          this.badDebtAmount = null;
          this.badDebtDescription = null;
          this.updateInvoice();
          this.matomoTracker.trackEvent(CATEGORY_DETAIL_PAGE, ACTION_EDIT, `Edit Bad Debt for  ${this.opportunityName}`);
        });
    }
  }

  cancel() {
    if (this.aclService.isPermission(this.aclService.features.invoiceEditBadDebt)) {
      this.badDebtAmount = this.originalBadDebt;
      this.badDebtDescription = this.invoice.badDebtDescription;
      this.showPopover = false;
    }
  }

  private updateInvoice() {
    const { badDebtUpdateDate } = this.invoice;
    const isDebtChange = !isEqual(this.originalBadDebt, this.badDebtAmount);
    const invoice = {
      ...this.invoice,
      badDebtAmount: toNumber(this.badDebtAmount),
      badDebtUpdateDate: isDebtChange ? new Date().toISOString() : badDebtUpdateDate,
      badDebtDescription: this.badDebtDescription,
    };
    this.badDebt.emit(invoice);
  }
}
