<div class="contract-information-border">
  <div class="header-title contract-title">
    <img src="/assets/svg/contract.svg" alt="" />
    <span class="blue size16">Legal Documents</span>
  </div>
  <div class="legal-documents-info information-detail">
    <div class="contract-info" *ngIf="legalDocumentsInfo?.withoutPoAmount !== null">
      <label>Amount Without Contract/PO</label>
      <span>{{ legalDocumentsInfo?.withoutPoAmount | currencyFormat : opportunityDetail?.currency : false }}</span>
    </div>
    <div>
      <label class="total-amount-label">Total Amount</label>
      <div class="total-amount-items">
        <div class="contract-info">
          <label>MSA</label>
          <span>
            {{
              legalDocumentsInfo?.legalDocumentTotalAmount?.amountOfMSA
                | currencyFormat : legalDocumentsInfo?.legalDocumentTotalAmount?.currency : false
            }}
          </span>
        </div>
        <div class="contract-info">
          <label>SOW</label>
          <span>
            {{
              legalDocumentsInfo?.legalDocumentTotalAmount?.amountOfSOW
                | currencyFormat : legalDocumentsInfo?.legalDocumentTotalAmount?.currency : false
            }}
          </span>
        </div>
        <div class="contract-info">
          <label>Service Contract</label>
          <span>
            {{
              legalDocumentsInfo?.legalDocumentTotalAmount?.amountOfServiceContract
                | currencyFormat : legalDocumentsInfo?.legalDocumentTotalAmount?.currency : false
            }}
          </span>
        </div>
        <div class="contract-info">
          <label>PO</label>
          <span>
            {{
              legalDocumentsInfo?.legalDocumentTotalAmount?.amountOfPO
                | currencyFormat : legalDocumentsInfo?.legalDocumentTotalAmount?.currency : false
            }}
          </span>
        </div>
      </div>
    </div>
    <div class="contract-note">
      <div class="note-container">
        <p>Note</p>
        <app-error-tooltip
          *ngIf="!aclService.isPermission(aclService.features.contractInfo)"
          [message]="'No permission to edit legal documents note'"
        ></app-error-tooltip>
      </div>
      <textarea
        maxlength="500"
        appMyAutofocus
        [disabled]="!aclService.isPermission(aclService.features.contractInfo)"
        (change)="editContractNote($event.target.value)"
        placeholder="comments here..."
        >{{ legalDocumentsInfo?.contractNote?.content }}</textarea
      >
    </div>
  </div>
  <div>
    <div class="list-header">
      <p class="list-title">Document List</p>
      <div class="add-item-container">
        <app-icon-text
          [className]="'icon-add'"
          [text]="'Add Item'"
          *ngIf="!disableAddItem && aclService.isPermission(aclService.features.contractInfo)"
          (click)="showEditLegalDocumentModal = true"
        ></app-icon-text>
      </div>
    </div>
    <nz-table
      #legalDocumentsTable
      class="legal-document-table"
      [nzData]="legalDocumentsInfo?.opportunityLegalDocuments"
      *ngIf="legalDocumentsInfo?.opportunityLegalDocuments?.length > 0"
      [nzWidthConfig]="legalDocumentsColumnsWidth"
      [nzScroll]="{ x: '961px' }"
      [nzShowPagination]="false"
      [nzFrontPagination]="false"
    >
      <thead>
        <tr>
          <th nzLeft>Reference No. Provided by Client</th>
          <th>Document Name</th>
          <th>Signed Legal Entity</th>
          <th nzShowSort [nzSortFn]="sortByDocumentType">Document Type</th>
          <th nzShowSort [nzSortFn]="sortByDate">Date Received</th>
          <th>Allocate From</th>
          <th>Allocate To</th>
          <th>Currency</th>
          <th>Allocated Amount (Excluding Tax)</th>
          <th>Document Format</th>
          <th nzRight class="operation-header-col">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of legalDocumentsTable.data" [class.highlight]="!item.contractReceivedDate">
          <td nzLeft>
            <i *ngIf="!item.contractReceivedDate" class="highlight-tag" nz-icon nzType="caret-right" nzTheme="fill"></i>
            {{ item.referenceNumber | emptyFormat }}
          </td>
          <td>{{ item.documentName | emptyFormat }}</td>
          <td>{{ item.signedLegalEntity }}</td>
          <td>{{ DocumentTypeLabel[item.documentType] }}</td>
          <td>{{ item.contractReceivedDate | emptyFormat }}</td>
          <td>
            <span>{{ item.allocatedFrom | emptyFormat }}</span>
          </td>
          <td>
            <span>{{ item.allocatedTo | emptyFormat }}</span>
          </td>
          <td>
            <span>{{ item.currency | emptyFormat }}</span>
          </td>
          <td>
            <span>{{ item.allocatedAmount | currencyFormat : item.currency : false }}</span>
          </td>
          <td>
            <ng-container *ngIf="item.documentFormat?.length > 0; else noDocumentFormat">
              <span class="document-format-item" *ngFor="let formatItem of item.documentFormat">{{ formatItem }}</span>
            </ng-container>
            <ng-template #noDocumentFormat>--</ng-template>
          </td>
          <td nzRight class="operation-col">
            <i
              *ngIf="aclService.isPermission(aclService.features.contractInfo)"
              nz-icon
              nz-tooltip
              nzType="svg:edit-no-circle"
              class="action-icon edit-icon circle-icon-wrapper"
              (click)="clickEditBtn(item)"
              nzTooltipOverlayClassName="custom-tooltip"
              nzTooltipTitle="Edit"
              nzTooltipPlacement="bottomLeft"
            ></i>
            <a
              class="google-drive-link"
              *ngIf="item.googleDriveLink"
              target="_blank"
              [href]="item.googleDriveLink"
              referrerpolicy="no-referrer"
              nz-tooltip
              nzTooltipOverlayClassName="custom-tooltip"
              nzTooltipTitle="Open Link"
              nzTooltipPlacement="bottomLeft"
            >
              <i nz-icon nzType="svg:external-link" class="action-icon circle-icon-wrapper"></i>
            </a>
            <i
              *ngIf="item.note"
              nz-icon
              nzType="svg:comment"
              class="action-icon circle-icon-wrapper"
              nz-tooltip
              nzTooltipOverlayClassName="custom-tooltip legal-document-note"
              [nzTooltipTitle]="'Note: ' + item.note"
              [nzTooltipPlacement]="['bottomLeft', 'bottomRight']"
            ></i>
          </td>
        </tr>
      </tbody>
    </nz-table>
    <div class="no-contract-info" *ngIf="legalDocumentsInfo?.opportunityLegalDocuments?.length === 0">
      <span>No Data Found</span>
    </div>
  </div>
</div>

<app-add-legal-document-dialog
  *ngIf="showEditLegalDocumentModal"
  [legalDocumentId]="currentLegalDocumentId"
  [defaultOpportunity]="opportunityDetail"
  (closeDialog)="closeDialog($event)"
></app-add-legal-document-dialog>
