import { Injectable } from '@angular/core';
import { SortMapping } from '../interfaces/SortMapping';

@Injectable({
  providedIn: 'root',
})
export class SortMappingService {
  private sortMapping: any = {};
  private hashMap: { [key: string]: any } = {};

  setSort(
    hashMapKey: 'account' | 'opportunity' | 'invoice' | 'milestone' | 'without-po' | 'owner' | 'high-risk' | 'legal-documents',
    sort: SortMapping,
  ) {
    this.sortMapping = sort;
    this.hashMap[hashMapKey] = this.sortMapping;
  }

  getSort(hashMapKey: 'account' | 'opportunity' | 'invoice' | 'milestone' | 'without-po' | 'owner' | 'high-risk' | 'legal-documents') {
    return this.hashMap[hashMapKey] || {};
  }

  clear(hashMapKey: 'account' | 'opportunity' | 'invoice' | 'milestone' | 'without-po' | 'owner' | 'high-risk' | 'legal-documents') {
    this.hashMap[hashMapKey] = {};
  }
}
