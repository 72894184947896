import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { assign, get, includes, isEmpty, isEqual, isNil, last, map, omit, orderBy, set, startCase } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { DsoHistory, OpportunityDetail } from '@interfaces/OpportunityDetail';
import { EchartsConfig } from '@interfaces/EchartsConfig';
import { getBarChartOption, orderDsoHistory } from '@utils/dso-history';
import { FIX_BID_TYPE, TIME_MATERIAL } from '@constants/currency';
import { MilestoneApi } from '@apis/milestone.api';
import { DSOMilestone } from '@interfaces/Milestone';
import { environment } from '@environments/environment';
import { NoteType } from '@constants/note';
import { NoteService } from '@services/note.service';
import { NotesApi } from '@apis/notes.api';
import { Subject } from 'rxjs';
import { OpportunityApi } from '@apis/opportunity.api';
import { UntypedFormBuilder } from '@angular/forms';
import { Invoice, InvoiceInfoWithTotal } from '@interfaces/Invoice';
import { InvoiceApi } from '@apis/invoice.api';
import { InvoiceOverduePageService } from '@services/invoice-overdue-page.service';
import { WithoutPoPageService } from '@services/without-po-page.service';
import { AclService } from '@services/acl.service';
import { MatomoTracker } from 'ngx-matomo';
import { ACTION_CLICK, ACTION_EDIT, CATEGORY_DATE_TIPS, CATEGORY_DETAIL_PAGE } from '@constants/matomo';
import { DateType } from '@constants/dateFormat';
import { completedStatus } from '@constants/invoiceStatus';
import { OpportunityStatusComponent } from '@view/dso-view/opportunity-detail/opportunity-status/opportunity-status.component';
import { SpinnerService } from '@services/spinner.service';
import { getInvoiceCountdown } from '@utils/utils';

@Component({
  selector: 'app-opportunity-detail',
  templateUrl: './opportunity-detail.component.html',
  styleUrls: ['./opportunity-detail.component.scss'],
})
export class OpportunityDetailComponent implements OnInit, OnDestroy {
  @ViewChild('amountInput') private amountInputEle: ElementRef;
  @ViewChild(OpportunityStatusComponent) opportunityStatusRef: OpportunityStatusComponent;
  dateType = DateType;
  opportunityDetail: OpportunityDetail = {} as OpportunityDetail;
  dsoHistory: DsoHistory[] = [];
  chartOption: EchartsConfig;
  milestones: DSOMilestone[] = [];
  invoices: Invoice[] = [];
  lastSubmitMilestones: DSOMilestone[] = [];
  invoicesTotalInfo: InvoiceInfoWithTotal = {};
  chartLoading = true;
  deactivationDate: string;
  deactivated: boolean;
  dailyRefreshDate = new Date().toDateString();
  salesFunnel = environment.salesFunnel;
  milestoneLoading = true;
  invoiceLoading = true;
  lastEditDate: string;
  isShowLastEdit = false;
  milestoneNoteType = NoteType.MILESTONE;
  invoiceNoteType = NoteType.INVOICE;
  isDataTipsVisible = false;
  isWithoutPO: boolean;
  pageName = CATEGORY_DETAIL_PAGE;
  private unsubscribe = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private noteService: NoteService,
    private milestoneApi: MilestoneApi,
    private notesApi: NotesApi,
    private opportunityApi: OpportunityApi,
    private invoiceApi: InvoiceApi,
    private formBuilder: UntypedFormBuilder,
    private invoiceOverduePageService: InvoiceOverduePageService,
    private withoutPoPageService: WithoutPoPageService,
    public aclService: AclService,
    private matomoTracker: MatomoTracker,
    private spinnerService: SpinnerService,
  ) {}

  ngOnInit() {
    this.getOpportunityDetail();
    this.initChartOption();
    this.getInvoices();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  shouldShowArAndWipAndRrInFunctionalCurrency() {
    return this.opportunityDetail.functionalCurrency && this.opportunityDetail.currency !== this.opportunityDetail.functionalCurrency;
  }

  isFixBidType() {
    return this.opportunityDetail.contractType === FIX_BID_TYPE;
  }

  isTimeMaterialType() {
    return this.opportunityDetail.contractType === TIME_MATERIAL;
  }

  openDataTips(): void {
    this.isDataTipsVisible = true;
    this.matomoTracker.trackEvent(CATEGORY_DATE_TIPS, ACTION_CLICK, `Check Data Specification of ${this.pageName}`);
  }

  closeDataTips(): void {
    this.isDataTipsVisible = false;
  }

  onChangeExpectedDueDate(editExpectedCollectionDueDate: string, id: number, index: number) {
    this.matomoTracker.trackEvent(this.pageName, ACTION_EDIT, `Edit Expected Due Date for ${startCase(this.opportunityDetail.name)}`);
    if (!isNil(editExpectedCollectionDueDate)) {
      if (!isEqual(this.invoices[index].expectedCollectionDueDate, editExpectedCollectionDueDate)) {
        this.invoiceApi
          .updateExpectedDueDate(id, editExpectedCollectionDueDate)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(() => {
            set(this.invoices[index], 'expectedCollectionDueDate', editExpectedCollectionDueDate);
            getInvoiceCountdown(this.invoices[index]);
            this.invoiceOverduePageService.updateExpectedCollectionDueDateAndCountdownByInvoiceId(
              id,
              editExpectedCollectionDueDate,
              this.invoices[index].countdown,
            );
          });
      }
    } else if (isNil(editExpectedCollectionDueDate) && !isNil(this.invoices[index].expectedCollectionDueDate)) {
      this.invoiceApi
        .deleteExpectedDueDate(id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          set(this.invoices[index], 'expectedCollectionDueDate', editExpectedCollectionDueDate);
          getInvoiceCountdown(this.invoices[index]);
          this.invoiceOverduePageService.updateExpectedCollectionDueDateAndCountdownByInvoiceId(
            id,
            editExpectedCollectionDueDate,
            this.invoices[index].countdown,
          );
        });
    }
  }

  updateBadDebt(invoice: Invoice) {
    this.invoices = map(this.invoices, (item) => {
      return item.id === invoice.id ? invoice : item;
    });
  }

  isInvoiceCompleted(invoiceStatus: string) {
    return !!includes(completedStatus, invoiceStatus);
  }

  private initChartOption() {
    this.chartOption = assign({}, getBarChartOption(this.dsoHistory));
    this.chartLoading = false;
  }

  private getOpportunityDetail() {
    this.route.data.pipe(takeUntil(this.unsubscribe)).subscribe(({ opportunityDetail }: { opportunityDetail: OpportunityDetail }) => {
      if (isEmpty(opportunityDetail.dsoHistory)) {
        this.opportunityDetail = omit(opportunityDetail, 'dsoHistory');
      } else {
        this.dsoHistory = orderDsoHistory(opportunityDetail);
        const { billedDso, unBilledDso, dso } = last(this.dsoHistory);
        this.opportunityDetail = assign(omit(opportunityDetail, 'dsoHistory'), { billedDso, unBilledDso, dso });
      }
      this.deactivationDate = get(opportunityDetail, 'deactivationDate');
      this.deactivated = !get(opportunityDetail, 'projectActive');
      this.isWithoutPO = !isNil(opportunityDetail.withoutPo);
      if (!this.isTimeMaterialType()) {
        if (this.showMilestoneEdit()) {
          this.getMilestones();
        }
        this.getMilestoneLastEditDate();
        this.getLastSubmitMilestone();
      }
    });
  }

  private getMilestones() {
    const projectId = this.opportunityDetail.id.toString();

    this.milestoneApi
      .getMilestones(projectId)
      .pipe(this.spinnerService.loading())
      .subscribe((data) => {
        this.milestoneLoading = false;
        this.milestones = data;
      });
  }

  private getMilestoneLastEditDate() {
    const projectId = this.opportunityDetail.sfProjectId;

    this.milestoneApi.getLastEditDate(projectId).subscribe(({ date }) => {
      this.lastEditDate = date;
      this.isShowLastEdit = !isEmpty(date);
    });
  }

  private getLastSubmitMilestone() {
    this.milestoneApi
      .getLastSubmitMilestones(this.opportunityDetail.id)
      .pipe(this.spinnerService.loading(), takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.lastSubmitMilestones = data as DSOMilestone[];
        this.lastSubmitMilestones.forEach((milestone) => {
          if (!isEmpty(milestone.notes)) {
            this.noteService.storeNotes(NoteType.MILESTONE, milestone.notes);
          }
        });
      });
  }

  private getInvoices() {
    this.invoiceApi
      .getInvoicesInfo(this.opportunityDetail.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: InvoiceInfoWithTotal) => {
        const { invoices, ...invoicesTotalInfo } = data;
        this.invoices = orderBy(invoices, ['invoiceSentDate'], ['asc']);
        this.invoicesTotalInfo = invoicesTotalInfo;
        this.invoiceLoading = false;
        this.invoices.forEach((invoice) => {
          if (!isEmpty(invoice.notes)) {
            this.noteService.storeNotes(NoteType.INVOICE, invoice.notes);
          }
        });
      });
  }

  async handleMilestoneChanged() {
    this.router.navigated = false;
    await this.router.navigate([this.router.url]);
  }

  showSubmittedMilestone() {
    return (
      this.aclService.hasAdminRole() ||
      !(this.aclService.hasDefaultPMRole() && this.findOpportunityPmEmails().includes(this.aclService.getCurrentUserEmail()))
    );
  }

  showMilestoneEdit() {
    return (
      this.aclService.hasAdminRole() ||
      (this.aclService.hasDefaultPMRole() && this.findOpportunityPmEmails().includes(this.aclService.getCurrentUserEmail()))
    );
  }

  findOpportunityPmEmails() {
    return [this.opportunityDetail.projectManagerEmail, this.opportunityDetail.delegatedProjectManagerEmail];
  }
}
