import { Component } from '@angular/core';
import { ACTION_CLICK, CATEGORY_NAVIGATION_TOP } from '@constants/matomo';
import { MatomoTracker } from 'ngx-matomo';

@Component({
  selector: 'app-contract-information',
  templateUrl: './contract-information.component.html',
  styleUrls: ['./contract-information.component.scss'],
})
export class ContractInformationComponent {
  constructor(private matomoTracker: MatomoTracker) {}

  handleMatomoClickEvent(name: string) {
    this.matomoTracker.trackEvent(CATEGORY_NAVIGATION_TOP, ACTION_CLICK, name);
  }
}
