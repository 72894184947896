import { Inject, Injectable, NgModule } from '@angular/core';
import { LayOutComponent } from './lay-out/lay-out.component';
import { DsoPageComponent } from './dso-page/dso-page.component';
import { OpportunityDetailComponent } from './opportunity-detail/opportunity-detail.component';
import { OpportunityDetailResolveService } from '@services/opportunity-detail-resolve.service';
import { MilestonePageComponent } from './milestone-page/milestone-page.component';
import { ActivatedRouteSnapshot, CanActivate, RouterModule, RouterStateSnapshot, Routes, RunGuardsAndResolvers } from '@angular/router';
import { OKTA_AUTH, OktaAuthGuard } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { WithoutContractPageComponent } from './without-contract-page/without-contract-page.component';
import { InvoicePageComponent } from './invoice-page/invoice-page.component';
import { RoleAccessComponent } from './role-access/role-access.component';
import { RoleResolveService } from '@services/role-resolve.service';
import { HighRiskPageComponent } from './high-risk-page/high-risk-page.component';
import { LegalDocumentsPageComponent } from '@view/dso-view/legal-documents-page/legal-documents-page.component';
import { AclService } from '@services/acl.service';
import { AclApi } from '@apis/acl.api';
import { SpinnerService } from '@services/spinner.service';
import { isEmpty } from 'lodash';
import { OpportunityPageComponent } from '@view/dso-view/opportunity-page/opportunity-page.component';

const roleRoute = {
  path: 'role',
  component: RoleAccessComponent,
  resolve: {
    roleAndPermissions: RoleResolveService,
  },
};

const opportunityDetailRoute = {
  path: 'opportunity/:id',
  component: OpportunityDetailComponent,
  runGuardsAndResolvers: 'always' as RunGuardsAndResolvers,
  resolve: {
    opportunityDetail: OpportunityDetailResolveService,
  },
};

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private oktaGuard: OktaAuthGuard,
    private aclService: AclService,
    private aclApi: AclApi,
    private spinnerService: SpinnerService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const isAuth = await this.oktaAuth.isAuthenticated();
    const accessToken = this.oktaAuth.getAccessToken();
    if (!isAuth || !accessToken) {
      sessionStorage.removeItem('permission');
    } else {
      if (isEmpty(this.aclService.getUser())) {
        const permission = await this.aclApi.getUserAcl().pipe(this.spinnerService.loading()).toPromise();
        this.aclService.updatePermissionInfo(permission);
        sessionStorage.setItem('permission', JSON.stringify(permission));
      }
    }
    sessionStorage.setItem('redirectUrl', state.url);
    return this.oktaGuard.canActivate(route, state);
  }
}

const routes: Routes = [
  {
    path: 'dso',
    component: LayOutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/dso/opportunity',
      },
      {
        path: 'opportunity',
        children: [
          {
            path: '',
            component: OpportunityPageComponent,
          },
          opportunityDetailRoute,
          roleRoute,
        ],
      },
      {
        path: 'dashboard',
        children: [
          {
            path: '',
            component: DsoPageComponent,
          },
          opportunityDetailRoute,
          roleRoute,
        ],
      },
      {
        path: 'milestone',
        children: [
          {
            path: '',
            component: MilestonePageComponent,
          },
          opportunityDetailRoute,
          roleRoute,
        ],
      },
      {
        path: 'po',
        children: [
          {
            path: '',
            component: WithoutContractPageComponent,
          },
          opportunityDetailRoute,
          roleRoute,
        ],
      },
      {
        path: 'invoice',
        children: [
          {
            path: '',
            component: InvoicePageComponent,
          },
          opportunityDetailRoute,
          roleRoute,
        ],
      },
      {
        path: 'risk-project',
        children: [
          {
            path: '',
            component: HighRiskPageComponent,
          },
          opportunityDetailRoute,
          roleRoute,
        ],
      },
      {
        path: 'legal-documents',
        children: [
          {
            path: '',
            component: LegalDocumentsPageComponent,
          },
          opportunityDetailRoute,
          roleRoute,
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DsoViewRoutingModule {}
