import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HighRiskOpportunityPageService } from '@services/high-risk-opportunity-page.service';
import { HighRiskOpportunityApi } from '@apis/high-risk-opportunity.api';
import { ToastMessageService } from '@services/toast-message.service';
import { AclService } from '@services/acl.service';
import { HIGH_RISK_CATEGORY_MAP } from '@constants/highRiskCategory';

@Component({
  selector: 'app-high-risk-tag',
  templateUrl: './high-risk-tag.component.html',
  styleUrls: ['./high-risk-tag.component.scss'],
})
export class HighRiskTagComponent implements OnInit {
  @Input() isHighRisk: boolean;
  @Input() opportunityId: number;
  @Input() riskCategories: string[];
  @Output() riskLevelChanged = new EventEmitter<boolean>();

  currentHighRisk = false;
  hasHighRiskTagEditPermission = false;
  showAllTagClearWarn = false;
  checkOptions: any = [
    { label: 'Without Contract', value: 'OPPORTUNITY_WITHOUT_CONTRACT', checked: false },
    { label: 'Milestone Overdue', value: 'MILESTONE_OVERDUE', checked: false },
    { label: 'Billing Overdue', value: 'BILLING_OVERDUE', checked: false },
    { label: 'Invoice Overdue', value: 'INVOICE_OVERDUE', checked: false },
    { label: 'Other Invoice Risk', value: 'OTHER_INVOICE_RISK', checked: false },
    { label: 'Internal Control Related', value: 'INTERNAL_CONTROL_RELATED', checked: false },
    { label: 'Others', value: 'OTHERS', checked: false },
  ];
  selectedCategoryOptions: any = [];

  constructor(
    private highRiskOpportunityApi: HighRiskOpportunityApi,
    private highRiskOpportunityPageService: HighRiskOpportunityPageService,
    public aclService: AclService,
    private toastMessageService: ToastMessageService,
  ) {}

  ngOnInit(): void {
    this.currentHighRisk = this.isHighRisk;
    this.initHighRiskTagEditPermission();
    this.updateCheckOptions();
  }

  initHighRiskTagEditPermission() {
    this.hasHighRiskTagEditPermission = this.isHighRisk
      ? this.aclService.isPermission(this.aclService.features.highRiskCreateAndDelete)
      : this.aclService.isPermission(this.aclService.features.highRiskCreateAndDelete);
  }

  updateCheckOptions() {
    this.checkOptions.forEach((option) => {
      option['checked'] = this.riskCategories.includes(option['value']);
    });
  }

  setIsHighRisk(value: boolean) {
    this.isHighRisk = value;
    this.currentHighRisk = this.isHighRisk;
    this.initHighRiskTagEditPermission();
  }

  onPopconfirmVisibleChange(visible: boolean) {
    if (visible) {
      this.currentHighRisk = true;
    } else {
      this.handleCancelConfirm();
    }
  }

  handleBeforeUpdateConfirm = () => {
    this.selectedCategoryOptions = this.getCurrentSelectedCategoryOptions();
    if (this.riskCategories.length === 0 && this.selectedCategoryOptions.length === 0) {
      this.handleCancelConfirm();
      return new Promise((resolve) => resolve(true));
    }
    return new Promise((resolve) => {
      this.highRiskOpportunityApi.saveHighRisk(this.opportunityId, this.selectedCategoryOptions).subscribe(() => {
        this.toastMessageService.success('Update risk project successfully');
        this.riskCategories = this.selectedCategoryOptions;
        if (this.selectedCategoryOptions.length > 0) {
          this.setIsHighRisk(true);
        } else {
          this.setIsHighRisk(false);
        }
        this.highRiskOpportunityPageService.updateHighRiskCategory();
        this.showAllTagClearWarn = false;
        resolve(true);
      });
    });
  };

  handleCancelConfirm() {
    this.updateCheckOptions();
    this.currentHighRisk = this.isHighRisk;
    this.showAllTagClearWarn = false;
  }

  handleCheckBoxChange() {
    this.selectedCategoryOptions = this.getCurrentSelectedCategoryOptions();
    this.showAllTagClearWarn = this.riskCategories.length > 0 && this.selectedCategoryOptions.length === 0;
  }

  private getCurrentSelectedCategoryOptions() {
    return this.checkOptions.filter((item) => item.checked).map((item) => item.value);
  }

  protected readonly HIGH_RISK_CATEGORY_MAP = HIGH_RISK_CATEGORY_MAP;
}
