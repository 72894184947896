import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AclApi } from '@apis/acl.api';
import { Role } from '@interfaces/Acl';
import { ESPeople, UserInfo } from '@interfaces/People';
import { SearchApi } from '@apis/search.api';
import { Subject } from 'rxjs';
import { debounceTime, filter, first, retry, switchMap, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { orderBy, remove } from 'lodash';
import { ToastMessageService } from '@services/toast-message.service';
import { ControlVisibleService } from '@services/control-visible.service';

@Component({
  selector: 'app-role-control',
  templateUrl: './role-control.component.html',
  styleUrls: ['./role-control.component.scss'],
})
export class RoleControlComponent implements OnInit, OnDestroy {
  @Input() role: Role;
  @Input() index = -1;
  @Output() emitDeleteRole = new EventEmitter();
  showRoleControl = false;
  visibleSearchList = false;
  inputValue: any;
  users: UserInfo[] = [];
  esPeople: ESPeople[] = [];
  loading = false;
  loadingSearch = false;
  private searchTextSub$ = new Subject();
  private unsubscribe = new Subject();

  constructor(
    private aclApi: AclApi,
    private searchApi: SearchApi,
    private toastMessageService: ToastMessageService,
    private controlVisibleService: ControlVisibleService,
  ) {
    this.subscribeInputChange();
  }

  ngOnInit(): void {
    this.triggerControlVisible();
  }

  private subscribeInputChange(): void {
    this.searchTextSub$
      .pipe(
        filter((input: any) => input.length >= 2),
        debounceTime(500),
        switchMap((input: any) => {
          this.visibleSearchList = true;
          this.loadingSearch = true;

          return this.searchApi.getSearchResponseByPeople(input);
        }),
        retry(3),
        takeUntil(this.unsubscribe),
      )
      .subscribe(
        (response: ESPeople[]) => {
          this.loadingSearch = false;
          this.esPeople = response;
        },
        () => {
          this.loadingSearch = false;
        },
      );
  }

  private triggerControlVisible() {
    this.controlVisibleService
      .listenVisible()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((index) => {
        this.showRoleControl = index === this.index;
        if (this.showRoleControl && this.role.id) {
          this.loading = true;
          this.initUsers();
        }
      });
  }

  handleSearchTextChange(inputText: string): void {
    this.searchTextSub$.next(inputText);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private initUsers() {
    this.aclApi
      .getUsers(this.role.id)
      .pipe(first())
      .subscribe(
        (data) => {
          this.users = orderBy(data, ['assignDate'], ['desc']);
          this.loading = false;
        },
        () => (this.loading = false),
      );
  }

  handleCancel() {
    this.showRoleControl = false;
  }

  onClickItem(esPeople: ESPeople) {
    this.visibleSearchList = false;
    this.inputValue = '';
    this.loading = true;
    this.addUserToRole(esPeople);
  }

  private addUserToRole(esPeople: ESPeople) {
    if (this.role.id) {
      this.addUserToExistedRole(esPeople.employeeId);
    } else {
      this.addUserToNewRole(esPeople);
    }
  }

  private addUserToNewRole(esPeople: ESPeople) {
    this.users.unshift({ email: esPeople.email, firstName: esPeople.name, employeeId: esPeople.employeeId });
    this.toastMessageService.success('Add user to role successfully');
    this.loading = false;
  }

  addUserToExistedRole(employeeIds: string, toast = true) {
    this.aclApi.addUserToRole(this.role.id, employeeIds).subscribe(
      () => {
        if (toast) {
          this.toastMessageService.success('Add user to role successfully');
        }
        this.initUsers();
      },
      (error: HttpErrorResponse) => {
        if (error.status === 422) {
          this.toastMessageService.warning('Cannot add the same user to role again!');
          this.loading = false;
        }
        if (error.status === 403) {
          this.loading = false;
        } else {
          this.toastMessageService.error('Failed to add user');
        }
      },
    );
  }

  deleteRole() {
    this.loading = true;
    if (this.role.id) {
      this.aclApi.deleteRole(this.role.id).subscribe(
        () => {
          this.showRoleControl = false;
          this.emitDeleteRole.emit();
        },
        () => {
          this.loading = false;
        },
      );
    } else {
      this.showRoleControl = false;
      this.emitDeleteRole.emit();
    }
  }

  deleteUser(employeeId: string) {
    this.loading = true;
    if (this.role.id) {
      this.aclApi.deleteUserToRole(this.role.id, employeeId).subscribe(
        () => {
          this.initUsers();
          this.toastMessageService.success('Deleted user from role successfully');
        },
        (error: HttpErrorResponse) => {
          if (error.status !== 403) {
            this.toastMessageService.error('Failed to delete user');
          }
          this.loading = false;
        },
      );
    } else {
      remove(this.users, { employeeId });
      this.toastMessageService.success('Deleted user from role successfully');
      this.loading = false;
    }
  }
}
