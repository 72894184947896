import { Injectable } from '@angular/core';
import { ToggleApi } from '../apis/toggle.api';
import { Toggle } from '../interfaces/Toggle';

@Injectable({
  providedIn: 'root',
})
export class ToggleService {
  private toggles: Toggle[];

  constructor(private toggleApi: ToggleApi) {}

  async getToggles() {
    if (!this.toggles) {
      this.toggles = await this.toggleApi.getToggles();
    }

    return this.toggles;
  }
}
