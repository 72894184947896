import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { InvoiceInfoWithTotal, InvoiceList, InvoiceTotal } from '@interfaces/Invoice';
import { SearchItem } from '@interfaces/Filter';
import { SortOrder } from '@interfaces/OpportunityDashboard';

@Injectable({ providedIn: 'root' })
export class InvoiceApi {
  constructor(private http: HttpClient) {}

  getInvoicesInfo = (opportunityId: number): Observable<InvoiceInfoWithTotal> => {
    return this.http.get<InvoiceInfoWithTotal>(`/invoice?opportunity-id=${opportunityId}`);
  };

  updateExpectedDueDate = (id: number, expectedCollectionDueDate: string): Observable<any> => {
    return this.http.put<any>(`/invoice/expected-collection-due-date/${id}`, {
      expectedCollectionDueDate,
    });
  };

  updateBadDebt = (id: number, badDebtAmount: number, badDebtDescription: string): Observable<any> => {
    return this.http.put<any>(`/invoice/bad-debt/${id}`, {
      badDebtAmount,
      badDebtDescription,
    });
  };

  deleteExpectedDueDate = (id: number): Observable<any> => {
    return this.http.delete<any>(`/invoice/expected-collection-due-date/${id}`);
  };

  deleteBadDebt = (id: number): Observable<any> => {
    return this.http.delete<any>(`/invoice/bad-debt/${id}`);
  };

  getInvoiceList = (page: number, size: number, filters: Array<SearchItem>, orders: SortOrder): Observable<InvoiceList> => {
    return this.http.post<InvoiceList>(`/invoice/overdue`, {
      page,
      size,
      filters,
      orders,
    });
  };

  getInvoiceTotalAmount = (filters: Array<SearchItem>): Observable<InvoiceTotal> => {
    return this.http.post<InvoiceTotal>(`/invoice/overdue-total-amount`, filters || []);
  };
}
