<app-icon-text
  nz-popover
  [className]="hasNote || hasSupportEmailInfo ? 'icon-check-blue' : 'icon-add'"
  (click)="clickPopover = true; clickPopoverBtn($event)"
  [nzPopoverTitle]="popoverTitle"
  nzPopoverTrigger="click"
  [(nzPopoverVisible)]="showPopover"
  (nzPopoverVisibleChange)="popoverVisibleChange($event)"
  [nzPopoverContent]="popoverContent"
  [nzPopoverPlacement]="popoverPlacement"
  [nzPopoverOverlayClassName]="'notesPopover'"
></app-icon-text>

<ng-template #popoverTitle>
  <div class="popover-title-section" (click)="clickPopover = true">
    <span class="note-title">Note</span>
    <app-icon-text
      [className]="'icon-add'"
      [text]="'Add Item'"
      (click)="addNewItem(disableEditNote)"
      [isDisabled]="disableAddItem || disableEditNote"
    ></app-icon-text>
  </div>
</ng-template>

<ng-template #popoverContent>
  <div class="popover-context-section" (click)="clickPopover = true" #popoverElement>
    <div class="popover-notes">
      <div *ngFor="let note of notes; index as i" class="milestone-note-container">
        <div class="item-delete">
          <span>{{ note.createdDate | date : dateType.DATE }}</span>
          <app-icon-text
            *ngIf="note.id && focusIndex === i"
            [className]="'icon-delete'"
            nz-popconfirm
            nzOkText="Yes"
            nzCancelText="Cancel"
            nzPopconfirmTitle="Are you sure to delete this note?"
            nzPopconfirmPlacement="bottom"
            nzPopconfirmOverlayClassName="confirmDeletePopover"
            [isDisabled]="disableEditNote"
            (nzOnConfirm)="deleteNote(note.id, i)"
          ></app-icon-text>
        </div>
        <div *ngIf="focusIndex !== i" class="text-content" (click)="focusNoteInput(i)">{{ note.content }}</div>
        <div *ngIf="focusIndex === i" class="milestone-note">
          <label>
            <textarea
              nz-input
              nzSize="small"
              (ngModelChange)="changeNotes($event)"
              [(ngModel)]="note.content"
              maxlength="500"
              [disabled]="disableEditNote"
              autofocus
            ></textarea>
          </label>
          <button nz-button nzType="default" [disabled]="disableEditNote" (click)="cancel(note.id, i)">Cancel</button>
          <button nz-button nzType="primary" (click)="save(note)" [disabled]="disableSave">
            <i nz-icon nzType="loading" *ngIf="isRequesting" class="centre"></i>
            <span *ngIf="!isRequesting">Save</span>
          </button>
          <div *ngIf="!hasNote && type !== highRiskNoteType" class="no-note-left">
            <img src="/assets/svg/error.svg" alt="error" />
            <span>No Note Left</span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="type === highRiskNoteType" class="popover-support-email-info">
      <div class="support-email-info-header">
        <span class="info-title">Support Email Info</span>
        <app-icon-text
          *ngIf="isEditSupportEmailInfo && hasSupportEmailInfo"
          [className]="'icon-delete'"
          nz-popconfirm
          nzOkText="Yes"
          nzCancelText="Cancel"
          nzPopconfirmTitle="Are you sure to delete the support email info?"
          nzPopconfirmPlacement="bottom"
          nzPopconfirmOverlayClassName="confirmDeletePopover"
          (nzOnConfirm)="saveSupportEmailInfo('')"
        ></app-icon-text>
      </div>
      <div class="support-email-info-content">
        <div *ngIf="!isEditSupportEmailInfo" class="text-content" (click)="!disableEditNote ? editSupportEmailInfo() : false">
          {{ supportEmailInfo }}
        </div>
        <div *ngIf="isEditSupportEmailInfo" class="edit-support-email-info">
          <label>
            <textarea
              nz-input
              nzSize="small"
              [disabled]="disableEditNote"
              (ngModelChange)="changeSupportEmailInfo($event)"
              [(ngModel)]="supportEmailInfo"
              maxlength="500"
              autofocus
            ></textarea>
          </label>
          <button nz-button nzType="default" (click)="cancelEditSupportEmail()" [disabled]="disableEditNote">Cancel</button>
          <button nz-button nzType="primary" (click)="saveSupportEmailInfo(supportEmailInfo)" [disabled]="disableSaveSupportEmailInfo">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
