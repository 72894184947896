import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HighRiskOpportunityList } from '@interfaces/HighRiskOpportunity';
import { SearchItem } from '@interfaces/Filter';
import { SortOrder } from '@interfaces/OpportunityDashboard';

@Injectable({ providedIn: 'root' })
export class HighRiskOpportunityApi {
  constructor(private http: HttpClient) {}

  getHighRiskOpportunityList = (
    page: number,
    size: number,
    filters: Array<SearchItem>,
    orders: SortOrder,
  ): Observable<HighRiskOpportunityList> => {
    return this.http.post<HighRiskOpportunityList>(`/risk-project/infos`, {
      page,
      size,
      filters,
      orders,
    });
  };

  saveHighRisk = (opportunityId: number, categories: string[]): Observable<any> => {
    return this.http.post<any>(`/risk-project`, {
      opportunityId,
      categories,
    });
  };

  removeHighRisk = (opportunityId: number): Observable<any> => {
    return this.http.delete<any>(`/risk-project/${opportunityId}`);
  };

  updateHighRiskOpportunityInfo = (id: number, category: string, saveValue: string): Observable<any> => {
    const body = {};
    body[category] = saveValue;

    return this.http.put<any>(`/risk-project/${id}`, body);
  };
}
