import { Component } from '@angular/core';

@Component({
  template:
    '<div class="error-page">\n' +
    '  <img src="/assets/svg/error-page.svg" alt="error-logo">\n' +
    '  <h1>Oops...Page Not Found</h1>\n' +
    '  <p>The page you visited does not exist.</p>\n' +
    '</div>',
  styleUrls: ['./error-page.component.scss'],
})
export class PageNotFoundComponent {}
