import { Component, Input } from '@angular/core';
import { DsoHistory } from '../../../../interfaces/OpportunityDetail';
import { EchartsConfig } from '../../../../interfaces/EchartsConfig';

@Component({
  selector: 'app-history-bar-chart',
  templateUrl: './history-bar-chart.component.html',
  styleUrls: ['./history-bar-chart.component.scss'],
})
export class HistoryBarChartComponent {
  @Input() data: DsoHistory[] = [];
  @Input() loading;
  @Input() chartOption: EchartsConfig;
}
