import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-table-no-data',
  templateUrl: './table-no-data.component.html',
  styleUrls: ['./table-no-data.component.scss'],
})
export class TableNoDataComponent {
  @Input() loadingFirst = false;
  @Input() type: 'list' | 'notification' | 'unreadNotification' | 'users' = 'list';
}
