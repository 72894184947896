<div>
  <app-search-input [page]="pageName" (changeSearch)="onSearchChange($event)"></app-search-input>
  <div class="opportunity-page">
    <div class="opportunity-header">
      <div class="title">
        <div>Opportunity</div>
        <div class="total-number">
          <span class="total">Total:</span>
          <span>{{ totalElements }}</span>
        </div>
      </div>
    </div>
    <app-advanced-filter
      (filterChanged)="handleFilterChanged($event)"
      (filterReset)="handleFilterReset($event)"
      (downloadEvent)="download()"
      (selectedFilterChanged)="handleSelectedCommonFilterChangeForMatomoRecord($event)"
    >
      <div class="hidden-filter">
        <nz-select
          [(ngModel)]="selectedMilestone"
          (ngModelChange)="handleSelectedAdvancedFilterChangeForMatomoRecord(NAME_FILTER_EVENT_MAPPING.totalAmountNotMatch)"
          [nzOptionHeightPx]="24"
          (ngModelChange)="handleOpportunityChange()"
          class="milestone-select gray-light-placeholder gap"
          nzPlaceHolder="Milestone Status"
          nzMode="multiple"
          nzShowArrow="true"
          nzMaxTagCount="1"
          data-testid="milestone-status-select"
        >
          <nz-option
            *ngFor="let milestoneFilterOption of milestoneFilterOptions"
            [nzLabel]="milestoneFilterOption.label"
            [nzValue]="milestoneFilterOption.value"
          ></nz-option>
        </nz-select>
        <nz-select
          [(ngModel)]="selectedIsOnGoing"
          (ngModelChange)="handleSelectedAdvancedFilterChangeForMatomoRecord(NAME_FILTER_EVENT_MAPPING.status)"
          [nzOptionHeightPx]="24"
          [disabled]="opportunityStatusDisabled"
          class="is-ongoing-select gray-light-placeholder gap"
          nzPlaceHolder="Is Ongoing"
          nzMode="multiple"
          nzShowArrow="true"
          nzMaxTagCount="1"
          data-testid="project-status-select"
        >
          <nz-option
            *ngFor="let ongoingOption of isOnGoingOptions"
            [nzLabel]="ongoingOption.label"
            [nzValue]="ongoingOption.value"
          ></nz-option>
        </nz-select>
      </div>
    </app-advanced-filter>
    <nz-table
      #opportunityDashboard
      [nzData]="opportunityList"
      [nzScroll]="{ y: 'calc(100vh - 238px)', x: '2305px' }"
      [nzFrontPagination]="false"
      [nzShowPagination]="false"
      [nzNoResult]="noData"
    >
      <thead>
        <tr>
          <th
            nzShowSort
            nzLeft
            class="account"
            nzWidth="145px"
            [nzSortOrder]="mapOfSort['account']"
            [nzSortFn]="true"
            (nzSortOrderChange)="sort($event, 'account')"
          >
            Account
          </th>
          <th
            nzShowSort
            nzLeft
            class="opportunity"
            nzWidth="145px"
            [nzSortOrder]="mapOfSort['opportunity']"
            [nzSortFn]="true"
            (nzSortOrderChange)="sort($event, 'opportunity')"
          >
            Opportunity
          </th>
          <th
            nzWidth="160px"
            nzShowSort
            [nzSortFn]="true"
            [nzSortOrder]="mapOfSort['projectCode']"
            (nzSortOrderChange)="sort($event, 'projectCode')"
          >
            Project Code
          </th>
          <th nzWidth="120px" nzShowSort [nzSortFn]="true" [nzSortOrder]="mapOfSort['market']" (nzSortOrderChange)="sort($event, 'market')">
            Market
          </th>
          <th
            nzWidth="125px"
            nzShowSort
            [nzSortFn]="true"
            [nzSortOrder]="mapOfSort['deliveryUnit']"
            (nzSortOrderChange)="sort($event, 'deliveryUnit')"
          >
            Delivery Unit
          </th>
          <th
            nzWidth="100px"
            nzShowSort
            [nzSortFn]="true"
            [nzSortOrder]="mapOfSort['startDate']"
            (nzSortOrderChange)="sort($event, 'startDate')"
          >
            Start Date
          </th>
          <th
            nzWidth="100px"
            nzShowSort
            [nzSortFn]="true"
            [nzSortOrder]="mapOfSort['endDate']"
            (nzSortOrderChange)="sort($event, 'endDate')"
          >
            End Date
          </th>
          <th
            nzWidth="130px"
            nzShowSort
            [nzSortFn]="true"
            [nzSortOrder]="mapOfSort['contractType']"
            (nzSortOrderChange)="sort($event, 'contractType')"
          >
            Contract Type
          </th>
          <th
            nzWidth="120px"
            nzShowSort
            [nzSortFn]="true"
            [nzSortOrder]="mapOfSort['contractAmount']"
            (nzSortOrderChange)="sort($event, 'contractAmount')"
          >
            Opportunity Amount
          </th>
          <th
            nzWidth="120px"
            nzShowSort
            [nzSortFn]="true"
            [nzSortOrder]="mapOfSort['totalMilestoneAmount']"
            (nzSortOrderChange)="sort($event, 'totalMilestoneAmount')"
          >
            Milestone Total Amount
          </th>
          <th
            nzWidth="120px"
            class="RR"
            nzShowSort
            [nzSortOrder]="mapOfSort['totalRecognizedRevenue']"
            [nzSortFn]="true"
            (nzSortOrderChange)="sort($event, 'totalRecognizedRevenue')"
          >
            Recognized Revenue
          </th>
          <th
            nzWidth="120px"
            nzShowSort
            [nzSortOrder]="mapOfSort['totalArRevenue']"
            [nzSortFn]="true"
            (nzSortOrderChange)="sort($event, 'totalArRevenue')"
          >
            AR
          </th>
          <th
            nzWidth="120px"
            nzShowSort
            [nzSortOrder]="mapOfSort['totalWipRevenue']"
            [nzSortFn]="true"
            (nzSortOrderChange)="sort($event, 'totalWipRevenue')"
          >
            WIP
          </th>
          <th
            class="opportunity-owner"
            nzWidth="120px"
            nzShowSort
            [nzSortOrder]="mapOfSort['ownerName']"
            [nzSortFn]="true"
            (nzSortOrderChange)="sort($event, 'ownerName')"
          >
            Opportunity Owner
          </th>
          <th
            nzShowSort
            nzWidth="120px"
            [nzSortOrder]="mapOfSort['projectManagerName']"
            [nzSortFn]="true"
            (nzSortOrderChange)="sort($event, 'projectManagerName')"
          >
            Project Manager
          </th>
          <th
            nzShowSort
            nzWidth="140px"
            [nzSortOrder]="mapOfSort['delegatedProjectManagerName']"
            [nzSortFn]="true"
            (nzSortOrderChange)="sort($event, 'delegatedProjectManagerName')"
          >
            Delegated Project Manager
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of opportunityList">
          <td nzLeft colspan="2">
            <app-small-mu-tag-combination
              [opportunityId]="item?.id"
              [opportunityName]="item?.name"
              [muName]="item?.market"
              [accountName]="item?.account?.name"
              [isDeactivated]="isDeactivated(item?.status)"
              [isHighRisk]="item?.isHighRisk"
              [riskCategories]="item?.riskCategories"
              [page]="pageName"
            ></app-small-mu-tag-combination>
          </td>
          <td>
            <span [class.deactivated-gray]="isDeactivated(item.status)">{{ item?.projectCode | emptyFormat }}</span>
          </td>
          <td class="opportunity-item">
            <span [class.deactivated-gray]="isDeactivated(item.status)">{{ item?.market | emptyFormat }}</span>
          </td>
          <td class="break-all">
            <span [class.deactivated-gray]="isDeactivated(item.status)">{{ item?.deliveryUnit | emptyFormat }}</span>
          </td>
          <td class="break-all">
            <span [class.deactivated-gray]="isDeactivated(item.status)">{{ item?.startDate | emptyFormat }}</span>
          </td>
          <td class="break-all">
            <span [class.deactivated-gray]="isDeactivated(item.status)">{{ item?.endDate | emptyFormat }}</span>
          </td>
          <td class="break-all">
            <span [class.deactivated-gray]="isDeactivated(item.status)">{{ item?.contractType | emptyFormat }}</span>
          </td>
          <td class="break-all">
            <span [class.deactivated-gray]="isDeactivated(item.status)">{{ item?.contractAmount | currencyFormat : 'CNY' }}</span>
          </td>
          <td class="break-all">
            <span [class.deactivated-gray]="isDeactivated(item.status)">{{ item?.totalMilestoneAmount | currencyFormat : 'CNY' }}</span>
          </td>
          <td class="break-all">
            <span [class.deactivated-gray]="isDeactivated(item.status)">
              {{ item?.totalRecognizedRevenue | currencyFormat : 'CNY' }}
            </span>
          </td>
          <td class="break-all">
            <span [class.deactivated-gray]="isDeactivated(item.status)">
              {{ item?.totalArRevenue | currencyFormat : 'CNY' }}
            </span>
          </td>
          <td class="break-all">
            <span [class.deactivated-gray]="isDeactivated(item.status)">
              {{ item?.totalWipRevenue | currencyFormat : 'CNY' }}
            </span>
          </td>
          <td>
            <span [class.deactivated-gray]="isDeactivated(item.status)">
              {{ item?.ownerName | emptyFormat }}
            </span>
          </td>
          <td>
            <span [class.deactivated-gray]="isDeactivated(item.status)">
              {{ item?.projectManagerName | emptyFormat }}
            </span>
          </td>
          <td>
            <span [class.deactivated-gray]="isDeactivated(item.status)">
              {{ item?.delegatedProjectManagerName | emptyFormat }}
            </span>
          </td>
        </tr>
        <tr *ngIf="opportunityList?.length > 0">
          <td colspan="11" style="border-bottom: none; background-color: white">
            <app-table-loading-more [isLastPage]="isLastPage" [loadingMore]="loadingMore"></app-table-loading-more>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>

<ng-template #noData>
  <app-table-no-data [loadingFirst]="loadingFirst"></app-table-no-data>
</ng-template>
