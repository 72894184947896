import { Injectable } from '@angular/core';
import { InvoiceList } from '../interfaces/Invoice';
import { HighRiskOpportunityList } from '../interfaces/HighRiskOpportunity';
import { Observable, Subject } from 'rxjs';
import { Filter } from '../interfaces/Filter';

@Injectable({
  providedIn: 'root',
})
export class HighRiskOpportunityPageService {
  highRiskPageSubject = new Subject();
  highRiskPage: HighRiskOpportunityList = {};

  set(page: InvoiceList) {
    this.highRiskPage = page;
  }

  get() {
    return this.highRiskPage;
  }

  updateHighRiskCategory() {
    this.highRiskPageSubject.next();
  }

  listenHighRiskPage(): Observable<Filter> {
    return this.highRiskPageSubject.asObservable();
  }
}
