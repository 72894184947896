export enum RoleType {
  UHead = 'Head',
  TA = 'TA',
  Others = 'Others',
}

export const RoleTypeLabel = {
  [RoleType.UHead]: 'U Head',
  [RoleType.TA]: 'TA',
  [RoleType.Others]: 'Others',
};

export const ROLE_TYPE_OPTIONS = [
  { label: RoleTypeLabel[RoleType.UHead], value: RoleType.UHead },
  { label: RoleTypeLabel[RoleType.TA], value: RoleType.TA },
  { label: RoleTypeLabel[RoleType.Others], value: RoleType.Others },
];
