<div class="bar-chart">
  <p class="title">DSO History</p>
  <nz-spin class="absolute" *ngIf="loading"></nz-spin>
  <div class="absolute" *ngIf="!loading && data.length === 0">
    <img src="/assets/svg/no-data-found.svg" alt="No Data Found" />
    <p>No Data Found</p>
  </div>
  <div class="legend" *ngIf="!loading && data.length !== 0">
    <div class="legend-unbilled">
      <span class="normal"></span>
      <span class="abnormal"></span>
      <p>unbilled</p>
    </div>
    <div class="legend-billed">
      <span class="normal"></span>
      <span class="abnormal"></span>
      <p>billed</p>
    </div>
  </div>
  <div echarts [options]="chartOption" class="chart"></div>
</div>
