import { Injectable } from '@angular/core';
import { BehaviorSubject, MonoTypeOperatorFunction } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  isRunning = new BehaviorSubject<boolean>(false);
  requestCount = 0;

  run() {
    this.requestCount = this.requestCount + 1;
    this.isRunning.next(true);
  }

  stop() {
    if (this.requestCount > 0) {
      this.requestCount = this.requestCount - 1;
    }

    this.isRunning.next(this.requestCount > 0);
  }

  loading<T>(): MonoTypeOperatorFunction<T> {
    this.run();
    return finalize(() => this.stop());
  }
}
