<app-icon-text
  nz-popover
  className="icon-comment-blue"
  [isDisabled]="isDisabled"
  [nzPopoverTrigger]="isDisabled ? null : 'click'"
  nzPopoverPlacement="left"
  [nzPopoverTitle]="popoverTitle"
  [nzPopoverContent]="popoverContent"
  [nzPopoverOverlayClassName]="'notesPopover'"
  (nzPopoverVisibleChange)="handleVisibleChange($event)"
  data-testid="history-entry-icon"
></app-icon-text>

<ng-template #popoverTitle>
  <div class="popover-title-container">
    <span class="popover-title">Record History</span>
  </div>
</ng-template>

<ng-template #popoverContent>
  <div class="popover-content">
    <nz-spin nzSimple *ngIf="isLoading" class="loading-spin"></nz-spin>
    <div *ngIf="!isLoading" class="history-container">
      <div *ngFor="let history of milestoneHistories; let $index = index" class="milestone-history">
        <div class="history-title">
          <div class="left-part">
            <span>{{ formatDate(history.createTime) }}</span>
            <span *ngIf="$index === 0" class="status-tag orange-warning">New</span>
          </div>
          <span>{{ history.operatorEmployeeName }}</span>
        </div>
        <ng-container *ngFor="let field of getHistoryFields(history)">
          <div [ngClass]="{ 'field-container': true, 'multiple-line': field.isMultipleLine, highlight: isChanged(field, $index) }">
            <span class="field-label">{{ field.label }}</span>
            <span class="field-value">{{ field.value }}</span>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
