import { Pipe, PipeTransform } from '@angular/core';
import { isNull, isUndefined } from 'lodash';

@Pipe({
  name: 'addSign',
})
export class AddSignPipe implements PipeTransform {
  transform(value: number): string {
    if (isNull(value) || isUndefined(value)) {
      return null;
    }
    const result: string = value.toString();
    if (result === '0') {
      return result;
    } else if (result.startsWith('-')) {
      return result.replace('-', '- ');
    } else {
      return '+ ' + result;
    }
  }
}
