export const sorting = {
  ascend: 'ASC',
  descend: 'DESC',
};

export const DEFAULT_OPPORTUNITY_SORTING = { startDate: sorting.ascend };

export const DEFAULT_DASHBOARD_OPPORTUNITY_SORTING = { dso: sorting.descend };

export const DEFAULT_DASHBOARD_ACCOUNT_SORTING = { dso: sorting.descend };

export const DEFAULT_DASHBOARD_OWNER_SORTING = { dso: sorting.descend };

export const DEFAULT_MILESTONE_SORTING = { originOverdueDays: sorting.descend };

export const DEFAULT_INVOICE_SORTING = { countDown: sorting.descend };

export const DEFAULT_WITHOUT_PO_SORTING = { daysWithoutPo: sorting.descend };

export const DEFAULT_HIGH_RISK_OPPORTUNITY_SORTING = { opportunity: sorting.ascend };

export const DEFAULT_LEGAL_DOCUMENTS_SORTING = {};

export const filterMapping = {
  mu: 'muName',
  account: 'accountId',
  opportunity: 'opportunityId',
  owner: 'opportunityOwnerEmail',
  document: 'documentId',
};

export const MAX_INPUT_LENGTH = '121';

export const SORT_MAP_TYPE_ACCOUNT = 'account';
export const SORT_MAP_TYPE_OPPORTUNITY = 'opportunity';
export const SORT_MAP_TYPE_OWNER = 'owner';
export const SORT_MAP_TYPE_MILESTONE = 'milestone';
export const SORT_MAP_TYPE_INVOICE = 'invoice';
export const SORT_MAP_TYPE_LEGAL_DOCUMENTS = 'legal-documents';
export const SORT_MAP_TYPE_WITHOUT_PO = 'without-po';
export const SORT_MAP_TYPE_HIGH_RISK = 'high-risk';
