import { AfterViewChecked, Directive, ElementRef, Input } from '@angular/core';
import { beforeCompletedStatus, completedInvoiceStatus } from '../constants/invoiceStatus';
import { includes, isNil } from 'lodash';
import { COUNTDOWN_L1_INVOICE, COUNTDOWN_L2_INVOICE, COUNTDOWN_L3_INVOICE } from '../constants/countdown';

@Directive({
  selector: '[appInvoiceHighlight]',
})
export class InvoiceHighlightDirective implements AfterViewChecked {
  @Input() countDown: number;
  @Input() invoiceStatus: string;

  constructor(private el: ElementRef) {}

  ngAfterViewChecked(): void {
    if (includes(completedInvoiceStatus, this.invoiceStatus)) {
      this.el.nativeElement.style.opacity = 0.4;
    }
    if (includes(beforeCompletedStatus, this.invoiceStatus) && !isNil(this.countDown)) {
      this.el.nativeElement.classList.remove('highlight-range-1');
      this.el.nativeElement.classList.remove('highlight-range-2');
      this.el.nativeElement.classList.remove('highlight-range-3');
      if (this.countDown > COUNTDOWN_L3_INVOICE) {
        this.el.nativeElement.classList.add('highlight-range-3');
      } else if (this.countDown > COUNTDOWN_L2_INVOICE) {
        this.el.nativeElement.classList.add('highlight-range-2');
      } else if (this.countDown > COUNTDOWN_L1_INVOICE) {
        this.el.nativeElement.classList.add('highlight-range-1');
      }
    }
  }
}
