import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BackToDashboardService } from '@services/back-to-dashboard.service';
import { MatomoTracker } from 'ngx-matomo';
import { indexOf, isNil, keys } from 'lodash';

import { ACTION_BACK, NAME_DASHBOARD_DSO, NAME_DASHBOARD_INVOICE, NAME_DASHBOARD_MILESTONE, NAME_DASHBOARD_PO } from '@constants/matomo';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent implements OnInit, OnDestroy {
  @Input() page: string = null;

  disabled: boolean;
  private win: Window;
  backPageMap = {
    dashboard: NAME_DASHBOARD_DSO,
    po: NAME_DASHBOARD_PO,
    milestone: NAME_DASHBOARD_MILESTONE,
    invoice: NAME_DASHBOARD_INVOICE,
  };

  constructor(
    @Inject(DOCUMENT) private doc: Document,
    private backToDashboardService: BackToDashboardService,
    private matomoTracker: MatomoTracker,
  ) {}

  ngOnInit(): void {
    this.win = this.doc.defaultView;
    this.disabled = this.backToDashboardService.disable;
  }

  backToDashboard() {
    if (!this.disabled) {
      this.win.history.back();
      if (!isNil(this.page) && this.win.location.pathname.split('/').length >= 3) {
        const type = this.win.location.pathname.split('/')[2];
        if (indexOf(keys(this.backPageMap), type) > -1) {
          this.matomoTracker.trackEvent(this.page, ACTION_BACK, `Back to ${this.backPageMap[type]}`);
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.backToDashboardService.disable = true;
  }
}
