import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calculatePercentage',
})
export class CalculatePercentagePipe implements PipeTransform {
  transform(value: number): string {
    return Number((value * 100).toFixed(2)) + '%';
  }
}
