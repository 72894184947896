<div class="due-date-container">
  <button
    nz-button
    nzType="link"
    nz-popover
    [nzPopoverContent]="expectedMilestoneDueDatePopoverContent"
    nzPopoverTrigger="click"
    nzPopoverOverlayClassName="expected-milestone-due-date-popover"
    class="expected-milestone-due-date"
    [class.bold]="popoverVisible"
    [(nzPopoverVisible)]="popoverVisible"
    [disabled]="!aclService.isPermission(aclService.features.milestoneEditNote) || isInvoiceCompleted(invoiceStatus)"
  >
    {{ expectedMilestoneDueDate | date : dateType.DATE | emptyFormat }}
  </button>
</div>

<ng-template #expectedMilestoneDueDatePopoverContent>
  <nz-date-picker
    [(ngModel)]="newExpectedMilestoneDueDate"
    nzAllowClear="false"
    nzShowToday="false"
    nzFormat="yyyy-MM-dd"
    [nzBorderless]="true"
    [nzSuffixIcon]="null"
    nzInline
    #datePicker
  ></nz-date-picker>

  <div class="due-date-comment">
    <app-icon-text [className]="'icon-time'" [text]="'Today'" (click)="onClickToday()"></app-icon-text>

    <div class="due-date-comment-label">
      <span class="blue-icon"></span>
      <span class="required-icon">*</span>
      Comment
    </div>
    <div class="due-date-comment-text">
      <div class="new-expected-milestone-due-date">{{ newExpectedMilestoneDueDate | date : dateType.DATE }}</div>
      <label>
        <textarea rows="3" nz-input [(ngModel)]="comment" minlength="1" maxlength="150"></textarea>
      </label>
      <div [ngClass]="{ 'comment-count': true, 'comment-count-highlight': comment.length === 150 }">
        {{ comment.length }}
        /150
      </div>
    </div>
    <div class="expected-due-date-btns">
      <button
        nz-button
        nzType="primary"
        class="expected-due-date-btn submit-btn"
        [disabled]="disabledSubmit() || isLoading"
        (click)="onSubmit()"
      >
        <i nz-icon nzType="loading" *ngIf="isLoading"></i>
        <span>Submit</span>
      </button>
      <button nz-button nzType="default" class="expected-due-date-btn cancel-btn" (click)="onCancel()">Cancel</button>
    </div>
  </div>
</ng-template>
