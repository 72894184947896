import { Injectable } from '@angular/core';
import { WithoutPoPageData } from '../interfaces/WithoutPo';

@Injectable({
  providedIn: 'root',
})
export class WithoutPoPageService {
  withoutPoPage: WithoutPoPageData = {};

  set(page: WithoutPoPageData) {
    this.withoutPoPage = page;
  }

  get() {
    return this.withoutPoPage;
  }
}
