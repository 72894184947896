import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ToastMessageService {
  message = '';
  messageType: 'success' | 'info' | 'error' | 'warning';
  iconType = '';

  error(message: string) {
    this.message = message;
    this.messageType = 'error';
    this.iconType = 'exclamation-circle';
    this.clear();
  }

  success(message: string) {
    this.message = message;
    this.messageType = 'success';
    this.iconType = 'check-circle';
    this.clear();
  }

  warning(message: string) {
    this.message = message;
    this.messageType = 'warning';
    this.iconType = 'exclamation-circle';
    this.clear();
  }

  clear() {
    window.setTimeout(() => (this.message = ''), 3000);
  }
}
