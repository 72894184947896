<div class="due-date-container">
  <button
    nz-button
    nzType="link"
    nz-popover
    [nzPopoverContent]="expectedCollectionDueDatePopoverContent"
    [ngClass]="{ 'add-expected-collection-due-Date': !expectedCollectionDueDate }"
    nzPopoverTrigger="click"
    nzPopoverOverlayClassName="expected-collection-due-date-popover"
    class="expected-collection-due-date"
    [class.bold]="popoverVisible"
    [(nzPopoverVisible)]="popoverVisible"
    [disabled]="!aclService.isPermission(aclService.features.invoiceEditExpectedDueDate)"
    #dueDateButton
  >
    {{ expectedCollectionDueDate | date : dateType.DATE }}
  </button>
</div>

<ng-template #expectedCollectionDueDatePopoverContent>
  <nz-date-picker
    [(ngModel)]="expectedCollectionDueDate"
    nzAllowClear="false"
    nzFormat="yyyy-MM-dd"
    [nzDisabledDate]="disabledInvoiceDueDate"
    (ngModelChange)="onDateChange()"
    [nzRenderExtraFooter]="clearDate"
    [nzBorderless]="true"
    [nzSuffixIcon]="null"
    nzInline
    #datePicker
  ></nz-date-picker>
</ng-template>

<ng-template #clearDate>
  <app-icon-text [className]="'icon-delete'" [text]="'Clear the Date'" (click)="onClickClear()"></app-icon-text>
</ng-template>
