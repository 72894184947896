import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-item-border',
  templateUrl: './item-border.component.html',
  styleUrls: ['./item-border.component.scss'],
})
export class ItemBorderComponent implements OnChanges {
  @Input() title: string;
  @Input() data = [];
  @Input() pageSize = 4;
  @Input() loading = false;
  @Input() pageIndex = 0;
  @Output() pageChange = new EventEmitter<number>();
  pageNumber = 0;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data?.currentValue) {
      this.pageNumber = Math.ceil(this.data.length / this.pageSize);
    }
  }
}
