import { Filter, SearchItem } from '@interfaces/Filter';

export const FILTER_TYPE_EQUAL = 'EQUAL';
export const FILTER_TYPE_CUSTOM = 'CUSTOM';
export const FILTER_TYPE_GREATER_THAN = 'GREATER_THAN';
export const FILTER_TYPE_GREATER_THAN_EQUAL = 'GREATER_THAN_EQUAL';
export const FILTER_TYPE_LESS_THAN_EQUAL = 'LESS_THAN_EQUAL';
export const FILTER_TYPE_LIKE = 'LIKE';
export const FILTER_TYPE_IN = 'IN';

export const FILTER_STATUS_ONGOING = 'ONGOING';
export const FILTER_STATUS_DEACTIVATED = 'DEACTIVATED';

export const DEFAULT_DASHBOARD_FILTER: SearchItem = {
  name: 'status',
  value: FILTER_STATUS_ONGOING,
  type: FILTER_TYPE_CUSTOM,
};

export const FILTER_WITHOUT_CONTRACT_DAYS = {
  DAYS_LEVEL_1: { from: 0, to: 30 },
  DAYS_LEVEL_2: { from: 31, to: 55 },
  DAYS_LEVEL_3: { from: 56, to: 90 },
  DAYS_LEVEL_4: { from: 91, to: null },
};

export const FILTER_MILESTONE_OVERDUE_DAYS = {
  DAYS_LEVEL_1: { from: -14, to: 0 },
  DAYS_LEVEL_2: { from: 1, to: 30 },
  DAYS_LEVEL_3: { from: 31, to: 55 },
  DAYS_LEVEL_4: { from: 56, to: 90 },
  DAYS_LEVEL_5: { from: 91, to: null },
};

export const FILTER_INVOICE_OVERDUE_DAYS = {
  DAYS_LEVEL_1: { from: 1, to: 30 },
  DAYS_LEVEL_2: { from: 31, to: 55 },
  DAYS_LEVEL_3: { from: 56, to: 90 },
  DAYS_LEVEL_4: { from: 91, to: null },
};

export enum FILTER_WITHOUT_CONTRACT_STATUS {
  ALL = 'WITHOUT_CONTRACT',
  NO_APPLICATION = 'NO_APPLICATION',
  APPLICATION_OVERDUE = 'APPLICATION_OVERDUE',
}

export enum FILTER_MILESTONE_TOTAL_AMOUNT_STATUS {
  ALL = '',
  MILESTONE_TOTAL_AMOUNT_NOT_MATCHED = 'FIELDS_NOT_EQUAL',
  MILESTONE_NOT_SUBMITTED = 'MILESTONE_NOT_SUBMITTED',
}

export enum FILTER_INVOICE_STATUS {
  INVOICE_POSTED_TO_AR = 'Invoice Posted to AR',
  PART_PAID = 'Part Paid',
}

export const DEFAULT_FILTER_ITEM: Filter = {
  searchItem: {},
  status: FILTER_STATUS_ONGOING,
  countDown: {},
  withoutPoDays: {},
  withoutContractStatus: [],
};
