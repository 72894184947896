import { Subject } from 'rxjs';
import { filter, noop, values } from 'lodash';

export function AutoUnsubscribe(name = 'unsubscribe') {
  return function (component: { new (...args): any; prototype: any }) {
    const onInit = component.prototype.ngOnInit;
    component.prototype.ngOnInit = function (...args: any[]) {
      if (!this[name]) {
        this[name] = new Subject();
      }
      (onInit || noop).apply(this, ...args);
    };

    const onDestroy = component.prototype.ngOnDestroy;
    component.prototype.ngOnDestroy = function (...args: any[]) {
      (onDestroy || noop).apply(this, ...args);
      if (this[name] && this[name].next) {
        this[name].next();
      }

      filter(values(this), (value) => value instanceof Subject && !value.closed).forEach((value: Subject<any>) => value.complete());
    };
  };
}
