import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ESPeople } from '@interfaces/People';
import { SearchApi } from '@apis/search.api';
import { Subject } from 'rxjs';
import { debounceTime, filter, finalize, retry, switchMap, takeUntil } from 'rxjs/operators';
import { cloneDeep, isEmpty, map, remove } from 'lodash';
import { ToastMessageService } from '@services/toast-message.service';
import { ROLE_TYPE_OPTIONS } from '@constants/governanceUnitRoleType';
import { GovernanceUnitApi } from '@apis/governance-unit.api';
import { AddGovernanceUserRoleRequest, GovernanceUnitUser } from '@interfaces/GovernanceUnit';

@Component({
  selector: 'app-edit-governance-unit-dialog',
  templateUrl: './edit-governance-unit-dialog.component.html',
  styleUrls: ['./edit-governance-unit-dialog.component.scss'],
})
export class EditGovernanceUnitDialogComponent implements OnDestroy, OnInit {
  @Input() unitName: string;
  @Input() existUserInfos: GovernanceUnitUser[];
  @Output() closeDialog = new EventEmitter<boolean>();
  visibleSearchList = false;
  inputValue: any;
  users: GovernanceUnitUser[] = [];
  esPeople: ESPeople[] = [];
  loadingSearch = false;
  isSaving = false;
  isTableEmpty = false;
  confirmPopVisible = false;
  private searchTextSub$ = new Subject();
  private unsubscribe = new Subject();
  readonly ROLE_TYPE_OPTIONS = ROLE_TYPE_OPTIONS;

  constructor(
    private governanceUnitApi: GovernanceUnitApi,
    private searchApi: SearchApi,
    private toastMessageService: ToastMessageService,
  ) {
    this.subscribeInputChange();
  }
  ngOnInit() {
    this.users = cloneDeep(this.existUserInfos);
  }

  private subscribeInputChange(): void {
    this.searchTextSub$
      .pipe(
        filter((input: any) => input.length >= 2),
        debounceTime(500),
        switchMap((input: any) => {
          this.visibleSearchList = true;
          this.loadingSearch = true;

          return this.searchApi.getSearchResponseByPeople(input);
        }),
        retry(3),
        takeUntil(this.unsubscribe),
      )
      .subscribe(
        (response: ESPeople[]) => {
          this.loadingSearch = false;
          const usersIdList = map(this.users, (user) => user.employeeId);
          this.esPeople = response.filter((people) => !usersIdList.includes(people.employeeId));
        },
        () => {
          this.loadingSearch = false;
        },
      );
  }

  handleSearchTextChange(inputText: string): void {
    this.searchTextSub$.next(inputText);
  }

  addUserToTable(esPeople: ESPeople) {
    this.isTableEmpty = false;
    this.visibleSearchList = false;
    this.esPeople = [];
    this.inputValue = '';
    this.users.push({ email: esPeople.email, name: esPeople.name, employeeId: esPeople.employeeId });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  deleteUser(employeeId: string): void {
    remove(this.users, { employeeId });
    if (this.users.length === 0) {
      this.isTableEmpty = true;
    }
  }

  handleCancel() {
    this.closeDialog.emit(false);
  }

  validateUserInfo() {
    let invalid = false;
    this.users.forEach((user) => {
      if (isEmpty(user.role)) {
        user.isRoleEmpty = true;
        invalid = true;
      }
    });

    if (this.users.length === 0) {
      this.isTableEmpty = true;
    } else if (invalid === false) {
      this.confirmPopVisible = true;
    }
  }

  saveUserInfo() {
    this.isSaving = true;
    const userRoleInfo: AddGovernanceUserRoleRequest = {
      governanceUnitName: this.unitName,
      governanceUserRole: map(this.users, (user) => ({
        employeeId: user.employeeId,
        role: user.role,
      })),
    };

    this.governanceUnitApi
      .addGovernanceUnitUsers(userRoleInfo)
      .pipe(
        finalize(() => {
          this.isSaving = false;
        }),
      )
      .subscribe(() => {
        this.closeDialog.emit(true);
        this.toastMessageService.success('Saved successfully');
      });
  }

  protected readonly isEmpty = isEmpty;
}
