import {
  NAME_DASHBOARD_DSO as DSO,
  NAME_DASHBOARD_HIGH_RISK as HIGH_RISK,
  NAME_DASHBOARD_INVOICE as INVOICE,
  NAME_DASHBOARD_LEGAL_DOCUMENTS as LEGAL_DOCUMENTS,
  NAME_DASHBOARD_MILESTONE as MILESTONE,
  NAME_DASHBOARD_PO as PO,
  NAME_OPPORTUNITY_DASHBOARD as OPPORTUNITY,
} from '@constants/matomo';

const normalPage = {
  placeholder: 'Search by Market/Account/Opportunity/Opportunity Owner/Project Code',
  dropdownClassName: 'ant-dropdown-menu dropdown',
};
const documentsPage = {
  placeholder: 'Search by Market/Account/Opportunity/Opportunity Owner/Project Code/Reference No./Document Name',
  dropdownClassName: 'ant-dropdown-menu legal-documents-page-dropdown',
};

export const PagesMap = {
  [OPPORTUNITY]: normalPage,
  [DSO]: normalPage,
  [PO]: normalPage,
  [MILESTONE]: normalPage,
  [INVOICE]: normalPage,
  [HIGH_RISK]: normalPage,
  [LEGAL_DOCUMENTS]: documentsPage,
};
