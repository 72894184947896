import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { DSOMilestone, DSOMilestonePayload } from '@interfaces/Milestone';
import { formatterMoney } from '@utils/money';
import { OpportunityDetail } from '@interfaces/OpportunityDetail';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '@app/decorators/autoUnsubscribe';
import { ToastMessageService } from '@services/toast-message.service';
import { SpinnerService } from '@services/spinner.service';
import { toDate } from '@utils/utils';
import { MilestoneStatus } from '@constants/opportunity';
import { YesOrNoOptions } from '@constants/common';
import { MatomoTracker } from 'ngx-matomo';
import {
  ACTION_CLICK,
  NAME_CANCEL_BUTTON,
  NAME_CANCEL_FOR_SUBMIT,
  NAME_MILESTONE_ADD_ITEM_ICON,
  NAME_MILESTONE_DELETE_ITEM_ICON,
  NAME_SAVE_MILESTONES,
  NAME_YES_FOR_SUBMIT,
} from '@constants/matomo';
import { DsoMilestoneService } from '@services/dso-milestone.service';
import { MilestoneActionType } from '@constants/milestone';

@AutoUnsubscribe()
@Component({
  selector: 'app-add-milestone-dialog',
  templateUrl: './add-milestone-dialog.component.html',
  styleUrls: ['./add-milestone-dialog.component.scss'],
  providers: [DsoMilestoneService],
})
export class AddMilestoneDialogComponent implements OnChanges, OnInit {
  @ViewChildren('milestoneRows') private milestoneRows: QueryList<ElementRef>;
  @Input() isVisible = false;
  @Input() opportunityDetail: OpportunityDetail;
  @Input() milestones: DSOMilestone[] = [];
  @Input() lastSubmitMilestones: DSOMilestone[] = [];
  @Input() pageName: string;
  @Output() cancelled = new EventEmitter();
  @Output() changed = new EventEmitter();

  confirmTip = '';
  isConfirmPopVisible = false;
  isSaving = false;
  isSubmitting = false;
  form: UntypedFormGroup = null;
  unsubscribe = new Subject();
  formatterMoney = formatterMoney;
  YesOrNoOptions = YesOrNoOptions;
  MilestoneStatus = MilestoneStatus;

  constructor(
    private fb: UntypedFormBuilder,
    private toastMessageService: ToastMessageService,
    private spinnerService: SpinnerService,
    private matomoTracker: MatomoTracker,
    private dsoMilestoneService: DsoMilestoneService,
  ) {
    this.dsoMilestoneService
      .listenMilestones()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((milestonePayload: DSOMilestonePayload) => {
        this.updateFormControls(milestonePayload);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.milestones || changes.opportunityDetail || changes.lastSubmitMilestones) {
      this.dsoMilestoneService.init({
        milestones: changes.milestones?.currentValue,
        opportunityDetail: changes.opportunityDetail?.currentValue,
        lastSubmitMilestones: changes.lastSubmitMilestones?.currentValue,
      });
    }
  }

  ngOnInit() {
    this.form.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.dsoMilestoneService.updateMilestones(this.form.getRawValue().milestones);
    });
  }

  private updateFormControls(milestonePayload: DSOMilestonePayload) {
    const { milestones, actionType, payload } = milestonePayload;
    const controls = milestones.map((milestone) => this.mapToFormGroup(milestone));
    if (this.form) {
      if (actionType === MilestoneActionType.Init) {
        this.form.setControl('milestones', this.fb.array(controls), { emitEvent: false });
      } else if (actionType === MilestoneActionType.Add) {
        this.formMilestones.push(controls[controls.length - 1], { emitEvent: false });
      } else if (actionType === MilestoneActionType.Delete) {
        this.formMilestones.removeAt(payload.deletedIndex, { emitEvent: false });
      } else if (actionType === MilestoneActionType.Update) {
        this.form.updateValueAndValidity({ emitEvent: false });
        this.updateFormControlEnableStatus(milestones);
      }
    } else {
      this.form = this.fb.group(
        {
          milestones: this.fb.array(controls),
        },
        { validators: [this.customFormValidator] },
      );
    }
  }

  private mapToFormGroup(milestone: DSOMilestone) {
    const disabled = milestone.status === MilestoneStatus.done;
    const dueInfoDisabled = !this.dsoMilestoneService.hasDueInfoSubmitted(milestone) || disabled;
    return this.fb.group({
      id: [milestone.id],
      milestoneEditId: [milestone.milestoneEditId],
      name: [{ value: milestone.name, disabled }, [Validators.required]],
      milestoneDueDate: [{ value: toDate(milestone.milestoneDueDate), disabled }, [Validators.required]],
      milestoneAmount: [{ value: milestone.milestoneAmount, disabled }, [Validators.required]],
      deliveryActionOwnerFullName: [milestone.deliveryActionOwnerFullName],
      deliveryActionOwner: [{ value: milestone.deliveryActionOwner, disabled: dueInfoDisabled }, [Validators.required]],
      demandActionNeeded: [{ value: milestone.demandActionNeeded, disabled: dueInfoDisabled }, [Validators.required]],
      demandActionOwnerFullName: [milestone.demandActionOwnerFullName],
      demandActionOwner: [
        { value: milestone.demandActionOwner, disabled: dueInfoDisabled || !milestone.demandActionNeeded },
        [Validators.required],
      ],
      hasDeliveryRisk: [{ value: milestone.hasDeliveryRisk, disabled: dueInfoDisabled }, [Validators.required]],
      reason: [{ value: milestone.reason, disabled: dueInfoDisabled }, [Validators.required]],
      status: [milestone.status],
      hasSubmitted: [milestone.hasSubmitted],
    });
  }

  private customFormValidator = (form: UntypedFormGroup): ValidationErrors | null => {
    if (form.pristine) {
      return null;
    }

    if (this.hasControlDirty(form, 'milestoneAmount') && !this.dsoMilestoneService.hasSameTotalAmount()) {
      return { amountError: true };
    }

    const dueDateIndexes = this.dsoMilestoneService.getSameDueDateIndexes();
    if (this.hasControlDirty(form, 'milestoneDueDate') && dueDateIndexes.length > 0) {
      return { dueDateError: true, indexes: dueDateIndexes };
    }
    return null;
  };

  private updateFormControlEnableStatus(milestones) {
    const nextMilestones = milestones;
    nextMilestones.forEach((milestone: DSOMilestone, index) => {
      if (!this.dsoMilestoneService.hasMilestoneSubmitted(milestone) || milestone.status === MilestoneStatus.done) {
        return;
      }
      const milestoneGroup = this.formMilestones.at(index) as UntypedFormGroup;
      const isDueInfoDisabled = this.formMilestones.at(index).get('deliveryActionOwner')?.disabled;
      if (isDueInfoDisabled) {
        const changed = this.dsoMilestoneService.getMilestoneChangedFields(milestone);
        if (changed.dueDate || changed.amount) {
          Object.keys(milestoneGroup.controls)
            .filter((field) => !['name', 'milestoneDueDate', 'milestoneAmount', 'demandActionOwner'].includes(field))
            .map((field) => {
              milestoneGroup.get(field).enable({ emitEvent: false });
            });
        }
      }
      if (!isDueInfoDisabled) {
        const demandActionOwnerControl = milestoneGroup.get('demandActionOwner');
        if (milestone.demandActionNeeded) {
          demandActionOwnerControl.enable({ emitEvent: false });
        } else {
          demandActionOwnerControl.reset(null, { emitEvent: false });
          demandActionOwnerControl.disable({ emitEvent: false });
        }
      }
    });
  }

  get formMilestones() {
    return this.form.get('milestones') as UntypedFormArray;
  }

  private getFormControl(index, key) {
    const formGroup = this.formMilestones.at(index) as FormGroup;
    return formGroup.get(key) as FormControl;
  }

  getFormControlStatus(index, key) {
    const formControl = this.getFormControl(index, key) as FormControl;
    return formControl.status;
  }

  getFormControlTouched(index, key) {
    const formControl = this.getFormControl(index, key) as FormControl;
    return formControl.touched;
  }

  get dialogName() {
    return `${this.pageName} Milestone ${this.dsoMilestoneService.hasSubmitted() ? 'Editing' : 'Adding'}`;
  }

  addMilestone() {
    this.dsoMilestoneService.addMilestone();
    this.milestoneRows.changes.pipe(take(1), takeUntil(this.unsubscribe)).subscribe({
      next: (changes) => changes.last.nativeElement.scrollIntoView({ behavior: 'smooth' }),
    });
    this.matomoTracker.trackEvent(this.dialogName, ACTION_CLICK, NAME_MILESTONE_ADD_ITEM_ICON);
  }

  deleteMilestone(index) {
    this.dsoMilestoneService.deleteMilestone(index);
    this.matomoTracker.trackEvent(this.dialogName, ACTION_CLICK, NAME_MILESTONE_DELETE_ITEM_ICON);
  }

  handleSave() {
    if (!this.form.valid || this.form.pristine) {
      this.triggerFormValidation();
    }
    if (this.form.valid) {
      this.isSaving = true;
      this.dsoMilestoneService
        .saveMilestones()
        .pipe(
          this.spinnerService.loading(),
          finalize(() => {
            this.isSaving = false;
          }),
        )
        .subscribe(() => {
          this.toastMessageService.success('Saved Successfully');
          this.changed.emit();
          this.matomoTracker.trackEvent(this.dialogName, ACTION_CLICK, NAME_SAVE_MILESTONES);
        });
    }
  }

  handleSubmit() {
    this.isSubmitting = true;
    this.dsoMilestoneService
      .submitMilestones()
      .pipe(
        this.spinnerService.loading(),
        finalize(() => {
          this.isSubmitting = false;
          this.isConfirmPopVisible = false;
        }),
      )
      .subscribe(() => {
        this.toastMessageService.success('Submit Successfully');
        this.changed.emit();
        this.matomoTracker.trackEvent(this.dialogName, ACTION_CLICK, NAME_YES_FOR_SUBMIT);
      });
  }

  private triggerFormValidation() {
    const controls = [];
    Object.values(this.formMilestones.controls).forEach((group: UntypedFormGroup) => {
      Object.values(group.controls).forEach((control) => {
        if (control instanceof UntypedFormGroup) {
          controls.push(...Object.values(control.controls));
        } else {
          controls.push(control);
        }
      });
    });
    controls.forEach((control) => {
      control.markAsDirty();
      if (control.enabled) {
        control.markAsTouched();
      }
      control.updateValueAndValidity({ onlySelf: true });
    });
    this.form.markAsDirty();
    this.form.updateValueAndValidity({ onlySelf: true });
  }

  getErrorMessage() {
    const errors = this.form?.errors;
    if (errors?.dueDateError) {
      return 'Please make sure the milestone due dates are not the same';
    } else if (errors?.amountError) {
      return 'Please make sure the Milestone Total Amount is the same as the Opportunity Amount';
    }
    return '';
  }

  handleCancel() {
    this.cancelled.emit();
    this.matomoTracker.trackEvent(this.dialogName, ACTION_CLICK, NAME_CANCEL_BUTTON);
  }

  handleAfterClose() {
    this.dsoMilestoneService.resetMilestones();
    this.isConfirmPopVisible = false;
  }

  openConfirmPop() {
    if (!this.form.valid || this.form.pristine) {
      this.triggerFormValidation();
    }
    if (this.form.valid) {
      const ignoreDueInfoMilestoneNames = this.dsoMilestoneService.getIgnoreDueInfoMilestones().map((milestone) => milestone.name);
      let confirmTip = '';
      if (ignoreDueInfoMilestoneNames.length > 0) {
        const nameString = ignoreDueInfoMilestoneNames.join(',');
        confirmTip = `The delay information such as Reason etc. of {${nameString}} will be cleared due to the Milestone Due Date and Milestone Amount are the same as the submitted version. Are you sure to submit it?`;
      } else {
        confirmTip = 'After submitted, the Milestone will public to the operations team. Are you sure to submit?';
      }
      this.confirmTip = confirmTip;
      this.isConfirmPopVisible = true;
    }
  }

  handleCancelSubmit() {
    this.isConfirmPopVisible = false;
    this.matomoTracker.trackEvent(this.dialogName, ACTION_CLICK, NAME_CANCEL_FOR_SUBMIT);
  }

  setFormItemValue(index: number, key: string, newValue: string) {
    const formControl = this.getFormControl(index, key) as FormControl;
    formControl.setValue(newValue);
  }

  markAsTouched(index: number, key: string) {
    const formControl = this.getFormControl(index, key) as FormControl;
    formControl.markAsTouched();
  }

  hasControlDirty(form, name) {
    return (form.get('milestones') as UntypedFormArray).controls
      .map((group: UntypedFormGroup) => group.get(name))
      .some((control) => control.dirty);
  }

  hasMilestoneSubmitted(milestone) {
    return this.dsoMilestoneService.hasMilestoneSubmitted(milestone);
  }

  allowDelete(index) {
    return this.dsoMilestoneService.allowDelete(index);
  }

  get totalAmount() {
    return this.dsoMilestoneService.calcTotalAmount();
  }

  get hasSubmitted() {
    return this.dsoMilestoneService.hasSubmitted();
  }

  get defaultDeliveryActionOwnerDropdownList() {
    return this.dsoMilestoneService.getDefaultDeliveryActionOwnerDropdownList();
  }

  get defaultDemandActionOwnerDropdownList() {
    return this.dsoMilestoneService.getDefaultDemandActionOwnerDropdownList();
  }

  get currency() {
    return this.dsoMilestoneService.getCurrency();
  }
}
