import moment from 'moment';

export const DateType = {
  DATE: 'yyyy-MM-dd',
};

export const MomentFormat = {
  DATE: 'YYYY-MM-DD',
  DATE_TIME: 'YYYY-MM-DD HH:mm:ss',
};

export function formatDate(date: string) {
  return moment(date).format(MomentFormat.DATE);
}
