import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';
import { AclService } from '@services/acl.service';
import { MilestoneApi } from '@apis/milestone.api';
import moment from 'moment';
import { completedStatus } from '@constants/invoiceStatus';
import { includes } from 'lodash';

import { DateType, MomentFormat } from '@constants/dateFormat';

@Component({
  selector: 'app-edit-expected-milestone-due-date',
  templateUrl: './edit-expected-milestone-due-date.component.html',
  styleUrls: ['./edit-expected-milestone-due-date.component.scss'],
})
export class EditExpectedMilestoneDueDateComponent implements OnInit {
  @Input() lookupId: string;
  @Input() expectedMilestoneDueDate: string;
  @Input() invoiceStatus: string;
  @Output() emitEditExpectedMilestoneDueDateAndComment = new EventEmitter<any>();
  @ViewChild('datePicker') private datePickerEle: NzDatePickerComponent;
  dateType = DateType;
  clickSubmit = false;
  popoverVisible = false;
  comment = '';
  newExpectedMilestoneDueDate: string;
  isLoading = false;

  constructor(public aclService: AclService, private milestoneApi: MilestoneApi) {}

  ngOnInit() {
    this.newExpectedMilestoneDueDate = this.expectedMilestoneDueDate;
  }

  onClickToday() {
    this.newExpectedMilestoneDueDate = moment().format(MomentFormat.DATE);
  }

  disabledSubmit() {
    return this.comment.trim().length === 0 || this.newExpectedMilestoneDueDate == null;
  }

  onCancel() {
    this.popoverVisible = false;
    this.comment = '';
    this.newExpectedMilestoneDueDate = this.expectedMilestoneDueDate;
  }

  onSubmit() {
    const employeeId = this.aclService.getUser().employeeId;
    this.isLoading = true;
    this.newExpectedMilestoneDueDate = moment(this.newExpectedMilestoneDueDate).format('YYYY-MM-DD');
    this.milestoneApi
      .createExpectedMilestoneDueDate(this.lookupId, employeeId, this.newExpectedMilestoneDueDate, this.comment)
      .subscribe((data) => {
        this.emitEditExpectedMilestoneDueDateAndComment.emit({
          expectedMilestoneDueDate: this.newExpectedMilestoneDueDate,
          comment: this.comment,
          id: data.id,
          creatDate: data.createdDate,
        });
        this.popoverVisible = false;
        this.comment = '';
        this.isLoading = false;
      });
  }

  isInvoiceCompleted(invoiceStatus: string) {
    return !!includes(completedStatus, invoiceStatus);
  }
}
