import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-right-drawer',
  templateUrl: './right-drawer.component.html',
  styleUrls: ['./right-drawer.component.scss'],
})
export class RightDrawerComponent {
  @Input() isDataTipsVisible = false;
  @Output() closeTips = new EventEmitter<any>();

  closeDrawer() {
    this.closeTips.emit();
  }
}
