<nz-modal class="role-control-model" [nzVisible]="true" [nzFooter]="modalFooter" (nzOnCancel)="handleCancel()">
  <ng-container *nzModalContent>
    <div class="role-control-content">
      <p class="role-control-header">Edit User to the Group</p>

      <div class="role-control-list">
        <div class="role-control-list-header">
          <div>
            <div class="title">Delivery Unit Name</div>
            <div class="role-description">
              {{ unitName }}
            </div>
          </div>
        </div>
        <div class="search-user">
          <div class="title">Add A User</div>
          <label nz-dropdown [nzDropdownMenu]="search" nzTrigger="click" [(nzVisible)]="visibleSearchList" class="user-search-input">
            <input
              type="text"
              nz-input
              nzSize="small"
              [(ngModel)]="inputValue"
              maxlength="121"
              placeholder="Search a user by name or email"
              (ngModelChange)="handleSearchTextChange($event)"
            />
          </label>
        </div>
        <div class="user-group-role">
          <div class="title">User in the Group</div>
          <nz-table [nzShowPagination]="false" [nzFrontPagination]="false" [nzTemplateMode]="true" class="user-group-table">
            <thead>
              <tr>
                <th nzWidth="260px">User</th>
                <th nzWidth="120px">Role</th>
                <th nzWidth="20px"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let user of users">
                <td class="table-people">
                  <span class="table-people-name">{{ user.name }}</span>
                  <span class="table-people-email">{{ user.email }}</span>
                </td>
                <td>
                  <nz-select
                    [(ngModel)]="user.role"
                    (ngModelChange)="user.isRoleEmpty = isEmpty(user.role)"
                    style="width: 100%"
                    [nzSuffixIcon]="selectIcon"
                    [nzOptionHeightPx]="32"
                    nzDropdownClassName="default-size-dropdown"
                    [nzStatus]="user.isRoleEmpty && 'error'"
                  >
                    <nz-option *ngFor="let roleType of ROLE_TYPE_OPTIONS" [nzLabel]="roleType.label" [nzValue]="roleType.value"></nz-option>
                  </nz-select>
                  <div *ngIf="user.isRoleEmpty" class="role-empty-error">Please select a role</div>
                </td>
                <td>
                  <div style="text-align: center">
                    <app-icon-text
                      className="icon-delete"
                      (click)="deleteUser(user.employeeId)"
                      data-testid="user-delete-btn"
                    ></app-icon-text>
                  </div>
                </td>
              </tr>
            </tbody>
          </nz-table>
          <div class="no-user-warning" *ngIf="isTableEmpty">
            <img src="/assets/svg/warning.svg" />
            Please add at least one user before submitting
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #modalFooter>
    <div class="operation-btns">
      <button class="cancel-btn" nz-button nzType="default" type="button" (click)="closeDialog.emit(false)">Cancel</button>
      <button
        nz-popconfirm
        [nzPopconfirmVisible]="confirmPopVisible"
        [nzPopconfirmTrigger]="null"
        [nzPopconfirmShowArrow]="false"
        nzOkText="Yes"
        nzCancelText="Cancel"
        nzPopconfirmTitle="Are you sure to submit it?"
        nzPopconfirmPlacement="bottom"
        nzPopconfirmOverlayClassName="confirmDeletePopover"
        nz-button
        nzType="primary"
        type="button"
        [nzLoading]="isSaving"
        (nzOnConfirm)="saveUserInfo()"
        (nzOnCancel)="confirmPopVisible = false"
        (click)="validateUserInfo()"
      >
        Submit
      </button>
    </div>
  </ng-template>
</nz-modal>

<nz-dropdown-menu #search="nzDropdownMenu">
  <div class="user-result-container" *ngIf="inputValue?.length >= 2">
    <div
      *ngFor="let item of esPeople"
      [class.hidden]="loadingSearch"
      class="item owner-item"
      (click)="addUserToTable(item)"
      data-testid="search-dropdown"
    >
      <div class="left">
        <div class="people-name">{{ item?.name }}</div>
        <div class="people-email">{{ item?.email }}</div>
      </div>
      <div class="right">
        <app-icon-text [className]="'icon-plus-default'"></app-icon-text>
      </div>
    </div>
    <p class="no-data-found" *ngIf="esPeople?.length === 0 && !loadingSearch">No user found</p>
    <nz-spin nzSimple class="load-icon-search" *ngIf="loadingSearch"></nz-spin>
  </div>
</nz-dropdown-menu>

<ng-template #selectIcon>
  <i class="select-arrow" nz-icon nzType="svg:arrow" alt="arrow"></i>
</ng-template>
