import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appDataTestId]',
})
export class TestIdDirective implements OnInit {
  @Input() dataTestId: string;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.elementRef.nativeElement.setAttribute('data-testid', this.dataTestId);
  }
}
