import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { SearchApi } from '@apis/search.api';
import { DOCUMENT } from '@angular/common';
import { SearchList, SearchResponse } from '@interfaces/SearchResult';
import { debounceTime, filter, map, retry, switchMap, takeUntil } from 'rxjs/operators';
import { FilterService } from '@services/filter.service';
import { search } from '@utils/search';
import { Filter, SearchItem } from '@interfaces/Filter';
import { get, isEmpty } from 'lodash';
import { MAX_INPUT_LENGTH } from '@constants/sortMapping';
import { DEFAULT_FILTER_ITEM } from '@constants/filterType';
import { MatomoTracker } from 'ngx-matomo';
import { ACTION_CLICK, CATEGORY_SEARCH, NAME_SEARCH_BOX } from '@constants/matomo';
import { PagesMap } from '@constants/pagesMapping';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('inputElement', { static: true }) inputCom: ElementRef;
  @Input() page: string;
  @Output() changeSearch: EventEmitter<SearchItem> = new EventEmitter<SearchItem>();
  inputValue: any;
  capsuleId: string;
  searchResult: SearchList;
  loadingSearchList = false;
  visibleSearchList = false;
  capsuleType: string;
  capsuleName: string;
  maxInputLength = MAX_INPUT_LENGTH;
  pagesMap = PagesMap;
  private unsubscribe = new Subject();

  constructor(
    private searchApi: SearchApi,
    private searchItemService: FilterService,
    private matomoTracker: MatomoTracker,
    @Inject(DOCUMENT) private doc: Document,
  ) {}

  ngOnInit(): void {
    this.selectSearchItem();
    this.getPreviousSearchValue();
    if (this.inputValue) {
      this.getPreviousSearchResult();
    }
  }

  ngAfterViewInit(): void {
    this.onSearchInputChange();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onSearchInputChange() {
    fromEvent(this.inputCom.nativeElement, 'input')
      .pipe(
        map((event: Event) => {
          const inputTarget = event.target as HTMLInputElement;

          return inputTarget.value;
        }),
        filter((input: any) => input.trim().length >= 2),
        debounceTime(500),
        switchMap((input: any) => {
          this.visibleSearchList = true;
          this.loadingSearchList = true;
          this.matomoTracker.trackEvent(CATEGORY_SEARCH, ACTION_CLICK, NAME_SEARCH_BOX + ' In ' + this.page);

          return this.searchApi.getSearchResponseByName(input);
        }),
        retry(3),
        takeUntil(this.unsubscribe),
      )
      .subscribe(
        (response: SearchResponse) => {
          this.loadingSearchList = false;
          this.searchResult = search(response);
        },
        () => {
          this.loadingSearchList = false;
        },
      );
  }

  changeHandle(value: any): void {
    if (!value.length) {
      this.subscribeFilterChange();
    }
    if (!this.isCapsuleEmpty()) {
      this.inputValue = ' ';
      this.inputCom.nativeElement.value = ' ';
      this.maxInputLength = '1';
    } else {
      this.maxInputLength = MAX_INPUT_LENGTH;
      this.inputCom.nativeElement.value = value;
    }
  }

  searchByCapsuleName(capsuleName) {
    this.loadingSearchList = true;
    this.searchApi.getSearchResponseByName(capsuleName).subscribe((response: SearchResponse) => {
      this.loadingSearchList = false;
      this.searchResult = search(response);
    });
  }

  isCapsuleEmpty() {
    return this.capsuleName === undefined;
  }

  preSearch() {
    if (!this.isCapsuleEmpty()) {
      this.getPreviousSearchResult();
    }
  }

  onClickMask() {
    this.visibleSearchList = false;
  }

  showInputMask() {
    return this.visibleSearchList && (this.inputValue.trim().length >= 2 || !this.isCapsuleEmpty());
  }

  private selectSearchItem() {
    this.searchItemService
      .listenFilter()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((item: Filter) => {
        this.FillInSearchItem(item.searchItem);
        this.visibleSearchList = false;
        this.changeSearch.emit(item.searchItem);
      });
  }

  private subscribeFilterChange(): void {
    if (!isEmpty(this.searchItemService.getSearchItem())) {
      this.searchItemService.triggerFilter(DEFAULT_FILTER_ITEM);
    }
  }

  private getPreviousSearchValue() {
    this.FillInSearchItem(this.searchItemService.getSearchItem());
  }

  private FillInSearchItem(item: any) {
    if (isEmpty(item)) {
      this.inputValue = '';
    } else {
      this.inputValue = ' ';
    }
    this.capsuleId = item.referenceNumber || '';
    this.capsuleName = get(item, 'name');
    this.capsuleType = get(item, 'type');
  }

  private getPreviousSearchResult() {
    this.loadingSearchList = true;
    this.searchApi
      .getSearchResponseByName(isEmpty(this.capsuleName) ? this.capsuleId : this.capsuleName)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response: SearchResponse) => {
        this.loadingSearchList = false;
        this.searchResult = search(response);
      });
  }
}
