<div class="opportunity-column">
  <span class="mu-tag" [ngStyle]="{ backgroundColor: getMUColor(muName) }"></span>
  <div class="account-opportunity">
    <p *ngFor="let config of tagConfig" class="tag" [ngClass]="config.className">{{ config.label }}</p>
    <p class="deactivated tag" *ngIf="isDeactivated">Deactive</p>
    <p class="no-contract tag yellow" *ngIf="noContracts">No Contract Info</p>
    <app-high-risk-tag
      *ngIf="!isDeactivated"
      [isHighRisk]="isHighRisk"
      [opportunityId]="opportunityId"
      [riskCategories]="riskCategories"
      (riskLevelChanged)="handleRiskLevelChanged($event)"
    ></app-high-risk-tag>
    <p class="account-info" [class.deactivated-gray]="isDeactivated">{{ accountName | emptyFormat }}</p>
    <a [routerLink]="opportunityLink" (click)="setBackButtonStatus(false)" target="_blank">
      <p class="opportunity-name" [class.deactivated-gray]="isDeactivated">{{ opportunityName | emptyFormat }}</p>
      <p class="project-code-name" *ngIf="isShowProjectCode">{{ projectCode | emptyFormat }}</p>
    </a>
  </div>
</div>
