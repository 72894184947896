import { Injectable } from '@angular/core';
import { OwnerPageData } from '../interfaces/OwnerList';

@Injectable({
  providedIn: 'root',
})
export class OpportunityOwnerPageService {
  ownerPage: OwnerPageData = {};

  set(page: OwnerPageData) {
    this.ownerPage = page;
  }

  get() {
    return this.ownerPage;
  }
}
