import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { set } from 'lodash';
import { NotificationItem } from '@interfaces/NotificationItem';
import { Subject } from 'rxjs';
import { WebsocketService } from '@services/websocket.service';
import { DsoSessionMessage } from '@interfaces/DsoSessionMessage';
import { DateType } from '@constants/dateFormat';

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss'],
})
export class NotificationItemComponent implements OnDestroy {
  @Input() item: NotificationItem;
  @Output() clickNotificationItem = new EventEmitter();
  private unsubscribe = new Subject();
  dateType = DateType;

  constructor(private service: WebsocketService) {}

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onClickNotification() {
    if (!this.item.read) {
      this.service.sendMessage(new DsoSessionMessage({ type: 'MARK_AS_READ', data: [this.item.id] }));
      set(this.item, 'read', true);
      this.clickNotificationItem.emit();
    }
  }
}
