import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { BarChart, PieChart, CandlestickChart } from 'echarts/charts';
import {
  TooltipComponent,
  GridComponent,
  DataZoomComponent,
  LegendComponent,
  MarkLineComponent,
  MarkPointComponent,
  MarkAreaComponent,
} from 'echarts/components';

echarts.use([
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  PieChart,
  CandlestickChart,
  MarkLineComponent,
  MarkPointComponent,
  CanvasRenderer,
  DataZoomComponent,
  MarkAreaComponent,
]);

export default echarts;
