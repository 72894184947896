<div class="multi-months-picker-panel">
  <div class="picker-header">
    <span (click)="onSelectPreviousYear()" class="prev-month">
      <span class="ant-picker-super-prev-icon"></span>
    </span>
    <span class="selected-year">{{ selectedYear }}</span>
    <span (click)="onSelectNextYear()" class="next-month">
      <span class="ant-picker-super-next-icon"></span>
    </span>
  </div>
  <div class="picker-body">
    <span
      class="month-item"
      *ngFor="let option of MONTH_OPTIONS"
      (click)="onMonthSelect(option.value)"
      [ngClass]="getMonthClass(option.value)"
    >
      <span class="month-text">{{ option.text }}</span>
    </span>
  </div>
</div>
