<nz-modal
  nzClassName="modal-common-style milestone-history-dialog"
  [nzWidth]="1136"
  [(nzVisible)]="isVisible"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="null"
  [nzBodyStyle]="{ overflow: 'auto', height: 'calc(100% - 64px)' }"
  (nzOnCancel)="handleCancel()"
  (nzAfterOpen)="handleAfterOpen()"
  (nzAfterClose)="handleAfterClose()"
>
  <ng-template #modalTitle>
    <div class="modal-title-with-label">Milestone Edit History</div>
  </ng-template>
  <ng-template #modalContent>
    <div class="milestone-history-list">
      <nz-spin nzSimple *ngIf="isLoading" class="loading-spin"></nz-spin>
      <nz-timeline *ngIf="!isLoading">
        <nz-timeline-item *ngFor="let group of groups; let i = index" class="timeline-item" [nzColor]="i === 0 ? 'blue' : 'gray'">
          <div class="history-title" [ngClass]="i === 0 && 'first'">
            <span>{{ group.createTime | date : dateType.DATE | emptyFormat }}</span>
            <span>
              <span class="edit-by-label">edited by</span>
              <span>{{ group.operatorEmployeeName }}</span>
            </span>
          </div>
          <div class="history-content">
            <div class="content-label">From</div>
            <ng-container [ngTemplateOutlet]="tableRef" [ngTemplateOutletContext]="{ snapshots: group.fromSnapshots }"></ng-container>
            <div class="divider"></div>
            <div class="content-label to-label">To</div>
            <ng-container [ngTemplateOutlet]="tableRef" [ngTemplateOutletContext]="{ snapshots: group.toSnapshots }"></ng-container>
          </div>
        </nz-timeline-item>
      </nz-timeline>
    </div>
  </ng-template>
</nz-modal>

<ng-template #tableRef let-snapshots="snapshots">
  <div class="table">
    <div class="table-header row">
      <div class="column">Milestone Due Date</div>
      <div class="column">Name</div>
      <div class="column">Amount</div>
    </div>
    <ng-container *ngFor="let snapshot of snapshots">
      <div class="table-row row" [ngClass]="getTagClass(snapshot.tag)" data-testid="tableRow">
        <div *ngIf="!!getTagText(snapshot.tag)" class="tag status-tag">{{ getTagText(snapshot.tag) }}</div>
        <div class="column">{{ snapshot.value.milestoneDueDate }}</div>
        <div class="column">
          <span class="text-ellipsis">{{ snapshot.value.name }}</span>
        </div>
        <div class="column">{{ snapshot.value.milestoneAmount | currencyFormat : snapshot.value.currency : false }}</div>
      </div>
    </ng-container>
  </div>
</ng-template>
