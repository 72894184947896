import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FilterService } from '@services/filter.service';
import { SearchList } from '@interfaces/SearchResult';
import { chunk, find, omit } from 'lodash';
import { Filter } from '@interfaces/Filter';
import {
  ACTION_SEARCH,
  CATEGORY_SEARCH,
  NAME_SEARCH_FOR_ACCOUNT,
  NAME_SEARCH_FOR_LEGAL_DOCUMENTS,
  NAME_SEARCH_FOR_MU,
  NAME_SEARCH_FOR_OPPORTUNITY,
  NAME_SEARCH_FOR_OPPORTUNITY_OWNER,
} from '@constants/matomo';
import { MatomoTracker } from 'ngx-matomo';
import { FILTER_WITHOUT_CONTRACT_STATUS } from '@app/constants/filterType';
import { PagesMap } from '@constants/pagesMapping';

@Component({
  selector: 'app-search-dropdown',
  templateUrl: './search-dropdown.component.html',
  styleUrls: ['./search-dropdown.component.scss'],
})
export class SearchDropdownComponent implements OnChanges {
  @Input() inputValue: any;
  @Input() data: SearchList;
  @Input() loading = false;
  @Input() showDropdown = false;
  @Input() page: string;

  pagesMap = PagesMap;

  dataInfo = {
    mus: {
      pageIndex: 0,
      pageSize: 8,
      data: [],
    },
    accounts: {
      pageIndex: 0,
      pageSize: 4,
      data: [],
    },
    opportunities: {
      pageIndex: 0,
      pageSize: 4,
      data: [],
    },
    opportunityOwners: {
      pageIndex: 0,
      pageSize: 4,
      data: [],
    },
    legalDocuments: {
      pageIndex: 0,
      pageSize: 4,
      data: [],
    },
  };

  constructor(private filterService: FilterService, private matomoTracker: MatomoTracker) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.setDataList('mus');
      this.setDataList('accounts');
      this.setDataList('opportunities');
      this.setDataList('opportunityOwners');
      this.setDataList('legalDocuments');
    }
  }

  handlePageChange(pageIndex: number, dataKey: string): void {
    this.dataInfo[dataKey].pageIndex = pageIndex;
    this.setDataList(dataKey);
  }

  private setDataList(dataKey: string): void {
    const { pageIndex, pageSize } = this.dataInfo[dataKey];
    const pageData = chunk(this.data?.[dataKey] || [], pageSize);
    this.dataInfo[dataKey].data = pageData[pageIndex];
  }

  onClickItem(item: any, type: string) {
    const searchItem = { ...item, type };
    const filterItem: Filter = {
      searchItem: omit(searchItem, 'status'),
      status: searchItem.status,
      countDown: {},
      withoutPoDays: {},
      withoutContractStatus: FILTER_WITHOUT_CONTRACT_STATUS.ALL,
    };
    this.filterService.triggerFilter(filterItem);
    const pageMap = [
      { mu: NAME_SEARCH_FOR_MU },
      { account: NAME_SEARCH_FOR_ACCOUNT },
      { opportunity: NAME_SEARCH_FOR_OPPORTUNITY },
      { owner: NAME_SEARCH_FOR_OPPORTUNITY_OWNER },
      { document: NAME_SEARCH_FOR_LEGAL_DOCUMENTS },
    ];
    this.matomoTracker.trackEvent(CATEGORY_SEARCH, ACTION_SEARCH, find(pageMap, type)[type]);
  }
}
