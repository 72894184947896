import { Component, OnDestroy, OnInit } from '@angular/core';
import { MuInfo } from '../../../../interfaces/MuList';
import { MuApi } from '../../../../apis/mu.api';
import { isEmpty } from 'lodash';
import { MuPageService } from '../../../../services/mu-page.service';
import { takeUntil } from 'rxjs/operators';
import { FilterService } from '../../../../services/filter.service';
import { Subject } from 'rxjs';
import { Filter, SearchItem } from '../../../../interfaces/Filter';
import { getMuFilters } from '@utils/filter';
import { SORT_MAP_TYPE_ACCOUNT, SORT_MAP_TYPE_OPPORTUNITY, SORT_MAP_TYPE_OWNER } from '../../../../constants/sortMapping';
import { SortMappingService } from '../../../../services/sort-mapping.service';
import { AccountPageService } from '../../../../services/account-page.service';
import { OpportunityOwnerPageService } from '../../../../services/opportunity-owner-page.service';
import { OpportunityPageService } from '../../../../services/opportunity-page.service';
import { transformMuList } from '@utils/dso-mu';

@Component({
  selector: 'app-mu-dashboard',
  templateUrl: './mu-dashboard.component.html',
  styleUrls: ['./mu-dashboard.component.scss'],
})
export class MuDashboardComponent implements OnInit, OnDestroy {
  muList: MuInfo[];
  loading = false;
  private unsubscribe = new Subject();
  private filters: Array<SearchItem> = [];

  constructor(
    private muApi: MuApi,
    private muPageService: MuPageService,
    private filterService: FilterService,
    private sortMappingService: SortMappingService,
    private accountPageService: AccountPageService,
    private opportunityOwnerPageService: OpportunityOwnerPageService,
    private opportunityPageService: OpportunityPageService,
  ) {}

  ngOnInit() {
    this.initFilterFromService();
    this.initMUPage();
    this.triggerFilter();
  }

  ngOnDestroy(): void {
    this.setMuPageToService();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private initFilterFromService() {
    this.filters = getMuFilters(this.filterService.getFilterItem());
  }

  private initMuPageData() {
    this.loading = true;
    this.muApi
      .getMuList(this.filters)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: MuInfo[]) => {
        this.muList = transformMuList(data);
        this.loading = false;
        this.muPageService.set(this.muList);
      });
  }

  private initMUPage() {
    const muList = this.muPageService.get();
    isEmpty(muList) ? this.initMuPageData() : (this.muList = muList);
  }

  private triggerFilter() {
    this.filterService
      .listenFilter()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((filter: Filter) => {
        this.filters = getMuFilters(filter);
        this.muList = [];
        this.initMuPageData();
        this.clearDsoOtherDashboard();
      });
  }

  private clearDsoOtherDashboard() {
    this.opportunityPageService.set({});
    this.sortMappingService.clear(SORT_MAP_TYPE_OPPORTUNITY);
    this.accountPageService.set({});
    this.sortMappingService.clear(SORT_MAP_TYPE_ACCOUNT);
    this.opportunityOwnerPageService.set({});
    this.sortMappingService.clear(SORT_MAP_TYPE_OWNER);
  }

  private setMuPageToService() {
    this.muPageService.set(this.muList);
  }
}
