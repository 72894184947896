import { Component } from '@angular/core';
import { ToastMessageService } from '@services/toast-message.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent {
  constructor(public toastMessageService: ToastMessageService) {}
}
