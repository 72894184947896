import { Component, Input } from '@angular/core';
import { TabIndexService } from '@services/tab-index.service';
import { Filter } from '@interfaces/Filter';
import { FilterService } from '@services/filter.service';
import { FILTER_STATUS_ONGOING } from '@constants/filterType';
import { getMuColor } from '@utils/dso-mu';

@Component({
  selector: 'app-mu-tag',
  templateUrl: './mu-tag.component.html',
  styleUrls: ['./mu-tag.component.scss'],
})
export class MuTagComponent {
  @Input() mu: string;
  @Input() muId: number;

  constructor(private tabIndexService: TabIndexService, private filterService: FilterService) {}

  getMuColor() {
    return getMuColor(this.mu);
  }

  filterAccountByMU() {
    const searchItem = { id: this.muId, name: this.mu, type: 'mu' };
    const filterItem: Filter = {
      searchItem,
      status: FILTER_STATUS_ONGOING,
      countDown: {},
      withoutPoDays: {},
    };
    this.filterService.triggerFilter(filterItem);
    this.tabIndexService.setIndex(1);
  }
}
