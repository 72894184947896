<ul class="input-group search-or-back" (click)="visibleSearchList = true">
  <li><i class="input-clear-icon" nz-icon nzTheme="outline" nzType="search" (click)="preSearch()"></i></li>
  <li>
    <label nz-dropdown [nzDropdownMenu]="search" [(nzVisible)]="visibleSearchList" nzTrigger="" class="search-input">
      <app-search-capsule
        [hidden]="isCapsuleEmpty()"
        [searchItem]="capsuleName"
        [searchItemId]="capsuleId"
        (searchByCapsuleName)="searchByCapsuleName($event)"
        [type]="capsuleType"
      ></app-search-capsule>
      <input
        type="text"
        nz-input
        nzSize="small"
        [(ngModel)]="inputValue"
        [maxlength]="maxInputLength"
        (ngModelChange)="changeHandle($event)"
        [placeholder]="pagesMap[page].placeholder"
        [readOnly]="!isCapsuleEmpty()"
        (focusin)="preSearch()"
        #inputElement
      />
    </label>
  </li>
</ul>
<div (click)="onClickMask()" *ngIf="showInputMask()" class="search-input-mask"></div>
<nz-dropdown-menu #search="nzDropdownMenu">
  <app-search-dropdown
    [inputValue]="inputValue"
    [data]="searchResult"
    [loading]="loadingSearchList"
    [showDropdown]="!isCapsuleEmpty() && visibleSearchList"
    [page]="page"
  ></app-search-dropdown>
</nz-dropdown-menu>
