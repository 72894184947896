import { Component, Input, OnInit } from '@angular/core';
import { AclService } from '@services/acl.service';
import { KeyLabel } from '@interfaces/common';
import { MatomoTracker } from 'ngx-matomo';
import { NotesApi } from '@apis/notes.api';
import { head, includes, intersection, isEmpty } from 'lodash';
import { OpportunityDetail, OpportunityStatusInfo } from '@interfaces/OpportunityDetail';
import { ACTION_EDIT, CATEGORY_DETAIL_PAGE } from '@constants/matomo';
import { NoteType } from '@constants/note';
import { OpportunityCardStatus, OpportunityStatusLabel } from '@constants/opportunity';
import { DateType } from '@constants/dateFormat';
import { OpportunityApi } from '@apis/opportunity.api';
import moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { UpdateOpportunityStatusService } from '@services/state-service/update-opportunity-status.service';
import { takeUntil } from 'rxjs/operators';
import { AutoUnsubscribe } from '@app/decorators/autoUnsubscribe';
import { Subject } from 'rxjs';

const opportunityStatusFields: KeyLabel[] = [
  {
    label: 'First Billable Timecard Date',
    key: 'firstBillableTimecardDate',
  },
  {
    label: 'Current DSO',
    key: 'currentDSO',
  },
  {
    label: 'Owner Name',
    key: 'ownerName',
  },
];

@AutoUnsubscribe()
@Component({
  selector: 'app-opportunity-status',
  templateUrl: './opportunity-status.component.html',
  styleUrls: ['./opportunity-status.component.scss'],
})
export class OpportunityStatusComponent implements OnInit {
  readonly DateType = DateType;
  @Input() opportunityDetail: OpportunityDetail;
  opportunityId: number;
  opportunityStatusInfo: OpportunityStatusInfo = {} as OpportunityStatusInfo;
  opportunityStatusFields: KeyLabel[] = opportunityStatusFields;
  displayedTag = '';
  unsubscribe: Subject<any>;
  showWithoutContractBlock = false;
  firstBillableDate: string;
  showDifference: boolean;

  get amountColor() {
    const amountDifference = this.opportunityPropertiesDifference?.amountDifference;
    if (amountDifference === null) {
      return '';
    }
    return amountDifference <= 0 ? 'blue-item' : 'red-item';
  }

  get manDayColor() {
    const manDayDifference = this.opportunityPropertiesDifference?.manDayDifference;
    if (manDayDifference === null) {
      return '';
    }
    return manDayDifference <= 0 ? 'blue-item' : 'red-item';
  }

  get countdownColor() {
    const countdown = this.opportunityPropertiesDifference?.countdown;
    if (countdown === null) {
      return '';
    }
    if (countdown > 0) {
      return 'red-item';
    } else if (countdown >= -3) {
      return 'yellow-item';
    } else {
      return 'blue-item';
    }
  }

  opportunityPropertiesDifference = {
    amountDifference: null,
    manDayDifference: null,
    countdown: null,
  };
  today = new Date();

  constructor(
    public aclService: AclService,
    public matomoTracker: MatomoTracker,
    public notesApi: NotesApi,
    private opportunityApi: OpportunityApi,
    private route: ActivatedRoute,
    private updateOpportunityStatusService: UpdateOpportunityStatusService,
  ) {}

  ngOnInit() {
    this.opportunityId = Number(this.route.snapshot.params.id);
    this.getOpportunityStatus();
    this.updateOpportunityStatusService.updateOpportunityStatus.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.getOpportunityStatus();
    });
  }

  getOpportunityStatus() {
    this.opportunityApi.getOpportunityStatusInfo(this.opportunityId).subscribe((data) => {
      this.opportunityStatusInfo = data;
      this.opportunityStatusInfo.firstBillableTimecardDate = this.opportunityDetail.firstBillableTimecardDate;
      this.opportunityStatusInfo.ownerName = this.opportunityDetail.ownerName;
      this.opportunityStatusInfo.currentDSO = this.opportunityDetail.dso == null ? null : Number(this.opportunityDetail.dso);
      this.showDifference =
        this.opportunityStatusInfo.contractStatus &&
        this.opportunityStatusInfo.contractStatus.includes(OpportunityCardStatus.WITHOUT_CONTRACT);

      this.calculateDifference();
      this.showWithoutContractBlock = includes(data.contractStatus, OpportunityCardStatus.WITHOUT_CONTRACT) || data.usedTobeWithoutContract;
      this.displayedTag =
        OpportunityStatusLabel[
          head(
            intersection(
              [OpportunityCardStatus.NO_APPLICATION, OpportunityCardStatus.APPLICATION_OVERDUE],
              this.opportunityStatusInfo.contractStatus,
            ),
          )
        ];
    });
  }

  editStatusNote(noteContent: string) {
    this.matomoTracker.trackEvent(CATEGORY_DETAIL_PAGE, ACTION_EDIT, `Edit Contract Note for ${this.opportunityDetail.name}`);

    if (isEmpty(noteContent)) {
      this.notesApi.deleteNotes(this.opportunityStatusInfo.note.id).subscribe();
      this.opportunityStatusInfo.note = null;
      this.getOpportunityStatus();
    } else {
      this.notesApi.createNotes(this.opportunityId, noteContent, NoteType.OPPORTUNITY_STATUS).subscribe((note) => {
        this.opportunityStatusInfo.note = note;
        this.getOpportunityStatus();
      });
    }
  }

  calculateDifference(): void {
    const { applicationInfo, recognizedRevenue, actualManDay } = this.opportunityStatusInfo;
    if (typeof recognizedRevenue !== 'number' || typeof applicationInfo?.committedAmount !== 'number') {
      this.opportunityPropertiesDifference.amountDifference = null;
    } else {
      this.opportunityPropertiesDifference.amountDifference = recognizedRevenue - applicationInfo.committedAmount;
    }

    if (typeof actualManDay !== 'number' || typeof applicationInfo?.expectedManDay !== 'number') {
      this.opportunityPropertiesDifference.manDayDifference = null;
    } else {
      this.opportunityPropertiesDifference.manDayDifference = actualManDay - applicationInfo.expectedManDay;
    }
    if (!applicationInfo?.expectedContractReceivingDate) {
      this.opportunityPropertiesDifference.countdown = null;
    } else {
      this.opportunityPropertiesDifference.countdown = moment([this.today.getFullYear(), this.today.getMonth(), this.today.getDate()]).diff(
        moment(applicationInfo.expectedContractReceivingDate),
        'days',
      );
    }
  }
}
