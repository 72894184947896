import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FILTER_TYPE_GREATER_THAN, FILTER_TYPE_LESS_THAN_EQUAL } from '@constants/filterType';
import { RangeFilter } from '@interfaces/RangeFilter';
import { ACTION_FILTER } from '@constants/matomo';
import { isNil } from 'lodash';
import { MatomoTracker } from 'ngx-matomo';

@Component({
  selector: 'app-range-filter',
  templateUrl: './range-filter.component.html',
  styleUrls: ['./range-filter.component.scss'],
})
export class RangeFilterComponent {
  @Output() addFilter = new EventEmitter<any>();
  @Input() matomoCategory: string;
  @Input() title: string;
  @Input() filterName: string;
  @Input() filterRangeList: RangeFilter[];
  activeIndex: null;

  constructor(private matomoTracker: MatomoTracker) {}

  onClick(range, index) {
    if (this.activeIndex === index) {
      this.activeIndex = null;
      this.addFilter.emit([]);
    } else {
      this.activeIndex = index;
      const filter = [{ name: this.filterName, value: range.from, type: FILTER_TYPE_GREATER_THAN }];
      if (!isNil(range.to)) {
        filter.push({ name: this.filterName, value: range.to, type: FILTER_TYPE_LESS_THAN_EQUAL });
      }
      this.addFilter.emit(filter);
    }
    this.matomoTracker.trackEvent(this.matomoCategory, ACTION_FILTER, 'Filter by ' + this.formatRange(range));
  }

  formatRange(range: { from: number; to: number }) {
    if (isNil(range.to)) {
      return `>${range.from}`;
    } else {
      return `${range.from + 1}-${range.to}`;
    }
  }

  reset() {
    this.activeIndex = null;
  }
}
