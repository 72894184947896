<app-opportunity-card cardTitle="Delivery Unit" class="delivery-unit-card">
  <div name="content" class="delivery-unit-content">
    <nz-table
      class="table-in-card"
      [nzScroll]="{ x: '1100px' }"
      [nzNoResult]="noData"
      [nzFrontPagination]="false"
      [nzShowPagination]="false"
      [nzData]="units"
    >
      <thead>
        <tr>
          <th nzLeft nzWidth="150px">Delivery Unit Name</th>
          <th nzWidth="105px">Total Number</th>
          <th nzWidth="600px">Member Name</th>
          <th nzRight nzAlign="center" nzWidth="150px">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of units">
          <td nzLeft>{{ item.unitName }}</td>
          <td>{{ item.totalNumber > 0 ? item.totalNumber : EmptyPlaceHolder }}</td>
          <td>
            <div
              nz-tooltip
              [nzTooltipTitle]="getUserNamesString(item.users)"
              nzTooltipOverlayClassName="custom-tooltip"
              nzTooltipPlacement="bottomLeft"
              class="text-ellipsis"
            >
              {{ getUserNamesString(item.users) | emptyFormat }}
            </div>
          </td>
          <td nzRight nzAlign="center" class="action-column">
            <app-icon-text
              class="action-item"
              className="icon-edit-blue"
              data-testid="user-edit-btn"
              (click)="openGovernanceUnitDialog(item.unitName, item.users)"
            ></app-icon-text>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</app-opportunity-card>

<app-edit-governance-unit-dialog
  [unitName]="currentUnitName"
  [existUserInfos]="currentUserInfos"
  (closeDialog)="closeEditDialog($event)"
  *ngIf="showEditGovernanceUnitModal"
></app-edit-governance-unit-dialog>

<ng-template #noData>
  <app-table-no-data [loadingFirst]="isLoading"></app-table-no-data>
</ng-template>
