import { Injectable } from '@angular/core';
import { AccountListExpand } from '../interfaces/AccountList';

@Injectable({
  providedIn: 'root',
})
export class AccountPageService {
  accountPage: AccountListExpand = {};

  set(page: any) {
    this.accountPage = page;
  }

  get() {
    return this.accountPage;
  }
}
