import { Component, OnDestroy } from '@angular/core';
import { saveAs } from 'file-saver';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ExportApi } from '@apis/export.api';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatomoTracker } from 'ngx-matomo';
import { ACTION_CLICK, ACTION_EXPORT, CATEGORY_NAVIGATION_TOP, NAME_EXPORT_MENU } from '@constants/matomo';
import { MomentFormat } from '@constants/dateFormat';
import { ToastMessageService } from '@services/toast-message.service';

const dropDownMenu = [
  {
    label: 'DSO Dashboard',
    type: 'dso',
  },
  {
    label: 'Opportunity Status',
    type: 'opportunity-status',
  },
  {
    label: 'Application Information',
    type: 'application-information',
  },
  {
    label: 'Milestone Information',
    type: 'dso-milestone',
  },
  {
    label: 'Milestone Overdue Dashboard',
    type: 'dso-milestone-overdue',
  },
  {
    label: 'Legal Documents',
    type: 'legal-documents',
  },
  {
    label: 'Opportunity Without Contract Dashboard',
    type: 'without-po',
  },
  {
    label: 'Invoice Information',
    type: 'invoice',
  },
  {
    label: 'Invoice Overdue Dashboard',
    type: 'invoice-overdue',
  },
  {
    label: 'Risk Project Dashboard',
    type: 'risk-project',
  },
];

@Component({
  selector: 'app-export-dropdown',
  templateUrl: './export-dropdown.component.html',
  styleUrls: ['./export-dropdown.component.scss'],
})
export class ExportDropdownComponent implements OnDestroy {
  private unsubscribe = new Subject();

  dropDownMenu = dropDownMenu;

  constructor(
    private exportApi: ExportApi,
    private message: NzMessageService,
    private toastService: ToastMessageService,
    private matomoTracker: MatomoTracker,
  ) {}

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  download(fileName: string, type: string) {
    this.matomoTracker.trackEvent(CATEGORY_NAVIGATION_TOP, ACTION_EXPORT, `Export ${fileName}`);
    const id = this.message.loading('Action in progress..', { nzDuration: 0 }).messageId;

    this.exportApi
      .exportExcel(type)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (data) => {
          const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
          this.message.remove(id);
          saveAs(blob, fileName + '(Download date ' + moment(Date.now()).format(MomentFormat.DATE) + ')');
          this.toastService.success('Export finished');
        },
        () => {
          this.message.remove(id);
        },
      );
  }

  onClickIcon() {
    this.matomoTracker.trackEvent(CATEGORY_NAVIGATION_TOP, ACTION_CLICK, NAME_EXPORT_MENU);
  }
}
