<div
  class="search-user"
  nz-dropdown
  [nzDropdownMenu]="search"
  nzTrigger="click"
  [(nzVisible)]="visibleSearchList"
  [nzDisabled]="isDisabled()"
>
  <input
    #inputField
    nz-input
    [ngClass]="{ invalid: showError(), disable: isDisabled() }"
    type="text"
    [(ngModel)]="inputValue"
    maxlength="30"
    [disabled]="isDisabled()"
    (ngModelChange)="handleSearchTextChange($event)"
    appDataTestId
    [dataTestId]="dataTestId"
  />
  <div *ngIf="!visibleSearchList" class="ant-form-item-explain-error" [class]="showError() ? '' : 'hide'">
    {{ errorTip }}
  </div>
</div>

<nz-dropdown-menu #search="nzDropdownMenu">
  <div class="user-result-container">
    <div *ngFor="let item of searchList" [class.hidden]="loading" class="result-item" (click)="onClickItem(item)">
      <div [class]="item?.employeeId ? 'people-name' : 'people-name people-name-full'">{{ item?.name }}</div>
      <div class="people-id" *ngIf="item?.employeeId">| {{ item?.employeeId }}</div>
    </div>
    <p class="no-data-found" *ngIf="searchList?.length === 0 && !loading">No user found</p>
    <nz-spin nzSimple class="load-icon-search" *ngIf="loading && searchList?.length === 0"></nz-spin>
  </div>
</nz-dropdown-menu>
