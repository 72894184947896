import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SearchItem } from '@interfaces/Filter';

@Injectable({ providedIn: 'root' })
export class ExportApi {
  constructor(private http: HttpClient) {}

  exportExcel(type: string, filters: SearchItem[] = null): Observable<any> {
    return this.http.post(`/export/${type}`, { filters }, { responseType: 'blob' });
  }
}
