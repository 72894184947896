import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'booleanFormat',
})
export class BooleanFormatPipe implements PipeTransform {
  transform(value: undefined | null | boolean): any {
    if (value === true) {
      return 'Yes';
    }
    if (value === false) {
      return 'No';
    }
    return '--';
  }
}
