import { Component, OnInit } from '@angular/core';
import { RegionalDso } from '@interfaces/Regional';
import { RegionalApi } from '@apis/regional.api';
import { TabIndexService } from '@services/tab-index.service';
import { MatomoTracker } from 'ngx-matomo';
import {
  ACTION_CLICK,
  ACTION_HOVER,
  CATEGORY_DASHBOARD_DSO,
  CATEGORY_DATE_TIPS,
  NAME_DASHBOARD_DSO,
  NAME_TOOLTIP_CHINA_OVERALL_DSO,
} from '@constants/matomo';
import { FilterService } from '@services/filter.service';
import { AutoUnsubscribe } from '@app/decorators/autoUnsubscribe';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { get } from 'lodash';

const tabTypeIndexMapper = {
  mu: 0,
  account: 1,
  opportunity: 2,
  owner: 3,
};

@AutoUnsubscribe()
@Component({
  selector: 'app-dso-dashboard',
  templateUrl: './dso-page.component.html',
  styleUrls: ['./dso-page.component.scss'],
})
export class DsoPageComponent implements OnInit {
  refreshDate: any;
  overallDSO: RegionalDso;
  totalRR: number;
  isDataTipsVisible = false;
  pageName = CATEGORY_DASHBOARD_DSO;
  unsubscribe: Subject<void>;

  constructor(
    private regionalApi: RegionalApi,
    private matomoTracker: MatomoTracker,
    public tabIndexService: TabIndexService,
    public filterService: FilterService,
  ) {}

  ngOnInit() {
    this.getOverallDso();
    this.filterService
      .listenFilter()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        const searchType = get(data.searchItem, 'type');
        if (searchType) {
          this.tabIndexService.setIndex(tabTypeIndexMapper[searchType]);
        }
      });
  }

  changeTabIndex(index) {
    this.tabIndexService.setIndex(index);
    const indexMap = { 0: 'MU', 1: 'Account', 2: 'Opportunity', 3: 'Opportunity Owner' };
    this.matomoTracker.trackEvent(CATEGORY_DASHBOARD_DSO, ACTION_CLICK, indexMap[index] + ' Tab');
  }

  getOverallDso() {
    this.regionalApi.getRegionalDso().subscribe((overallDSO) => {
      this.overallDSO = overallDSO;
      this.totalRR = overallDSO.regionalWipRevenue + overallDSO.regionalArRevenue;
    });
  }

  openDataTips(): void {
    this.isDataTipsVisible = true;
    this.matomoTracker.trackEvent(CATEGORY_DATE_TIPS, ACTION_CLICK, `Check Data Specification of ${this.pageName}`);
  }

  closeDataTips(): void {
    this.isDataTipsVisible = false;
  }

  onVisibleChange(visible: boolean) {
    if (visible) {
      this.matomoTracker.trackEvent(NAME_DASHBOARD_DSO, ACTION_HOVER, NAME_TOOLTIP_CHINA_OVERALL_DSO);
    }
  }
}
