import { Component, Inject, OnInit } from '@angular/core';
import { NzI18nService, en_US } from 'ng-zorro-antd/i18n';
import { AclApi } from '@apis/acl.api';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { debounceTime } from 'rxjs/operators';
import { SpinnerService } from '@services/spinner.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isLoading = false;
  delay = 200;
  constructor(
    private aclApi: AclApi,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private i18n: NzI18nService,
    private spinnerService: SpinnerService,
  ) {}

  async ngOnInit() {
    this.i18n.setLocale(en_US);
    this.spinnerService.isRunning.pipe(debounceTime(this.delay)).subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
  }
}
