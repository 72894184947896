<nz-table
  class="role-control"
  [nzData]="role"
  [nzScroll]="{ x: getTableWidth() + 'px' }"
  nzBordered
  [nzShowPagination]="false"
  [formGroup]="myGroup"
>
  <thead>
    <tr>
      <th nzWidth="300px" nzLeft="0" class="access-title">
        Access
        <app-error-tooltip
          type="warning"
          [message]="'View all and view affiliated information permission should be checked one at least'"
        ></app-error-tooltip>
      </th>
      <ng-container *ngFor="let item of role; index as i">
        <th nzWidth="160px" *ngIf="!(showEditRole && focusIndex === i)">
          <app-role-title-container
            type="original"
            [role]="item"
            (emitEditBtn)="onClickEditBtn(item, i)"
            [isAdmin]="item?.isAdmin"
            (emitDeleteRole)="DeleteRole()"
            [index]="i"
            [disableControl]="focusIndex !== -1 || (!adminRole && item.isAdmin)"
          ></app-role-title-container>
        </th>
        <th nzWidth="160px" *ngIf="showEditRole && focusIndex === i">
          <app-role-title-container
            type="edit"
            [role]="item"
            (emitClickSaveBtn)="onClickSaveBtn($event, item)"
            [isAdmin]="item?.isAdmin"
            (emitDeleteRole)="DeleteRole()"
            [index]="i"
            [disableControl]="focusIndex !== i || (!adminRole && item.isAdmin)"
          ></app-role-title-container>
        </th>
      </ng-container>
      <th nzWidth="160px" *ngIf="showNewRole">
        <app-role-title-container
          type="edit"
          (emitClickSaveBtn)="onClickSaveBtn($event, null)"
          (emitDeleteRole)="DeleteRole()"
          [index]="role?.length"
          #newRoleComponent
        ></app-role-title-container>
      </th>
      <th nzWidth="160px" class="add-role-title-container">
        <div class="add-role-title">
          <div>Role</div>
          <app-icon-text [className]="'icon-add'" [text]="'Add Role'" (click)="onClickAddRole()"></app-icon-text>
        </div>
      </th>
      <th *ngIf="adjustTable()"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let permissionItem of permission">
      <td class="permission" nzLeft="0">{{ permissionItem.description }}</td>
      <ng-container *ngFor="let item of role; index as i">
        <td *ngIf="!(showEditRole && focusIndex === i)" class="checkbox-td">
          <div class="checkbox-ui" [ngClass]="{ active: isActive(permissionItem, item), deactive: !isActive(permissionItem, item) }"></div>
        </td>
        <td *ngIf="showEditRole && focusIndex === i" class="checkbox-td">
          <label nz-checkbox [formControlName]="permissionItem.name" class="checkbox-regular" nzNoAnimation></label>
        </td>
      </ng-container>
      <td *ngIf="showNewRole" class="checkbox-td">
        <label nz-checkbox [formControlName]="permissionItem.name" class="checkbox-regular" nzNoAnimation></label>
      </td>
      <td></td>
    </tr>
  </tbody>
</nz-table>
