<app-right-drawer [isDataTipsVisible]="isDataTipsVisible" (closeTips)="closeTips()">
  <div class="info-area">
    <div class="info-world">Currency</div>
    <div>The currency of the milestone amount is unified as CNY.</div>
  </div>
  <div class="info-area">
    <div class="info-world">Overdue Days</div>
    <ul class="invoice-info top16 countdown-info">
      <li class="bold">
        <div></div>
        <div>Update Frequency</div>
        <div>Calculation Logic</div>
      </li>
      <li>
        <div><p class="bold ita">Overdue Days</p></div>
        <div>Daily</div>
        <div>
          <p>Overdue Days = Date Today - Milestone Due Date</p>
          <p class="top16">The milestone should be removed from this dashboard when the milestone was marked done.</p>
        </div>
      </li>
    </ul>
  </div>
  <div class="info-area">
    <p class="bold ita">* Default sorting</p>
    <span>The default sorting of the dashboard is countdown descending.</span>
  </div>
</app-right-drawer>
