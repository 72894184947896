import { Pipe, PipeTransform } from '@angular/core';
import { isEmpty, isNumber } from 'lodash';
import { EmptyPlaceHolder } from '@constants/common';

@Pipe({
  name: 'emptyFormat',
})
export class EmptyFormatPipe implements PipeTransform {
  transform(value: undefined | null | string | number, type?: string): any {
    return isEmpty(value) && !isNumber(value) ? (type === 'tag' ? 'Unassigned' : EmptyPlaceHolder) : value;
  }
}
