<nz-drawer [nzClosable]="false" [nzVisible]="isDataTipsVisible" nzPlacement="right" [nzWidth]="512" (nzOnClose)="closeDrawer()">
  <div class="drawer-content" *nzDrawerContent>
    <div class="info-column" (click)="closeDrawer()">
      <app-info-tag></app-info-tag>
    </div>
    <div class="info-detail">
      <p class="info-title">Data Specification</p>
      <div class="info-content">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</nz-drawer>
