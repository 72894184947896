export const INVOICE_STATUS_POSTED_TO_AR = 'Invoice Posted to AR';
export const INVOICE_STATUS_PAID = 'Invoice Paid';
export const INVOICE_STATUS_PART_PAID = 'Part Paid';
export const INVOICE_STATUS_CREDIT = 'Credit Posted to PS';
export const INVOICE_STATUS_NO_NEED_POST = 'No need to post';
export const INVOICE_STATUS_CLEARED = 'Cleared';

export const beforeCompletedStatus = [INVOICE_STATUS_POSTED_TO_AR];

export const completedStatus = [
  INVOICE_STATUS_PAID,
  INVOICE_STATUS_PART_PAID,
  INVOICE_STATUS_CREDIT,
  INVOICE_STATUS_CLEARED,
  INVOICE_STATUS_NO_NEED_POST,
];

export const stoppedStatus = [
  INVOICE_STATUS_POSTED_TO_AR,
  INVOICE_STATUS_PAID,
  INVOICE_STATUS_PART_PAID,
  INVOICE_STATUS_CREDIT,
  INVOICE_STATUS_NO_NEED_POST,
];

export const completedInvoiceStatus = [INVOICE_STATUS_PAID, INVOICE_STATUS_PART_PAID, INVOICE_STATUS_CREDIT];

export const updateCountdown = [INVOICE_STATUS_PAID, INVOICE_STATUS_PART_PAID];

export const badDebtStatus = [INVOICE_STATUS_POSTED_TO_AR, INVOICE_STATUS_PART_PAID];
