import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NzTableComponent } from 'ng-zorro-antd/table';
import { exhaustMap, filter, first, map, takeUntil, throttleTime } from 'rxjs/operators';
import { fromEvent, interval, Observable, Subject } from 'rxjs';
import { assign, findIndex, get, indexOf, isElement, isEmpty, last, set, startCase } from 'lodash';
import {
  DEFAULT_DASHBOARD_ACCOUNT_SORTING,
  SORT_MAP_TYPE_ACCOUNT,
  SORT_MAP_TYPE_OPPORTUNITY,
  SORT_MAP_TYPE_OWNER,
  sorting,
} from '@constants/sortMapping';
import { FilterService } from '@services/filter.service';
import { Filter, SearchItem } from '@interfaces/Filter';
import { getAccountFilters } from '@utils/filter';
import { SortMappingService } from '@services/sort-mapping.service';
import { AccountExpand, AccountInfo, AccountList, AccountListExpand, AccountMuList } from '@interfaces/AccountList';
import { AccountApi } from '@apis/account.api';
import { AccountPageService } from '@services/account-page.service';
import { OpportunityOwnerPageService } from '@services/opportunity-owner-page.service';
import { OpportunityPageService } from '@services/opportunity-page.service';
import { MuPageService } from '@services/mu-page.service';
import { MatomoTracker } from 'ngx-matomo';
import { ACTION_EDIT, ACTION_SORT, CATEGORY_DASHBOARD_ACCOUNT } from '@constants/matomo';
import { TabIndexService } from '@services/tab-index.service';
import { FILTER_STATUS_ONGOING } from '@constants/filterType';
import { transformMuList } from '@utils/dso-mu';

@Component({
  selector: 'app-account-list',
  templateUrl: './account-list.component.html',
  styleUrls: ['./account-list.component.scss'],
})
export class AccountListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('accountListEle') tableCom: NzTableComponent<any>;
  mapOfExpandedData: AccountExpand | AccountInfo[] = {};
  hashMap: any = [];
  columnsWidth = ['10%', '10%', '9%', '9%', '9%', '9%', '9%', '9%', '9%'];
  loadingFirst = false;
  loadingMore = false;
  isLastPage = false;
  filters: Array<SearchItem>;
  mapOfSort = {};
  muList: AccountMuList[];
  selectedAccount: number;
  private pageIndex = 0;
  private pageSize = 100;
  private scrollTop: number[] = [];
  private scrollEle: HTMLElement;
  private orders: any = DEFAULT_DASHBOARD_ACCOUNT_SORTING;
  private unsubscribe = new Subject();

  constructor(
    private accountApi: AccountApi,
    private tabIndexService: TabIndexService,
    private filterService: FilterService,
    private sortMappingService: SortMappingService,
    private accountPageService: AccountPageService,
    private opportunityOwnerPageService: OpportunityOwnerPageService,
    private opportunityPageService: OpportunityPageService,
    private muPageService: MuPageService,
    private matomoTracker: MatomoTracker,
  ) {}

  ngOnInit() {
    this.initFilterAndSortFromService();
    this.initAccountPage();
    this.triggerFilter();
    this.getMuList();
  }

  ngAfterViewInit(): void {
    this.listenScrollAndLoadMore();
  }

  ngOnDestroy(): void {
    this.setAccountPageToService();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private initFilterAndSortFromService() {
    this.mapOfSort = this.sortMappingService.getSort(SORT_MAP_TYPE_ACCOUNT);
    const searchItem = this.filterService.getSearchItem();
    this.getSelectedAccount(searchItem);
    this.filters = getAccountFilters(searchItem);
  }

  private initAccountPage() {
    const accountPage = get(this.accountPageService.get(), 'content', []);
    const isLastPage = get(this.accountPageService.get(), 'last', false);
    isEmpty(accountPage) && !isLastPage ? this.initPageData() : this.setPageData(this.accountPageService.get());
  }

  private initPageData() {
    this.loadingFirst = true;
    this.mapOfExpandedData = {};
    this.hashMap = [];
    this.pageIndex = -1;
    this.scrollTop = [];
    this.isLastPage = false;
    this.postForAccountList()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: AccountList) => {
        this.loadingFirst = false;
        this.scrollEle = null;
        this.setPageData(data, true);
      });
  }

  private setPageData(data: AccountList | AccountListExpand, shouldTransform = false) {
    if (shouldTransform) {
      this.mapOfExpandedData = this.setMapOfExpandedData(data.content as AccountInfo[]);
      this.hashMap = get(this.mapOfExpandedData, 'id', []);
    } else {
      this.mapOfExpandedData = get(data, 'content', {});
      this.hashMap = get(data, 'content.id', []);
    }
    this.pageIndex = data.number;
    this.isLastPage = data.last;
  }

  private setAccountPageToService() {
    const accountPage = {
      content: this.mapOfExpandedData,
      number: this.pageIndex,
      last: this.isLastPage,
    };
    this.accountPageService.set(accountPage);
  }

  private listenScrollAndLoadMore() {
    const nativeElement = this.tableCom.nzTableInnerScrollComponent.tableBodyElement.nativeElement;
    this.listenScroll(nativeElement)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: AccountList) => {
        this.loadingMore = false;
        set(data, 'content', assign({}, this.mapOfExpandedData, this.setMapOfExpandedData(data.content)));
        this.setPageData(data);
        this.scrollToCurrentListTop();
      });
  }

  private listenScroll(nativeElement) {
    return fromEvent(nativeElement, 'scroll').pipe(
      map((event) => this.scrollEle || (event as Event).target),
      filter((ele: HTMLElement) => {
        const isTriggerBottom = ele.scrollTop + ele.offsetHeight + 20 >= ele.scrollHeight;

        return !this.isLastPage && ele.scrollHeight !== 0 && isTriggerBottom;
      }),
      exhaustMap((ele: HTMLElement) => {
        this.scrollEle = ele;
        this.scrollTop.push(ele.scrollHeight - ele.offsetHeight);

        this.loadingMore = true;

        return this.postForAccountList();
      }),
      throttleTime(1000),
    );
  }

  private postForAccountList(): Observable<AccountList> {
    const targetPageIndex = this.pageIndex + 1;

    return this.accountApi.getAccountList(targetPageIndex, this.pageSize, this.orders, this.filters);
  }

  private scrollToCurrentListTop() {
    interval(50)
      .pipe(first(), takeUntil(this.unsubscribe))
      .subscribe(() => {
        if (isElement(this.scrollEle)) {
          this.scrollEle.scrollTop = last(this.scrollTop) || 0;
        }
      });
  }

  private setMapOfExpandedData(content: AccountInfo[]) {
    const cacheMap = {};
    if (!isEmpty(content)) {
      content.forEach((item) => {
        set(cacheMap, item.id, this.convertTreeToList(item));
      });
      set(cacheMap, 'id', assign([], this.hashMap));
    }

    return cacheMap;
  }

  private getSelectedAccount(searchItem) {
    const searchType = get(searchItem, 'type', undefined);
    if (searchType === 'account') {
      this.selectedAccount = get(searchItem, 'id', undefined);
    } else if (searchType === 'opportunity') {
      this.selectedAccount = get(searchItem, 'account.id', undefined);
    } else {
      this.selectedAccount = null;
    }
  }

  private triggerFilter() {
    this.filterService
      .listenFilter()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((item: Filter) => {
        const { searchItem } = item;
        this.getSelectedAccount(searchItem);
        this.filters = getAccountFilters(searchItem);
        this.clearSort();
        this.initPageData();
        this.clearDsoOtherDashboard();
      });
  }

  private clearDsoOtherDashboard() {
    this.muPageService.set([]);
    this.opportunityPageService.set({});
    this.sortMappingService.clear(SORT_MAP_TYPE_OPPORTUNITY);
    this.opportunityOwnerPageService.set({});
    this.sortMappingService.clear(SORT_MAP_TYPE_OWNER);
  }

  private clearSort() {
    this.orders = DEFAULT_DASHBOARD_ACCOUNT_SORTING;
    this.mapOfSort = {};
    this.sortMappingService.clear(SORT_MAP_TYPE_ACCOUNT);
  }

  getSort(value) {
    return get(this.mapOfSort, value, null);
  }

  convertTreeToList(root: AccountInfo): AccountExpand[] {
    const stack: AccountExpand[] = [];
    const array: AccountExpand[] = [];
    stack.push({ ...root, level: 0, expand: true });

    while (stack.length !== 0) {
      const node = stack.pop();
      this.visitNode(node, array);
      if (node.children) {
        for (let i = node.children.length - 1; i >= 0; i--) {
          stack.push({ ...node.children[i], level: get(node, 'level', 0) + 1, expand: false, parent: node });
        }
      }
    }

    return array;
  }

  private visitNode(node: AccountExpand, array: AccountExpand[]): void {
    if (indexOf(this.hashMap, node.id) === -1) {
      this.hashMap.push(node.id);
      array.push(node);
    }
  }

  sort(sortingType, sortKey: string) {
    if (!isEmpty(sortingType)) {
      this.orders = { [sortKey]: sorting[sortingType] };
      this.mapOfSort = { [sortKey]: sortingType };
      this.sortMappingService.setSort(SORT_MAP_TYPE_ACCOUNT, this.mapOfSort);
      this.initPageData();
      this.matomoTracker.trackEvent(CATEGORY_DASHBOARD_ACCOUNT, ACTION_SORT, `Sort by ${startCase(sortKey)}`);
    }
  }

  getMuList() {
    this.accountApi
      .getMUList()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((muList) => (this.muList = transformMuList(muList)));
  }

  onChangeMu(editMu: string, id: number, item: any) {
    const index = findIndex(this.mapOfExpandedData[id], item);
    set(this.mapOfExpandedData[id][index], 'mu', editMu);
    this.muPageService.set([]);
    this.matomoTracker.trackEvent(CATEGORY_DASHBOARD_ACCOUNT, ACTION_EDIT, `Edit MU of ${item.name}`);
  }

  filterOpportunityByAccount(accountName: string, accountId: number) {
    const searchItem = { id: accountId, name: accountName, type: 'account' };
    const filterItem: Filter = {
      searchItem,
      status: FILTER_STATUS_ONGOING,
      countDown: {},
      withoutPoDays: {},
    };
    this.filterService.triggerFilter(filterItem);
    this.tabIndexService.setIndex(2);
  }
}
