import { Component } from '@angular/core';

@Component({
  selector: 'app-role-access',
  templateUrl: './role-access.component.html',
  styleUrls: ['./role-access.component.scss'],
})
export class RoleAccessComponent {
  selectedTabIndex = 0;
}
