<div class="left-part" [hidden]="!showAll">
  <div class="information-item">
    <label>Email Subject</label>
    <span class="email-subject">{{ applicationInfo?.emailSubject | emptyFormat }}</span>
  </div>
  <div class="information-item">
    <label>Application Date</label>
    <span>{{ applicationInfo?.applicationDate | emptyFormat }}</span>
  </div>
</div>
<div class="right-part">
  <div class="information-item">
    <label>Committed Amount</label>
    <span>{{ applicationInfo?.committedAmount | currencyFormat : applicationInfo?.currency : false }}</span>
  </div>

  <div class="information-item">
    <label>Expected Man Day</label>
    <span>{{ applicationInfo?.expectedManDay | number : '1.0-2' : 'en-US' | emptyFormat }}</span>
  </div>

  <div class="information-item">
    <label>Expected Contract Receiving Date</label>
    <span>{{ applicationInfo?.expectedContractReceivingDate | emptyFormat }}</span>
  </div>
</div>
<div class="operation-icons">
  <a
    class="google-drive-link"
    *ngIf="!!applicationInfo?.googleDriveLink"
    [href]="applicationInfo?.googleDriveLink"
    target="_blank"
    referrerpolicy="no-referrer"
    nz-tooltip
    nzTooltipOverlayClassName="application-tooltip"
    nzTooltipTitle="Open Link"
    nzTooltipPlacement="bottomLeft"
  >
    <i nz-icon nzType="svg:external-link" class="circle-icon-wrapper"></i>
  </a>
  <i
    *ngIf="aclService.isPermission(aclService.features.applicationInformationEdit)"
    nz-icon
    nzType="svg:edit-no-circle"
    class="edit-icon circle-icon-wrapper"
    (click)="handleOpenEdit()"
    nz-tooltip
    nzTooltipOverlayClassName="application-tooltip"
    [nzTooltipTitle]="isCreate ? 'Add an Application' : 'Update Application'"
    nzTooltipPlacement="bottomLeft"
  ></i>
</div>
<a nz-button nzType="link" class="show-detail" *ngIf="!showAll; else hideDetail" (click)="showAll = true">
  See Detail
  <i nz-icon nzType="svg:right-arrow"></i>
</a>
<ng-template #hideDetail>
  <a nz-button nzType="link" class="hide-detail" (click)="showAll = false">
    <i nz-icon nzType="svg:left-arrow"></i>
    Hide
  </a>
</ng-template>
<nz-modal
  nzClassName="application-modal"
  [nzVisible]="isShowEditModal"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="null"
  (nzOnCancel)="handleCancelEdit()"
>
  <ng-template #modalTitle>
    <div class="modal-title-with-label">Application Information</div>
  </ng-template>

  <ng-template #modalContent>
    <form *ngIf="!!applicationForm" nz-form nzLayout="vertical" [formGroup]="applicationForm">
      <nz-form-item class="email-subject-input">
        <nz-form-label nzRequired>Email Subject</nz-form-label>
        <nz-form-control nzErrorTip="Please input E-mail subject">
          <nz-textarea-count [nzMaxCharacterCount]="255">
            <textarea nz-input formControlName="emailSubject" nzAutosize maxlength="255"></textarea>
          </nz-textarea-count>
        </nz-form-control>
      </nz-form-item>
      <div nz-row [nzGutter]="16">
        <div nz-col [nzSpan]="12">
          <nz-form-item>
            <nz-form-label nzRequired>Expected Contract Receiving Date</nz-form-label>
            <nz-form-control nzErrorTip="Please select date">
              <nz-date-picker
                nzPlaceHolder=" "
                formControlName="expectedContractReceivingDate"
                [nzSuffixIcon]="null"
                nzFormat="yyyy-MM-dd"
              ></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12">
          <nz-form-item>
            <nz-form-label>Application Date</nz-form-label>
            <nz-form-control>
              <nz-date-picker
                nzPlaceHolder=" "
                formControlName="applicationDate"
                [nzSuffixIcon]="null"
                nzFormat="yyyy-MM-dd"
              ></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row [nzGutter]="16">
        <div nz-col [nzSpan]="12">
          <nz-form-item>
            <nz-form-label>Committed Amount in Application</nz-form-label>
            <nz-form-control
              [nzValidateStatus]="applicationForm.controls.committedAmount.errors?.max ? 'error' : 'success'"
              nzErrorTip="Maximum value exceeded"
            >
              <nz-input-group nzCompact>
                <nz-select
                  style="width: 32%"
                  formControlName="currency"
                  [nzSuffixIcon]="selectIcon"
                  class="currency-select"
                  [nzOptionHeightPx]="32"
                  nzDropdownClassName="default-size-dropdown"
                >
                  <nz-option *ngFor="let currencyItem of CURRENCYS" [nzLabel]="currencyItem" [nzValue]="currencyItem"></nz-option>
                </nz-select>
                <ng-template #selectIcon>
                  <i class="select-arrow" nz-icon nzType="svg:arrow" alt="arrow"></i>
                </ng-template>
                <nz-input-number
                  style="width: 68%"
                  formControlName="committedAmount"
                  [nzPrecision]="2"
                  nzPrecisionMode="cut"
                  [nzFormatter]="formatterMoney"
                  (keydown)="preventTypingWhenExceed($event, 11)"
                ></nz-input-number>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12">
          <nz-form-item>
            <nz-form-label>Expected Man Day in Application</nz-form-label>
            <nz-form-control>
              <nz-input-number
                style="width: 100%"
                formControlName="expectedManDay"
                [nzPrecision]="2"
                nzPrecisionMode="cut"
              ></nz-input-number>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <nz-form-item class="last-form-item">
        <nz-form-label>Google Drive Link</nz-form-label>
        <nz-form-control nzErrorTip="Please input a valid link">
          <input formControlName="googleDriveLink" nz-input maxlength="255" />
        </nz-form-control>
      </nz-form-item>
      <div class="operation-btns">
        <button nz-button nzType="default" type="button" (click)="handleCancelEdit()">Cancel</button>
        <button nz-button nzType="primary" type="button" [nzLoading]="isSaving" (click)="submitForm()">Save</button>
      </div>
    </form>
  </ng-template>
</nz-modal>
