export const EditType = {
  EDIT: 'edited',
  NEW: 'new',
  DELETE: 'deleted',
};

export const HistoryTagType = {
  EDITED: 'edited',
  NEW: 'new',
  DELETED: 'deleted',
  DONE: 'done',
  NO_CHANGED: 'no-changed',
};
