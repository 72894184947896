import { NgModule } from '@angular/core';
import { MilestoneHighlightDirective } from './milestone-highlight.directive';
import { ToggleDirective } from './toogle.directive';
import { MyAutofocusDirective } from './my-autofocus.directive';
import { ChangeCurrencyDirective } from './change-currency.directive';
import { WithoutPoDaysClickDirective } from './without-po-days-click.directive';
import { InvoiceHighlightDirective } from './invoice-highlight.directive';
import { NullDefaultValueDirective } from './null-default-value.directive';
import { TestIdDirective } from '@app/directives/test-id.directive';

@NgModule({
  declarations: [
    MilestoneHighlightDirective,
    ToggleDirective,
    MyAutofocusDirective,
    ChangeCurrencyDirective,
    WithoutPoDaysClickDirective,
    InvoiceHighlightDirective,
    NullDefaultValueDirective,
    TestIdDirective,
  ],
  exports: [
    MilestoneHighlightDirective,
    ToggleDirective,
    MyAutofocusDirective,
    ChangeCurrencyDirective,
    WithoutPoDaysClickDirective,
    InvoiceHighlightDirective,
    NullDefaultValueDirective,
    TestIdDirective,
  ],
})
export class DirectiveModule {}
