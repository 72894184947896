import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from '@view/error-page/not-found.component';
import { ForbiddenComponent } from '@view/error-page/forbidden.component';
import { UploadRevenueComponent } from '@view/upload-view/upload-revenue/upload-revenue.component';
import { AuthGuard } from '@view/dso-view/dso-view-routing.module';
import { OktaCallbackComponent } from '@okta/okta-angular';
import { OpportunityNoPermissionComponent } from '@view/error-page/opportunity-no-permission.component';

const routes: Routes = [
  {
    path: 'implicit/callback',
    component: OktaCallbackComponent,
  },
  {
    path: 'upload/revenue',
    component: UploadRevenueComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/dso/opportunity',
  },
  {
    path: 'forbidden',
    component: ForbiddenComponent,
  },
  {
    path: 'opportunity-no-permission',
    component: OpportunityNoPermissionComponent,
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  providers: [],
})
export class AppRoutingModule {}
