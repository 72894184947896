<span
  *ngIf="!invoice.badDebtAmount"
  class="bad-debt bad-debt-text"
  nz-popover
  [nzPopoverTitle]="badDebtPopoverTitle"
  [nzPopoverContent]="badDebtPopoverContent"
  [nzPopoverOverlayClassName]="'notesPopover'"
  nzPopoverPlacement="leftBottom"
  [nzPopoverTrigger]="popoverTrigger"
  [(nzPopoverVisible)]="showPopover"
>
  Bad Debt
</span>

<span
  *ngIf="!!invoice.badDebtAmount"
  class="bad-debt bad-debt-amount"
  nz-popover
  [nzPopoverTitle]="badDebtPopoverTitle"
  [nzPopoverContent]="badDebtPopoverContent"
  [nzPopoverOverlayClassName]="'notesPopover'"
  nzPopoverPlacement="leftBottom"
  [nzPopoverTrigger]="popoverTrigger"
  [(nzPopoverVisible)]="showPopover"
>
  {{ invoice.badDebtAmount | currencyFormat : invoice.badDebtCurrency }}
</span>

<ng-template #badDebtPopoverTitle>
  <div class="bad-debt-title-container">
    <div class="debt-title">
      <div class="title">Add Bad Debt Reason</div>
      <app-icon-text
        [className]="'icon-delete'"
        [text]="'Remove the Tag'"
        [isDisabled]="invoice.badDebtAmount <= 0 || !aclService.isPermission(aclService.features.invoiceEditBadDebt)"
        nz-popconfirm
        [nzPopconfirmTrigger]="removeTrigger"
        nzOkText="Yes"
        nzCancelText="Cancel"
        nzPopconfirmTitle="You will lose the bad debt amount and note if you remove the tag. Are you sure to remove it?"
        nzPopconfirmPlacement="bottom"
        nzPopconfirmOverlayClassName="confirmDeletePopover"
        (nzOnConfirm)="deleteBadDebt()"
      ></app-icon-text>
    </div>
    <div class="invoice-id">Invoice ID: {{ invoice.congaInvoiceNumber }}</div>
  </div>
</ng-template>

<ng-template #badDebtPopoverContent>
  <div class="bad-debt-content-container">
    <div class="bad-debt-label">
      <div>
        <span class="required-icon">*</span>
        Bad Debt Amount (Including Tax)
      </div>
      <span *ngIf="invoice.badDebtAmount">{{ invoice.badDebtUpdateDate | date : dateType.DATE }}</span>
    </div>
    <div class="amount-input">
      <span class="amount-prefix" appChangeCurrency [currency]="invoice.badDebtCurrency"></span>
      <input
        type="number"
        (input)="onInputAmount($event.target.value)"
        (blur)="onBlur($event.target.value)"
        [value]="badDebtAmount"
        [disabled]="!aclService.isPermission(aclService.features.invoiceEditBadDebt)"
      />
    </div>
    <div class="maximum">*maximum {{ invoice?.maxBadDebtAmount | currencyFormat : invoice?.badDebtCurrency }}</div>
    <label>Description</label>
    <textarea
      maxlength="500"
      nz-input
      [(ngModel)]="badDebtDescription"
      [disabled]="!aclService.isPermission(aclService.features.invoiceEditBadDebt)"
    ></textarea>
    <div class="btn-wrapper">
      <button nz-button nzType="default" (click)="cancel()" [disabled]="!aclService.isPermission(aclService.features.invoiceEditBadDebt)">
        Cancel
      </button>
      <button
        nz-button
        nzType="primary"
        nz-popconfirm
        nzOkText="Yes"
        nzCancelText="Cancel"
        nzPopconfirmTitle="Are you sure to tag this invoice as bad debt?"
        nzPopconfirmPlacement="bottom"
        nzPopconfirmOverlayClassName="confirmDeletePopover"
        (nzOnConfirm)="save()"
        [disabled]="badDebtAmount <= 0 || !aclService.isPermission(aclService.features.invoiceEditBadDebt)"
      >
        <i nz-icon nzType="loading" *ngIf="isRequesting" class="centre"></i>
        <span *ngIf="!isRequesting">Save</span>
      </button>
    </div>
  </div>
</ng-template>
