import { AfterContentChecked, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BackToDashboardService } from '@services/back-to-dashboard.service';
import { Router } from '@angular/router';
import { AclService } from '@services/acl.service';
import { drop, get, join, map, take } from 'lodash';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { isNil } from 'ng-zorro-antd/core/util';
import { TableRole } from '@interfaces/Acl';
import { MatomoTracker } from 'ngx-matomo';
import { AclApi } from '@apis/acl.api';
import { ACTION_CLICK, CATEGORY_NAVIGATION_TOP, NAME_ENTER_INFO, NAME_ENTER_ROLE_PAGE } from '@constants/matomo';
import { formatRole } from '@utils/utils';

@Component({
  selector: 'app-login-menu',
  templateUrl: './login-menu.component.html',
  styleUrls: ['./login-menu.component.scss'],
})
export class LoginMenuComponent implements AfterContentChecked, OnInit {
  @ViewChild('userInfo') template: TemplateRef<any>;
  roleLink: string;
  roles: TableRole[];

  constructor(
    private backToDashboardService: BackToDashboardService,
    private route: Router,
    public aclService: AclService,
    private notificationService: NzNotificationService,
    private aclApi: AclApi,
    private matomoTracker: MatomoTracker,
  ) {}

  ngOnInit(): void {
    const userId = get(this.aclService.getUser(), 'employeeId', null);
    if (!isNil(userId)) {
      this.matomoTracker.setUserId(userId);
      this.matomoTracker.setCustomVariable(1, 'userType', join(map(formatRole(this.aclService.getRoles()), 'name'), '-'), 'visit');
    }
  }

  ngAfterContentChecked(): void {
    const path = drop(take(this.route.url.split('/'), 3), 2);
    this.roleLink = `/dso/${path}/role`;
  }

  setBackButtonStatus() {
    this.backToDashboardService.disable = false;
    this.onClickMatomoEvent(NAME_ENTER_ROLE_PAGE);
  }

  showAllRoles() {
    this.notificationService.template(this.template);
    this.onClickMatomoEvent(NAME_ENTER_INFO);
  }

  onClickMatomoEvent(name: string) {
    this.matomoTracker.trackEvent(CATEGORY_NAVIGATION_TOP, ACTION_CLICK, name);
  }
}
