import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DSOMilestone } from '@interfaces/Milestone';
import { MilestoneApi } from '@apis/milestone.api';
import { finalize, takeUntil } from 'rxjs/operators';
import { ToastMessageService } from '@services/toast-message.service';
import { SpinnerService } from '@services/spinner.service';
import { Subject } from 'rxjs';
import { MatomoTracker } from 'ngx-matomo';
import { ACTION_CLICK, NAME_CANCEL_BUTTON, NAME_CANCEL_FOR_SUBMIT, NAME_YES_FOR_SUBMIT } from '@constants/matomo';
import { UploadComponent } from '@shared/upload/upload.component';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-mark-milestone-done-dialog',
  templateUrl: './mark-milestone-done-dialog.component.html',
  styleUrls: ['./mark-milestone-done-dialog.component.scss'],
})
export class MarkMilestoneDoneDialogComponent {
  @ViewChild(UploadComponent) uploadComponent: UploadComponent;
  @Input() title = '';
  @Input() isVisible = false;
  @Input() currency = '';
  @Input() pageName = '';
  @Input() milestone: DSOMilestone;
  @Input() isReupload = false;
  @Output() cancelled = new EventEmitter();
  @Output() saved = new EventEmitter();

  isConfirmPopVisible = false;
  isSubmitting = false;
  unsubscribe = new Subject();
  isDriveLinkInvalid = false;
  showWarn = false;

  constructor(
    private milestoneApi: MilestoneApi,
    private toastMessageService: ToastMessageService,
    private spinnerService: SpinnerService,
    private matomoTracker: MatomoTracker,
  ) {}

  get dialogName() {
    return `${this.pageName} Milestone Mark Done`;
  }

  handleCancel() {
    this.isConfirmPopVisible = false;
    this.showWarn = false;
    this.cancelled.emit();
    this.uploadComponent.cancelSubmit();
    this.matomoTracker.trackEvent(this.dialogName, ACTION_CLICK, NAME_CANCEL_BUTTON);
  }

  handleSubmit() {
    if (this.uploadComponent.validFiles()) {
      this.isSubmitting = true;
      const fileIds = this.uploadComponent.fileList.map((item) => item.response.fileId);
      const params = new HttpParams().set('fileIds', fileIds.toString());
      if (this.isReupload) {
        params.set('milestoneEditId', this.milestone.milestoneEditId.toString());
        this.milestoneApi
          .reuploadFile(fileIds, this.milestone.milestoneEditId)
          .pipe(
            this.spinnerService.loading(),
            takeUntil(this.unsubscribe),
            finalize(() => {
              this.isSubmitting = false;
              this.isConfirmPopVisible = false;
            }),
          )
          .subscribe(() => {
            this.toastMessageService.success('Submit Successfully');
            this.saved.emit();
            this.matomoTracker.trackEvent(this.dialogName, ACTION_CLICK, NAME_YES_FOR_SUBMIT);
          });
      } else {
        this.milestoneApi
          .markMilestoneAsDone(this.milestone, params)
          .pipe(
            this.spinnerService.loading(),
            takeUntil(this.unsubscribe),
            finalize(() => {
              this.isSubmitting = false;
              this.isConfirmPopVisible = false;
            }),
          )
          .subscribe(() => {
            this.toastMessageService.success('Submit Successfully');
            this.saved.emit();
            this.matomoTracker.trackEvent(this.dialogName, ACTION_CLICK, NAME_YES_FOR_SUBMIT);
          });
      }
    }

    this.isConfirmPopVisible = false;
  }

  openConfirmPop() {
    if (this.uploadComponent.validFiles()) {
      this.isConfirmPopVisible = true;
    } else {
      this.showWarn = true;
    }
  }

  validateDriveLink() {
    this.isDriveLinkInvalid = !this.milestone.googleDriveLink?.match(/^https:\/\/(drive|docs).google.com/);
    return this.isDriveLinkInvalid;
  }

  handleCancelSubmit() {
    this.isConfirmPopVisible = false;
    this.matomoTracker.trackEvent(this.dialogName, ACTION_CLICK, NAME_CANCEL_FOR_SUBMIT);
  }

  submitFile() {
    this.showWarn = false;
  }
}
