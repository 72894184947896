<i
  nz-icon
  nzType="exclamation-circle"
  nzTheme="fill"
  [ngClass]="{ 'error-tips-icon': type === 'error', 'warning-tips-icon': type === 'warning' }"
  nz-tooltip
  nzTooltipPlacement="rightTop"
  [nzTooltipTitle]="permissionError"
></i>
<ng-template #permissionError>
  <div class="error-message">
    {{ message }}
  </div>
</ng-template>
