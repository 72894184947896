import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-info-edit-selector',
  templateUrl: './info-edit-selector.component.html',
  styleUrls: ['./info-edit-selector.component.scss'],
})
export class InfoEditSelectorComponent implements OnInit {
  @Input() initValue: string;
  @Input() optionList: Array<{ label: string; value: string }>;
  @Output() save = new EventEmitter<string>();
  selectedValue = null;

  ngOnInit(): void {
    this.selectedValue = this.initValue;
  }
  callSave($event: MouseEvent) {
    $event.stopPropagation();
    this.save.emit(this.selectedValue);
  }
}
