import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { sortBy } from 'lodash';
import { YearMonthsInfo } from '@interfaces/YearMonthsInfo';

@Component({
  selector: 'app-months-picker',
  templateUrl: './months-picker.component.html',
  styleUrls: ['./months-picker.component.scss'],
})
export class MonthsPickerComponent implements OnInit {
  @Input() defaultMonthsInfo: string;
  @Output() submitMonths = new EventEmitter<string>();
  @Output() cancel = new EventEmitter();

  MULTI_SEPARATOR = ',';
  RANGE_SEPARATOR = ' to ';
  disableSubmit = false;
  rangePickerModel = [];
  monthPickerModel = [];
  currentMonthsInfo: YearMonthsInfo = {
    isRange: true,
    values: [],
  };

  ngOnInit(): void {
    this.reset();
  }

  onChangePickerType() {
    this.clear();
  }

  onRangeCalendarChange(months: Array<Date>) {
    let startMonth = '';
    let endMonth = '';
    let monthsInputValue;
    if (months.length > 0) {
      startMonth = this.getYearMonthByDate(months[0]);
    }
    if (months.length > 1) {
      endMonth = this.getYearMonthByDate(months[1]);
      monthsInputValue = sortBy([startMonth, endMonth]);
    } else {
      monthsInputValue = [startMonth, endMonth];
    }
    this.currentMonthsInfo = {
      isRange: this.currentMonthsInfo.isRange,
      values: monthsInputValue,
    };
    this.disableSubmit = months.length === 1;
  }

  onMultiMonthsSelect(yearMonths: Array<string>) {
    this.currentMonthsInfo = {
      isRange: this.currentMonthsInfo.isRange,
      values: yearMonths,
    };
  }

  onClickCancel() {
    this.cancel.emit();
  }

  onClickSubmit() {
    this.submitMonths.emit(this.convertYearMonthsInfoToPeriod(this.currentMonthsInfo));
  }

  clear() {
    this.currentMonthsInfo.values = [];
    this.rangePickerModel = [];
    this.monthPickerModel = [];
    this.disableSubmit = false;
  }

  reset() {
    this.currentMonthsInfo = this.convertPeriodString(this.defaultMonthsInfo);
    const values = this.currentMonthsInfo.values;
    if (this.currentMonthsInfo.isRange) {
      if (values.length > 1) {
        const startYearMonth = new Date(values[0]);
        const endYearMonth = new Date(values[1]);
        this.rangePickerModel = [startYearMonth, endYearMonth];
      }
    } else {
      if (values.length > 0) {
        this.monthPickerModel = [...values];
      }
    }
  }

  getYearMonthByDate(date: Date) {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);

    return `${year}-${month}`;
  }

  convertPeriodString(period: string): YearMonthsInfo {
    const periodValue = period || '';
    let isRange = true;
    let values = periodValue.split(this.RANGE_SEPARATOR);
    if (periodValue !== '' && (periodValue.includes(this.MULTI_SEPARATOR) || !periodValue.includes(this.RANGE_SEPARATOR))) {
      isRange = false;
      values = periodValue.split(this.MULTI_SEPARATOR);
    }

    return {
      isRange,
      values,
    };
  }

  convertYearMonthsInfoToPeriod(yearMonthsInfo: YearMonthsInfo): string {
    if (yearMonthsInfo.isRange) {
      return yearMonthsInfo.values.join(this.RANGE_SEPARATOR);
    }

    return yearMonthsInfo.values.join(this.MULTI_SEPARATOR);
  }
}
