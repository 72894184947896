<div class="opportunity-info" cdkScrollable>
  <app-as-off-date [date]="dailyRefreshDate"></app-as-off-date>
  <div class="opportunity-title-stamp">
    <div class="mu-account">
      <app-mu-tag [mu]="opportunityDetail?.market"></app-mu-tag>
      <div class="account-name" [class.deactive-gray]="deactivated">{{ opportunityDetail?.account?.name }}</div>
    </div>
    <div class="stamp" *ngIf="deactivated">
      <div class="stamp-title">Deactive</div>
      <div class="stamp-date">
        <span class="info">Deactivation Date：</span>
        <span class="date">{{ deactivationDate | date : dateType.DATE | emptyFormat }}</span>
      </div>
    </div>
    <div class="delivery-unit">
      <div class="title">Delivery Unit</div>
      <div class="name">{{ opportunityDetail?.deliveryUnit | emptyFormat }}</div>
    </div>
  </div>
  <div class="opportunity-name" [class.deactive-gray]="deactivated">
    <label class="label">
      <a
        nzTooltipPlacement="top"
        target="_blank"
        [nzTooltipTitle]="opportunityNameToolTips"
        nz-tooltip
        href="{{ salesFunnel }}{{ opportunityDetail.sfOpportunityId }}"
      >
        {{ opportunityDetail?.name }}
      </a>
    </label>
  </div>
  <div class="opportunity-status-legal-documents">
    <app-opportunity-status [opportunityDetail]="opportunityDetail"></app-opportunity-status>
    <app-legal-documents class="opportunity-card legal-documents-border" [opportunityDetail]="opportunityDetail"></app-legal-documents>
  </div>
  <div class="opportunity-detail">
    <div>
      <div class="opportunity-card billing-info">
        <div class="header-title">
          <img src="/assets/svg/billing-info.svg" alt="" />
          <span class="blue size16">Billing Information</span>
        </div>
        <div class="billing-detail">
          <div class="billing-row">
            <div class="column">
              <div>
                <label class="label">Amount</label>
                <span>{{ opportunityDetail?.contractAmount | currencyFormat : opportunityDetail.currency }}</span>
              </div>
              <div>
                <label class="label">Start Date</label>
                <span>{{ opportunityDetail?.startDate | date : dateType.DATE | emptyFormat }}</span>
              </div>
            </div>
            <div class="column">
              <div>
                <label class="label">Currency</label>
                <span>{{ opportunityDetail?.currency | emptyFormat }}</span>
              </div>
              <div>
                <label class="label">End Date</label>
                <span>{{ opportunityDetail?.endDate | date : dateType.DATE | emptyFormat }}</span>
              </div>
            </div>
            <div class="column project-code">
              <div>
                <label class="label">Project Code</label>
                <span>{{ opportunityDetail?.projectCode | emptyFormat }}</span>
              </div>
              <div>
                <label class="label">Opportunity Close Date</label>
                <span>{{ opportunityDetail?.closeDate | date : dateType.DATE | emptyFormat }}</span>
              </div>
            </div>
          </div>
          <div class="billing-grid">
            <div>
              <label class="label">Type</label>
              <span>{{ opportunityDetail?.contractType | emptyFormat }}</span>
            </div>
            <div>
              <label class="label">Next Invoice Date</label>
              <span>{{ opportunityDetail?.nextInvoiceDueDate | date : dateType.DATE | emptyFormat }}</span>
            </div>
            <div>
              <label class="label">Owner Name</label>
              <span class="padding-right-size8">{{ opportunityDetail?.ownerName | emptyFormat }}</span>
            </div>
            <div>
              <label class="label">Project Manager</label>
              <span class="padding-right-size8">{{ opportunityDetail?.projectManagerName | emptyFormat }}</span>
            </div>
            <div>
              <label class="label">
                Delegated
                <br />
                Project Manager
              </label>
              <span class="padding-right-size8">{{ opportunityDetail?.delegatedProjectManagerName | emptyFormat }}</span>
            </div>
            <div>
              <label class="label">Payment Term</label>
              <span>{{ opportunityDetail?.paymentTerms | emptyFormat }}</span>
            </div>
            <div>
              <label class="label">Region</label>
              <span>{{ opportunityDetail?.region | emptyFormat }}</span>
            </div>
            <div class="owner-email">
              <label class="label">Owner E-mail</label>
              <span>{{ opportunityDetail?.ownerEmail | emptyFormat }}</span>
            </div>
            <div class="owner-email">
              <label class="label">Project Manager E-mail</label>
              <span>{{ opportunityDetail?.projectManagerEmail | emptyFormat }}</span>
            </div>
            <div class="owner-email">
              <label class="label">
                Delegated
                <br />
                Project Manager E-mail
              </label>
              <span>{{ opportunityDetail?.delegatedProjectManagerEmail | emptyFormat }}</span>
            </div>
            <div *ngIf="!isFixBidType()">
              <label class="label">Invoice Frequency</label>
              <span>{{ opportunityDetail?.invoiceFrequency | emptyFormat }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="opportunity-card">
      <div class="dso-header">
        <div class="header-title">
          <img src="/assets/svg/dso-info.svg" alt="" />
          <span class="blue size16">Opportunity DSO</span>
        </div>
        <div class="date">
          <app-as-off-date [date]="opportunityDetail.refreshDate"></app-as-off-date>
        </div>
      </div>
      <div class="dso-detail">
        <div>
          <div class="dso-detail-current">
            <div class="column">
              <div>
                <label class="label">
                  Current
                  <span [nzTooltipTitle]="currentToolTips" nzTooltipPlacement="rightTop" nz-tooltip>
                    <i nz-icon nzType="exclamation-circle" nzTheme="fill" class="current-tips-icon"></i>
                  </span>
                </label>
                <app-dso-format dsoType="dso" [dsoValue]="opportunityDetail.dso"></app-dso-format>
              </div>
              <div>
                <label class="label">Billed</label>
                <app-dso-format dsoType="billed" [dsoValue]="opportunityDetail.billedDso"></app-dso-format>
              </div>
            </div>
            <div class="column">
              <div class="unbilled">
                <label class="label">Unbilled</label>
                <app-dso-format dsoType="unbilled" [dsoValue]="opportunityDetail.unBilledDso"></app-dso-format>
              </div>
            </div>
          </div>
          <div class="dso-detail-daily">
            <div class="column" *ngIf="shouldShowArAndWipAndRrInFunctionalCurrency()">
              <div class="dso-daily-title">Functional Currency</div>
              <div class="dso-detail-currency">
                {{ opportunityDetail.functionalCurrency }}
              </div>
              <div>
                <label class="label">Recognized Revenue</label>
                <span>
                  {{ opportunityDetail.totalRecognizedRevenueInFunctionalCurrency | currencyFormat : opportunityDetail.functionalCurrency }}
                </span>
              </div>
              <div>
                <label class="label">AR</label>
                <span>{{ opportunityDetail.totalArRevenue | currencyFormat : opportunityDetail.functionalCurrency }}</span>
              </div>
              <div>
                <label class="label">WIP</label>
                <span>{{ opportunityDetail.totalWipRevenue | currencyFormat : opportunityDetail.functionalCurrency }}</span>
              </div>
            </div>
            <div class="column">
              <div class="dso-daily-title">Opportunity Currency</div>
              <div class="dso-detail-currency">
                {{ opportunityDetail.currency }}
              </div>
              <div>
                <label class="label">Recognized Revenue</label>
                <span>{{ opportunityDetail.totalRecognizedRevenue | currencyFormat : opportunityDetail.currency }}</span>
              </div>
              <div>
                <label class="label">AR</label>
                <span>{{ opportunityDetail.totalArRevenueInOpportunityCurrency | currencyFormat : opportunityDetail.currency }}</span>
              </div>
              <div>
                <label class="label">WIP</label>
                <span>{{ opportunityDetail.totalWipRevenueInOpportunityCurrency | currencyFormat : opportunityDetail.currency }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="dso-detail-chart">
          <app-history-bar-chart [data]="dsoHistory" [loading]="chartLoading" [chartOption]="chartOption"></app-history-bar-chart>
        </div>
      </div>
    </div>
  </div>
  <ng-container *appFeatureToggle="'showMilestoneFeature'">
    <ng-container *ngIf="isFixBidType()">
      <app-opportunity-milestone-edit
        *ngIf="showMilestoneEdit()"
        [opportunityDetail]="opportunityDetail"
        [milestones]="milestones"
        [lastSubmitMilestones]="lastSubmitMilestones"
        (changed)="handleMilestoneChanged()"
      ></app-opportunity-milestone-edit>
      <app-opportunity-milestone
        *ngIf="showSubmittedMilestone()"
        [opportunityDetail]="opportunityDetail"
        [lastSubmitMilestones]="lastSubmitMilestones"
        (changed)="handleMilestoneChanged()"
      ></app-opportunity-milestone>
    </ng-container>
  </ng-container>
  <div class="opportunity-card">
    <div class="header-title">
      <img src="/assets/svg/invoice.svg" alt="" />
      <span class="blue size16">Invoice</span>
    </div>
    <ul class="milestone-list invoice-list">
      <li class="milestone-list-header">
        <div>Invoice ID</div>
        <div>Invoice Amount</div>
        <div>Invoice Amount (Exclude Tax)</div>
        <div>Countdown</div>
        <div>Invoice Status</div>
        <div>Payment Amount</div>
        <div>Date Invoice Sent</div>
        <div>Payment Received Date</div>
        <div class="expected-due-date-title">
          Expected Collection Due Date
          <app-error-tooltip
            *ngIf="!aclService.isPermission(aclService.features.invoiceEditExpectedDueDate)"
            [message]="'No permission to edit invoice expected due date'"
          ></app-error-tooltip>
        </div>
        <div class="bad-debt-title">
          Bad Debt
          <app-error-tooltip
            *ngIf="!aclService.isPermission(aclService.features.invoiceEditBadDebt)"
            [message]="'No permission to edit invoice bad debt'"
          ></app-error-tooltip>
        </div>
        <div class="note-title">
          Note
          <app-error-tooltip
            *ngIf="!aclService.isPermission(aclService.features.invoiceEditNote)"
            [message]="'No permission to edit invoice note'"
          ></app-error-tooltip>
        </div>
      </li>
      <ul *ngIf="invoices?.length > 0">
        <li
          *ngFor="let item of invoices; index as i"
          appInvoiceHighlight
          [countDown]="item?.countdown"
          [invoiceStatus]="item?.invoiceStatus"
        >
          <div>{{ item?.congaInvoiceNumber | emptyFormat }}</div>
          <div>{{ item?.invoiceAmount | currencyFormat : item?.invoiceCurrency }}</div>
          <div>{{ item?.invoiceAmountExcludeTax | currencyFormat : item?.invoiceCurrency }}</div>
          <app-countdown-format-invoice
            [countDown]="item?.countdown"
            [invoiceStatus]="item?.invoiceStatus"
            [invoiceDueDate]="item?.invoiceDueDate"
          ></app-countdown-format-invoice>
          <div class="invoice-status">
            <span></span>
            {{ item?.invoiceStatus | emptyFormat }}
          </div>
          <div>{{ item?.paymentAmount | currencyFormat : item?.paymentCurrency }}</div>
          <div>{{ item?.invoiceSentDate | date : 'yyyy-MM-dd' | emptyFormat }}</div>
          <div>{{ item?.paymentReceiveDate | date : 'yyyy-MM-dd' | emptyFormat }}</div>
          <app-expected-collection-due-date
            [expectedCollectionDueDate]="item?.expectedCollectionDueDate"
            [dateInvoiceSent]="item?.invoiceSentDate"
            (emitEditExpectedCollectionDueDate)="onChangeExpectedDueDate($event, item?.id, i)"
          ></app-expected-collection-due-date>
          <app-bad-debt [invoice]="item" (badDebt)="updateBadDebt($event)" [opportunityName]="opportunityDetail.name"></app-bad-debt>
          <div>
            <app-note
              [page]="pageName"
              [type]="invoiceNoteType"
              [lookupId]="item.id.toString()"
              [invoiceNote]="true"
              [disableEditNote]="!aclService.isPermission(aclService.features.invoiceEditNote)"
              [opportunityName]="opportunityDetail?.name"
            ></app-note>
          </div>
        </li>
        <li class="invoice-total">
          <div class="invoice-total-header">Total</div>
          <div>{{ invoicesTotalInfo.totalInvoiceAmount | currencyFormat : invoicesTotalInfo.invoiceCurrency }}</div>
          <div>{{ invoicesTotalInfo.totalInvoiceAmountExcludeTax | currencyFormat : invoicesTotalInfo.invoiceCurrency }}</div>
          <div></div>
          <div></div>
          <div>{{ invoicesTotalInfo.totalPaymentAmount | currencyFormat : invoicesTotalInfo.paymentCurrency }}</div>
        </li>
      </ul>
      <div *ngIf="invoices?.length === 0" class="no-data">
        <nz-spin nzSimple *ngIf="invoiceLoading"></nz-spin>
        <span *ngIf="!invoiceLoading">No Data Found</span>
      </div>
    </ul>
  </div>
</div>

<app-detail-data-tips [isDataTipsVisible]="isDataTipsVisible" (closeDataTips)="closeDataTips()"></app-detail-data-tips>

<ng-template #currentToolTips>
  <div class="current-tool-tips">
    <app-as-off-date [date]="opportunityDetail.refreshDate"></app-as-off-date>
    <!--    <div class="current-ar">-->
    <!--      <label>AR</label>-->
    <!--      <span class="amount">{{ opportunityDetail.totalArRevenue | currencyFormat: opportunityDetail.currency }}</span>-->
    <!--    </div>-->
    <!--    <div class="current-wip">-->
    <!--      <label>WIP</label>-->
    <!--      <span class="amount">{{ opportunityDetail.totalWipRevenue | currencyFormat: opportunityDetail.currency }}</span>-->
    <!--    </div>-->
  </div>
</ng-template>

<ng-template #opportunityNameToolTips>
  <div class="opportunity-name-tooltips">
    <span>Redirect to SalesFunnel</span>
  </div>
</ng-template>
