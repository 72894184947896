export const MU_COLOR = [
  {
    mu: 'China North',
    color: '#799C8D',
  },
  {
    mu: 'China South',
    color: '#8ba9c8',
  },
  {
    mu: 'China East',
    color: '#6c93ab',
  },
  {
    mu: 'China Europe',
    color: '#c2a3c0',
  },
  {
    mu: 'China Australia',
    color: '#bbbf90',
  },
  {
    mu: 'China Telecom Digital',
    color: '#b99e73',
  },
  {
    mu: 'China North America',
    color: '#8992b4',
  },
  {
    mu: 'China HK/Macau',
    color: '#a8a1c0',
  },
  {
    mu: 'China SEA',
    color: '#b59090',
  },
];

export const DEFAULT_COLOR_DARK = '#9b9b9b';
