import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';
import * as moment from 'moment';

@Component({
  selector: 'app-info-edit-date-picker',
  templateUrl: './info-edit-date-picker.component.html',
  styleUrls: ['./info-edit-date-picker.component.scss'],
})
export class InfoEditDatePickerComponent {
  @Input() initValue: string;
  @Input() disableEdit: boolean;
  @Output() emitDate = new EventEmitter<string>();
  @ViewChild('datePicker') private datePickerEle: NzDatePickerComponent;
  @ViewChild('infoEditDateButton', { read: ElementRef }) private infoEditDateButton: ElementRef;
  popoverVisible = false;

  onDateChange() {
    this.initValue = moment(this.initValue).format('YYYY-MM-DD');
    this.emitDate.emit(this.initValue);
    this.popoverVisible = false;
    this.infoEditDateButton.nativeElement.blur();
  }

  onClickClear() {
    this.initValue = null;
    this.emitDate.emit(null);
    this.popoverVisible = false;
  }
}
