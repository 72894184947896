import { Injectable } from '@angular/core';
import { InvoiceList } from '../interfaces/Invoice';
import { isNil } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class InvoiceOverduePageService {
  invoiceOverduePage: InvoiceList = {};
  invoiceTotalAmount: number;

  set(page: InvoiceList) {
    this.invoiceOverduePage = page;
  }

  get() {
    return this.invoiceOverduePage;
  }

  setInvoiceTotalAmount(totalAmount: number) {
    this.invoiceTotalAmount = totalAmount;
  }

  getInvoiceTotalAmount() {
    return this.invoiceTotalAmount;
  }

  updateExpectedCollectionDueDateAndCountdownByInvoiceId(id: number, expectedCollectionDueDate: string, countdown: number) {
    if (!isNil(this.invoiceOverduePage.content)) {
      const invoice = this.invoiceOverduePage.content.find((e) => e.id === id);
      if (!isNil(invoice)) {
        invoice.expectedCollectionDueDate = expectedCollectionDueDate;
        invoice.countdown = countdown;
      }
    }
  }
}
