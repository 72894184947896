import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NotificationItem } from '@interfaces/NotificationItem';
import { Subject } from 'rxjs';
import { map as _map, filter, forEach, isEmpty, orderBy, chunk } from 'lodash';
import { WebsocketService } from '@services/websocket.service';
import { DsoSessionMessage } from '@interfaces/DsoSessionMessage';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit, OnDestroy {
  @Input() isNotificationVisible = false;
  @Output() closeNotification = new EventEmitter<any>();
  @Output() emitHasNotification = new EventEmitter<boolean>();
  selectorInfo = {
    pageSize: 7,
    pageIndex: 0,
    pageNumber: 0,
  };
  currentNotificationList: NotificationItem[] = [];
  data: NotificationItem[] = [];
  disableMarkButton = false;
  cloneData: NotificationItem[];
  viewAll = false;
  status: 'ALL' | 'UNREAD' = 'UNREAD';
  private unsubscribe = new Subject();
  private hasUnreadNotifications = false;
  private cloneAllPageIndex = 0;
  private cloneUnreadPageIndex = 0;

  constructor(private service: WebsocketService) {}

  async ngOnInit() {
    await this.service.connect();
    this.updateNotification();
  }

  private updateNotification() {
    return this.service.messages$.subscribe((data: NotificationItem[]) => {
      this.cloneData = data;
      this.switchRadioButton(this.status);
      this.disabledMarkButton();
      this.updateNotificationIcon();
    });
  }

  private disabledMarkButton() {
    this.disableMarkButton = isEmpty(this.findCurrentPageUnreadNotifications());
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.service.close();
  }

  closeDrawer() {
    this.closeNotification.emit();
  }

  markAllRead() {
    if (!this.disableMarkButton) {
      this.saveCurrentPage();
      this.markNotificationReadByApi();
      this.markNotificationItemRead();
      this.switchRadioButton(this.status);
      this.updateNotificationIcon();
    }
  }

  handlePageChange(pageIndex: number): void {
    this.selectorInfo.pageIndex = pageIndex;
    this.setCurrentNotificationList();
    this.disabledMarkButton();
  }

  setCurrentNotificationList(): void {
    const { pageSize, pageIndex } = this.selectorInfo;
    this.selectorInfo.pageNumber = Math.ceil(this.data.length / pageSize);
    const pageData = chunk(this.data || [], pageSize);
    this.currentNotificationList = pageData[pageIndex];
  }

  private saveCurrentPage() {
    if (this.status === 'ALL') {
      this.cloneAllPageIndex = this.selectorInfo.pageIndex;
    } else {
      this.cloneUnreadPageIndex = this.selectorInfo.pageIndex;
    }
  }

  private markNotificationItemRead() {
    forEach(this.findCurrentPageUnreadNotifications(), (item) => (item.read = true));
  }

  private findAllUnreadNotifications() {
    return filter(this.cloneData, (item) => !item.read);
  }

  private findCurrentPageUnreadNotifications() {
    return filter(this.currentNotificationList, (item) => !item.read);
  }

  private markNotificationReadByApi() {
    this.service.sendMessage(new DsoSessionMessage({ type: 'MARK_AS_READ', data: _map(this.findCurrentPageUnreadNotifications(), 'id') }));
  }

  private updateNotificationIcon() {
    this.hasUnreadNotifications = this.findAllUnreadNotifications().length > 0;
    this.emitHasNotification.emit(this.hasUnreadNotifications);
  }

  switchRadioButton(status: 'ALL' | 'UNREAD') {
    const { pageIndex } = this.selectorInfo;
    if (status === 'ALL') {
      this.viewAll = true;
      this.data = orderBy(this.cloneData, ['createdDate', 'id'], ['desc', 'asc']);
      this.cloneUnreadPageIndex = pageIndex;
    } else {
      this.viewAll = false;
      this.data = orderBy(this.findAllUnreadNotifications(), ['createdDate', 'id'], ['desc', 'asc']);
      this.cloneAllPageIndex = pageIndex;
    }

    this.selectorInfo.pageIndex = 0;
    this.keepCurrentPage(status);
    this.setCurrentNotificationList();
  }

  private keepCurrentPage(status: 'ALL' | 'UNREAD') {
    if (status === 'ALL') {
      this.selectorInfo.pageIndex = this.cloneAllPageIndex;
    } else if (this.selectorInfo.pageNumber >= this.cloneUnreadPageIndex) {
      this.selectorInfo.pageIndex = this.cloneUnreadPageIndex;
    }
  }

  onClickItem() {
    this.saveCurrentPage();
    this.updateNotificationIcon();
  }
}
