import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N, zh_CN } from 'ng-zorro-antd/i18n';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, registerLocaleData } from '@angular/common';
import zh from '@angular/common/locales/zh';
import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { FileUploadModule } from 'ng2-file-upload';
import { environment } from '@environments/environment';
import { AuthInterceptor } from '@apis/http-auth-interceptor';
import { HttpResponseInterceptor } from '@apis/http-response-interceptor';
import { OktaCallbackComponent } from '@view/error-page/okta-callback.component';
import { SharedModule } from '@shared/shared.module';
import { DsoViewModule } from '@view/dso-view/dso-view.module';
import { UploadRevenueComponent } from '@view/upload-view/upload-revenue/upload-revenue.component';
import { MatomoModule } from 'ngx-matomo';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NgxEchartsModule } from 'ngx-echarts';
import echarts from '@utils/echart';

registerLocaleData(zh);
const oktaAuth = new OktaAuth(environment.authConfig);

@NgModule({
  declarations: [AppComponent, OktaCallbackComponent, UploadRevenueComponent],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    DsoViewModule,
    CommonModule,
    AppRoutingModule,
    OktaAuthModule,
    FileUploadModule,
    MatomoModule.forRoot({
      scriptUrl: `${environment.matomoConfig.url}matomo.js`,
      trackers: [
        {
          trackerUrl: `${environment.matomoConfig.url}matomo.php`,
          siteId: environment.matomoConfig.id,
        },
      ],
    }),
    NgxEchartsModule.forRoot({ echarts }),
    NzSpinModule,
  ],
  providers: [
    NzMessageService,
    NzNotificationService,
    {
      provide: OKTA_CONFIG,
      useValue: { oktaAuth },
    },
    {
      provide: NZ_I18N,
      useValue: zh_CN,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpResponseInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
